._button_vjswe_1j0zl_7:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-button-radius-TgsrkU, 2px);
  border:1px solid;
  border:1px solid;
  border:var(--border-field-width-3GX4nr, 1px) solid;
  padding:4px 20px;
  padding:4px 20px;
  padding:var(--space-scaled-xxs-wz9S8K, 4px) var(--space-l-1RnAcv, 20px);
  font-weight:700;
  letter-spacing:0.25px;
  display:inline-block;
  text-decoration:none;
  cursor:pointer;
}
._button_vjswe_1j0zl_7._variant-normal_vjswe_1j0zl_27:not(#\9){
  background:#ffffff;
  background:#ffffff;
  background:var(--color-background-button-normal-default-3w9fMz, #ffffff);
  color:#545b64;
  color:#545b64;
  color:var(--color-text-button-normal-default-1oBHNI, #545b64);
  border-color:#545b64;
  border-color:#545b64;
  border-color:var(--color-border-button-normal-default-3zU3ek, #545b64);
  position:relative;
  text-decoration:none;
}
._button_vjswe_1j0zl_7._variant-normal_vjswe_1j0zl_27:not(#\9):hover{
  background:#fafafa;
  background:#fafafa;
  background:var(--color-background-button-normal-hover-1OJlgw, #fafafa);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-button-normal-hover-18qOom, #16191f);
  border-color:#16191f;
  border-color:#16191f;
  border-color:var(--color-border-button-normal-hover-ots1Pn, #16191f);
  text-decoration:none;
}
._button_vjswe_1j0zl_7._variant-normal_vjswe_1j0zl_27:not(#\9):active{
  background:#eaeded;
  background:#eaeded;
  background:var(--color-background-button-normal-active-2WWahd, #eaeded);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-button-normal-active-18qOom, #16191f);
}
._button_vjswe_1j0zl_7._variant-normal_vjswe_1j0zl_27:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._button_vjswe_1j0zl_7._variant-normal_vjswe_1j0zl_27[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._button_vjswe_1j0zl_7._variant-normal_vjswe_1j0zl_27[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(3px - 1px);
}
._button_vjswe_1j0zl_7._variant-normal_vjswe_1j0zl_27[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-3px;
  top:-3px;
  width:calc(100% + 2 * 3px);
  height:calc(100% + 2 * 3px);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._button_vjswe_1j0zl_7._variant-normal_vjswe_1j0zl_27._disabled_vjswe_1j0zl_66:not(#\9){
  background:#ffffff;
  background:#ffffff;
  background:var(--color-background-button-normal-disabled-3w9fMz, #ffffff);
  border-color:#d5dbdb;
  border-color:#d5dbdb;
  border-color:var(--color-border-button-normal-disabled-11nezK, #d5dbdb);
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
  text-decoration:none;
  pointer-events:none;
  cursor:auto;
}
._button_vjswe_1j0zl_7._variant-primary_vjswe_1j0zl_74:not(#\9){
  background:#ec7211;
  background:#ec7211;
  background:var(--color-background-button-primary-default-NYv9af, #ec7211);
  color:#ffffff;
  color:#ffffff;
  color:var(--color-text-button-primary-default-DnITSk, #ffffff);
  border-color:#ec7211;
  border-color:#ec7211;
  border-color:var(--color-background-button-primary-default-NYv9af, #ec7211);
  position:relative;
  text-decoration:none;
}
._button_vjswe_1j0zl_7._variant-primary_vjswe_1j0zl_74:not(#\9):hover{
  background:#eb5f07;
  background:#eb5f07;
  background:var(--color-background-button-primary-hover-39GfvT, #eb5f07);
  color:#ffffff;
  color:#ffffff;
  color:var(--color-text-button-primary-hover-DnITSk, #ffffff);
  border-color:#dd6b10;
  border-color:#dd6b10;
  border-color:var(--color-border-button-primary-hover-2NzUKZ, #dd6b10);
  text-decoration:none;
}
._button_vjswe_1j0zl_7._variant-primary_vjswe_1j0zl_74:not(#\9):active{
  background:#dd6b10;
  background:#dd6b10;
  background:var(--color-background-button-primary-active-1lqtcO, #dd6b10);
  color:#ffffff;
  color:#ffffff;
  color:var(--color-text-button-primary-active-DnITSk, #ffffff);
}
._button_vjswe_1j0zl_7._variant-primary_vjswe_1j0zl_74:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._button_vjswe_1j0zl_7._variant-primary_vjswe_1j0zl_74[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._button_vjswe_1j0zl_7._variant-primary_vjswe_1j0zl_74[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(3px - 1px);
}
._button_vjswe_1j0zl_7._variant-primary_vjswe_1j0zl_74[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-3px;
  top:-3px;
  width:calc(100% + 2 * 3px);
  height:calc(100% + 2 * 3px);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._button_vjswe_1j0zl_7._variant-primary_vjswe_1j0zl_74._disabled_vjswe_1j0zl_66:not(#\9){
  background:#ffffff;
  background:#ffffff;
  background:var(--color-background-button-primary-disabled-3w9fMz, #ffffff);
  border-color:#d5dbdb;
  border-color:#d5dbdb;
  border-color:var(--color-border-button-primary-disabled-11nezK, #d5dbdb);
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
  text-decoration:none;
  pointer-events:none;
  cursor:auto;
}
._button_vjswe_1j0zl_7._variant-link_vjswe_1j0zl_121:not(#\9){
  background:transparent;
  color:#545b64;
  color:#545b64;
  color:var(--color-text-button-normal-default-1oBHNI, #545b64);
  border-color:transparent;
  position:relative;
  text-decoration:none;
}
._button_vjswe_1j0zl_7._variant-link_vjswe_1j0zl_121:not(#\9):hover{
  background:#fafafa;
  background:#fafafa;
  background:var(--color-background-button-link-hover-1OJlgw, #fafafa);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-button-normal-hover-18qOom, #16191f);
  border-color:#fafafa;
  border-color:#fafafa;
  border-color:var(--color-background-button-link-hover-1OJlgw, #fafafa);
  text-decoration:none;
}
._button_vjswe_1j0zl_7._variant-link_vjswe_1j0zl_121:not(#\9):active{
  background:#eaeded;
  background:#eaeded;
  background:var(--color-background-button-link-active-2WWahd, #eaeded);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-button-normal-active-18qOom, #16191f);
}
._button_vjswe_1j0zl_7._variant-link_vjswe_1j0zl_121:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._button_vjswe_1j0zl_7._variant-link_vjswe_1j0zl_121[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._button_vjswe_1j0zl_7._variant-link_vjswe_1j0zl_121[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(3px - 1px);
}
._button_vjswe_1j0zl_7._variant-link_vjswe_1j0zl_121[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-3px;
  top:-3px;
  width:calc(100% + 2 * 3px);
  height:calc(100% + 2 * 3px);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._button_vjswe_1j0zl_7._variant-link_vjswe_1j0zl_121._disabled_vjswe_1j0zl_66:not(#\9){
  background:transparent;
  border-color:transparent;
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
  text-decoration:none;
  pointer-events:none;
  cursor:auto;
}
._button_vjswe_1j0zl_7._variant-icon_vjswe_1j0zl_168:not(#\9){
  background:transparent;
  color:#545b64;
  color:#545b64;
  color:var(--color-text-interactive-default-1oBHNI, #545b64);
  border-color:transparent;
  position:relative;
  text-decoration:none;
}
._button_vjswe_1j0zl_7._variant-icon_vjswe_1j0zl_168:not(#\9):hover{
  background:transparent;
  color:#16191f;
  color:#16191f;
  color:var(--color-text-interactive-hover-18qOom, #16191f);
  border-color:transparent;
  text-decoration:none;
}
._button_vjswe_1j0zl_7._variant-icon_vjswe_1j0zl_168:not(#\9):active{
  background:transparent;
  color:#545b64;
  color:#545b64;
  color:var(--color-text-interactive-default-1oBHNI, #545b64);
}
._button_vjswe_1j0zl_7._variant-icon_vjswe_1j0zl_168:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._button_vjswe_1j0zl_7._variant-icon_vjswe_1j0zl_168[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._button_vjswe_1j0zl_7._variant-icon_vjswe_1j0zl_168[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(3px - 1px);
}
._button_vjswe_1j0zl_7._variant-icon_vjswe_1j0zl_168[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-3px;
  top:-3px;
  width:calc(100% + 2 * 3px);
  height:calc(100% + 2 * 3px);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._button_vjswe_1j0zl_7._variant-icon_vjswe_1j0zl_168._disabled_vjswe_1j0zl_66:not(#\9){
  background:transparent;
  border-color:transparent;
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
  text-decoration:none;
  pointer-events:none;
  cursor:auto;
}
._button_vjswe_1j0zl_7._variant-flashbar-icon_vjswe_1j0zl_215:not(#\9){
  background:transparent;
  color:#d5dbdb;
  color:#d5dbdb;
  color:var(--color-text-interactive-inverted-default-Xkg9ln, #d5dbdb);
  border-color:transparent;
  position:relative;
  text-decoration:none;
}
._button_vjswe_1j0zl_7._variant-flashbar-icon_vjswe_1j0zl_215:not(#\9):hover{
  background:transparent;
  color:#fafafa;
  color:#fafafa;
  color:var(--color-text-interactive-inverted-hover-JWSlel, #fafafa);
  border-color:transparent;
  text-decoration:none;
}
._button_vjswe_1j0zl_7._variant-flashbar-icon_vjswe_1j0zl_215:not(#\9):active{
  background:transparent;
  color:#d5dbdb;
  color:#d5dbdb;
  color:var(--color-text-interactive-inverted-default-Xkg9ln, #d5dbdb);
}
._button_vjswe_1j0zl_7._variant-flashbar-icon_vjswe_1j0zl_215:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._button_vjswe_1j0zl_7._variant-flashbar-icon_vjswe_1j0zl_215[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._button_vjswe_1j0zl_7._variant-flashbar-icon_vjswe_1j0zl_215[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(3px - 1px);
}
._button_vjswe_1j0zl_7._variant-flashbar-icon_vjswe_1j0zl_215[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-3px;
  top:-3px;
  width:calc(100% + 2 * 3px);
  height:calc(100% + 2 * 3px);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._button_vjswe_1j0zl_7._variant-flashbar-icon_vjswe_1j0zl_215._disabled_vjswe_1j0zl_66:not(#\9){
  background:transparent;
  border-color:transparent;
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
  text-decoration:none;
  pointer-events:none;
  cursor:auto;
}
._button_vjswe_1j0zl_7._variant-breadcrumb-group_vjswe_1j0zl_262:not(#\9){
  background:transparent;
  color:#0073bb;
  color:#0073bb;
  color:var(--color-text-link-default-Bc0LvG, #0073bb);
  border-color:transparent;
  border-width:0;
  position:relative;
  text-decoration:none;
  padding:0;
  font-weight:normal;
}
._button_vjswe_1j0zl_7._variant-breadcrumb-group_vjswe_1j0zl_262:not(#\9):hover{
  background:transparent;
  color:#0073bb;
  color:#0073bb;
  color:var(--color-text-link-default-Bc0LvG, #0073bb);
  border-color:transparent;
  text-decoration:none;
}
._button_vjswe_1j0zl_7._variant-breadcrumb-group_vjswe_1j0zl_262:not(#\9):active{
  background:transparent;
  color:#0073bb;
  color:#0073bb;
  color:var(--color-text-link-default-Bc0LvG, #0073bb);
}
._button_vjswe_1j0zl_7._variant-breadcrumb-group_vjswe_1j0zl_262:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._button_vjswe_1j0zl_7._variant-breadcrumb-group_vjswe_1j0zl_262[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._button_vjswe_1j0zl_7._variant-breadcrumb-group_vjswe_1j0zl_262[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(3px - 1px);
}
._button_vjswe_1j0zl_7._variant-breadcrumb-group_vjswe_1j0zl_262[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-3px;
  top:-3px;
  width:calc(100% + 2 * 3px);
  height:calc(100% + 2 * 3px);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._button_vjswe_1j0zl_7._variant-breadcrumb-group_vjswe_1j0zl_262._disabled_vjswe_1j0zl_66:not(#\9){
  background:transparent;
  border-color:transparent;
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
  text-decoration:none;
  pointer-events:none;
  cursor:auto;
}
._button_vjswe_1j0zl_7._button-no-text_vjswe_1j0zl_312:not(#\9){
  padding-left:16px;
  padding-left:16px;
  padding-left:var(--space-m-208vQh, 16px);
  padding-right:16px;
  padding-right:16px;
  padding-right:var(--space-m-208vQh, 16px);
}
._button_vjswe_1j0zl_7._button-no-wrap_vjswe_1j0zl_316:not(#\9){
  white-space:nowrap;
}
._button_vjswe_1j0zl_7._variant-icon_vjswe_1j0zl_168:not(#\9), ._button_vjswe_1j0zl_7._variant-flashbar-icon_vjswe_1j0zl_215:not(#\9){
  padding-left:4px;
  padding-left:4px;
  padding-left:var(--space-xxs-10c0Z4, 4px);
  padding-right:4px;
  padding-right:4px;
  padding-right:var(--space-xxs-10c0Z4, 4px);
}
._button_vjswe_1j0zl_7._variant-inline-icon_vjswe_1j0zl_323:not(#\9){
  padding-top:0;
  padding-bottom:0;
  border:0;
}
._button_vjswe_1j0zl_7 > ._icon-left_vjswe_1j0zl_328:not(#\9){
  position:relative;
  left:calc(-1 * 4px);
  left:calc(-1 * 4px);
  left:calc(-1 * var(--space-xxs-10c0Z4, 4px));
  margin-right:4px;
  margin-right:4px;
  margin-right:var(--space-xxs-10c0Z4, 4px);
}
._button_vjswe_1j0zl_7 > ._icon-right_vjswe_1j0zl_333:not(#\9){
  position:relative;
  right:calc(-1 * 4px);
  right:calc(-1 * 4px);
  right:calc(-1 * var(--space-xxs-10c0Z4, 4px));
  margin-left:4px;
  margin-left:4px;
  margin-left:var(--space-xxs-10c0Z4, 4px);
}
._button_vjswe_1j0zl_7._button-no-text_vjswe_1j0zl_312 > ._icon_vjswe_1j0zl_328:not(#\9){
  margin-right:auto;
  margin-left:auto;
  right:0;
  left:0;
}
:root{
  --color-grey-100-2AKqb7:#fafafa;
  --color-grey-150-3kuzTS:#f2f3f3;
  --color-grey-200-XNKkLQ:#eaeded;
  --color-grey-300-q6XEFF:#d5dbdb;
  --color-grey-400-30uhvb:#aab7b8;
  --color-grey-450-3RXUpq:#95a5a6;
  --color-grey-500-RUsejK:#879596;
  --color-grey-550-1_pyCK:#687078;
  --color-grey-600-1cltHv:#545b64;
  --color-grey-650-3EXt7H:#414750;
  --color-grey-700-2ayya1:#2a2e33;
  --color-grey-750-vMCyzl:#21252c;
  --color-grey-800-1UF29O:#1a2029;
  --color-grey-900-w0NbTF:#16191f;
  --color-grey-950-1aaxHz:#222222;
  --color-grey-transparent-heavy-3Le0m-:rgba(0, 28, 36, 0.5);
  --color-grey-transparent-1Qa-ea:rgba(0, 28, 36, 0.3);
  --color-grey-transparent-light-5LOYx8:rgba(0, 28, 36, 0.15);
  --color-grey-opaque-25-3gH_CO:rgba(255, 255, 255, 0.25);
  --color-grey-opaque-40-TOWdmw:rgba(0, 0, 0, 0.4);
  --color-grey-opaque-50-Y1DpJP:rgba(0, 0, 0, 0.5);
  --color-grey-opaque-70-1KHXJU:rgba(255, 255, 255, 0.7);
  --color-grey-opaque-80-1Db7V9:rgba(22, 25, 31, 0.8);
  --color-grey-opaque-90-2vUzuC:rgba(242, 243, 243, 0.9);
  --color-orange-100-2th-I8:#fef6f0;
  --color-orange-500-2Or5uE:#ec7211;
  --color-orange-600-FMAoZX:#eb5f07;
  --color-orange-700-2D5pfk:#dd6b10;
  --color-blue-100-1UlEIX:#f1faff;
  --color-blue-300-3K_wpm:#99cbe4;
  --color-blue-400-2_UE0j:#44b9d6;
  --color-blue-500-2Y609w:#00a1c9;
  --color-blue-600-3mTfxB:#0073bb;
  --color-blue-700-1Gxewg:#0a4a74;
  --color-blue-900-1NqxOj:#12293b;
  --color-blue-opaque-O9fuAK:rgba(51, 136, 221, 0.5);
  --color-green-100-1FQHEX:#f2f8f0;
  --color-green-500-jl-Bwc:#6aaf35;
  --color-green-600-3iv9Fc:#1d8102;
  --color-green-700-28ULdF:#1a520f;
  --color-green-900-jZfd0l:#172211;
  --color-red-100-1fXiDO:#fdf3f1;
  --color-red-500-c21Nsx:#ff5d64;
  --color-red-600-2N9Epo:#d13212;
  --color-red-700-BBZR7Y:#7c2718;
  --color-red-900-3SXvmY:#270a11;
  --color-black-2XzW-x:#000000;
  --color-white-3kAlam:#ffffff;
  --color-aws-squid-ink-3ksxto:#232f3e;
  --color-amazon-orange-1i3bQi:#ff9900;
  --color-transparent-25e1K_:transparent;
  --color-charts-red-300-iHH87A:#ea7158;
  --color-charts-red-400-2JfTL4:#dc5032;
  --color-charts-red-500-2KGzVl:#d13313;
  --color-charts-red-600-sMhEtb:#ba2e0f;
  --color-charts-red-700-1vSLrI:#a82a0c;
  --color-charts-red-800-2cvTzr:#972709;
  --color-charts-red-900-lyJiKz:#892407;
  --color-charts-red-1000-10wpD-:#7d2105;
  --color-charts-red-1100-2b6xrX:#721e03;
  --color-charts-red-1200-3h9HjA:#671c00;
  --color-charts-orange-300-3Fazlv:#e07941;
  --color-charts-orange-400-3rh-oy:#cc5f21;
  --color-charts-orange-500-29F5cF:#bc4d01;
  --color-charts-orange-600-3j_60e:#a84401;
  --color-charts-orange-700-d-ZF6X:#983c02;
  --color-charts-orange-800-3dMR9p:#8a3603;
  --color-charts-orange-900-3eCLL9:#7e3103;
  --color-charts-orange-1000-1ft3LA:#732c02;
  --color-charts-orange-1100-1rFHIx:#692801;
  --color-charts-orange-1200-haIcdJ:#602400;
  --color-charts-yellow-300-1QSL1Q:#b49116;
  --color-charts-yellow-400-1pmUpr:#9c7b0b;
  --color-charts-yellow-500-Fbq27g:#8a6b05;
  --color-charts-yellow-600-x0XTst:#7b5f04;
  --color-charts-yellow-700-uYNHas:#6f5504;
  --color-charts-yellow-800-2jsiC7:#654d03;
  --color-charts-yellow-900-1leOJ5:#5d4503;
  --color-charts-yellow-1000-G-JOv_:#553f03;
  --color-charts-yellow-1100-38YDD5:#4d3901;
  --color-charts-yellow-1200-ZEWsG4:#483300;
  --color-charts-green-300-Gk-x5D:#67a353;
  --color-charts-green-400-3Gp5UC:#41902c;
  --color-charts-green-500-1UIHe-:#1f8104;
  --color-charts-green-600-11aNBs:#1a7302;
  --color-charts-green-700-ZgWDvM:#176702;
  --color-charts-green-800-2ZMVcX:#145d02;
  --color-charts-green-900-h9AbBY:#125502;
  --color-charts-green-1000-2Obz4Q:#104d01;
  --color-charts-green-1100-X5c9eG:#0f4601;
  --color-charts-green-1200-3NUZfG:#0d4000;
  --color-charts-teal-300-1cN8Cn:#2ea597;
  --color-charts-teal-400-2vraJk:#1c8e81;
  --color-charts-teal-500-9FrMoX:#0d7d70;
  --color-charts-teal-600-2UUnei:#096f64;
  --color-charts-teal-700-NU-4qs:#06645a;
  --color-charts-teal-800-1mmgce:#045b52;
  --color-charts-teal-900-2R6Ae7:#03524a;
  --color-charts-teal-1000-3UqmIz:#014b44;
  --color-charts-teal-1100-1DI6zP:#01443e;
  --color-charts-teal-1200-1e8w30:#003e38;
  --color-charts-blue-1-300-NVZfma:#529ccb;
  --color-charts-blue-1-400-3EeZFw:#3184c2;
  --color-charts-blue-1-500-1l-PV1:#0273bb;
  --color-charts-blue-1-600-G6Tezt:#0166ab;
  --color-charts-blue-1-700-2TnoOg:#015b9d;
  --color-charts-blue-1-800-1eLbUq:#015292;
  --color-charts-blue-1-900-3_JXMA:#014a87;
  --color-charts-blue-1-1000-pMtBAm:#01437d;
  --color-charts-blue-1-1100-1tENEi:#003c75;
  --color-charts-blue-1-1200-1EYZ5e:#00366d;
  --color-charts-blue-2-300-17FRhH:#7492e7;
  --color-charts-blue-2-400-3axVbM:#5978e3;
  --color-charts-blue-2-500-2QbTAr:#4066df;
  --color-charts-blue-2-600-w14n0w:#3759ce;
  --color-charts-blue-2-700-37mL3D:#314fbf;
  --color-charts-blue-2-800-bUsied:#2c46b1;
  --color-charts-blue-2-900-33Y3FX:#273ea5;
  --color-charts-blue-2-1000-rzcdFI:#23379b;
  --color-charts-blue-2-1100-3q7MzB:#1f3191;
  --color-charts-blue-2-1200-2SqsCy:#1b2b88;
  --color-charts-purple-300-La1b0W:#a783e1;
  --color-charts-purple-400-2L2Pop:#9469d6;
  --color-charts-purple-500-2NvZZb:#8456ce;
  --color-charts-purple-600-Zh4UZk:#7749bf;
  --color-charts-purple-700-2hpuWi:#6b40b2;
  --color-charts-purple-800-mt0PPD:#6237a7;
  --color-charts-purple-900-1iuo0e:#59309d;
  --color-charts-purple-1000-1LOfvT:#512994;
  --color-charts-purple-1100-dcmh3E:#4a238b;
  --color-charts-purple-1200-3Bndoz:#431d84;
  --color-charts-pink-300-2XoT6f:#da7596;
  --color-charts-pink-400-1YTbxv:#ce567c;
  --color-charts-pink-500-1ueMSc:#c33d69;
  --color-charts-pink-600-rMNZvg:#b1325c;
  --color-charts-pink-700-2zpGOH:#a32952;
  --color-charts-pink-800--or4cb:#962249;
  --color-charts-pink-900-mI7qx_:#8b1b42;
  --color-charts-pink-1000-3Xhw0C:#81143b;
  --color-charts-pink-1100-8q1611:#780d35;
  --color-charts-pink-1200-2Q8hyB:#6f062f;
  --space-none-2rGI2r:0;
  --space-xxxs-2Fa4lw:2px;
  --space-xxs-10c0Z4:4px;
  --space-xs-3JTBUA:8px;
  --space-s-1Qj4Fv:12px;
  --space-m-208vQh:16px;
  --space-l-1RnAcv:20px;
  --space-xl-3Pai2K:24px;
  --space-xxl-2yP61J:32px;
  --space-xxxl-25ySnK:40px;
  --color-text-body-default-mcmuEw:var(--color-grey-900-w0NbTF);
  --color-text-body-secondary-1oBHNI:var(--color-grey-600-1cltHv);
  --color-text-button-primary-default-DnITSk:var(--color-white-3kAlam);
  --color-text-button-primary-hover-DnITSk:var(--color-white-3kAlam);
  --color-text-button-primary-active-DnITSk:var(--color-white-3kAlam);
  --color-text-button-normal-default-1oBHNI:var(--color-grey-600-1cltHv);
  --color-text-button-normal-hover-18qOom:var(--color-grey-900-w0NbTF);
  --color-text-button-normal-active-18qOom:var(--color-grey-900-w0NbTF);
  --color-text-heading-default-2S6vEC:var(--color-grey-900-w0NbTF);
  --color-text-heading-secondary-1oBHNI:var(--color-grey-600-1cltHv);
  --color-text-home-header-default-pzFO4O:var(--color-white-3kAlam);
  --color-text-home-header-secondary-1OFKGI:var(--color-grey-300-q6XEFF);
  --color-text-label-3qT4rX:var(--color-grey-600-1cltHv);
  --color-text-accent-NYv9af:var(--color-orange-500-2Or5uE);
  --color-text-empty-6_f-gS:var(--color-grey-550-1_pyCK);
  --color-text-link-default-Bc0LvG:var(--color-blue-600-3mTfxB);
  --color-text-interactive-default-1oBHNI:var(--color-grey-600-1cltHv);
  --color-text-interactive-disabled-TVd7uP:var(--color-grey-400-30uhvb);
  --color-text-interactive-active-18qOom:var(--color-grey-900-w0NbTF);
  --color-text-interactive-hover-18qOom:var(--color-grey-900-w0NbTF);
  --color-text-interactive-inverted-default-Xkg9ln:var(--color-grey-300-q6XEFF);
  --color-text-interactive-inverted-hover-JWSlel:var(--color-grey-100-2AKqb7);
  --color-text-small-2ZkiuH:var(--color-grey-550-1_pyCK);
  --color-text-inverted-DnITSk:var(--color-white-3kAlam);
  --color-text-disabled-TVd7uP:var(--color-grey-400-30uhvb);
  --color-text-form-default-mcmuEw:var(--color-grey-900-w0NbTF);
  --color-text-form-secondary-2ZkiuH:var(--color-grey-550-1_pyCK);
  --color-text-form-label-WiQFVx:var(--color-text-form-default-mcmuEw);
  --color-text-input-disabled-3ki3SQ:var(--color-grey-500-RUsejK);
  --color-text-input-placeholder-TVd7uP:var(--color-grey-400-30uhvb);
  --color-text-control-disabled-KGAbe7:var(--color-text-interactive-disabled-TVd7uP);
  --color-text-status-error-1jj2sL:var(--color-red-600-2N9Epo);
  --color-text-status-warning-1jj2sL:var(--color-red-600-2N9Epo);
  --color-text-status-success-CuCgtA:var(--color-green-600-3iv9Fc);
  --color-text-status-info-Bc0LvG:var(--color-blue-600-3mTfxB);
  --color-text-status-inactive-2ZkiuH:var(--color-grey-550-1_pyCK);
  --color-text-breadcrumb-6_f-gS:var(--color-grey-550-1_pyCK);
  --color-text-icon-caret-BxOb2m:var(--color-grey-500-RUsejK);
  --color-text-icon-subtle-22ofsX:var(--color-grey-550-1_pyCK);
  --color-text-column-header-3qT4rX:var(--color-grey-600-1cltHv);
  --color-text-dropdown-item-default-WiQFVx:var(--color-text-form-default-mcmuEw);
  --color-text-dropdown-item-highlighted-2S6vEC:var(--color-grey-900-w0NbTF);
  --color-text-dropdown-item-secondary--r75hR:var(--color-text-form-secondary-2ZkiuH);
  --color-text-dropdown-item-disabled-KGAbe7:var(--color-text-interactive-disabled-TVd7uP);
  --color-text-dropdown-item-filter-match-Bc0LvG:var(--color-blue-600-3mTfxB);
  --color-text-dropdown-group-label-2XOlKp:var(--color-text-label-3qT4rX);
  --color-text-dropdown-footer--r75hR:var(--color-text-form-secondary-2ZkiuH);
  --color-text-notification-default-JWSlel:var(--color-grey-100-2AKqb7);
  --color-text-code-editor-gutter-default-mcmuEw:var(--color-grey-900-w0NbTF);
  --color-text-code-editor-gutter-active-line-DnITSk:var(--color-white-3kAlam);
  --color-text-code-editor-status-bar-disabled-3ki3SQ:var(--color-grey-500-RUsejK);
  --color-text-code-editor-tab-button-error-DnITSk:var(--color-white-3kAlam);
  --color-text-counter-2ZkiuH:var(--color-grey-550-1_pyCK);
  --color-foreground-control-default-3g5IJp:var(--color-white-3kAlam);
  --color-foreground-control-disabled-3d6ker:var(--color-white-3kAlam);
  --color-background-home-header-ScmmEP:var(--color-aws-squid-ink-3ksxto);
  --color-background-layout-main-3Bydyi:var(--color-grey-150-3kuzTS);
  --color-background-layout-panel-content-1Z_Abk:var(--color-background-container-content-3w9fMz);
  --color-background-layout-panel-hover-38loDV:var(--color-grey-200-XNKkLQ);
  --color-background-item-selected-WkeJRF:var(--color-blue-100-1UlEIX);
  --color-background-modal-overlay-3ptHv2:var(--color-grey-opaque-90-2vUzuC);
  --color-background-status-error-3eo6Wg:var(--color-red-100-1fXiDO);
  --color-background-status-warning-3w9fMz:var(--color-white-3kAlam);
  --color-background-status-success-_mCzOj:var(--color-green-100-1FQHEX);
  --color-background-status-info-WkeJRF:var(--color-blue-100-1UlEIX);
  --color-background-progress-bar-layout-default-38loDV:var(--color-grey-200-XNKkLQ);
  --color-background-progress-bar-layout-in-flash-2azuvH:var(--color-grey-opaque-25-3gH_CO);
  --color-background-progress-bar-content-default-3uW6lA:var(--color-blue-600-3mTfxB);
  --color-background-progress-bar-content-in-flash-1gkz93:var(--color-grey-opaque-70-1KHXJU);
  --color-background-calendar-today-3Bydyi:var(--color-grey-150-3kuzTS);
  --color-background-container-header-1OJlgw:var(--color-grey-100-2AKqb7);
  --color-background-container-content-3w9fMz:var(--color-white-3kAlam);
  --color-background-control-default-528DLz:var(--color-white-3kAlam);
  --color-background-control-checked-3uW6lA:var(--color-blue-600-3mTfxB);
  --color-background-control-disabled-11nezK:var(--color-grey-300-q6XEFF);
  --color-background-input-default-528DLz:var(--color-white-3kAlam);
  --color-background-input-disabled-38loDV:var(--color-grey-200-XNKkLQ);
  --color-background-tiles-disabled-1gYBaj:var(--color-grey-200-XNKkLQ);
  --color-background-popover-BPqwPK:var(--color-white-3kAlam);
  --color-background-toggle-default-3zU3ek:var(--color-grey-600-1cltHv);
  --color-background-toggle-checked-disabled-uMVvXl:var(--color-blue-300-3K_wpm);
  --color-background-button-normal-default-3w9fMz:var(--color-white-3kAlam);
  --color-background-button-normal-disabled-3w9fMz:var(--color-white-3kAlam);
  --color-background-button-normal-hover-1OJlgw:var(--color-grey-100-2AKqb7);
  --color-background-button-normal-active-2WWahd:var(--color-grey-200-XNKkLQ);
  --color-background-button-primary-default-NYv9af:var(--color-orange-500-2Or5uE);
  --color-background-button-primary-disabled-3w9fMz:var(--color-white-3kAlam);
  --color-background-button-primary-hover-39GfvT:var(--color-orange-600-FMAoZX);
  --color-background-button-primary-active-1lqtcO:var(--color-orange-700-2D5pfk);
  --color-background-button-link-hover-1OJlgw:var(--color-grey-100-2AKqb7);
  --color-background-button-link-active-2WWahd:var(--color-grey-200-XNKkLQ);
  --color-background-dropdown-item-default-3w9fMz:var(--color-white-3kAlam);
  --color-background-dropdown-item-selected-nrt43a:var(--color-background-item-selected-WkeJRF);
  --color-background-dropdown-item-hover-35tDd2:var(--color-grey-150-3kuzTS);
  --color-background-dropdown-item-filter-match-WkeJRF:var(--color-blue-100-1UlEIX);
  --color-background-notification-grey-3n2Isj:var(--color-grey-600-1cltHv);
  --color-background-notification-green-1ceXEb:var(--color-green-600-3iv9Fc);
  --color-background-notification-blue-Y4F8hz:var(--color-blue-600-3mTfxB);
  --color-background-notification-red-3bUMsg:var(--color-red-600-2N9Epo);
  --color-background-code-editor-gutter-default-ikVlHL:var(--color-grey-150-3kuzTS);
  --color-background-code-editor-gutter-active-line-default-3mUVYZ:var(--color-grey-550-1_pyCK);
  --color-background-code-editor-gutter-active-line-error-1jj2sL:var(--color-red-600-2N9Epo);
  --color-background-code-editor-status-bar-ikVlHL:var(--color-grey-150-3kuzTS);
  --color-background-code-editor-pane-item-hover-38loDV:var(--color-grey-200-XNKkLQ);
  --color-border-item-focused-2uP71z:var(--color-blue-500-2Y609w);
  --color-border-item-selected-6FRHtw:var(--color-blue-500-2Y609w);
  --color-border-status-success-1ceXEb:var(--color-green-600-3iv9Fc);
  --color-border-status-error-3bUMsg:var(--color-red-600-2N9Epo);
  --color-border-status-warning-27WVST:var(--color-grey-400-30uhvb);
  --color-border-status-info-3uW6lA:var(--color-blue-600-3mTfxB);
  --color-border-container-top-1gYBaj:var(--color-grey-200-XNKkLQ);
  --color-border-control-default-27WVST:var(--color-grey-400-30uhvb);
  --color-border-control-checked-3JzNc6:var(--color-background-control-checked-3uW6lA);
  --color-border-control-disabled-Rx1zFC:var(--color-background-control-disabled-11nezK);
  --color-border-input-default-27WVST:var(--color-grey-400-30uhvb);
  --color-border-input-disabled-1QUbGm:var(--color-background-input-disabled-38loDV);
  --color-border-segment-default-3mUVYZ:var(--color-grey-550-1_pyCK);
  --color-border-segment-hover-1dD-mx:var(--color-grey-900-w0NbTF);
  --color-border-tiles-disabled-1D4yG6:var(--color-transparent-25e1K_);
  --color-border-tabs-2fNNxL:var(--color-grey-400-30uhvb);
  --color-border-popover-hSim3z:var(--color-grey-300-q6XEFF);
  --color-border-button-normal-default-3zU3ek:var(--color-grey-600-1cltHv);
  --color-border-button-normal-hover-ots1Pn:var(--color-grey-900-w0NbTF);
  --color-border-button-normal-disabled-11nezK:var(--color-grey-300-q6XEFF);
  --color-border-button-primary-hover-2NzUKZ:var(--color-orange-700-2D5pfk);
  --color-border-button-primary-disabled-11nezK:var(--color-grey-300-q6XEFF);
  --color-border-dropdown-item-default-2jqiTh:var(--color-border-divider-default-38loDV);
  --color-border-dropdown-item-selected-1BqG1q:var(--color-border-item-selected-6FRHtw);
  --color-border-dropdown-item-hover-3Saafg:var(--color-grey-500-RUsejK);
  --color-border-dropdown-item-top-h3yn0h:var(--color-border-container-top-1gYBaj);
  --color-border-dropdown-group-PaVVo1:var(--color-border-dropdown-item-default-2jqiTh);
  --color-border-divider-default-38loDV:var(--color-grey-200-XNKkLQ);
  --color-border-divider-active-3Saafg:var(--color-grey-500-RUsejK);
  --color-border-layout-11nezK:var(--color-grey-300-q6XEFF);
  --color-border-code-editor-default-1MzhzU:var(--color-grey-300-q6XEFF);
  --color-border-code-editor-pane-item-hover-3mUVYZ:var(--color-grey-550-1_pyCK);
  --color-shadow-default-3LpQcN:var(--color-grey-transparent-heavy-3Le0m-);
  --color-shadow-medium-3CtI4L:var(--color-grey-transparent-1Qa-ea);
  --color-shadow-side-2cOaCx:var(--color-grey-transparent-light-5LOYx8);
  --color-charts-status-critical-3OhIPH:var(--color-charts-red-1000-10wpD-);
  --color-charts-status-high-2Kj1VI:var(--color-charts-red-600-sMhEtb);
  --color-charts-status-medium-1G_3c2:var(--color-charts-orange-400-3rh-oy);
  --color-charts-status-low-336vTu:var(--color-charts-yellow-300-1QSL1Q);
  --color-charts-status-positive-2PAhQm:var(--color-charts-green-300-Gk-x5D);
  --color-charts-status-info-3f36yo:var(--color-charts-blue-1-400-3EeZFw);
  --color-charts-status-neutral-3Saafg:var(--color-grey-500-RUsejK);
  --color-charts-threshold-negative-1jj2sL:var(--color-red-600-2N9Epo);
  --color-charts-threshold-positive-CuCgtA:var(--color-green-600-3iv9Fc);
  --color-charts-threshold-info-Bc0LvG:var(--color-blue-600-3mTfxB);
  --color-charts-threshold-neutral-2ZkiuH:var(--color-grey-550-1_pyCK);
  --color-charts-line-grid-11nezK:var(--color-grey-300-q6XEFF);
  --color-charts-line-tick-11nezK:var(--color-grey-300-q6XEFF);
  --color-charts-line-axis-11nezK:var(--color-grey-300-q6XEFF);
  --color-charts-palette-categorical-1-1-pTru:var(--color-charts-blue-2-300-17FRhH);
  --color-charts-palette-categorical-2-34HR6o:var(--color-charts-pink-500-1ueMSc);
  --color-charts-palette-categorical-3-szhk2o:var(--color-charts-teal-300-1cN8Cn);
  --color-charts-palette-categorical-4-1Quw1Y:var(--color-charts-purple-500-2NvZZb);
  --color-charts-palette-categorical-5-3JVo2f:var(--color-charts-orange-300-3Fazlv);
  --color-charts-palette-categorical-6-SU6PW_:var(--color-charts-blue-2-600-w14n0w);
  --color-charts-palette-categorical-7-3IYXwW:var(--color-charts-pink-800--or4cb);
  --color-charts-palette-categorical-8-yHJ2-F:var(--color-charts-teal-600-2UUnei);
  --color-charts-palette-categorical-9-2qUf2R:var(--color-charts-purple-800-mt0PPD);
  --color-charts-palette-categorical-10-12jyfA:var(--color-charts-orange-600-3j_60e);
  --color-charts-palette-categorical-11-2pfiQa:var(--color-charts-blue-2-900-33Y3FX);
  --color-charts-palette-categorical-12-2PcgQF:var(--color-charts-pink-1100-8q1611);
  --color-charts-palette-categorical-13-37MGoo:var(--color-charts-teal-900-2R6Ae7);
  --color-charts-palette-categorical-14-3aISc9:var(--color-charts-purple-1100-dcmh3E);
  --color-charts-palette-categorical-15-2Gp9uo:var(--color-charts-orange-900-3eCLL9);
  --color-charts-palette-categorical-16-28q0ff:var(--color-charts-blue-2-1200-2SqsCy);
  --color-charts-palette-categorical-17-1aB9Zq:var(--color-charts-pink-400-1YTbxv);
  --color-charts-palette-categorical-18-2yMkrj:var(--color-charts-teal-1200-1e8w30);
  --color-charts-palette-categorical-19-1NJbV1:var(--color-charts-purple-400-2L2Pop);
  --color-charts-palette-categorical-20-1wLgQL:var(--color-charts-orange-1200-haIcdJ);
  --color-charts-palette-categorical-21-1FqHMb:var(--color-charts-blue-2-500-2QbTAr);
  --color-charts-palette-categorical-22-pC2Dnu:var(--color-charts-pink-700-2zpGOH);
  --color-charts-palette-categorical-23-26JiEH:var(--color-charts-teal-500-9FrMoX);
  --color-charts-palette-categorical-24-2WNr7z:var(--color-charts-purple-700-2hpuWi);
  --color-charts-palette-categorical-25-1bNnKJ:var(--color-charts-orange-500-29F5cF);
  --color-charts-palette-categorical-26-1eblPy:var(--color-charts-blue-2-800-bUsied);
  --color-charts-palette-categorical-27-1cCzE_:var(--color-charts-pink-1000-3Xhw0C);
  --color-charts-palette-categorical-28-3XHS8e:var(--color-charts-teal-800-1mmgce);
  --color-charts-palette-categorical-29-36AEib:var(--color-charts-purple-1000-1LOfvT);
  --color-charts-palette-categorical-30-1f8RyN:var(--color-charts-orange-800-3dMR9p);
  --color-charts-palette-categorical-31-3p8fai:var(--color-charts-blue-2-1100-3q7MzB);
  --color-charts-palette-categorical-32-2jN07T:var(--color-charts-pink-300-2XoT6f);
  --color-charts-palette-categorical-33-2iB0R0:var(--color-charts-teal-1100-1DI6zP);
  --color-charts-palette-categorical-34-3fOTRf:var(--color-charts-purple-300-La1b0W);
  --color-charts-palette-categorical-35-m3BG6Z:var(--color-charts-orange-1100-1rFHIx);
  --color-charts-palette-categorical-36-8yefO3:var(--color-charts-blue-2-400-3axVbM);
  --color-charts-palette-categorical-37-2K_jwO:var(--color-charts-pink-600-rMNZvg);
  --color-charts-palette-categorical-38-1PQZLc:var(--color-charts-teal-400-2vraJk);
  --color-charts-palette-categorical-39-1agnOi:var(--color-charts-purple-600-Zh4UZk);
  --color-charts-palette-categorical-40-1id8iv:var(--color-charts-orange-400-3rh-oy);
  --color-charts-palette-categorical-41-1E5KJb:var(--color-charts-blue-2-700-37mL3D);
  --color-charts-palette-categorical-42-JzwWC7:var(--color-charts-pink-900-mI7qx_);
  --color-charts-palette-categorical-43-194bmz:var(--color-charts-teal-700-NU-4qs);
  --color-charts-palette-categorical-44-1AQ6-c:var(--color-charts-purple-900-1iuo0e);
  --color-charts-palette-categorical-45-3YZvZb:var(--color-charts-orange-700-d-ZF6X);
  --color-charts-palette-categorical-46-2WkUD0:var(--color-charts-blue-2-1000-rzcdFI);
  --color-charts-palette-categorical-47-PPA-r9:var(--color-charts-pink-1200-2Q8hyB);
  --color-charts-palette-categorical-48-1VCkkT:var(--color-charts-teal-1000-3UqmIz);
  --color-charts-palette-categorical-49-2F1j6B:var(--color-charts-purple-1200-3Bndoz);
  --color-charts-palette-categorical-50-2V2z5L:var(--color-charts-orange-1000-1ft3LA);
  --size-icon-normal-x2Xc-V:16px;
  --size-icon-big-Z5s8Q6:32px;
  --size-icon-large-RBwaJq:48px;
  --size-control-1N7V2o:14px;
  --font-fontFamily-base-1sydl0:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  --font-fontFamily-monospace-1bAn4w:Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;
  --font-body-s-size-3NP7Rr:12px;
  --font-body-s-lineHeight-x2Xc-V:16px;
  --font-body-m-size-1N7V2o:14px;
  --font-body-m-lineHeight-2Vn4pu:22px;
  --font-display-l-size-3ybwom:44px;
  --font-display-l-lineHeight-2EHwYK:56px;
  --font-heading-xl-weight-3o4GPz:400;
  --font-heading-xl-size-2ac18Z:28px;
  --font-heading-xl-lineHeight-3i5g66:36px;
  --font-heading-l-weight-2stUXL:700;
  --font-heading-l-size-3IMO0y:18px;
  --font-heading-l-lineHeight-2Vn4pu:22px;
  --font-heading-m-weight-3o4GPz:400;
  --font-heading-m-size-3IMO0y:18px;
  --font-heading-m-lineHeight-2Vn4pu:22px;
  --font-heading-s-weight-2stUXL:700;
  --font-heading-s-size-x2Xc-V:16px;
  --font-heading-s-lineHeight-8Y2p4W:20px;
  --font-heading-xs-size-x2Xc-V:16px;
  --font-heading-xs-lineHeight-8Y2p4W:20px;
  --border-button-radius-TgsrkU:2px;
  --border-button-width-3GX4nr:1px;
  --border-container-radius-Z3aihh:0px;
  --border-field-radius-TgsrkU:2px;
  --border-field-width-3GX4nr:1px;
  --border-invalid-width-SMAYHq:4px;
  --border-item-radius-Z3aihh:0px;
  --border-item-width-3GX4nr:1px;
  --border-dropdown-radius-Z3aihh:0px;
  --border-calendar-grid-radius-Z3aihh:0px;
  --border-badge-radius-x2Xc-V:16px;
  --border-active-radius-Z3aihh:0px;
  --border-active-width-TgsrkU:2px;
  --border-divider-list-width-3GX4nr:1px;
  --border-divider-section-width-3GX4nr:1px;
  --border-line-chart-width-TgsrkU:2px;
  --border-bar-chart-radius-Z3aihh:0px;
  --motion-duration-extra-fast-1YDA0Q:45ms;
  --motion-duration-fast-oPeLPK:90ms;
  --motion-duration-moderate-3V3IbE:135ms;
  --motion-duration-slow-39Jq2G:180ms;
  --motion-duration-extra-slow-39YF13:270ms;
  --motion-duration-show-quick-TMbCmo:var(--motion-duration-moderate-3V3IbE);
  --motion-duration-show-paced-2crOWV:var(--motion-duration-slow-39Jq2G);
  --motion-duration-rotate-90-TMbCmo:var(--motion-duration-moderate-3V3IbE);
  --motion-duration-rotate-180-TMbCmo:var(--motion-duration-moderate-3V3IbE);
  --motion-duration-transition-show-quick-2hvSaJ:var(--motion-duration-fast-oPeLPK);
  --motion-duration-transition-show-paced-2crOWV:var(--motion-duration-slow-39Jq2G);
  --motion-duration-transition-quick-2hvSaJ:var(--motion-duration-fast-oPeLPK);
  --motion-easing-ease-out-quart-cJfwJd:cubic-bezier(0.165, 0.84, 0.44, 1);
  --motion-easing-show-quick-25z_bd:ease-out;
  --motion-easing-show-paced-25z_bd:ease-out;
  --motion-easing-rotate-90-2ebTCo:var(--motion-easing-ease-out-quart-cJfwJd);
  --motion-easing-rotate-180-2ebTCo:var(--motion-easing-ease-out-quart-cJfwJd);
  --motion-easing-transition-show-quick-2nchNs:linear;
  --motion-easing-transition-show-paced-25z_bd:ease-out;
  --motion-easing-transition-quick-2nchNs:linear;
  --space-scaled-none-1ld6ZS:var(--space-none-2rGI2r);
  --space-scaled-xxxs-1yX_gQ:var(--space-xxxs-2Fa4lw);
  --space-scaled-xxs-wz9S8K:var(--space-xxs-10c0Z4);
  --space-scaled-xs-19adjU:var(--space-xs-3JTBUA);
  --space-scaled-s-1sAHT4:var(--space-s-1Qj4Fv);
  --space-scaled-m-p3tAne:var(--space-m-208vQh);
  --space-scaled-l-17GFGI:var(--space-l-1RnAcv);
  --space-scaled-xl-axUbkA:var(--space-xl-3Pai2K);
  --space-scaled-xxl-238u6Z:var(--space-xxl-2yP61J);
  --space-scaled-xxxl-3VX7k3:var(--space-xxxl-25ySnK);
  --space-scaled-2x-none-1ld6ZS:var(--space-none-2rGI2r);
  --space-scaled-2x-xxxs-1yX_gQ:var(--space-xxxs-2Fa4lw);
  --space-scaled-2x-xxs-wz9S8K:var(--space-xxs-10c0Z4);
  --space-scaled-2x-xs-19adjU:var(--space-xs-3JTBUA);
  --space-scaled-2x-s-1sAHT4:var(--space-s-1Qj4Fv);
  --space-scaled-2x-m-p3tAne:var(--space-m-208vQh);
  --space-scaled-2x-l-17GFGI:var(--space-l-1RnAcv);
  --space-scaled-2x-xl-axUbkA:var(--space-xl-3Pai2K);
  --space-scaled-2x-xxl-238u6Z:var(--space-xxl-2yP61J);
  --space-scaled-2x-xxxl-3VX7k3:var(--space-xxxl-25ySnK);
  --space-grid-gutter-17GFGI:var(--space-l-1RnAcv);
  --size-vertical-input-2yP61J:32px;
  --size-vertical-panel-icon-offset-_sbW5y:15px;
}

.awsui-polaris-dark-mode:not(#\9){
  --color-grey-transparent-heavy-3Le0m-:rgba(0, 0, 0, 0.5);
  --color-grey-transparent-1Qa-ea:rgba(0, 0, 0, 0.3);
  --color-grey-transparent-light-5LOYx8:rgba(0, 0, 0, 0.3);
  --color-charts-red-300-iHH87A:#d63f38;
  --color-charts-red-400-2JfTL4:#ed5958;
  --color-charts-red-500-2KGzVl:#fe6e73;
  --color-charts-red-600-sMhEtb:#ff8a8a;
  --color-charts-red-700-1vSLrI:#ffa09e;
  --color-charts-red-800-2cvTzr:#ffb3b0;
  --color-charts-red-900-lyJiKz:#ffc4c0;
  --color-charts-red-1000-10wpD-:#ffd2cf;
  --color-charts-red-1100-2b6xrX:#ffe0dd;
  --color-charts-red-1200-3h9HjA:#ffecea;
  --color-charts-orange-300-3Fazlv:#c55305;
  --color-charts-orange-400-3rh-oy:#de6923;
  --color-charts-orange-500-29F5cF:#f27c36;
  --color-charts-orange-600-3j_60e:#f89256;
  --color-charts-orange-700-d-ZF6X:#fca572;
  --color-charts-orange-800-3dMR9p:#ffb68b;
  --color-charts-orange-900-3eCLL9:#ffc6a4;
  --color-charts-orange-1000-1ft3LA:#ffd4bb;
  --color-charts-orange-1100-1rFHIx:#ffe1cf;
  --color-charts-orange-1200-haIcdJ:#ffede2;
  --color-charts-yellow-300-1QSL1Q:#977001;
  --color-charts-yellow-400-1pmUpr:#b08400;
  --color-charts-yellow-500-Fbq27g:#c59600;
  --color-charts-yellow-600-x0XTst:#d3a61c;
  --color-charts-yellow-700-uYNHas:#dfb52c;
  --color-charts-yellow-800-2jsiC7:#eac33a;
  --color-charts-yellow-900-1leOJ5:#f1cf65;
  --color-charts-yellow-1000-G-JOv_:#f7db8a;
  --color-charts-yellow-1100-38YDD5:#fce5a8;
  --color-charts-yellow-1200-ZEWsG4:#ffefc9;
  --color-charts-green-300-Gk-x5D:#48851a;
  --color-charts-green-400-3Gp5UC:#5a9b29;
  --color-charts-green-500-1UIHe-:#69ae34;
  --color-charts-green-600-11aNBs:#7dbd4c;
  --color-charts-green-700-ZgWDvM:#8fca61;
  --color-charts-green-800-2ZMVcX:#9fd673;
  --color-charts-green-900-h9AbBY:#b2df8d;
  --color-charts-green-1000-2Obz4Q:#c5e7a8;
  --color-charts-green-1100-X5c9eG:#d5efbe;
  --color-charts-green-1200-3NUZfG:#e4f7d5;
  --color-charts-teal-300-1cN8Cn:#018574;
  --color-charts-teal-400-2vraJk:#009d89;
  --color-charts-teal-500-9FrMoX:#00b09b;
  --color-charts-teal-600-2UUnei:#40bfa9;
  --color-charts-teal-700-NU-4qs:#5fccb7;
  --color-charts-teal-800-1mmgce:#77d7c3;
  --color-charts-teal-900-2R6Ae7:#94e0d0;
  --color-charts-teal-1000-3UqmIz:#ace9db;
  --color-charts-teal-1100-1DI6zP:#c2f0e6;
  --color-charts-teal-1200-1e8w30:#d7f7f0;
  --color-charts-blue-1-300-NVZfma:#00819c;
  --color-charts-blue-1-400-3EeZFw:#0497ba;
  --color-charts-blue-1-500-1l-PV1:#08aad2;
  --color-charts-blue-1-600-G6Tezt:#44b9dd;
  --color-charts-blue-1-700-2TnoOg:#63c6e7;
  --color-charts-blue-1-800-1eLbUq:#79d2f0;
  --color-charts-blue-1-900-3_JXMA:#98dcf5;
  --color-charts-blue-1-1000-pMtBAm:#b3e4f8;
  --color-charts-blue-1-1100-1tENEi:#caedfc;
  --color-charts-blue-1-1200-1EYZ5e:#ddf4ff;
  --color-charts-blue-2-300-17FRhH:#486de8;
  --color-charts-blue-2-400-3axVbM:#6384f5;
  --color-charts-blue-2-500-2QbTAr:#7698fe;
  --color-charts-blue-2-600-w14n0w:#8ea9ff;
  --color-charts-blue-2-700-37mL3D:#a2b8ff;
  --color-charts-blue-2-800-bUsied:#b1c5ff;
  --color-charts-blue-2-900-33Y3FX:#c3d1ff;
  --color-charts-blue-2-1000-rzcdFI:#d2dcff;
  --color-charts-blue-2-1100-3q7MzB:#dfe6ff;
  --color-charts-blue-2-1200-2SqsCy:#ecf0ff;
  --color-charts-purple-300-La1b0W:#8d59de;
  --color-charts-purple-400-2L2Pop:#a173ea;
  --color-charts-purple-500-2NvZZb:#b088f5;
  --color-charts-purple-600-Zh4UZk:#bf9bf9;
  --color-charts-purple-700-2hpuWi:#cbabfc;
  --color-charts-purple-800-mt0PPD:#d6baff;
  --color-charts-purple-900-1iuo0e:#dfc8ff;
  --color-charts-purple-1000-1LOfvT:#e8d5ff;
  --color-charts-purple-1100-dcmh3E:#efe2ff;
  --color-charts-purple-1200-3Bndoz:#f5edff;
  --color-charts-pink-300-2XoT6f:#c64a70;
  --color-charts-pink-400-1YTbxv:#d56889;
  --color-charts-pink-500-1ueMSc:#e07f9d;
  --color-charts-pink-600-rMNZvg:#eb92ad;
  --color-charts-pink-700-2zpGOH:#f5a2bb;
  --color-charts-pink-800--or4cb:#ffb0c8;
  --color-charts-pink-900-mI7qx_:#ffc1d4;
  --color-charts-pink-1000-3Xhw0C:#ffd1de;
  --color-charts-pink-1100-8q1611:#ffdfe8;
  --color-charts-pink-1200-2Q8hyB:#ffecf1;
  --color-text-body-default-mcmuEw:var(--color-grey-300-q6XEFF);
  --color-text-body-secondary-1oBHNI:var(--color-grey-300-q6XEFF);
  --color-text-button-primary-default-DnITSk:var(--color-grey-900-w0NbTF);
  --color-text-button-primary-hover-DnITSk:var(--color-grey-900-w0NbTF);
  --color-text-button-primary-active-DnITSk:var(--color-grey-900-w0NbTF);
  --color-text-button-normal-default-1oBHNI:var(--color-grey-300-q6XEFF);
  --color-text-button-normal-hover-18qOom:var(--color-grey-100-2AKqb7);
  --color-text-button-normal-active-18qOom:var(--color-grey-100-2AKqb7);
  --color-text-heading-default-2S6vEC:var(--color-grey-200-XNKkLQ);
  --color-text-heading-secondary-1oBHNI:var(--color-grey-300-q6XEFF);
  --color-text-home-header-default-pzFO4O:var(--color-grey-200-XNKkLQ);
  --color-text-home-header-secondary-1OFKGI:var(--color-grey-300-q6XEFF);
  --color-text-label-3qT4rX:var(--color-grey-450-3RXUpq);
  --color-text-accent-NYv9af:var(--color-orange-500-2Or5uE);
  --color-text-empty-6_f-gS:var(--color-grey-300-q6XEFF);
  --color-text-link-default-Bc0LvG:var(--color-blue-400-2_UE0j);
  --color-text-interactive-default-1oBHNI:var(--color-grey-300-q6XEFF);
  --color-text-interactive-disabled-TVd7uP:var(--color-grey-550-1_pyCK);
  --color-text-interactive-active-18qOom:var(--color-grey-100-2AKqb7);
  --color-text-interactive-hover-18qOom:var(--color-grey-100-2AKqb7);
  --color-text-interactive-inverted-default-Xkg9ln:var(--color-grey-300-q6XEFF);
  --color-text-interactive-inverted-hover-JWSlel:var(--color-grey-100-2AKqb7);
  --color-text-small-2ZkiuH:var(--color-grey-450-3RXUpq);
  --color-text-inverted-DnITSk:var(--color-grey-900-w0NbTF);
  --color-text-disabled-TVd7uP:var(--color-grey-550-1_pyCK);
  --color-text-form-default-mcmuEw:var(--color-grey-300-q6XEFF);
  --color-text-form-secondary-2ZkiuH:var(--color-grey-450-3RXUpq);
  --color-text-form-label-WiQFVx:var(--color-text-form-default-mcmuEw);
  --color-text-input-disabled-3ki3SQ:var(--color-grey-550-1_pyCK);
  --color-text-input-placeholder-TVd7uP:var(--color-grey-550-1_pyCK);
  --color-text-control-disabled-KGAbe7:var(--color-text-interactive-disabled-TVd7uP);
  --color-text-status-error-1jj2sL:var(--color-red-500-c21Nsx);
  --color-text-status-warning-1jj2sL:var(--color-red-500-c21Nsx);
  --color-text-status-success-CuCgtA:var(--color-green-500-jl-Bwc);
  --color-text-status-info-Bc0LvG:var(--color-blue-400-2_UE0j);
  --color-text-status-inactive-2ZkiuH:var(--color-grey-450-3RXUpq);
  --color-text-breadcrumb-6_f-gS:var(--color-grey-300-q6XEFF);
  --color-text-icon-caret-BxOb2m:var(--color-grey-450-3RXUpq);
  --color-text-icon-subtle-22ofsX:var(--color-grey-400-30uhvb);
  --color-text-column-header-3qT4rX:var(--color-grey-450-3RXUpq);
  --color-text-dropdown-item-default-WiQFVx:var(--color-text-form-default-mcmuEw);
  --color-text-dropdown-item-highlighted-2S6vEC:var(--color-grey-200-XNKkLQ);
  --color-text-dropdown-item-secondary--r75hR:var(--color-text-form-secondary-2ZkiuH);
  --color-text-dropdown-item-disabled-KGAbe7:var(--color-text-interactive-disabled-TVd7uP);
  --color-text-dropdown-item-filter-match-Bc0LvG:var(--color-blue-400-2_UE0j);
  --color-text-dropdown-group-label-2XOlKp:var(--color-text-label-3qT4rX);
  --color-text-dropdown-footer--r75hR:var(--color-text-form-secondary-2ZkiuH);
  --color-text-notification-default-JWSlel:var(--color-grey-100-2AKqb7);
  --color-text-code-editor-gutter-default-mcmuEw:var(--color-grey-300-q6XEFF);
  --color-text-code-editor-gutter-active-line-DnITSk:var(--color-grey-900-w0NbTF);
  --color-text-code-editor-status-bar-disabled-3ki3SQ:var(--color-grey-550-1_pyCK);
  --color-text-code-editor-tab-button-error-DnITSk:var(--color-grey-900-w0NbTF);
  --color-text-counter-2ZkiuH:var(--color-grey-450-3RXUpq);
  --color-foreground-control-default-3g5IJp:var(--color-white-3kAlam);
  --color-foreground-control-disabled-3d6ker:var(--color-grey-550-1_pyCK);
  --color-background-home-header-ScmmEP:var(--color-black-2XzW-x);
  --color-background-layout-main-3Bydyi:var(--color-grey-900-w0NbTF);
  --color-background-layout-panel-content-1Z_Abk:var(--color-background-container-content-3w9fMz);
  --color-background-layout-panel-hover-38loDV:var(--color-grey-650-3EXt7H);
  --color-background-item-selected-WkeJRF:var(--color-blue-900-1NqxOj);
  --color-background-modal-overlay-3ptHv2:var(--color-grey-opaque-80-1Db7V9);
  --color-background-status-error-3eo6Wg:var(--color-red-900-3SXvmY);
  --color-background-status-warning-3w9fMz:var(--color-grey-700-2ayya1);
  --color-background-status-success-_mCzOj:var(--color-green-900-jZfd0l);
  --color-background-status-info-WkeJRF:var(--color-blue-900-1NqxOj);
  --color-background-progress-bar-layout-default-38loDV:var(--color-grey-650-3EXt7H);
  --color-background-progress-bar-layout-in-flash-2azuvH:var(--color-grey-opaque-25-3gH_CO);
  --color-background-progress-bar-content-default-3uW6lA:var(--color-blue-500-2Y609w);
  --color-background-progress-bar-content-in-flash-1gkz93:var(--color-grey-100-2AKqb7);
  --color-background-calendar-today-3Bydyi:var(--color-grey-900-w0NbTF);
  --color-background-container-header-1OJlgw:var(--color-grey-750-vMCyzl);
  --color-background-container-content-3w9fMz:var(--color-grey-700-2ayya1);
  --color-background-control-default-528DLz:var(--color-grey-800-1UF29O);
  --color-background-control-checked-3uW6lA:var(--color-blue-500-2Y609w);
  --color-background-control-disabled-11nezK:var(--color-grey-650-3EXt7H);
  --color-background-input-default-528DLz:var(--color-grey-800-1UF29O);
  --color-background-input-disabled-38loDV:var(--color-grey-650-3EXt7H);
  --color-background-tiles-disabled-1gYBaj:var(--color-grey-700-2ayya1);
  --color-background-popover-BPqwPK:var(--color-grey-750-vMCyzl);
  --color-background-toggle-default-3zU3ek:var(--color-grey-500-RUsejK);
  --color-background-toggle-checked-disabled-uMVvXl:var(--color-blue-700-1Gxewg);
  --color-background-button-normal-default-3w9fMz:var(--color-grey-700-2ayya1);
  --color-background-button-normal-disabled-3w9fMz:var(--color-grey-700-2ayya1);
  --color-background-button-normal-hover-1OJlgw:var(--color-grey-750-vMCyzl);
  --color-background-button-normal-active-2WWahd:var(--color-grey-900-w0NbTF);
  --color-background-button-primary-default-NYv9af:var(--color-orange-500-2Or5uE);
  --color-background-button-primary-disabled-3w9fMz:var(--color-grey-700-2ayya1);
  --color-background-button-primary-hover-39GfvT:var(--color-orange-600-FMAoZX);
  --color-background-button-primary-active-1lqtcO:var(--color-orange-500-2Or5uE);
  --color-background-button-link-hover-1OJlgw:var(--color-grey-750-vMCyzl);
  --color-background-button-link-active-2WWahd:var(--color-grey-900-w0NbTF);
  --color-background-dropdown-item-default-3w9fMz:var(--color-grey-700-2ayya1);
  --color-background-dropdown-item-selected-nrt43a:var(--color-background-item-selected-WkeJRF);
  --color-background-dropdown-item-hover-35tDd2:var(--color-grey-650-3EXt7H);
  --color-background-dropdown-item-filter-match-WkeJRF:var(--color-blue-900-1NqxOj);
  --color-background-notification-grey-3n2Isj:var(--color-grey-550-1_pyCK);
  --color-background-notification-green-1ceXEb:var(--color-green-600-3iv9Fc);
  --color-background-notification-blue-Y4F8hz:var(--color-blue-600-3mTfxB);
  --color-background-notification-red-3bUMsg:var(--color-red-600-2N9Epo);
  --color-background-code-editor-gutter-default-ikVlHL:var(--color-grey-750-vMCyzl);
  --color-background-code-editor-gutter-active-line-default-3mUVYZ:var(--color-grey-500-RUsejK);
  --color-background-code-editor-gutter-active-line-error-1jj2sL:var(--color-red-500-c21Nsx);
  --color-background-code-editor-status-bar-ikVlHL:var(--color-grey-750-vMCyzl);
  --color-background-code-editor-pane-item-hover-38loDV:var(--color-grey-650-3EXt7H);
  --color-border-item-focused-2uP71z:var(--color-blue-500-2Y609w);
  --color-border-item-selected-6FRHtw:var(--color-blue-500-2Y609w);
  --color-border-status-success-1ceXEb:var(--color-green-600-3iv9Fc);
  --color-border-status-error-3bUMsg:var(--color-red-600-2N9Epo);
  --color-border-status-warning-27WVST:var(--color-grey-500-RUsejK);
  --color-border-status-info-3uW6lA:var(--color-blue-500-2Y609w);
  --color-border-container-top-1gYBaj:var(--color-grey-700-2ayya1);
  --color-border-control-default-27WVST:var(--color-grey-500-RUsejK);
  --color-border-control-checked-3JzNc6:var(--color-background-control-checked-3uW6lA);
  --color-border-control-disabled-Rx1zFC:var(--color-background-control-disabled-11nezK);
  --color-border-input-default-27WVST:var(--color-grey-500-RUsejK);
  --color-border-input-disabled-1QUbGm:var(--color-background-input-disabled-38loDV);
  --color-border-segment-default-3mUVYZ:var(--color-grey-500-RUsejK);
  --color-border-segment-hover-1dD-mx:var(--color-white-3kAlam);
  --color-border-tiles-disabled-1D4yG6:var(--color-grey-650-3EXt7H);
  --color-border-tabs-2fNNxL:var(--color-grey-650-3EXt7H);
  --color-border-popover-hSim3z:var(--color-grey-600-1cltHv);
  --color-border-button-normal-default-3zU3ek:var(--color-grey-500-RUsejK);
  --color-border-button-normal-hover-ots1Pn:var(--color-grey-400-30uhvb);
  --color-border-button-normal-disabled-11nezK:var(--color-grey-650-3EXt7H);
  --color-border-button-primary-hover-2NzUKZ:var(--color-orange-600-FMAoZX);
  --color-border-button-primary-disabled-11nezK:var(--color-grey-650-3EXt7H);
  --color-border-dropdown-item-default-2jqiTh:var(--color-border-divider-default-38loDV);
  --color-border-dropdown-item-selected-1BqG1q:var(--color-border-item-selected-6FRHtw);
  --color-border-dropdown-item-hover-3Saafg:var(--color-grey-500-RUsejK);
  --color-border-dropdown-item-top-h3yn0h:var(--color-border-container-top-1gYBaj);
  --color-border-dropdown-group-PaVVo1:var(--color-border-dropdown-item-default-2jqiTh);
  --color-border-divider-default-38loDV:var(--color-grey-650-3EXt7H);
  --color-border-divider-active-3Saafg:var(--color-grey-500-RUsejK);
  --color-border-layout-11nezK:var(--color-grey-650-3EXt7H);
  --color-border-code-editor-default-1MzhzU:var(--color-grey-550-1_pyCK);
  --color-border-code-editor-pane-item-hover-3mUVYZ:var(--color-grey-500-RUsejK);
  --color-shadow-default-3LpQcN:var(--color-grey-transparent-heavy-3Le0m-);
  --color-shadow-medium-3CtI4L:var(--color-grey-transparent-1Qa-ea);
  --color-shadow-side-2cOaCx:var(--color-grey-transparent-light-5LOYx8);
  --color-charts-status-critical-3OhIPH:var(--color-charts-red-300-iHH87A);
  --color-charts-status-high-2Kj1VI:var(--color-charts-red-500-2KGzVl);
  --color-charts-status-medium-1G_3c2:var(--color-charts-orange-600-3j_60e);
  --color-charts-status-low-336vTu:var(--color-charts-yellow-700-uYNHas);
  --color-charts-status-positive-2PAhQm:var(--color-charts-green-500-1UIHe-);
  --color-charts-status-info-3f36yo:var(--color-charts-blue-1-500-1l-PV1);
  --color-charts-status-neutral-3Saafg:var(--color-grey-500-RUsejK);
  --color-charts-threshold-negative-1jj2sL:var(--color-red-500-c21Nsx);
  --color-charts-threshold-positive-CuCgtA:var(--color-green-500-jl-Bwc);
  --color-charts-threshold-info-Bc0LvG:var(--color-blue-400-2_UE0j);
  --color-charts-threshold-neutral-2ZkiuH:var(--color-grey-450-3RXUpq);
  --color-charts-line-grid-11nezK:var(--color-grey-650-3EXt7H);
  --color-charts-line-tick-11nezK:var(--color-grey-650-3EXt7H);
  --color-charts-line-axis-11nezK:var(--color-grey-650-3EXt7H);
  --color-charts-palette-categorical-1-1-pTru:var(--color-charts-blue-2-300-17FRhH);
  --color-charts-palette-categorical-2-34HR6o:var(--color-charts-pink-500-1ueMSc);
  --color-charts-palette-categorical-3-szhk2o:var(--color-charts-teal-300-1cN8Cn);
  --color-charts-palette-categorical-4-1Quw1Y:var(--color-charts-purple-500-2NvZZb);
  --color-charts-palette-categorical-5-3JVo2f:var(--color-charts-orange-300-3Fazlv);
  --color-charts-palette-categorical-6-SU6PW_:var(--color-charts-blue-2-600-w14n0w);
  --color-charts-palette-categorical-7-3IYXwW:var(--color-charts-pink-800--or4cb);
  --color-charts-palette-categorical-8-yHJ2-F:var(--color-charts-teal-600-2UUnei);
  --color-charts-palette-categorical-9-2qUf2R:var(--color-charts-purple-800-mt0PPD);
  --color-charts-palette-categorical-10-12jyfA:var(--color-charts-orange-600-3j_60e);
  --color-charts-palette-categorical-11-2pfiQa:var(--color-charts-blue-2-900-33Y3FX);
  --color-charts-palette-categorical-12-2PcgQF:var(--color-charts-pink-1100-8q1611);
  --color-charts-palette-categorical-13-37MGoo:var(--color-charts-teal-900-2R6Ae7);
  --color-charts-palette-categorical-14-3aISc9:var(--color-charts-purple-1100-dcmh3E);
  --color-charts-palette-categorical-15-2Gp9uo:var(--color-charts-orange-900-3eCLL9);
  --color-charts-palette-categorical-16-28q0ff:var(--color-charts-blue-2-1200-2SqsCy);
  --color-charts-palette-categorical-17-1aB9Zq:var(--color-charts-pink-400-1YTbxv);
  --color-charts-palette-categorical-18-2yMkrj:var(--color-charts-teal-1200-1e8w30);
  --color-charts-palette-categorical-19-1NJbV1:var(--color-charts-purple-400-2L2Pop);
  --color-charts-palette-categorical-20-1wLgQL:var(--color-charts-orange-1200-haIcdJ);
  --color-charts-palette-categorical-21-1FqHMb:var(--color-charts-blue-2-500-2QbTAr);
  --color-charts-palette-categorical-22-pC2Dnu:var(--color-charts-pink-700-2zpGOH);
  --color-charts-palette-categorical-23-26JiEH:var(--color-charts-teal-500-9FrMoX);
  --color-charts-palette-categorical-24-2WNr7z:var(--color-charts-purple-700-2hpuWi);
  --color-charts-palette-categorical-25-1bNnKJ:var(--color-charts-orange-500-29F5cF);
  --color-charts-palette-categorical-26-1eblPy:var(--color-charts-blue-2-800-bUsied);
  --color-charts-palette-categorical-27-1cCzE_:var(--color-charts-pink-1000-3Xhw0C);
  --color-charts-palette-categorical-28-3XHS8e:var(--color-charts-teal-800-1mmgce);
  --color-charts-palette-categorical-29-36AEib:var(--color-charts-purple-1000-1LOfvT);
  --color-charts-palette-categorical-30-1f8RyN:var(--color-charts-orange-800-3dMR9p);
  --color-charts-palette-categorical-31-3p8fai:var(--color-charts-blue-2-1100-3q7MzB);
  --color-charts-palette-categorical-32-2jN07T:var(--color-charts-pink-300-2XoT6f);
  --color-charts-palette-categorical-33-2iB0R0:var(--color-charts-teal-1100-1DI6zP);
  --color-charts-palette-categorical-34-3fOTRf:var(--color-charts-purple-300-La1b0W);
  --color-charts-palette-categorical-35-m3BG6Z:var(--color-charts-orange-1100-1rFHIx);
  --color-charts-palette-categorical-36-8yefO3:var(--color-charts-blue-2-400-3axVbM);
  --color-charts-palette-categorical-37-2K_jwO:var(--color-charts-pink-600-rMNZvg);
  --color-charts-palette-categorical-38-1PQZLc:var(--color-charts-teal-400-2vraJk);
  --color-charts-palette-categorical-39-1agnOi:var(--color-charts-purple-600-Zh4UZk);
  --color-charts-palette-categorical-40-1id8iv:var(--color-charts-orange-400-3rh-oy);
  --color-charts-palette-categorical-41-1E5KJb:var(--color-charts-blue-2-700-37mL3D);
  --color-charts-palette-categorical-42-JzwWC7:var(--color-charts-pink-900-mI7qx_);
  --color-charts-palette-categorical-43-194bmz:var(--color-charts-teal-700-NU-4qs);
  --color-charts-palette-categorical-44-1AQ6-c:var(--color-charts-purple-900-1iuo0e);
  --color-charts-palette-categorical-45-3YZvZb:var(--color-charts-orange-700-d-ZF6X);
  --color-charts-palette-categorical-46-2WkUD0:var(--color-charts-blue-2-1000-rzcdFI);
  --color-charts-palette-categorical-47-PPA-r9:var(--color-charts-pink-1200-2Q8hyB);
  --color-charts-palette-categorical-48-1VCkkT:var(--color-charts-teal-1000-3UqmIz);
  --color-charts-palette-categorical-49-2F1j6B:var(--color-charts-purple-1200-3Bndoz);
  --color-charts-palette-categorical-50-2V2z5L:var(--color-charts-orange-1000-1ft3LA);
  --motion-duration-show-quick-TMbCmo:var(--motion-duration-moderate-3V3IbE);
  --motion-duration-show-paced-2crOWV:var(--motion-duration-slow-39Jq2G);
  --motion-duration-rotate-90-TMbCmo:var(--motion-duration-moderate-3V3IbE);
  --motion-duration-rotate-180-TMbCmo:var(--motion-duration-moderate-3V3IbE);
  --motion-duration-transition-show-quick-2hvSaJ:var(--motion-duration-fast-oPeLPK);
  --motion-duration-transition-show-paced-2crOWV:var(--motion-duration-slow-39Jq2G);
  --motion-duration-transition-quick-2hvSaJ:var(--motion-duration-fast-oPeLPK);
  --motion-easing-rotate-90-2ebTCo:var(--motion-easing-ease-out-quart-cJfwJd);
  --motion-easing-rotate-180-2ebTCo:var(--motion-easing-ease-out-quart-cJfwJd);
  --space-scaled-none-1ld6ZS:var(--space-none-2rGI2r);
  --space-scaled-2x-none-1ld6ZS:var(--space-none-2rGI2r);
}

.awsui-visual-refresh:not(#\9){
  --color-text-accent-NYv9af:var(--color-orange-500-2Or5uE);
  --color-text-interactive-inverted-default-Xkg9ln:var(--color-grey-300-q6XEFF);
  --color-text-interactive-inverted-hover-JWSlel:var(--color-grey-100-2AKqb7);
  --color-text-form-label-WiQFVx:var(--color-text-form-default-mcmuEw);
  --color-text-control-disabled-KGAbe7:var(--color-text-interactive-disabled-TVd7uP);
  --color-text-dropdown-item-default-WiQFVx:var(--color-text-form-default-mcmuEw);
  --color-text-dropdown-item-secondary--r75hR:var(--color-text-form-secondary-2ZkiuH);
  --color-text-dropdown-item-disabled-KGAbe7:var(--color-text-interactive-disabled-TVd7uP);
  --color-text-dropdown-group-label-2XOlKp:var(--color-text-label-3qT4rX);
  --color-text-dropdown-footer--r75hR:var(--color-text-form-secondary-2ZkiuH);
  --color-text-notification-default-JWSlel:var(--color-grey-100-2AKqb7);
  --color-foreground-control-default-3g5IJp:var(--color-white-3kAlam);
  --color-background-layout-panel-content-1Z_Abk:var(--color-background-container-content-3w9fMz);
  --color-background-button-primary-default-NYv9af:var(--color-orange-500-2Or5uE);
  --color-background-button-primary-hover-39GfvT:var(--color-orange-600-FMAoZX);
  --color-background-dropdown-item-selected-nrt43a:var(--color-background-item-selected-WkeJRF);
  --color-background-notification-green-1ceXEb:var(--color-green-600-3iv9Fc);
  --color-background-notification-blue-Y4F8hz:var(--color-blue-600-3mTfxB);
  --color-background-notification-red-3bUMsg:var(--color-red-600-2N9Epo);
  --color-border-item-selected-6FRHtw:var(--color-blue-500-2Y609w);
  --color-border-status-success-1ceXEb:var(--color-green-600-3iv9Fc);
  --color-border-status-error-3bUMsg:var(--color-red-600-2N9Epo);
  --color-border-control-checked-3JzNc6:var(--color-background-control-checked-3uW6lA);
  --color-border-control-disabled-Rx1zFC:var(--color-background-control-disabled-11nezK);
  --color-border-input-disabled-1QUbGm:var(--color-background-input-disabled-38loDV);
  --color-border-dropdown-item-default-2jqiTh:var(--color-border-divider-default-38loDV);
  --color-border-dropdown-item-selected-1BqG1q:var(--color-border-item-selected-6FRHtw);
  --color-border-dropdown-item-hover-3Saafg:var(--color-grey-500-RUsejK);
  --color-border-dropdown-item-top-h3yn0h:var(--color-border-container-top-1gYBaj);
  --color-border-dropdown-group-PaVVo1:var(--color-border-dropdown-item-default-2jqiTh);
  --color-border-divider-active-3Saafg:var(--color-grey-500-RUsejK);
  --color-shadow-default-3LpQcN:var(--color-grey-transparent-heavy-3Le0m-);
  --color-shadow-medium-3CtI4L:var(--color-grey-transparent-1Qa-ea);
  --color-shadow-side-2cOaCx:var(--color-grey-transparent-light-5LOYx8);
  --color-charts-status-neutral-3Saafg:var(--color-grey-500-RUsejK);
  --color-charts-palette-categorical-1-1-pTru:var(--color-charts-blue-2-300-17FRhH);
  --color-charts-palette-categorical-2-34HR6o:var(--color-charts-pink-500-1ueMSc);
  --color-charts-palette-categorical-3-szhk2o:var(--color-charts-teal-300-1cN8Cn);
  --color-charts-palette-categorical-4-1Quw1Y:var(--color-charts-purple-500-2NvZZb);
  --color-charts-palette-categorical-5-3JVo2f:var(--color-charts-orange-300-3Fazlv);
  --color-charts-palette-categorical-6-SU6PW_:var(--color-charts-blue-2-600-w14n0w);
  --color-charts-palette-categorical-7-3IYXwW:var(--color-charts-pink-800--or4cb);
  --color-charts-palette-categorical-8-yHJ2-F:var(--color-charts-teal-600-2UUnei);
  --color-charts-palette-categorical-9-2qUf2R:var(--color-charts-purple-800-mt0PPD);
  --color-charts-palette-categorical-10-12jyfA:var(--color-charts-orange-600-3j_60e);
  --color-charts-palette-categorical-11-2pfiQa:var(--color-charts-blue-2-900-33Y3FX);
  --color-charts-palette-categorical-12-2PcgQF:var(--color-charts-pink-1100-8q1611);
  --color-charts-palette-categorical-13-37MGoo:var(--color-charts-teal-900-2R6Ae7);
  --color-charts-palette-categorical-14-3aISc9:var(--color-charts-purple-1100-dcmh3E);
  --color-charts-palette-categorical-15-2Gp9uo:var(--color-charts-orange-900-3eCLL9);
  --color-charts-palette-categorical-16-28q0ff:var(--color-charts-blue-2-1200-2SqsCy);
  --color-charts-palette-categorical-17-1aB9Zq:var(--color-charts-pink-400-1YTbxv);
  --color-charts-palette-categorical-18-2yMkrj:var(--color-charts-teal-1200-1e8w30);
  --color-charts-palette-categorical-19-1NJbV1:var(--color-charts-purple-400-2L2Pop);
  --color-charts-palette-categorical-20-1wLgQL:var(--color-charts-orange-1200-haIcdJ);
  --color-charts-palette-categorical-21-1FqHMb:var(--color-charts-blue-2-500-2QbTAr);
  --color-charts-palette-categorical-22-pC2Dnu:var(--color-charts-pink-700-2zpGOH);
  --color-charts-palette-categorical-23-26JiEH:var(--color-charts-teal-500-9FrMoX);
  --color-charts-palette-categorical-24-2WNr7z:var(--color-charts-purple-700-2hpuWi);
  --color-charts-palette-categorical-25-1bNnKJ:var(--color-charts-orange-500-29F5cF);
  --color-charts-palette-categorical-26-1eblPy:var(--color-charts-blue-2-800-bUsied);
  --color-charts-palette-categorical-27-1cCzE_:var(--color-charts-pink-1000-3Xhw0C);
  --color-charts-palette-categorical-28-3XHS8e:var(--color-charts-teal-800-1mmgce);
  --color-charts-palette-categorical-29-36AEib:var(--color-charts-purple-1000-1LOfvT);
  --color-charts-palette-categorical-30-1f8RyN:var(--color-charts-orange-800-3dMR9p);
  --color-charts-palette-categorical-31-3p8fai:var(--color-charts-blue-2-1100-3q7MzB);
  --color-charts-palette-categorical-32-2jN07T:var(--color-charts-pink-300-2XoT6f);
  --color-charts-palette-categorical-33-2iB0R0:var(--color-charts-teal-1100-1DI6zP);
  --color-charts-palette-categorical-34-3fOTRf:var(--color-charts-purple-300-La1b0W);
  --color-charts-palette-categorical-35-m3BG6Z:var(--color-charts-orange-1100-1rFHIx);
  --color-charts-palette-categorical-36-8yefO3:var(--color-charts-blue-2-400-3axVbM);
  --color-charts-palette-categorical-37-2K_jwO:var(--color-charts-pink-600-rMNZvg);
  --color-charts-palette-categorical-38-1PQZLc:var(--color-charts-teal-400-2vraJk);
  --color-charts-palette-categorical-39-1agnOi:var(--color-charts-purple-600-Zh4UZk);
  --color-charts-palette-categorical-40-1id8iv:var(--color-charts-orange-400-3rh-oy);
  --color-charts-palette-categorical-41-1E5KJb:var(--color-charts-blue-2-700-37mL3D);
  --color-charts-palette-categorical-42-JzwWC7:var(--color-charts-pink-900-mI7qx_);
  --color-charts-palette-categorical-43-194bmz:var(--color-charts-teal-700-NU-4qs);
  --color-charts-palette-categorical-44-1AQ6-c:var(--color-charts-purple-900-1iuo0e);
  --color-charts-palette-categorical-45-3YZvZb:var(--color-charts-orange-700-d-ZF6X);
  --color-charts-palette-categorical-46-2WkUD0:var(--color-charts-blue-2-1000-rzcdFI);
  --color-charts-palette-categorical-47-PPA-r9:var(--color-charts-pink-1200-2Q8hyB);
  --color-charts-palette-categorical-48-1VCkkT:var(--color-charts-teal-1000-3UqmIz);
  --color-charts-palette-categorical-49-2F1j6B:var(--color-charts-purple-1200-3Bndoz);
  --color-charts-palette-categorical-50-2V2z5L:var(--color-charts-orange-1000-1ft3LA);
  --font-display-l-size-3ybwom:64px;
  --font-display-l-lineHeight-2EHwYK:112%;
  --font-heading-xl-weight-3o4GPz:900;
  --font-heading-xl-size-2ac18Z:32px;
  --font-heading-xl-lineHeight-3i5g66:120%;
  --font-heading-l-weight-2stUXL:900;
  --font-heading-l-size-3IMO0y:24px;
  --font-heading-l-lineHeight-2Vn4pu:120%;
  --font-heading-m-weight-3o4GPz:900;
  --font-heading-m-size-3IMO0y:20px;
  --font-heading-m-lineHeight-2Vn4pu:120%;
  --font-heading-s-weight-2stUXL:900;
  --font-heading-s-size-x2Xc-V:16px;
  --font-heading-s-lineHeight-8Y2p4W:120%;
  --border-button-radius-TgsrkU:40px;
  --border-button-width-3GX4nr:2px;
  --border-container-radius-Z3aihh:16px;
  --border-field-radius-TgsrkU:8px;
  --border-field-width-3GX4nr:2px;
  --border-invalid-width-SMAYHq:var(--border-field-width-3GX4nr);
  --border-item-radius-Z3aihh:var(--border-field-radius-TgsrkU);
  --border-item-width-3GX4nr:2px;
  --border-dropdown-radius-Z3aihh:var(--border-button-radius-TgsrkU);
  --border-calendar-grid-radius-Z3aihh:40px;
  --border-badge-radius-x2Xc-V:4px;
  --border-active-radius-Z3aihh:40px;
  --border-active-width-TgsrkU:4px;
  --border-divider-section-width-3GX4nr:2px;
  --border-line-chart-width-TgsrkU:4px;
  --border-bar-chart-radius-Z3aihh:4px;
  --motion-duration-show-quick-TMbCmo:var(--motion-duration-moderate-3V3IbE);
  --motion-duration-show-paced-2crOWV:var(--motion-duration-slow-39Jq2G);
  --motion-duration-rotate-90-TMbCmo:var(--motion-duration-moderate-3V3IbE);
  --motion-duration-rotate-180-TMbCmo:var(--motion-duration-moderate-3V3IbE);
  --motion-duration-transition-show-quick-2hvSaJ:var(--motion-duration-fast-oPeLPK);
  --motion-duration-transition-show-paced-2crOWV:var(--motion-duration-slow-39Jq2G);
  --motion-duration-transition-quick-2hvSaJ:var(--motion-duration-fast-oPeLPK);
  --motion-easing-rotate-90-2ebTCo:var(--motion-easing-ease-out-quart-cJfwJd);
  --motion-easing-rotate-180-2ebTCo:var(--motion-easing-ease-out-quart-cJfwJd);
  --space-scaled-none-1ld6ZS:var(--space-none-2rGI2r);
  --space-scaled-2x-none-1ld6ZS:var(--space-none-2rGI2r);
}

.awsui-polaris-compact-mode:not(#\9){
  --color-text-accent-NYv9af:var(--color-orange-500-2Or5uE);
  --color-text-interactive-inverted-default-Xkg9ln:var(--color-grey-300-q6XEFF);
  --color-text-interactive-inverted-hover-JWSlel:var(--color-grey-100-2AKqb7);
  --color-text-form-label-WiQFVx:var(--color-text-form-default-mcmuEw);
  --color-text-control-disabled-KGAbe7:var(--color-text-interactive-disabled-TVd7uP);
  --color-text-dropdown-item-default-WiQFVx:var(--color-text-form-default-mcmuEw);
  --color-text-dropdown-item-secondary--r75hR:var(--color-text-form-secondary-2ZkiuH);
  --color-text-dropdown-item-disabled-KGAbe7:var(--color-text-interactive-disabled-TVd7uP);
  --color-text-dropdown-group-label-2XOlKp:var(--color-text-label-3qT4rX);
  --color-text-dropdown-footer--r75hR:var(--color-text-form-secondary-2ZkiuH);
  --color-text-notification-default-JWSlel:var(--color-grey-100-2AKqb7);
  --color-foreground-control-default-3g5IJp:var(--color-white-3kAlam);
  --color-background-layout-panel-content-1Z_Abk:var(--color-background-container-content-3w9fMz);
  --color-background-button-primary-default-NYv9af:var(--color-orange-500-2Or5uE);
  --color-background-button-primary-hover-39GfvT:var(--color-orange-600-FMAoZX);
  --color-background-dropdown-item-selected-nrt43a:var(--color-background-item-selected-WkeJRF);
  --color-background-notification-green-1ceXEb:var(--color-green-600-3iv9Fc);
  --color-background-notification-blue-Y4F8hz:var(--color-blue-600-3mTfxB);
  --color-background-notification-red-3bUMsg:var(--color-red-600-2N9Epo);
  --color-border-item-selected-6FRHtw:var(--color-blue-500-2Y609w);
  --color-border-status-success-1ceXEb:var(--color-green-600-3iv9Fc);
  --color-border-status-error-3bUMsg:var(--color-red-600-2N9Epo);
  --color-border-control-checked-3JzNc6:var(--color-background-control-checked-3uW6lA);
  --color-border-control-disabled-Rx1zFC:var(--color-background-control-disabled-11nezK);
  --color-border-input-disabled-1QUbGm:var(--color-background-input-disabled-38loDV);
  --color-border-dropdown-item-default-2jqiTh:var(--color-border-divider-default-38loDV);
  --color-border-dropdown-item-selected-1BqG1q:var(--color-border-item-selected-6FRHtw);
  --color-border-dropdown-item-hover-3Saafg:var(--color-grey-500-RUsejK);
  --color-border-dropdown-item-top-h3yn0h:var(--color-border-container-top-1gYBaj);
  --color-border-dropdown-group-PaVVo1:var(--color-border-dropdown-item-default-2jqiTh);
  --color-border-divider-active-3Saafg:var(--color-grey-500-RUsejK);
  --color-shadow-default-3LpQcN:var(--color-grey-transparent-heavy-3Le0m-);
  --color-shadow-medium-3CtI4L:var(--color-grey-transparent-1Qa-ea);
  --color-shadow-side-2cOaCx:var(--color-grey-transparent-light-5LOYx8);
  --color-charts-status-neutral-3Saafg:var(--color-grey-500-RUsejK);
  --color-charts-palette-categorical-1-1-pTru:var(--color-charts-blue-2-300-17FRhH);
  --color-charts-palette-categorical-2-34HR6o:var(--color-charts-pink-500-1ueMSc);
  --color-charts-palette-categorical-3-szhk2o:var(--color-charts-teal-300-1cN8Cn);
  --color-charts-palette-categorical-4-1Quw1Y:var(--color-charts-purple-500-2NvZZb);
  --color-charts-palette-categorical-5-3JVo2f:var(--color-charts-orange-300-3Fazlv);
  --color-charts-palette-categorical-6-SU6PW_:var(--color-charts-blue-2-600-w14n0w);
  --color-charts-palette-categorical-7-3IYXwW:var(--color-charts-pink-800--or4cb);
  --color-charts-palette-categorical-8-yHJ2-F:var(--color-charts-teal-600-2UUnei);
  --color-charts-palette-categorical-9-2qUf2R:var(--color-charts-purple-800-mt0PPD);
  --color-charts-palette-categorical-10-12jyfA:var(--color-charts-orange-600-3j_60e);
  --color-charts-palette-categorical-11-2pfiQa:var(--color-charts-blue-2-900-33Y3FX);
  --color-charts-palette-categorical-12-2PcgQF:var(--color-charts-pink-1100-8q1611);
  --color-charts-palette-categorical-13-37MGoo:var(--color-charts-teal-900-2R6Ae7);
  --color-charts-palette-categorical-14-3aISc9:var(--color-charts-purple-1100-dcmh3E);
  --color-charts-palette-categorical-15-2Gp9uo:var(--color-charts-orange-900-3eCLL9);
  --color-charts-palette-categorical-16-28q0ff:var(--color-charts-blue-2-1200-2SqsCy);
  --color-charts-palette-categorical-17-1aB9Zq:var(--color-charts-pink-400-1YTbxv);
  --color-charts-palette-categorical-18-2yMkrj:var(--color-charts-teal-1200-1e8w30);
  --color-charts-palette-categorical-19-1NJbV1:var(--color-charts-purple-400-2L2Pop);
  --color-charts-palette-categorical-20-1wLgQL:var(--color-charts-orange-1200-haIcdJ);
  --color-charts-palette-categorical-21-1FqHMb:var(--color-charts-blue-2-500-2QbTAr);
  --color-charts-palette-categorical-22-pC2Dnu:var(--color-charts-pink-700-2zpGOH);
  --color-charts-palette-categorical-23-26JiEH:var(--color-charts-teal-500-9FrMoX);
  --color-charts-palette-categorical-24-2WNr7z:var(--color-charts-purple-700-2hpuWi);
  --color-charts-palette-categorical-25-1bNnKJ:var(--color-charts-orange-500-29F5cF);
  --color-charts-palette-categorical-26-1eblPy:var(--color-charts-blue-2-800-bUsied);
  --color-charts-palette-categorical-27-1cCzE_:var(--color-charts-pink-1000-3Xhw0C);
  --color-charts-palette-categorical-28-3XHS8e:var(--color-charts-teal-800-1mmgce);
  --color-charts-palette-categorical-29-36AEib:var(--color-charts-purple-1000-1LOfvT);
  --color-charts-palette-categorical-30-1f8RyN:var(--color-charts-orange-800-3dMR9p);
  --color-charts-palette-categorical-31-3p8fai:var(--color-charts-blue-2-1100-3q7MzB);
  --color-charts-palette-categorical-32-2jN07T:var(--color-charts-pink-300-2XoT6f);
  --color-charts-palette-categorical-33-2iB0R0:var(--color-charts-teal-1100-1DI6zP);
  --color-charts-palette-categorical-34-3fOTRf:var(--color-charts-purple-300-La1b0W);
  --color-charts-palette-categorical-35-m3BG6Z:var(--color-charts-orange-1100-1rFHIx);
  --color-charts-palette-categorical-36-8yefO3:var(--color-charts-blue-2-400-3axVbM);
  --color-charts-palette-categorical-37-2K_jwO:var(--color-charts-pink-600-rMNZvg);
  --color-charts-palette-categorical-38-1PQZLc:var(--color-charts-teal-400-2vraJk);
  --color-charts-palette-categorical-39-1agnOi:var(--color-charts-purple-600-Zh4UZk);
  --color-charts-palette-categorical-40-1id8iv:var(--color-charts-orange-400-3rh-oy);
  --color-charts-palette-categorical-41-1E5KJb:var(--color-charts-blue-2-700-37mL3D);
  --color-charts-palette-categorical-42-JzwWC7:var(--color-charts-pink-900-mI7qx_);
  --color-charts-palette-categorical-43-194bmz:var(--color-charts-teal-700-NU-4qs);
  --color-charts-palette-categorical-44-1AQ6-c:var(--color-charts-purple-900-1iuo0e);
  --color-charts-palette-categorical-45-3YZvZb:var(--color-charts-orange-700-d-ZF6X);
  --color-charts-palette-categorical-46-2WkUD0:var(--color-charts-blue-2-1000-rzcdFI);
  --color-charts-palette-categorical-47-PPA-r9:var(--color-charts-pink-1200-2Q8hyB);
  --color-charts-palette-categorical-48-1VCkkT:var(--color-charts-teal-1000-3UqmIz);
  --color-charts-palette-categorical-49-2F1j6B:var(--color-charts-purple-1200-3Bndoz);
  --color-charts-palette-categorical-50-2V2z5L:var(--color-charts-orange-1000-1ft3LA);
  --motion-duration-show-quick-TMbCmo:var(--motion-duration-moderate-3V3IbE);
  --motion-duration-show-paced-2crOWV:var(--motion-duration-slow-39Jq2G);
  --motion-duration-rotate-90-TMbCmo:var(--motion-duration-moderate-3V3IbE);
  --motion-duration-rotate-180-TMbCmo:var(--motion-duration-moderate-3V3IbE);
  --motion-duration-transition-show-quick-2hvSaJ:var(--motion-duration-fast-oPeLPK);
  --motion-duration-transition-show-paced-2crOWV:var(--motion-duration-slow-39Jq2G);
  --motion-duration-transition-quick-2hvSaJ:var(--motion-duration-fast-oPeLPK);
  --motion-easing-rotate-90-2ebTCo:var(--motion-easing-ease-out-quart-cJfwJd);
  --motion-easing-rotate-180-2ebTCo:var(--motion-easing-ease-out-quart-cJfwJd);
  --space-scaled-none-1ld6ZS:var(--space-none-2rGI2r);
  --space-scaled-xxxs-1yX_gQ:var(--space-none-2rGI2r);
  --space-scaled-xxs-wz9S8K:var(--space-xxxs-2Fa4lw);
  --space-scaled-xs-19adjU:var(--space-xxs-10c0Z4);
  --space-scaled-s-1sAHT4:var(--space-xs-3JTBUA);
  --space-scaled-m-p3tAne:var(--space-s-1Qj4Fv);
  --space-scaled-l-17GFGI:var(--space-m-208vQh);
  --space-scaled-xl-axUbkA:var(--space-l-1RnAcv);
  --space-scaled-xxl-238u6Z:var(--space-xl-3Pai2K);
  --space-scaled-xxxl-3VX7k3:var(--space-xxl-2yP61J);
  --space-scaled-2x-none-1ld6ZS:var(--space-none-2rGI2r);
  --space-scaled-2x-xxxs-1yX_gQ:var(--space-none-2rGI2r);
  --space-scaled-2x-xxs-wz9S8K:var(--space-none-2rGI2r);
  --space-scaled-2x-xs-19adjU:var(--space-none-2rGI2r);
  --space-scaled-2x-s-1sAHT4:var(--space-xxs-10c0Z4);
  --space-scaled-2x-m-p3tAne:var(--space-xs-3JTBUA);
  --space-scaled-2x-l-17GFGI:var(--space-s-1Qj4Fv);
  --space-scaled-2x-xl-axUbkA:var(--space-m-208vQh);
  --space-scaled-2x-xxl-238u6Z:var(--space-l-1RnAcv);
  --space-scaled-2x-xxxl-3VX7k3:var(--space-xl-3Pai2K);
  --space-grid-gutter-17GFGI:var(--space-m-208vQh);
  --size-vertical-input-2yP61J:28px;
  --size-vertical-panel-icon-offset-_sbW5y:13px;
}

.awsui-motion-disabled:not(#\9){
  --color-text-accent-NYv9af:var(--color-orange-500-2Or5uE);
  --color-text-interactive-inverted-default-Xkg9ln:var(--color-grey-300-q6XEFF);
  --color-text-interactive-inverted-hover-JWSlel:var(--color-grey-100-2AKqb7);
  --color-text-form-label-WiQFVx:var(--color-text-form-default-mcmuEw);
  --color-text-control-disabled-KGAbe7:var(--color-text-interactive-disabled-TVd7uP);
  --color-text-dropdown-item-default-WiQFVx:var(--color-text-form-default-mcmuEw);
  --color-text-dropdown-item-secondary--r75hR:var(--color-text-form-secondary-2ZkiuH);
  --color-text-dropdown-item-disabled-KGAbe7:var(--color-text-interactive-disabled-TVd7uP);
  --color-text-dropdown-group-label-2XOlKp:var(--color-text-label-3qT4rX);
  --color-text-dropdown-footer--r75hR:var(--color-text-form-secondary-2ZkiuH);
  --color-text-notification-default-JWSlel:var(--color-grey-100-2AKqb7);
  --color-foreground-control-default-3g5IJp:var(--color-white-3kAlam);
  --color-background-layout-panel-content-1Z_Abk:var(--color-background-container-content-3w9fMz);
  --color-background-button-primary-default-NYv9af:var(--color-orange-500-2Or5uE);
  --color-background-button-primary-hover-39GfvT:var(--color-orange-600-FMAoZX);
  --color-background-dropdown-item-selected-nrt43a:var(--color-background-item-selected-WkeJRF);
  --color-background-notification-green-1ceXEb:var(--color-green-600-3iv9Fc);
  --color-background-notification-blue-Y4F8hz:var(--color-blue-600-3mTfxB);
  --color-background-notification-red-3bUMsg:var(--color-red-600-2N9Epo);
  --color-border-item-selected-6FRHtw:var(--color-blue-500-2Y609w);
  --color-border-status-success-1ceXEb:var(--color-green-600-3iv9Fc);
  --color-border-status-error-3bUMsg:var(--color-red-600-2N9Epo);
  --color-border-control-checked-3JzNc6:var(--color-background-control-checked-3uW6lA);
  --color-border-control-disabled-Rx1zFC:var(--color-background-control-disabled-11nezK);
  --color-border-input-disabled-1QUbGm:var(--color-background-input-disabled-38loDV);
  --color-border-dropdown-item-default-2jqiTh:var(--color-border-divider-default-38loDV);
  --color-border-dropdown-item-selected-1BqG1q:var(--color-border-item-selected-6FRHtw);
  --color-border-dropdown-item-hover-3Saafg:var(--color-grey-500-RUsejK);
  --color-border-dropdown-item-top-h3yn0h:var(--color-border-container-top-1gYBaj);
  --color-border-dropdown-group-PaVVo1:var(--color-border-dropdown-item-default-2jqiTh);
  --color-border-divider-active-3Saafg:var(--color-grey-500-RUsejK);
  --color-shadow-default-3LpQcN:var(--color-grey-transparent-heavy-3Le0m-);
  --color-shadow-medium-3CtI4L:var(--color-grey-transparent-1Qa-ea);
  --color-shadow-side-2cOaCx:var(--color-grey-transparent-light-5LOYx8);
  --color-charts-status-neutral-3Saafg:var(--color-grey-500-RUsejK);
  --color-charts-palette-categorical-1-1-pTru:var(--color-charts-blue-2-300-17FRhH);
  --color-charts-palette-categorical-2-34HR6o:var(--color-charts-pink-500-1ueMSc);
  --color-charts-palette-categorical-3-szhk2o:var(--color-charts-teal-300-1cN8Cn);
  --color-charts-palette-categorical-4-1Quw1Y:var(--color-charts-purple-500-2NvZZb);
  --color-charts-palette-categorical-5-3JVo2f:var(--color-charts-orange-300-3Fazlv);
  --color-charts-palette-categorical-6-SU6PW_:var(--color-charts-blue-2-600-w14n0w);
  --color-charts-palette-categorical-7-3IYXwW:var(--color-charts-pink-800--or4cb);
  --color-charts-palette-categorical-8-yHJ2-F:var(--color-charts-teal-600-2UUnei);
  --color-charts-palette-categorical-9-2qUf2R:var(--color-charts-purple-800-mt0PPD);
  --color-charts-palette-categorical-10-12jyfA:var(--color-charts-orange-600-3j_60e);
  --color-charts-palette-categorical-11-2pfiQa:var(--color-charts-blue-2-900-33Y3FX);
  --color-charts-palette-categorical-12-2PcgQF:var(--color-charts-pink-1100-8q1611);
  --color-charts-palette-categorical-13-37MGoo:var(--color-charts-teal-900-2R6Ae7);
  --color-charts-palette-categorical-14-3aISc9:var(--color-charts-purple-1100-dcmh3E);
  --color-charts-palette-categorical-15-2Gp9uo:var(--color-charts-orange-900-3eCLL9);
  --color-charts-palette-categorical-16-28q0ff:var(--color-charts-blue-2-1200-2SqsCy);
  --color-charts-palette-categorical-17-1aB9Zq:var(--color-charts-pink-400-1YTbxv);
  --color-charts-palette-categorical-18-2yMkrj:var(--color-charts-teal-1200-1e8w30);
  --color-charts-palette-categorical-19-1NJbV1:var(--color-charts-purple-400-2L2Pop);
  --color-charts-palette-categorical-20-1wLgQL:var(--color-charts-orange-1200-haIcdJ);
  --color-charts-palette-categorical-21-1FqHMb:var(--color-charts-blue-2-500-2QbTAr);
  --color-charts-palette-categorical-22-pC2Dnu:var(--color-charts-pink-700-2zpGOH);
  --color-charts-palette-categorical-23-26JiEH:var(--color-charts-teal-500-9FrMoX);
  --color-charts-palette-categorical-24-2WNr7z:var(--color-charts-purple-700-2hpuWi);
  --color-charts-palette-categorical-25-1bNnKJ:var(--color-charts-orange-500-29F5cF);
  --color-charts-palette-categorical-26-1eblPy:var(--color-charts-blue-2-800-bUsied);
  --color-charts-palette-categorical-27-1cCzE_:var(--color-charts-pink-1000-3Xhw0C);
  --color-charts-palette-categorical-28-3XHS8e:var(--color-charts-teal-800-1mmgce);
  --color-charts-palette-categorical-29-36AEib:var(--color-charts-purple-1000-1LOfvT);
  --color-charts-palette-categorical-30-1f8RyN:var(--color-charts-orange-800-3dMR9p);
  --color-charts-palette-categorical-31-3p8fai:var(--color-charts-blue-2-1100-3q7MzB);
  --color-charts-palette-categorical-32-2jN07T:var(--color-charts-pink-300-2XoT6f);
  --color-charts-palette-categorical-33-2iB0R0:var(--color-charts-teal-1100-1DI6zP);
  --color-charts-palette-categorical-34-3fOTRf:var(--color-charts-purple-300-La1b0W);
  --color-charts-palette-categorical-35-m3BG6Z:var(--color-charts-orange-1100-1rFHIx);
  --color-charts-palette-categorical-36-8yefO3:var(--color-charts-blue-2-400-3axVbM);
  --color-charts-palette-categorical-37-2K_jwO:var(--color-charts-pink-600-rMNZvg);
  --color-charts-palette-categorical-38-1PQZLc:var(--color-charts-teal-400-2vraJk);
  --color-charts-palette-categorical-39-1agnOi:var(--color-charts-purple-600-Zh4UZk);
  --color-charts-palette-categorical-40-1id8iv:var(--color-charts-orange-400-3rh-oy);
  --color-charts-palette-categorical-41-1E5KJb:var(--color-charts-blue-2-700-37mL3D);
  --color-charts-palette-categorical-42-JzwWC7:var(--color-charts-pink-900-mI7qx_);
  --color-charts-palette-categorical-43-194bmz:var(--color-charts-teal-700-NU-4qs);
  --color-charts-palette-categorical-44-1AQ6-c:var(--color-charts-purple-900-1iuo0e);
  --color-charts-palette-categorical-45-3YZvZb:var(--color-charts-orange-700-d-ZF6X);
  --color-charts-palette-categorical-46-2WkUD0:var(--color-charts-blue-2-1000-rzcdFI);
  --color-charts-palette-categorical-47-PPA-r9:var(--color-charts-pink-1200-2Q8hyB);
  --color-charts-palette-categorical-48-1VCkkT:var(--color-charts-teal-1000-3UqmIz);
  --color-charts-palette-categorical-49-2F1j6B:var(--color-charts-purple-1200-3Bndoz);
  --color-charts-palette-categorical-50-2V2z5L:var(--color-charts-orange-1000-1ft3LA);
  --motion-duration-extra-fast-1YDA0Q:0ms;
  --motion-duration-fast-oPeLPK:0ms;
  --motion-duration-moderate-3V3IbE:0ms;
  --motion-duration-slow-39Jq2G:0ms;
  --motion-duration-extra-slow-39YF13:0ms;
  --motion-duration-show-quick-TMbCmo:var(--motion-duration-moderate-3V3IbE);
  --motion-duration-show-paced-2crOWV:var(--motion-duration-slow-39Jq2G);
  --motion-duration-rotate-90-TMbCmo:var(--motion-duration-moderate-3V3IbE);
  --motion-duration-rotate-180-TMbCmo:var(--motion-duration-moderate-3V3IbE);
  --motion-duration-transition-show-quick-2hvSaJ:var(--motion-duration-fast-oPeLPK);
  --motion-duration-transition-show-paced-2crOWV:var(--motion-duration-slow-39Jq2G);
  --motion-duration-transition-quick-2hvSaJ:var(--motion-duration-fast-oPeLPK);
  --motion-easing-rotate-90-2ebTCo:var(--motion-easing-ease-out-quart-cJfwJd);
  --motion-easing-rotate-180-2ebTCo:var(--motion-easing-ease-out-quart-cJfwJd);
  --space-scaled-none-1ld6ZS:var(--space-none-2rGI2r);
  --space-scaled-2x-none-1ld6ZS:var(--space-none-2rGI2r);
}
._icon_h11ix_1gvkl_3:not(#\9){
  display:inline-block;
  vertical-align:top;
}
._icon_h11ix_1gvkl_3 > svg:not(#\9){
  pointer-events:none;
}
._icon_h11ix_1gvkl_3 > svg:not(#\9){
  fill:none;
}
._icon_h11ix_1gvkl_3 > svg *:not(#\9){
  stroke:currentColor;
}
._icon_h11ix_1gvkl_3 > svg .stroke-linejoin-round:not(#\9){
  stroke-linejoin:round;
}
._icon_h11ix_1gvkl_3 > svg .stroke-linecap-square:not(#\9){
  stroke-linecap:square;
}
._icon_h11ix_1gvkl_3 > svg .stroke-linecap-round:not(#\9){
  stroke-linecap:round;
}
._icon_h11ix_1gvkl_3 > svg .filled:not(#\9){
  fill:currentColor;
}
._icon_h11ix_1gvkl_3._size-small_h11ix_1gvkl_28:not(#\9){
  width:16px;
  width:16px;
  width:var(--size-icon-normal-x2Xc-V, 16px);
  height:16px;
  height:16px;
  height:var(--font-body-s-lineHeight-x2Xc-V, 16px);
  padding:calc((16px - 16px) / 2) 0;
  padding:calc((16px - 16px) / 2) 0;
  padding:calc((var(--font-body-s-lineHeight-x2Xc-V, 16px) - var(--size-icon-normal-x2Xc-V, 16px)) / 2) 0;
  box-sizing:border-box;
}
._icon_h11ix_1gvkl_3._size-small_h11ix_1gvkl_28 > svg:not(#\9),
._icon_h11ix_1gvkl_3._size-small_h11ix_1gvkl_28 > img:not(#\9){
  width:16px;
  width:16px;
  width:var(--size-icon-normal-x2Xc-V, 16px);
  height:16px;
  height:16px;
  height:var(--size-icon-normal-x2Xc-V, 16px);
  vertical-align:top;
}
._icon_h11ix_1gvkl_3._size-small_h11ix_1gvkl_28 > svg:not(#\9),
._icon_h11ix_1gvkl_3._size-small_h11ix_1gvkl_28 > svg *:not(#\9){
  stroke-width:2px;
}
._icon_h11ix_1gvkl_3._size-normal_h11ix_1gvkl_44:not(#\9){
  width:16px;
  width:16px;
  width:var(--size-icon-normal-x2Xc-V, 16px);
  height:22px;
  height:22px;
  height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  padding:calc((22px - 16px) / 2) 0;
  padding:calc((22px - 16px) / 2) 0;
  padding:calc((var(--font-body-m-lineHeight-2Vn4pu, 22px) - var(--size-icon-normal-x2Xc-V, 16px)) / 2) 0;
  box-sizing:border-box;
}
._icon_h11ix_1gvkl_3._size-normal_h11ix_1gvkl_44 > svg:not(#\9),
._icon_h11ix_1gvkl_3._size-normal_h11ix_1gvkl_44 > img:not(#\9){
  width:16px;
  width:16px;
  width:var(--size-icon-normal-x2Xc-V, 16px);
  height:16px;
  height:16px;
  height:var(--size-icon-normal-x2Xc-V, 16px);
  vertical-align:top;
}
._icon_h11ix_1gvkl_3._size-normal_h11ix_1gvkl_44 > svg:not(#\9),
._icon_h11ix_1gvkl_3._size-normal_h11ix_1gvkl_44 > svg *:not(#\9){
  stroke-width:2px;
}
._icon_h11ix_1gvkl_3._size-big_h11ix_1gvkl_60:not(#\9){
  width:32px;
  width:32px;
  width:var(--size-icon-big-Z5s8Q6, 32px);
  height:36px;
  height:36px;
  height:var(--font-heading-xl-lineHeight-3i5g66, 36px);
  padding:calc((36px - 32px) / 2) 0;
  padding:calc((36px - 32px) / 2) 0;
  padding:calc((var(--font-heading-xl-lineHeight-3i5g66, 36px) - var(--size-icon-big-Z5s8Q6, 32px)) / 2) 0;
  box-sizing:border-box;
}
._icon_h11ix_1gvkl_3._size-big_h11ix_1gvkl_60 > svg:not(#\9),
._icon_h11ix_1gvkl_3._size-big_h11ix_1gvkl_60 > img:not(#\9){
  width:32px;
  width:32px;
  width:var(--size-icon-big-Z5s8Q6, 32px);
  height:32px;
  height:32px;
  height:var(--size-icon-big-Z5s8Q6, 32px);
  vertical-align:top;
}
._icon_h11ix_1gvkl_3._size-big_h11ix_1gvkl_60 > svg:not(#\9),
._icon_h11ix_1gvkl_3._size-big_h11ix_1gvkl_60 > svg *:not(#\9){
  stroke-width:2px;
}
._icon_h11ix_1gvkl_3._size-large_h11ix_1gvkl_76:not(#\9){
  width:48px;
  width:48px;
  width:var(--size-icon-large-RBwaJq, 48px);
  height:56px;
  height:56px;
  height:var(--font-display-l-lineHeight-2EHwYK, 56px);
  padding:calc((56px - 48px) / 2) 0;
  padding:calc((56px - 48px) / 2) 0;
  padding:calc((var(--font-display-l-lineHeight-2EHwYK, 56px) - var(--size-icon-large-RBwaJq, 48px)) / 2) 0;
  box-sizing:border-box;
}
._icon_h11ix_1gvkl_3._size-large_h11ix_1gvkl_76 > svg:not(#\9),
._icon_h11ix_1gvkl_3._size-large_h11ix_1gvkl_76 > img:not(#\9){
  width:48px;
  width:48px;
  width:var(--size-icon-large-RBwaJq, 48px);
  height:48px;
  height:48px;
  height:var(--size-icon-large-RBwaJq, 48px);
  vertical-align:top;
}
._icon_h11ix_1gvkl_3._size-large_h11ix_1gvkl_76 > svg:not(#\9),
._icon_h11ix_1gvkl_3._size-large_h11ix_1gvkl_76 > svg *:not(#\9){
  stroke-width:1.2px;
}
._icon_h11ix_1gvkl_3._variant-normal_h11ix_1gvkl_92:not(#\9){
  color:currentColor;
}
._icon_h11ix_1gvkl_3._variant-disabled_h11ix_1gvkl_95:not(#\9){
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
}
._icon_h11ix_1gvkl_3._variant-inverted_h11ix_1gvkl_98:not(#\9){
  color:#ffffff;
  color:#ffffff;
  color:var(--color-text-inverted-DnITSk, #ffffff);
}
._icon_h11ix_1gvkl_3._variant-subtle_h11ix_1gvkl_101:not(#\9){
  color:#687078;
  color:#687078;
  color:var(--color-text-icon-subtle-22ofsX, #687078);
}
._icon_h11ix_1gvkl_3._variant-warning_h11ix_1gvkl_104:not(#\9){
  color:#d13212;
  color:#d13212;
  color:var(--color-text-status-warning-1jj2sL, #d13212);
}
._icon_h11ix_1gvkl_3._variant-error_h11ix_1gvkl_107:not(#\9){
  color:#d13212;
  color:#d13212;
  color:var(--color-text-status-error-1jj2sL, #d13212);
}
._icon_h11ix_1gvkl_3._variant-success_h11ix_1gvkl_110:not(#\9){
  color:#1d8102;
  color:#1d8102;
  color:var(--color-text-status-success-CuCgtA, #1d8102);
}
._icon_h11ix_1gvkl_3._variant-link_h11ix_1gvkl_113:not(#\9){
  color:#0073bb;
  color:#0073bb;
  color:var(--color-text-link-default-Bc0LvG, #0073bb);
}
@-webkit-keyframes _spinner-rotator_1612d_1wtce_1{
  0%{
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg);
  }
  100%{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg);
  }
}
@keyframes _spinner-rotator_1612d_1wtce_1{
  0%{
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg);
  }
  100%{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg);
  }
}
@-webkit-keyframes _spinner-line-left_1612d_1wtce_1{
  0%{
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg);
  }
  50%{
    -webkit-transform:rotate(120deg);
            transform:rotate(120deg);
  }
  100%{
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg);
  }
}
@keyframes _spinner-line-left_1612d_1wtce_1{
  0%{
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg);
  }
  50%{
    -webkit-transform:rotate(120deg);
            transform:rotate(120deg);
  }
  100%{
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg);
  }
}
@-webkit-keyframes _spinner-line-right_1612d_1wtce_1{
  0%{
    -webkit-transform:rotate(90deg);
            transform:rotate(90deg);
  }
  50%{
    -webkit-transform:rotate(-30deg);
            transform:rotate(-30deg);
  }
  100%{
    -webkit-transform:rotate(90deg);
            transform:rotate(90deg);
  }
}
@keyframes _spinner-line-right_1612d_1wtce_1{
  0%{
    -webkit-transform:rotate(90deg);
            transform:rotate(90deg);
  }
  50%{
    -webkit-transform:rotate(-30deg);
            transform:rotate(-30deg);
  }
  100%{
    -webkit-transform:rotate(90deg);
            transform:rotate(90deg);
  }
}
._root_1612d_1wtce_33:not(#\9){
  display:inline-block;
  vertical-align:top;
  -webkit-animation:_spinner-rotator_1612d_1wtce_1 0.7s linear infinite;
          animation:_spinner-rotator_1612d_1wtce_1 0.7s linear infinite;
  box-sizing:border-box;
  line-height:0;
}
._root_1612d_1wtce_33._size-normal_1612d_1wtce_41:not(#\9){
  width:16px;
  width:16px;
  width:var(--size-icon-normal-x2Xc-V, 16px);
  height:16px;
  height:16px;
  height:var(--size-icon-normal-x2Xc-V, 16px);
  padding:calc((16px - 12px) / 2);
  padding:calc((16px - 12px) / 2);
  padding:calc((var(--size-icon-normal-x2Xc-V, 16px) - 12px) / 2);
  margin-top:calc((22px - 16px) / 2);
  margin-top:calc((22px - 16px) / 2);
  margin-top:calc((var(--font-body-m-lineHeight-2Vn4pu, 22px) - var(--size-icon-normal-x2Xc-V, 16px)) / 2);
  margin-bottom:calc((22px - 16px) / 2);
  margin-bottom:calc((22px - 16px) / 2);
  margin-bottom:calc((var(--font-body-m-lineHeight-2Vn4pu, 22px) - var(--size-icon-normal-x2Xc-V, 16px)) / 2);
  box-sizing:border-box;
}
._root_1612d_1wtce_33._size-big_1612d_1wtce_49:not(#\9){
  width:32px;
  width:32px;
  width:var(--size-icon-big-Z5s8Q6, 32px);
  height:32px;
  height:32px;
  height:var(--size-icon-big-Z5s8Q6, 32px);
  padding:calc((32px - 24px) / 2);
  padding:calc((32px - 24px) / 2);
  padding:calc((var(--size-icon-big-Z5s8Q6, 32px) - 24px) / 2);
  margin-top:calc((36px - 32px) / 2);
  margin-top:calc((36px - 32px) / 2);
  margin-top:calc((var(--font-heading-xl-lineHeight-3i5g66, 36px) - var(--size-icon-big-Z5s8Q6, 32px)) / 2);
  margin-bottom:calc((36px - 32px) / 2);
  margin-bottom:calc((36px - 32px) / 2);
  margin-bottom:calc((var(--font-heading-xl-lineHeight-3i5g66, 36px) - var(--size-icon-big-Z5s8Q6, 32px)) / 2);
  box-sizing:border-box;
}
._root_1612d_1wtce_33._size-large_1612d_1wtce_57:not(#\9){
  width:48px;
  width:48px;
  width:var(--size-icon-large-RBwaJq, 48px);
  height:48px;
  height:48px;
  height:var(--size-icon-large-RBwaJq, 48px);
  padding:calc((48px - 36px) / 2);
  padding:calc((48px - 36px) / 2);
  padding:calc((var(--size-icon-large-RBwaJq, 48px) - 36px) / 2);
  margin-top:calc((56px - 48px) / 2);
  margin-top:calc((56px - 48px) / 2);
  margin-top:calc((var(--font-display-l-lineHeight-2EHwYK, 56px) - var(--size-icon-large-RBwaJq, 48px)) / 2);
  margin-bottom:calc((56px - 48px) / 2);
  margin-bottom:calc((56px - 48px) / 2);
  margin-bottom:calc((var(--font-display-l-lineHeight-2EHwYK, 56px) - var(--size-icon-large-RBwaJq, 48px)) / 2);
  box-sizing:border-box;
}
._root_1612d_1wtce_33._variant-normal_1612d_1wtce_65:not(#\9){
  color:currentColor;
}
._root_1612d_1wtce_33._variant-disabled_1612d_1wtce_68:not(#\9){
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
}
._root_1612d_1wtce_33._variant-inverted_1612d_1wtce_71:not(#\9){
  color:#ffffff;
  color:#ffffff;
  color:var(--color-text-inverted-DnITSk, #ffffff);
}

._circle_1612d_1wtce_75:not(#\9){
  display:inline-block;
  width:50%;
  height:100%;
  overflow:hidden;
  position:relative;
}
._circle_1612d_1wtce_75:not(#\9):after{
  position:absolute;
  box-sizing:border-box;
  content:"";
  border-radius:50%;
  border:2px solid;
  border-right-color:transparent;
  border-bottom-color:transparent;
}
._circle_1612d_1wtce_75:not(#\9):after{
  -webkit-animation:1.5s ease-in-out infinite;
          animation:1.5s ease-in-out infinite;
  top:0;
  left:0;
  height:100%;
  width:200%;
}
._circle_1612d_1wtce_75._circle-left_1612d_1wtce_99:not(#\9):after{
  left:0;
  -webkit-animation-name:_spinner-line-left_1612d_1wtce_1;
          animation-name:_spinner-line-left_1612d_1wtce_1;
}
._circle_1612d_1wtce_75._circle-right_1612d_1wtce_104:not(#\9):after{
  left:-100%;
  -webkit-animation-name:_spinner-line-right_1612d_1wtce_1;
          animation-name:_spinner-line-right_1612d_1wtce_1;
}
._alert_mx3cw_1diu9_3:not(#\9){
  -webkit-animation:_awsui-motion-fade-in_mx3cw_1diu9_1 180ms ease-out;
          animation:_awsui-motion-fade-in_mx3cw_1diu9_1 180ms ease-out;
  animation:_awsui-motion-fade-in_mx3cw_1diu9_1 180ms ease-out;
  -webkit-animation:_awsui-motion-fade-in_mx3cw_1diu9_1 var(--motion-duration-show-paced-2crOWV, 180ms) var(--motion-easing-show-paced-25z_bd, ease-out);
          animation:_awsui-motion-fade-in_mx3cw_1diu9_1 var(--motion-duration-show-paced-2crOWV, 180ms) var(--motion-easing-show-paced-25z_bd, ease-out);
}
@-webkit-keyframes _awsui-motion-fade-in_mx3cw_1diu9_1{
  from{
    opacity:0.2;
  }
  to{
    opacity:1;
  }
}
@keyframes _awsui-motion-fade-in_mx3cw_1diu9_1{
  from{
    opacity:0.2;
  }
  to{
    opacity:1;
  }
}
@media (prefers-reduced-motion: reduce){
  ._alert_mx3cw_1diu9_3:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._alert_mx3cw_1diu9_3:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._root_mx3cw_1diu9_25:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  word-wrap:break-word;
  max-width:100%;
  overflow:hidden;
  display:block;
}
._root_mx3cw_1diu9_25._hidden_mx3cw_1diu9_38:not(#\9){
  display:none;
}

._alert_mx3cw_1diu9_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  position:relative;
  display:flex;
  justify-content:flex-start;
  align-items:flex-start;
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  border:1px solid;
  border:1px solid;
  border:var(--border-field-width-3GX4nr, 1px) solid;
  padding:12px 20px;
  padding:12px 20px;
  padding:var(--space-scaled-s-1sAHT4, 12px) var(--space-l-1RnAcv, 20px);
  background-color:#ffffff;
  background-color:#ffffff;
  background-color:var(--color-background-container-content-3w9fMz, #ffffff);
}

._body_mx3cw_1diu9_60:not(#\9){
  display:flex;
  flex:1 1;
  min-width:0;
}

._header_mx3cw_1diu9_66:not(#\9){
  font-weight:700;
}

._action_mx3cw_1diu9_70:not(#\9){
  white-space:nowrap;
  margin-left:20px;
  margin-left:20px;
  margin-left:var(--space-l-1RnAcv, 20px);
}

._root_mx3cw_1diu9_25._breakpoint-default_mx3cw_1diu9_79 > ._alert_mx3cw_1diu9_3 > ._body_mx3cw_1diu9_60:not(#\9){
  display:block;
}
._root_mx3cw_1diu9_25._breakpoint-default_mx3cw_1diu9_79 > ._alert_mx3cw_1diu9_3 > ._body_mx3cw_1diu9_60 > ._action_mx3cw_1diu9_70:not(#\9){
  margin-left:0;
}

._message_mx3cw_1diu9_86:not(#\9){
  flex:1 1;
  margin:4px 0;
  margin:4px 0;
  margin:var(--space-xxs-10c0Z4, 4px) 0;
  min-width:0;
}

._dismiss_mx3cw_1diu9_96:not(#\9){
  flex:0 0 auto;
  margin-right:calc(-1 * 4px);
  margin-right:calc(-1 * 4px);
  margin-right:calc(-1 * var(--space-xxs-10c0Z4, 4px));
}

._dismiss-with-button_mx3cw_1diu9_105:not(#\9){
  margin-left:12px;
  margin-left:12px;
  margin-left:var(--space-s-1Qj4Fv, 12px);
}

._icon_mx3cw_1diu9_109:not(#\9){
  flex:0 0 auto;
  margin-right:8px;
  margin-right:8px;
  margin-right:var(--space-xs-3JTBUA, 8px);
  margin-top:4px;
  margin-top:4px;
  margin-top:var(--space-xxs-10c0Z4, 4px);
}

._type-error_mx3cw_1diu9_115:not(#\9){
  border-color:#d13212;
  border-color:#d13212;
  border-color:var(--color-border-status-error-3bUMsg, #d13212);
  background-color:#fdf3f1;
  background-color:#fdf3f1;
  background-color:var(--color-background-status-error-3eo6Wg, #fdf3f1);
}
._type-error_mx3cw_1diu9_115 > ._icon_mx3cw_1diu9_109:not(#\9){
  color:#d13212;
  color:#d13212;
  color:var(--color-text-status-error-1jj2sL, #d13212);
}

._type-warning_mx3cw_1diu9_123:not(#\9){
  border-color:#aab7b8;
  border-color:#aab7b8;
  border-color:var(--color-border-status-warning-27WVST, #aab7b8);
  background-color:#ffffff;
  background-color:#ffffff;
  background-color:var(--color-background-status-warning-3w9fMz, #ffffff);
}
._type-warning_mx3cw_1diu9_123 > ._icon_mx3cw_1diu9_109:not(#\9){
  color:#d13212;
  color:#d13212;
  color:var(--color-text-status-warning-1jj2sL, #d13212);
}

._type-success_mx3cw_1diu9_131:not(#\9){
  border-color:#1d8102;
  border-color:#1d8102;
  border-color:var(--color-border-status-success-1ceXEb, #1d8102);
  background-color:#f2f8f0;
  background-color:#f2f8f0;
  background-color:var(--color-background-status-success-_mCzOj, #f2f8f0);
}
._type-success_mx3cw_1diu9_131 > ._icon_mx3cw_1diu9_109:not(#\9){
  color:#1d8102;
  color:#1d8102;
  color:var(--color-text-status-success-CuCgtA, #1d8102);
}

._type-info_mx3cw_1diu9_139:not(#\9){
  border-color:#0073bb;
  border-color:#0073bb;
  border-color:var(--color-border-status-info-3uW6lA, #0073bb);
  background-color:#f1faff;
  background-color:#f1faff;
  background-color:var(--color-background-status-info-WkeJRF, #f1faff);
}
._type-info_mx3cw_1diu9_139 > ._icon_mx3cw_1diu9_109:not(#\9){
  color:#0073bb;
  color:#0073bb;
  color:var(--color-text-status-info-Bc0LvG, #0073bb);
}
._arrow_1hpp3_l0452_3:not(#\9){
  width:20px;
  height:10px;
}
._arrow-outer_1hpp3_l0452_7:not(#\9), ._arrow-inner_1hpp3_l0452_7:not(#\9){
  position:absolute;
  overflow:hidden;
  width:20px;
  height:10px;
  top:0;
  left:0;
}
._arrow-outer_1hpp3_l0452_7:not(#\9)::after, ._arrow-inner_1hpp3_l0452_7:not(#\9)::after{
  content:"";
  box-sizing:border-box;
  display:inline-block;
  position:absolute;
  border-radius:2px 0 0 0;
  bottom:0;
  left:0;
  width:14px;
  height:14px;
  -webkit-transform:rotate(45deg);
          transform:rotate(45deg);
  -webkit-transform-origin:0 100%;
          transform-origin:0 100%;
}
._arrow-outer_1hpp3_l0452_7:not(#\9)::after{
  background-color:#0073bb;
  background-color:#0073bb;
  background-color:var(--color-border-status-info-3uW6lA, #0073bb);
}
._arrow-inner_1hpp3_l0452_7:not(#\9){
  top:2px;
}
._arrow-inner_1hpp3_l0452_7:not(#\9)::after{
  border-radius:1px 0 0 0;
  background-color:#f1faff;
  background-color:#f1faff;
  background-color:var(--color-background-status-info-WkeJRF, #f1faff);
}
._arrow-position-right-top_1hpp3_l0452_38 > ._arrow-outer_1hpp3_l0452_7:not(#\9)::after, ._arrow-position-right-bottom_1hpp3_l0452_38 > ._arrow-outer_1hpp3_l0452_7:not(#\9)::after{
  box-shadow:-0.71px 0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:-0.71px 0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:-0.71px 0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}
._arrow-position-left-top_1hpp3_l0452_41 > ._arrow-outer_1hpp3_l0452_7:not(#\9)::after, ._arrow-position-left-bottom_1hpp3_l0452_41 > ._arrow-outer_1hpp3_l0452_7:not(#\9)::after{
  box-shadow:0.71px -0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0.71px -0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0.71px -0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}
._arrow-position-top-center_1hpp3_l0452_44 > ._arrow-outer_1hpp3_l0452_7:not(#\9)::after, ._arrow-position-top-responsive_1hpp3_l0452_44 > ._arrow-outer_1hpp3_l0452_7:not(#\9)::after{
  box-shadow:-0.71px -0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:-0.71px -0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:-0.71px -0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}
._arrow-position-bottom-center_1hpp3_l0452_47 > ._arrow-outer_1hpp3_l0452_7:not(#\9)::after, ._arrow-position-bottom-responsive_1hpp3_l0452_47 > ._arrow-outer_1hpp3_l0452_7:not(#\9)::after{
  box-shadow:0.71px 0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0.71px 0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0.71px 0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}

._description_1hpp3_l0452_61:not(#\9){
  overflow:hidden;
  margin-top:4px;
  margin-top:4px;
  margin-top:var(--space-xxs-10c0Z4, 4px);
}

._actionBar_1hpp3_l0452_66:not(#\9){
  display:flex;
  justify-content:space-between;
  align-items:center;
  position:relative;
}

._stepCounter_1hpp3_l0452_73:not(#\9){
  margin-right:20px;
}

._divider_1hpp3_l0452_77:not(#\9){
  border-bottom:1px solid #c1c1c1;
}

._hotspot_1hpp3_l0452_81:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  background:transparent;
  border:none;
  padding:0;
  cursor:pointer;
  width:16px;
  height:16px;
}
._hotspot_1hpp3_l0452_81:not(#\9):focus{
  outline:none;
}
._hotspot_1hpp3_l0452_81[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._hotspot_1hpp3_l0452_81[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(2px - 1px);
}
._hotspot_1hpp3_l0452_81[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-2px;
  top:-2px;
  width:calc(100% + 2 * 2px);
  height:calc(100% + 2 * 2px);
  border-radius:50%;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._hotspot_1hpp3_l0452_81 > svg:not(#\9){
  position:relative;
  stroke:#0073bb;
  stroke:#0073bb;
  stroke:var(--color-text-link-default-Bc0LvG, #0073bb);
}
._root_18wu0_vpmym_3:not(#\9){
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}
._box_18wu0_vpmym_16._p-variant_18wu0_vpmym_16._color-default_18wu0_vpmym_16:not(#\9), ._box_18wu0_vpmym_16._b-variant_18wu0_vpmym_16._color-default_18wu0_vpmym_16:not(#\9), ._box_18wu0_vpmym_16._strong-variant_18wu0_vpmym_16._color-default_18wu0_vpmym_16:not(#\9), ._box_18wu0_vpmym_16._p-variant_18wu0_vpmym_16._color-default_18wu0_vpmym_16:not(#\9), ._box_18wu0_vpmym_16._code-variant_18wu0_vpmym_16._color-default_18wu0_vpmym_16:not(#\9), ._box_18wu0_vpmym_16._pre-variant_18wu0_vpmym_16._color-default_18wu0_vpmym_16:not(#\9), ._box_18wu0_vpmym_16._samp-variant_18wu0_vpmym_16._color-default_18wu0_vpmym_16:not(#\9){
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
}

._box_18wu0_vpmym_16._h1-variant_18wu0_vpmym_20._color-default_18wu0_vpmym_16:not(#\9), ._box_18wu0_vpmym_16._h2-variant_18wu0_vpmym_20._color-default_18wu0_vpmym_16:not(#\9), ._box_18wu0_vpmym_16._h3-variant_18wu0_vpmym_20._color-default_18wu0_vpmym_16:not(#\9), ._box_18wu0_vpmym_16._h4-variant_18wu0_vpmym_20._color-default_18wu0_vpmym_16:not(#\9), ._box_18wu0_vpmym_16._h5-variant_18wu0_vpmym_20._color-default_18wu0_vpmym_16:not(#\9){
  color:#16191f;
  color:#16191f;
  color:var(--color-text-heading-default-2S6vEC, #16191f);
}

._box_18wu0_vpmym_16._small-variant_18wu0_vpmym_24._color-default_18wu0_vpmym_16:not(#\9){
  color:#687078;
  color:#687078;
  color:var(--color-text-small-2ZkiuH, #687078);
}

._box_18wu0_vpmym_16._a-variant_18wu0_vpmym_28._color-default_18wu0_vpmym_16:not(#\9){
  color:#0073bb;
  color:#0073bb;
  color:var(--color-text-link-default-Bc0LvG, #0073bb);
}

._box_18wu0_vpmym_16._small-variant_18wu0_vpmym_24._font-size-default_18wu0_vpmym_32:not(#\9), ._box_18wu0_vpmym_16._code-variant_18wu0_vpmym_16._font-size-default_18wu0_vpmym_32:not(#\9){
  font-size:12px;
  font-size:12px;
  font-size:var(--font-body-s-size-3NP7Rr, 12px);
  line-height:16px;
  line-height:16px;
  line-height:var(--font-body-s-lineHeight-x2Xc-V, 16px);
}

._box_18wu0_vpmym_16._p-variant_18wu0_vpmym_16._font-size-default_18wu0_vpmym_32:not(#\9), ._box_18wu0_vpmym_16._b-variant_18wu0_vpmym_16._font-size-default_18wu0_vpmym_32:not(#\9), ._box_18wu0_vpmym_16._strong-variant_18wu0_vpmym_16._font-size-default_18wu0_vpmym_32:not(#\9), ._box_18wu0_vpmym_16._pre-variant_18wu0_vpmym_16._font-size-default_18wu0_vpmym_32:not(#\9), ._box_18wu0_vpmym_16._samp-variant_18wu0_vpmym_16._font-size-default_18wu0_vpmym_32:not(#\9), ._box_18wu0_vpmym_16._a-variant_18wu0_vpmym_28._font-size-default_18wu0_vpmym_32:not(#\9){
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
}

._box_18wu0_vpmym_16._h5-variant_18wu0_vpmym_20._font-size-default_18wu0_vpmym_32:not(#\9){
  font-size:16px;
  font-size:16px;
  font-size:var(--font-heading-xs-size-x2Xc-V, 16px);
  line-height:20px;
  line-height:20px;
  line-height:var(--font-heading-xs-lineHeight-8Y2p4W, 20px);
}

._box_18wu0_vpmym_16._h4-variant_18wu0_vpmym_20._font-size-default_18wu0_vpmym_32:not(#\9){
  font-size:16px;
  font-size:16px;
  font-size:var(--font-heading-s-size-x2Xc-V, 16px);
  line-height:20px;
  line-height:20px;
  line-height:var(--font-heading-s-lineHeight-8Y2p4W, 20px);
}

._box_18wu0_vpmym_16._h3-variant_18wu0_vpmym_20._font-size-default_18wu0_vpmym_32:not(#\9){
  font-size:18px;
  font-size:18px;
  font-size:var(--font-heading-m-size-3IMO0y, 18px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-heading-m-lineHeight-2Vn4pu, 22px);
}

._box_18wu0_vpmym_16._h2-variant_18wu0_vpmym_20._font-size-default_18wu0_vpmym_32:not(#\9){
  font-size:18px;
  font-size:18px;
  font-size:var(--font-heading-l-size-3IMO0y, 18px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-heading-l-lineHeight-2Vn4pu, 22px);
}

._box_18wu0_vpmym_16._h1-variant_18wu0_vpmym_20._font-size-default_18wu0_vpmym_32:not(#\9){
  font-size:28px;
  font-size:28px;
  font-size:var(--font-heading-xl-size-2ac18Z, 28px);
  line-height:36px;
  line-height:36px;
  line-height:var(--font-heading-xl-lineHeight-3i5g66, 36px);
}

._box_18wu0_vpmym_16._h1-variant_18wu0_vpmym_20._font-weight-default_18wu0_vpmym_67:not(#\9), ._box_18wu0_vpmym_16._h3-variant_18wu0_vpmym_20._font-weight-default_18wu0_vpmym_67:not(#\9), ._box_18wu0_vpmym_16._h5-variant_18wu0_vpmym_20._font-weight-default_18wu0_vpmym_67:not(#\9), ._box_18wu0_vpmym_16._p-variant_18wu0_vpmym_16._font-weight-default_18wu0_vpmym_67:not(#\9), ._box_18wu0_vpmym_16._p-variant_18wu0_vpmym_16._font-weight-default_18wu0_vpmym_67:not(#\9), ._box_18wu0_vpmym_16._code-variant_18wu0_vpmym_16._font-weight-default_18wu0_vpmym_67:not(#\9), ._box_18wu0_vpmym_16._pre-variant_18wu0_vpmym_16._font-weight-default_18wu0_vpmym_67:not(#\9), ._box_18wu0_vpmym_16._samp-variant_18wu0_vpmym_16._font-weight-default_18wu0_vpmym_67:not(#\9), ._box_18wu0_vpmym_16._small-variant_18wu0_vpmym_24._font-weight-default_18wu0_vpmym_67:not(#\9), ._box_18wu0_vpmym_16._a-variant_18wu0_vpmym_28._font-weight-default_18wu0_vpmym_67:not(#\9){
  font-weight:400;
}

._box_18wu0_vpmym_16._h2-variant_18wu0_vpmym_20._font-weight-default_18wu0_vpmym_67:not(#\9), ._box_18wu0_vpmym_16._h4-variant_18wu0_vpmym_20._font-weight-default_18wu0_vpmym_67:not(#\9), ._box_18wu0_vpmym_16._strong-variant_18wu0_vpmym_16._font-weight-default_18wu0_vpmym_67:not(#\9), ._box_18wu0_vpmym_16._b-variant_18wu0_vpmym_16._font-weight-default_18wu0_vpmym_67:not(#\9){
  font-weight:700;
}

._box_18wu0_vpmym_16._h1-variant_18wu0_vpmym_20:not(#\9), ._box_18wu0_vpmym_16._h2-variant_18wu0_vpmym_20:not(#\9), ._box_18wu0_vpmym_16._h3-variant_18wu0_vpmym_20:not(#\9), ._box_18wu0_vpmym_16._h4-variant_18wu0_vpmym_20:not(#\9), ._box_18wu0_vpmym_16._h5-variant_18wu0_vpmym_20:not(#\9), ._box_18wu0_vpmym_16._p-variant_18wu0_vpmym_16:not(#\9){
  margin:0;
  text-decoration:none;
  padding:4px 0;
  padding:4px 0;
  padding:var(--space-xxs-10c0Z4, 4px) 0;
}
._box_18wu0_vpmym_16._small-variant_18wu0_vpmym_24:not(#\9){
  display:inline-block;
}
._box_18wu0_vpmym_16._code-variant_18wu0_vpmym_16:not(#\9), ._box_18wu0_vpmym_16._pre-variant_18wu0_vpmym_16:not(#\9), ._box_18wu0_vpmym_16._samp-variant_18wu0_vpmym_16:not(#\9){
  font-family:Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;
  font-family:Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;
  font-family:var(--font-fontFamily-monospace-1bAn4w, Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace);
  background:transparent;
}

._box_18wu0_vpmym_16._color-inverted_18wu0_vpmym_88:not(#\9){
  color:#fafafa;
  color:#fafafa;
  color:var(--color-text-notification-default-JWSlel, #fafafa);
}
._box_18wu0_vpmym_16._color-text-label_18wu0_vpmym_91:not(#\9){
  color:#545b64;
  color:#545b64;
  color:var(--color-text-label-3qT4rX, #545b64);
}
._box_18wu0_vpmym_16._color-text-body-secondary_18wu0_vpmym_94:not(#\9){
  color:#545b64;
  color:#545b64;
  color:var(--color-text-body-secondary-1oBHNI, #545b64);
}
._box_18wu0_vpmym_16._color-text-status-error_18wu0_vpmym_97:not(#\9){
  color:#d13212;
  color:#d13212;
  color:var(--color-text-status-error-1jj2sL, #d13212);
}
._box_18wu0_vpmym_16._color-text-status-success_18wu0_vpmym_100:not(#\9){
  color:#1d8102;
  color:#1d8102;
  color:var(--color-text-status-success-CuCgtA, #1d8102);
}
._box_18wu0_vpmym_16._color-text-status-info_18wu0_vpmym_103:not(#\9){
  color:#0073bb;
  color:#0073bb;
  color:var(--color-text-status-info-Bc0LvG, #0073bb);
}
._box_18wu0_vpmym_16._color-text-status-inactive_18wu0_vpmym_106:not(#\9){
  color:#687078;
  color:#687078;
  color:var(--color-text-status-inactive-2ZkiuH, #687078);
}
._box_18wu0_vpmym_16._color-inherit_18wu0_vpmym_109:not(#\9){
  color:inherit;
}
._box_18wu0_vpmym_16._font-size-body-s_18wu0_vpmym_112:not(#\9){
  font-size:12px;
  font-size:12px;
  font-size:var(--font-body-s-size-3NP7Rr, 12px);
  line-height:16px;
  line-height:16px;
  line-height:var(--font-body-s-lineHeight-x2Xc-V, 16px);
}
._box_18wu0_vpmym_16._font-size-body-m_18wu0_vpmym_116:not(#\9){
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
}
._box_18wu0_vpmym_16._font-size-heading-xs_18wu0_vpmym_120:not(#\9){
  font-size:16px;
  font-size:16px;
  font-size:var(--font-heading-xs-size-x2Xc-V, 16px);
  line-height:20px;
  line-height:20px;
  line-height:var(--font-heading-xs-lineHeight-8Y2p4W, 20px);
}
._box_18wu0_vpmym_16._font-size-heading-s_18wu0_vpmym_124:not(#\9){
  font-size:16px;
  font-size:16px;
  font-size:var(--font-heading-s-size-x2Xc-V, 16px);
  line-height:20px;
  line-height:20px;
  line-height:var(--font-heading-s-lineHeight-8Y2p4W, 20px);
}
._box_18wu0_vpmym_16._font-size-heading-m_18wu0_vpmym_128:not(#\9){
  font-size:18px;
  font-size:18px;
  font-size:var(--font-heading-m-size-3IMO0y, 18px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-heading-m-lineHeight-2Vn4pu, 22px);
}
._box_18wu0_vpmym_16._font-size-heading-l_18wu0_vpmym_132:not(#\9){
  font-size:18px;
  font-size:18px;
  font-size:var(--font-heading-l-size-3IMO0y, 18px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-heading-l-lineHeight-2Vn4pu, 22px);
}
._box_18wu0_vpmym_16._font-size-heading-xl_18wu0_vpmym_136:not(#\9){
  font-size:28px;
  font-size:28px;
  font-size:var(--font-heading-xl-size-2ac18Z, 28px);
  line-height:36px;
  line-height:36px;
  line-height:var(--font-heading-xl-lineHeight-3i5g66, 36px);
}
._box_18wu0_vpmym_16._font-size-display-l_18wu0_vpmym_140:not(#\9){
  font-size:44px;
  font-size:44px;
  font-size:var(--font-display-l-size-3ybwom, 44px);
  line-height:56px;
  line-height:56px;
  line-height:var(--font-display-l-lineHeight-2EHwYK, 56px);
}
._box_18wu0_vpmym_16._font-weight-light_18wu0_vpmym_144:not(#\9){
  font-weight:300;
}
._box_18wu0_vpmym_16._font-weight-normal_18wu0_vpmym_147:not(#\9){
  font-weight:400;
}
._box_18wu0_vpmym_16._font-weight-bold_18wu0_vpmym_150:not(#\9){
  font-weight:700;
}

._t-left_18wu0_vpmym_154:not(#\9){
  text-align:left;
}

._t-right_18wu0_vpmym_158:not(#\9){
  text-align:right;
}

._t-center_18wu0_vpmym_162:not(#\9){
  text-align:center;
}
._box_18wu0_vpmym_16._p-n_18wu0_vpmym_169:not(#\9){
  padding:0 0;
  padding:0 0;
  padding:var(--space-scaled-none-1ld6ZS, 0) var(--space-none-2rGI2r, 0);
}

._box_18wu0_vpmym_16._p-top-n_18wu0_vpmym_173:not(#\9),
._box_18wu0_vpmym_16._p-vertical-n_18wu0_vpmym_174:not(#\9){
  padding-top:0;
  padding-top:0;
  padding-top:var(--space-scaled-none-1ld6ZS, 0);
}

._box_18wu0_vpmym_16._p-right-n_18wu0_vpmym_178:not(#\9),
._box_18wu0_vpmym_16._p-horizontal-n_18wu0_vpmym_179:not(#\9){
  padding-right:0;
  padding-right:0;
  padding-right:var(--space-none-2rGI2r, 0);
}

._box_18wu0_vpmym_16._p-bottom-n_18wu0_vpmym_183:not(#\9),
._box_18wu0_vpmym_16._p-vertical-n_18wu0_vpmym_174:not(#\9){
  padding-bottom:0;
  padding-bottom:0;
  padding-bottom:var(--space-scaled-none-1ld6ZS, 0);
}

._box_18wu0_vpmym_16._p-left-n_18wu0_vpmym_188:not(#\9),
._box_18wu0_vpmym_16._p-horizontal-n_18wu0_vpmym_179:not(#\9){
  padding-left:0;
  padding-left:0;
  padding-left:var(--space-none-2rGI2r, 0);
}

._box_18wu0_vpmym_16._p-xxxs_18wu0_vpmym_193:not(#\9){
  padding:2px 2px;
  padding:2px 2px;
  padding:var(--space-scaled-xxxs-1yX_gQ, 2px) var(--space-xxxs-2Fa4lw, 2px);
}

._box_18wu0_vpmym_16._p-top-xxxs_18wu0_vpmym_197:not(#\9),
._box_18wu0_vpmym_16._p-vertical-xxxs_18wu0_vpmym_198:not(#\9){
  padding-top:2px;
  padding-top:2px;
  padding-top:var(--space-scaled-xxxs-1yX_gQ, 2px);
}

._box_18wu0_vpmym_16._p-right-xxxs_18wu0_vpmym_202:not(#\9),
._box_18wu0_vpmym_16._p-horizontal-xxxs_18wu0_vpmym_203:not(#\9){
  padding-right:2px;
  padding-right:2px;
  padding-right:var(--space-xxxs-2Fa4lw, 2px);
}

._box_18wu0_vpmym_16._p-bottom-xxxs_18wu0_vpmym_207:not(#\9),
._box_18wu0_vpmym_16._p-vertical-xxxs_18wu0_vpmym_198:not(#\9){
  padding-bottom:2px;
  padding-bottom:2px;
  padding-bottom:var(--space-scaled-xxxs-1yX_gQ, 2px);
}

._box_18wu0_vpmym_16._p-left-xxxs_18wu0_vpmym_212:not(#\9),
._box_18wu0_vpmym_16._p-horizontal-xxxs_18wu0_vpmym_203:not(#\9){
  padding-left:2px;
  padding-left:2px;
  padding-left:var(--space-xxxs-2Fa4lw, 2px);
}

._box_18wu0_vpmym_16._p-xxs_18wu0_vpmym_217:not(#\9){
  padding:4px 4px;
  padding:4px 4px;
  padding:var(--space-scaled-xxs-wz9S8K, 4px) var(--space-xxs-10c0Z4, 4px);
}

._box_18wu0_vpmym_16._p-top-xxs_18wu0_vpmym_221:not(#\9),
._box_18wu0_vpmym_16._p-vertical-xxs_18wu0_vpmym_222:not(#\9){
  padding-top:4px;
  padding-top:4px;
  padding-top:var(--space-scaled-xxs-wz9S8K, 4px);
}

._box_18wu0_vpmym_16._p-right-xxs_18wu0_vpmym_226:not(#\9),
._box_18wu0_vpmym_16._p-horizontal-xxs_18wu0_vpmym_227:not(#\9){
  padding-right:4px;
  padding-right:4px;
  padding-right:var(--space-xxs-10c0Z4, 4px);
}

._box_18wu0_vpmym_16._p-bottom-xxs_18wu0_vpmym_231:not(#\9),
._box_18wu0_vpmym_16._p-vertical-xxs_18wu0_vpmym_222:not(#\9){
  padding-bottom:4px;
  padding-bottom:4px;
  padding-bottom:var(--space-scaled-xxs-wz9S8K, 4px);
}

._box_18wu0_vpmym_16._p-left-xxs_18wu0_vpmym_236:not(#\9),
._box_18wu0_vpmym_16._p-horizontal-xxs_18wu0_vpmym_227:not(#\9){
  padding-left:4px;
  padding-left:4px;
  padding-left:var(--space-xxs-10c0Z4, 4px);
}

._box_18wu0_vpmym_16._p-xs_18wu0_vpmym_241:not(#\9){
  padding:8px 8px;
  padding:8px 8px;
  padding:var(--space-scaled-xs-19adjU, 8px) var(--space-xs-3JTBUA, 8px);
}

._box_18wu0_vpmym_16._p-top-xs_18wu0_vpmym_245:not(#\9),
._box_18wu0_vpmym_16._p-vertical-xs_18wu0_vpmym_246:not(#\9){
  padding-top:8px;
  padding-top:8px;
  padding-top:var(--space-scaled-xs-19adjU, 8px);
}

._box_18wu0_vpmym_16._p-right-xs_18wu0_vpmym_250:not(#\9),
._box_18wu0_vpmym_16._p-horizontal-xs_18wu0_vpmym_251:not(#\9){
  padding-right:8px;
  padding-right:8px;
  padding-right:var(--space-xs-3JTBUA, 8px);
}

._box_18wu0_vpmym_16._p-bottom-xs_18wu0_vpmym_255:not(#\9),
._box_18wu0_vpmym_16._p-vertical-xs_18wu0_vpmym_246:not(#\9){
  padding-bottom:8px;
  padding-bottom:8px;
  padding-bottom:var(--space-scaled-xs-19adjU, 8px);
}

._box_18wu0_vpmym_16._p-left-xs_18wu0_vpmym_260:not(#\9),
._box_18wu0_vpmym_16._p-horizontal-xs_18wu0_vpmym_251:not(#\9){
  padding-left:8px;
  padding-left:8px;
  padding-left:var(--space-xs-3JTBUA, 8px);
}

._box_18wu0_vpmym_16._p-s_18wu0_vpmym_265:not(#\9){
  padding:12px 12px;
  padding:12px 12px;
  padding:var(--space-scaled-s-1sAHT4, 12px) var(--space-s-1Qj4Fv, 12px);
}

._box_18wu0_vpmym_16._p-top-s_18wu0_vpmym_269:not(#\9),
._box_18wu0_vpmym_16._p-vertical-s_18wu0_vpmym_270:not(#\9){
  padding-top:12px;
  padding-top:12px;
  padding-top:var(--space-scaled-s-1sAHT4, 12px);
}

._box_18wu0_vpmym_16._p-right-s_18wu0_vpmym_274:not(#\9),
._box_18wu0_vpmym_16._p-horizontal-s_18wu0_vpmym_275:not(#\9){
  padding-right:12px;
  padding-right:12px;
  padding-right:var(--space-s-1Qj4Fv, 12px);
}

._box_18wu0_vpmym_16._p-bottom-s_18wu0_vpmym_279:not(#\9),
._box_18wu0_vpmym_16._p-vertical-s_18wu0_vpmym_270:not(#\9){
  padding-bottom:12px;
  padding-bottom:12px;
  padding-bottom:var(--space-scaled-s-1sAHT4, 12px);
}

._box_18wu0_vpmym_16._p-left-s_18wu0_vpmym_284:not(#\9),
._box_18wu0_vpmym_16._p-horizontal-s_18wu0_vpmym_275:not(#\9){
  padding-left:12px;
  padding-left:12px;
  padding-left:var(--space-s-1Qj4Fv, 12px);
}

._box_18wu0_vpmym_16._p-m_18wu0_vpmym_289:not(#\9){
  padding:16px 16px;
  padding:16px 16px;
  padding:var(--space-scaled-m-p3tAne, 16px) var(--space-m-208vQh, 16px);
}

._box_18wu0_vpmym_16._p-top-m_18wu0_vpmym_293:not(#\9),
._box_18wu0_vpmym_16._p-vertical-m_18wu0_vpmym_294:not(#\9){
  padding-top:16px;
  padding-top:16px;
  padding-top:var(--space-scaled-m-p3tAne, 16px);
}

._box_18wu0_vpmym_16._p-right-m_18wu0_vpmym_298:not(#\9),
._box_18wu0_vpmym_16._p-horizontal-m_18wu0_vpmym_299:not(#\9){
  padding-right:16px;
  padding-right:16px;
  padding-right:var(--space-m-208vQh, 16px);
}

._box_18wu0_vpmym_16._p-bottom-m_18wu0_vpmym_303:not(#\9),
._box_18wu0_vpmym_16._p-vertical-m_18wu0_vpmym_294:not(#\9){
  padding-bottom:16px;
  padding-bottom:16px;
  padding-bottom:var(--space-scaled-m-p3tAne, 16px);
}

._box_18wu0_vpmym_16._p-left-m_18wu0_vpmym_308:not(#\9),
._box_18wu0_vpmym_16._p-horizontal-m_18wu0_vpmym_299:not(#\9){
  padding-left:16px;
  padding-left:16px;
  padding-left:var(--space-m-208vQh, 16px);
}

._box_18wu0_vpmym_16._p-l_18wu0_vpmym_188:not(#\9){
  padding:20px 20px;
  padding:20px 20px;
  padding:var(--space-scaled-l-17GFGI, 20px) var(--space-l-1RnAcv, 20px);
}

._box_18wu0_vpmym_16._p-top-l_18wu0_vpmym_317:not(#\9),
._box_18wu0_vpmym_16._p-vertical-l_18wu0_vpmym_318:not(#\9){
  padding-top:20px;
  padding-top:20px;
  padding-top:var(--space-scaled-l-17GFGI, 20px);
}

._box_18wu0_vpmym_16._p-right-l_18wu0_vpmym_322:not(#\9),
._box_18wu0_vpmym_16._p-horizontal-l_18wu0_vpmym_323:not(#\9){
  padding-right:20px;
  padding-right:20px;
  padding-right:var(--space-l-1RnAcv, 20px);
}

._box_18wu0_vpmym_16._p-bottom-l_18wu0_vpmym_327:not(#\9),
._box_18wu0_vpmym_16._p-vertical-l_18wu0_vpmym_318:not(#\9){
  padding-bottom:20px;
  padding-bottom:20px;
  padding-bottom:var(--space-scaled-l-17GFGI, 20px);
}

._box_18wu0_vpmym_16._p-left-l_18wu0_vpmym_332:not(#\9),
._box_18wu0_vpmym_16._p-horizontal-l_18wu0_vpmym_323:not(#\9){
  padding-left:20px;
  padding-left:20px;
  padding-left:var(--space-l-1RnAcv, 20px);
}

._box_18wu0_vpmym_16._p-xl_18wu0_vpmym_337:not(#\9){
  padding:24px 24px;
  padding:24px 24px;
  padding:var(--space-scaled-xl-axUbkA, 24px) var(--space-xl-3Pai2K, 24px);
}

._box_18wu0_vpmym_16._p-top-xl_18wu0_vpmym_341:not(#\9),
._box_18wu0_vpmym_16._p-vertical-xl_18wu0_vpmym_342:not(#\9){
  padding-top:24px;
  padding-top:24px;
  padding-top:var(--space-scaled-xl-axUbkA, 24px);
}

._box_18wu0_vpmym_16._p-right-xl_18wu0_vpmym_346:not(#\9),
._box_18wu0_vpmym_16._p-horizontal-xl_18wu0_vpmym_347:not(#\9){
  padding-right:24px;
  padding-right:24px;
  padding-right:var(--space-xl-3Pai2K, 24px);
}

._box_18wu0_vpmym_16._p-bottom-xl_18wu0_vpmym_351:not(#\9),
._box_18wu0_vpmym_16._p-vertical-xl_18wu0_vpmym_342:not(#\9){
  padding-bottom:24px;
  padding-bottom:24px;
  padding-bottom:var(--space-scaled-xl-axUbkA, 24px);
}

._box_18wu0_vpmym_16._p-left-xl_18wu0_vpmym_356:not(#\9),
._box_18wu0_vpmym_16._p-horizontal-xl_18wu0_vpmym_347:not(#\9){
  padding-left:24px;
  padding-left:24px;
  padding-left:var(--space-xl-3Pai2K, 24px);
}

._box_18wu0_vpmym_16._p-xxl_18wu0_vpmym_361:not(#\9){
  padding:32px 32px;
  padding:32px 32px;
  padding:var(--space-scaled-xxl-238u6Z, 32px) var(--space-xxl-2yP61J, 32px);
}

._box_18wu0_vpmym_16._p-top-xxl_18wu0_vpmym_365:not(#\9),
._box_18wu0_vpmym_16._p-vertical-xxl_18wu0_vpmym_366:not(#\9){
  padding-top:32px;
  padding-top:32px;
  padding-top:var(--space-scaled-xxl-238u6Z, 32px);
}

._box_18wu0_vpmym_16._p-right-xxl_18wu0_vpmym_370:not(#\9),
._box_18wu0_vpmym_16._p-horizontal-xxl_18wu0_vpmym_371:not(#\9){
  padding-right:32px;
  padding-right:32px;
  padding-right:var(--space-xxl-2yP61J, 32px);
}

._box_18wu0_vpmym_16._p-bottom-xxl_18wu0_vpmym_375:not(#\9),
._box_18wu0_vpmym_16._p-vertical-xxl_18wu0_vpmym_366:not(#\9){
  padding-bottom:32px;
  padding-bottom:32px;
  padding-bottom:var(--space-scaled-xxl-238u6Z, 32px);
}

._box_18wu0_vpmym_16._p-left-xxl_18wu0_vpmym_380:not(#\9),
._box_18wu0_vpmym_16._p-horizontal-xxl_18wu0_vpmym_371:not(#\9){
  padding-left:32px;
  padding-left:32px;
  padding-left:var(--space-xxl-2yP61J, 32px);
}

._box_18wu0_vpmym_16._m-n_18wu0_vpmym_385:not(#\9){
  margin:0 0;
  margin:0 0;
  margin:var(--space-scaled-none-1ld6ZS, 0) var(--space-none-2rGI2r, 0);
}

._box_18wu0_vpmym_16._m-top-n_18wu0_vpmym_389:not(#\9),
._box_18wu0_vpmym_16._m-vertical-n_18wu0_vpmym_390:not(#\9){
  margin-top:0;
  margin-top:0;
  margin-top:var(--space-scaled-none-1ld6ZS, 0);
}

._box_18wu0_vpmym_16._m-right-n_18wu0_vpmym_394:not(#\9),
._box_18wu0_vpmym_16._m-horizontal-n_18wu0_vpmym_395:not(#\9){
  margin-right:0;
  margin-right:0;
  margin-right:var(--space-none-2rGI2r, 0);
}

._box_18wu0_vpmym_16._m-bottom-n_18wu0_vpmym_399:not(#\9),
._box_18wu0_vpmym_16._m-vertical-n_18wu0_vpmym_390:not(#\9){
  margin-bottom:0;
  margin-bottom:0;
  margin-bottom:var(--space-scaled-none-1ld6ZS, 0);
}

._box_18wu0_vpmym_16._m-left-n_18wu0_vpmym_404:not(#\9),
._box_18wu0_vpmym_16._m-horizontal-n_18wu0_vpmym_395:not(#\9){
  margin-left:0;
  margin-left:0;
  margin-left:var(--space-none-2rGI2r, 0);
}

._box_18wu0_vpmym_16._m-xxxs_18wu0_vpmym_409:not(#\9){
  margin:2px 2px;
  margin:2px 2px;
  margin:var(--space-scaled-xxxs-1yX_gQ, 2px) var(--space-xxxs-2Fa4lw, 2px);
}

._box_18wu0_vpmym_16._m-top-xxxs_18wu0_vpmym_413:not(#\9),
._box_18wu0_vpmym_16._m-vertical-xxxs_18wu0_vpmym_414:not(#\9){
  margin-top:2px;
  margin-top:2px;
  margin-top:var(--space-scaled-xxxs-1yX_gQ, 2px);
}

._box_18wu0_vpmym_16._m-right-xxxs_18wu0_vpmym_418:not(#\9),
._box_18wu0_vpmym_16._m-horizontal-xxxs_18wu0_vpmym_419:not(#\9){
  margin-right:2px;
  margin-right:2px;
  margin-right:var(--space-xxxs-2Fa4lw, 2px);
}

._box_18wu0_vpmym_16._m-bottom-xxxs_18wu0_vpmym_423:not(#\9),
._box_18wu0_vpmym_16._m-vertical-xxxs_18wu0_vpmym_414:not(#\9){
  margin-bottom:2px;
  margin-bottom:2px;
  margin-bottom:var(--space-scaled-xxxs-1yX_gQ, 2px);
}

._box_18wu0_vpmym_16._m-left-xxxs_18wu0_vpmym_428:not(#\9),
._box_18wu0_vpmym_16._m-horizontal-xxxs_18wu0_vpmym_419:not(#\9){
  margin-left:2px;
  margin-left:2px;
  margin-left:var(--space-xxxs-2Fa4lw, 2px);
}

._box_18wu0_vpmym_16._m-xxs_18wu0_vpmym_433:not(#\9){
  margin:4px 4px;
  margin:4px 4px;
  margin:var(--space-scaled-xxs-wz9S8K, 4px) var(--space-xxs-10c0Z4, 4px);
}

._box_18wu0_vpmym_16._m-top-xxs_18wu0_vpmym_437:not(#\9),
._box_18wu0_vpmym_16._m-vertical-xxs_18wu0_vpmym_438:not(#\9){
  margin-top:4px;
  margin-top:4px;
  margin-top:var(--space-scaled-xxs-wz9S8K, 4px);
}

._box_18wu0_vpmym_16._m-right-xxs_18wu0_vpmym_442:not(#\9),
._box_18wu0_vpmym_16._m-horizontal-xxs_18wu0_vpmym_443:not(#\9){
  margin-right:4px;
  margin-right:4px;
  margin-right:var(--space-xxs-10c0Z4, 4px);
}

._box_18wu0_vpmym_16._m-bottom-xxs_18wu0_vpmym_447:not(#\9),
._box_18wu0_vpmym_16._m-vertical-xxs_18wu0_vpmym_438:not(#\9){
  margin-bottom:4px;
  margin-bottom:4px;
  margin-bottom:var(--space-scaled-xxs-wz9S8K, 4px);
}

._box_18wu0_vpmym_16._m-left-xxs_18wu0_vpmym_452:not(#\9),
._box_18wu0_vpmym_16._m-horizontal-xxs_18wu0_vpmym_443:not(#\9){
  margin-left:4px;
  margin-left:4px;
  margin-left:var(--space-xxs-10c0Z4, 4px);
}

._box_18wu0_vpmym_16._m-xs_18wu0_vpmym_457:not(#\9){
  margin:8px 8px;
  margin:8px 8px;
  margin:var(--space-scaled-xs-19adjU, 8px) var(--space-xs-3JTBUA, 8px);
}

._box_18wu0_vpmym_16._m-top-xs_18wu0_vpmym_461:not(#\9),
._box_18wu0_vpmym_16._m-vertical-xs_18wu0_vpmym_462:not(#\9){
  margin-top:8px;
  margin-top:8px;
  margin-top:var(--space-scaled-xs-19adjU, 8px);
}

._box_18wu0_vpmym_16._m-right-xs_18wu0_vpmym_466:not(#\9),
._box_18wu0_vpmym_16._m-horizontal-xs_18wu0_vpmym_467:not(#\9){
  margin-right:8px;
  margin-right:8px;
  margin-right:var(--space-xs-3JTBUA, 8px);
}

._box_18wu0_vpmym_16._m-bottom-xs_18wu0_vpmym_471:not(#\9),
._box_18wu0_vpmym_16._m-vertical-xs_18wu0_vpmym_462:not(#\9){
  margin-bottom:8px;
  margin-bottom:8px;
  margin-bottom:var(--space-scaled-xs-19adjU, 8px);
}

._box_18wu0_vpmym_16._m-left-xs_18wu0_vpmym_476:not(#\9),
._box_18wu0_vpmym_16._m-horizontal-xs_18wu0_vpmym_467:not(#\9){
  margin-left:8px;
  margin-left:8px;
  margin-left:var(--space-xs-3JTBUA, 8px);
}

._box_18wu0_vpmym_16._m-s_18wu0_vpmym_481:not(#\9){
  margin:12px 12px;
  margin:12px 12px;
  margin:var(--space-scaled-s-1sAHT4, 12px) var(--space-s-1Qj4Fv, 12px);
}

._box_18wu0_vpmym_16._m-top-s_18wu0_vpmym_485:not(#\9),
._box_18wu0_vpmym_16._m-vertical-s_18wu0_vpmym_486:not(#\9){
  margin-top:12px;
  margin-top:12px;
  margin-top:var(--space-scaled-s-1sAHT4, 12px);
}

._box_18wu0_vpmym_16._m-right-s_18wu0_vpmym_490:not(#\9),
._box_18wu0_vpmym_16._m-horizontal-s_18wu0_vpmym_491:not(#\9){
  margin-right:12px;
  margin-right:12px;
  margin-right:var(--space-s-1Qj4Fv, 12px);
}

._box_18wu0_vpmym_16._m-bottom-s_18wu0_vpmym_495:not(#\9),
._box_18wu0_vpmym_16._m-vertical-s_18wu0_vpmym_486:not(#\9){
  margin-bottom:12px;
  margin-bottom:12px;
  margin-bottom:var(--space-scaled-s-1sAHT4, 12px);
}

._box_18wu0_vpmym_16._m-left-s_18wu0_vpmym_500:not(#\9),
._box_18wu0_vpmym_16._m-horizontal-s_18wu0_vpmym_491:not(#\9){
  margin-left:12px;
  margin-left:12px;
  margin-left:var(--space-s-1Qj4Fv, 12px);
}

._box_18wu0_vpmym_16._m-m_18wu0_vpmym_505:not(#\9){
  margin:16px 16px;
  margin:16px 16px;
  margin:var(--space-scaled-m-p3tAne, 16px) var(--space-m-208vQh, 16px);
}

._box_18wu0_vpmym_16._m-top-m_18wu0_vpmym_509:not(#\9),
._box_18wu0_vpmym_16._m-vertical-m_18wu0_vpmym_510:not(#\9){
  margin-top:16px;
  margin-top:16px;
  margin-top:var(--space-scaled-m-p3tAne, 16px);
}

._box_18wu0_vpmym_16._m-right-m_18wu0_vpmym_514:not(#\9),
._box_18wu0_vpmym_16._m-horizontal-m_18wu0_vpmym_515:not(#\9){
  margin-right:16px;
  margin-right:16px;
  margin-right:var(--space-m-208vQh, 16px);
}

._box_18wu0_vpmym_16._m-bottom-m_18wu0_vpmym_519:not(#\9),
._box_18wu0_vpmym_16._m-vertical-m_18wu0_vpmym_510:not(#\9){
  margin-bottom:16px;
  margin-bottom:16px;
  margin-bottom:var(--space-scaled-m-p3tAne, 16px);
}

._box_18wu0_vpmym_16._m-left-m_18wu0_vpmym_524:not(#\9),
._box_18wu0_vpmym_16._m-horizontal-m_18wu0_vpmym_515:not(#\9){
  margin-left:16px;
  margin-left:16px;
  margin-left:var(--space-m-208vQh, 16px);
}

._box_18wu0_vpmym_16._m-l_18wu0_vpmym_404:not(#\9){
  margin:20px 20px;
  margin:20px 20px;
  margin:var(--space-scaled-l-17GFGI, 20px) var(--space-l-1RnAcv, 20px);
}

._box_18wu0_vpmym_16._m-top-l_18wu0_vpmym_533:not(#\9),
._box_18wu0_vpmym_16._m-vertical-l_18wu0_vpmym_534:not(#\9){
  margin-top:20px;
  margin-top:20px;
  margin-top:var(--space-scaled-l-17GFGI, 20px);
}

._box_18wu0_vpmym_16._m-right-l_18wu0_vpmym_538:not(#\9),
._box_18wu0_vpmym_16._m-horizontal-l_18wu0_vpmym_539:not(#\9){
  margin-right:20px;
  margin-right:20px;
  margin-right:var(--space-l-1RnAcv, 20px);
}

._box_18wu0_vpmym_16._m-bottom-l_18wu0_vpmym_543:not(#\9),
._box_18wu0_vpmym_16._m-vertical-l_18wu0_vpmym_534:not(#\9){
  margin-bottom:20px;
  margin-bottom:20px;
  margin-bottom:var(--space-scaled-l-17GFGI, 20px);
}

._box_18wu0_vpmym_16._m-left-l_18wu0_vpmym_548:not(#\9),
._box_18wu0_vpmym_16._m-horizontal-l_18wu0_vpmym_539:not(#\9){
  margin-left:20px;
  margin-left:20px;
  margin-left:var(--space-l-1RnAcv, 20px);
}

._box_18wu0_vpmym_16._m-xl_18wu0_vpmym_553:not(#\9){
  margin:24px 24px;
  margin:24px 24px;
  margin:var(--space-scaled-xl-axUbkA, 24px) var(--space-xl-3Pai2K, 24px);
}

._box_18wu0_vpmym_16._m-top-xl_18wu0_vpmym_557:not(#\9),
._box_18wu0_vpmym_16._m-vertical-xl_18wu0_vpmym_558:not(#\9){
  margin-top:24px;
  margin-top:24px;
  margin-top:var(--space-scaled-xl-axUbkA, 24px);
}

._box_18wu0_vpmym_16._m-right-xl_18wu0_vpmym_562:not(#\9),
._box_18wu0_vpmym_16._m-horizontal-xl_18wu0_vpmym_563:not(#\9){
  margin-right:24px;
  margin-right:24px;
  margin-right:var(--space-xl-3Pai2K, 24px);
}

._box_18wu0_vpmym_16._m-bottom-xl_18wu0_vpmym_567:not(#\9),
._box_18wu0_vpmym_16._m-vertical-xl_18wu0_vpmym_558:not(#\9){
  margin-bottom:24px;
  margin-bottom:24px;
  margin-bottom:var(--space-scaled-xl-axUbkA, 24px);
}

._box_18wu0_vpmym_16._m-left-xl_18wu0_vpmym_572:not(#\9),
._box_18wu0_vpmym_16._m-horizontal-xl_18wu0_vpmym_563:not(#\9){
  margin-left:24px;
  margin-left:24px;
  margin-left:var(--space-xl-3Pai2K, 24px);
}

._box_18wu0_vpmym_16._m-xxl_18wu0_vpmym_577:not(#\9){
  margin:32px 32px;
  margin:32px 32px;
  margin:var(--space-scaled-xxl-238u6Z, 32px) var(--space-xxl-2yP61J, 32px);
}

._box_18wu0_vpmym_16._m-top-xxl_18wu0_vpmym_581:not(#\9),
._box_18wu0_vpmym_16._m-vertical-xxl_18wu0_vpmym_582:not(#\9){
  margin-top:32px;
  margin-top:32px;
  margin-top:var(--space-scaled-xxl-238u6Z, 32px);
}

._box_18wu0_vpmym_16._m-right-xxl_18wu0_vpmym_586:not(#\9),
._box_18wu0_vpmym_16._m-horizontal-xxl_18wu0_vpmym_587:not(#\9){
  margin-right:32px;
  margin-right:32px;
  margin-right:var(--space-xxl-2yP61J, 32px);
}

._box_18wu0_vpmym_16._m-bottom-xxl_18wu0_vpmym_591:not(#\9),
._box_18wu0_vpmym_16._m-vertical-xxl_18wu0_vpmym_582:not(#\9){
  margin-bottom:32px;
  margin-bottom:32px;
  margin-bottom:var(--space-scaled-xxl-238u6Z, 32px);
}

._box_18wu0_vpmym_16._m-left-xxl_18wu0_vpmym_596:not(#\9),
._box_18wu0_vpmym_16._m-horizontal-xxl_18wu0_vpmym_587:not(#\9){
  margin-left:32px;
  margin-left:32px;
  margin-left:var(--space-xxl-2yP61J, 32px);
}

._box_18wu0_vpmym_16._d-block_18wu0_vpmym_601:not(#\9){
  display:block;
}
._box_18wu0_vpmym_16._d-inline_18wu0_vpmym_604:not(#\9){
  display:inline;
}
._box_18wu0_vpmym_16._d-inline-block_18wu0_vpmym_607:not(#\9){
  display:inline-block;
}
._box_18wu0_vpmym_16._d-none_18wu0_vpmym_610:not(#\9){
  display:none;
}

._f-left_18wu0_vpmym_614:not(#\9){
  float:left;
}

._f-right_18wu0_vpmym_618:not(#\9){
  float:right;
}
._root_18582_tghqp_3:not(#\9){
  display:flex;
}

._child_18582_tghqp_7:not(#\9):empty{
  display:none;
}
._horizontal_18582_tghqp_18:not(#\9){
  flex-direction:row;
  flex-wrap:wrap;
}
._horizontal-xxxs_18582_tghqp_22:not(#\9){
  margin-left:calc(-1 * 2px);
  margin-left:calc(-1 * 2px);
  margin-left:calc(-1 * var(--space-xxxs-2Fa4lw, 2px));
  margin-bottom:calc(-1 * 2px);
  margin-bottom:calc(-1 * 2px);
  margin-bottom:calc(-1 * var(--space-xxxs-2Fa4lw, 2px));
}
._horizontal-xxs_18582_tghqp_26:not(#\9){
  margin-left:calc(-1 * 4px);
  margin-left:calc(-1 * 4px);
  margin-left:calc(-1 * var(--space-xxs-10c0Z4, 4px));
  margin-bottom:calc(-1 * 4px);
  margin-bottom:calc(-1 * 4px);
  margin-bottom:calc(-1 * var(--space-xxs-10c0Z4, 4px));
}
._horizontal-xs_18582_tghqp_30:not(#\9){
  margin-left:calc(-1 * 8px);
  margin-left:calc(-1 * 8px);
  margin-left:calc(-1 * var(--space-xs-3JTBUA, 8px));
  margin-bottom:calc(-1 * 8px);
  margin-bottom:calc(-1 * 8px);
  margin-bottom:calc(-1 * var(--space-xs-3JTBUA, 8px));
}
._horizontal-s_18582_tghqp_34:not(#\9){
  margin-left:calc(-1 * 12px);
  margin-left:calc(-1 * 12px);
  margin-left:calc(-1 * var(--space-s-1Qj4Fv, 12px));
  margin-bottom:calc(-1 * 12px);
  margin-bottom:calc(-1 * 12px);
  margin-bottom:calc(-1 * var(--space-s-1Qj4Fv, 12px));
}
._horizontal-m_18582_tghqp_38:not(#\9){
  margin-left:calc(-1 * 16px);
  margin-left:calc(-1 * 16px);
  margin-left:calc(-1 * var(--space-m-208vQh, 16px));
  margin-bottom:calc(-1 * 16px);
  margin-bottom:calc(-1 * 16px);
  margin-bottom:calc(-1 * var(--space-m-208vQh, 16px));
}
._horizontal-l_18582_tghqp_42:not(#\9){
  margin-left:calc(-1 * 20px);
  margin-left:calc(-1 * 20px);
  margin-left:calc(-1 * var(--space-l-1RnAcv, 20px));
  margin-bottom:calc(-1 * 20px);
  margin-bottom:calc(-1 * 20px);
  margin-bottom:calc(-1 * var(--space-l-1RnAcv, 20px));
}
._horizontal-xl_18582_tghqp_46:not(#\9){
  margin-left:calc(-1 * 24px);
  margin-left:calc(-1 * 24px);
  margin-left:calc(-1 * var(--space-xl-3Pai2K, 24px));
  margin-bottom:calc(-1 * 24px);
  margin-bottom:calc(-1 * 24px);
  margin-bottom:calc(-1 * var(--space-xl-3Pai2K, 24px));
}
._horizontal-xxl_18582_tghqp_50:not(#\9){
  margin-left:calc(-1 * 32px);
  margin-left:calc(-1 * 32px);
  margin-left:calc(-1 * var(--space-xxl-2yP61J, 32px));
  margin-bottom:calc(-1 * 32px);
  margin-bottom:calc(-1 * 32px);
  margin-bottom:calc(-1 * var(--space-xxl-2yP61J, 32px));
}

._child-horizontal-xxxs_18582_tghqp_55:not(#\9){
  margin-left:2px;
  margin-left:2px;
  margin-left:var(--space-xxxs-2Fa4lw, 2px);
  margin-bottom:2px;
  margin-bottom:2px;
  margin-bottom:var(--space-xxxs-2Fa4lw, 2px);
  min-width:1px;
}

._child-horizontal-xxs_18582_tghqp_61:not(#\9){
  margin-left:4px;
  margin-left:4px;
  margin-left:var(--space-xxs-10c0Z4, 4px);
  margin-bottom:4px;
  margin-bottom:4px;
  margin-bottom:var(--space-xxs-10c0Z4, 4px);
  min-width:1px;
}

._child-horizontal-xs_18582_tghqp_67:not(#\9){
  margin-left:8px;
  margin-left:8px;
  margin-left:var(--space-xs-3JTBUA, 8px);
  margin-bottom:8px;
  margin-bottom:8px;
  margin-bottom:var(--space-xs-3JTBUA, 8px);
  min-width:1px;
}

._child-horizontal-s_18582_tghqp_73:not(#\9){
  margin-left:12px;
  margin-left:12px;
  margin-left:var(--space-s-1Qj4Fv, 12px);
  margin-bottom:12px;
  margin-bottom:12px;
  margin-bottom:var(--space-s-1Qj4Fv, 12px);
  min-width:1px;
}

._child-horizontal-m_18582_tghqp_79:not(#\9){
  margin-left:16px;
  margin-left:16px;
  margin-left:var(--space-m-208vQh, 16px);
  margin-bottom:16px;
  margin-bottom:16px;
  margin-bottom:var(--space-m-208vQh, 16px);
  min-width:1px;
}

._child-horizontal-l_18582_tghqp_85:not(#\9){
  margin-left:20px;
  margin-left:20px;
  margin-left:var(--space-l-1RnAcv, 20px);
  margin-bottom:20px;
  margin-bottom:20px;
  margin-bottom:var(--space-l-1RnAcv, 20px);
  min-width:1px;
}

._child-horizontal-xl_18582_tghqp_91:not(#\9){
  margin-left:24px;
  margin-left:24px;
  margin-left:var(--space-xl-3Pai2K, 24px);
  margin-bottom:24px;
  margin-bottom:24px;
  margin-bottom:var(--space-xl-3Pai2K, 24px);
  min-width:1px;
}

._child-horizontal-xxl_18582_tghqp_97:not(#\9){
  margin-left:32px;
  margin-left:32px;
  margin-left:var(--space-xxl-2yP61J, 32px);
  margin-bottom:32px;
  margin-bottom:32px;
  margin-bottom:var(--space-xxl-2yP61J, 32px);
  min-width:1px;
}
._vertical_18582_tghqp_106:not(#\9){
  flex-direction:column;
}

._child-vertical-xxxs_18582_tghqp_110:not(#\9):not(:last-child){
  margin-bottom:2px;
  margin-bottom:2px;
  margin-bottom:var(--space-xxxs-2Fa4lw, 2px);
}

._child-vertical-xxs_18582_tghqp_114:not(#\9):not(:last-child){
  margin-bottom:4px;
  margin-bottom:4px;
  margin-bottom:var(--space-xxs-10c0Z4, 4px);
}

._child-vertical-xs_18582_tghqp_118:not(#\9):not(:last-child){
  margin-bottom:8px;
  margin-bottom:8px;
  margin-bottom:var(--space-xs-3JTBUA, 8px);
}

._child-vertical-s_18582_tghqp_122:not(#\9):not(:last-child){
  margin-bottom:12px;
  margin-bottom:12px;
  margin-bottom:var(--space-scaled-s-1sAHT4, 12px);
}

._child-vertical-m_18582_tghqp_126:not(#\9):not(:last-child){
  margin-bottom:16px;
  margin-bottom:16px;
  margin-bottom:var(--space-scaled-m-p3tAne, 16px);
}

._child-vertical-l_18582_tghqp_130:not(#\9):not(:last-child){
  margin-bottom:20px;
  margin-bottom:20px;
  margin-bottom:var(--space-scaled-l-17GFGI, 20px);
}

._child-vertical-xl_18582_tghqp_134:not(#\9):not(:last-child){
  margin-bottom:24px;
  margin-bottom:24px;
  margin-bottom:var(--space-scaled-xl-axUbkA, 24px);
}

._child-vertical-xxl_18582_tghqp_138:not(#\9):not(:last-child){
  margin-bottom:32px;
  margin-bottom:32px;
  margin-bottom:var(--space-scaled-xxl-238u6Z, 32px);
}
._arrow_xjuzf_e0h3u_5:not(#\9){
  width:20px;
  height:10px;
}
._arrow-outer_xjuzf_e0h3u_9:not(#\9), ._arrow-inner_xjuzf_e0h3u_9:not(#\9){
  position:absolute;
  overflow:hidden;
  width:20px;
  height:10px;
  top:0;
  left:0;
}
._arrow-outer_xjuzf_e0h3u_9:not(#\9)::after, ._arrow-inner_xjuzf_e0h3u_9:not(#\9)::after{
  content:"";
  box-sizing:border-box;
  display:inline-block;
  position:absolute;
  border-radius:2px 0 0 0;
  bottom:0;
  left:0;
  width:14px;
  height:14px;
  -webkit-transform:rotate(45deg);
          transform:rotate(45deg);
  -webkit-transform-origin:0 100%;
          transform-origin:0 100%;
}
._arrow-outer_xjuzf_e0h3u_9:not(#\9)::after{
  background-color:#d5dbdb;
  background-color:#d5dbdb;
  background-color:var(--color-border-popover-hSim3z, #d5dbdb);
}
._arrow-inner_xjuzf_e0h3u_9:not(#\9){
  top:2px;
}
._arrow-inner_xjuzf_e0h3u_9:not(#\9)::after{
  border-radius:1px 0 0 0;
  background-color:#ffffff;
  background-color:#ffffff;
  background-color:var(--color-background-popover-BPqwPK, #ffffff);
}
._arrow-position-right-top_xjuzf_e0h3u_40 > ._arrow-outer_xjuzf_e0h3u_9:not(#\9)::after, ._arrow-position-right-bottom_xjuzf_e0h3u_40 > ._arrow-outer_xjuzf_e0h3u_9:not(#\9)::after{
  box-shadow:-0.71px 0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:-0.71px 0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:-0.71px 0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}
._arrow-position-left-top_xjuzf_e0h3u_43 > ._arrow-outer_xjuzf_e0h3u_9:not(#\9)::after, ._arrow-position-left-bottom_xjuzf_e0h3u_43 > ._arrow-outer_xjuzf_e0h3u_9:not(#\9)::after{
  box-shadow:0.71px -0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0.71px -0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0.71px -0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}
._arrow-position-top-center_xjuzf_e0h3u_46 > ._arrow-outer_xjuzf_e0h3u_9:not(#\9)::after, ._arrow-position-top-responsive_xjuzf_e0h3u_46 > ._arrow-outer_xjuzf_e0h3u_9:not(#\9)::after{
  box-shadow:-0.71px -0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:-0.71px -0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:-0.71px -0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}
._arrow-position-bottom-center_xjuzf_e0h3u_49 > ._arrow-outer_xjuzf_e0h3u_9:not(#\9)::after, ._arrow-position-bottom-responsive_xjuzf_e0h3u_49 > ._arrow-outer_xjuzf_e0h3u_9:not(#\9)::after{
  box-shadow:0.71px 0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0.71px 0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0.71px 0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}
._body_xjuzf_e0h3u_55:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  word-wrap:break-word;
  max-width:100%;
  overflow:hidden;
  box-sizing:border-box;
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  padding:12px 16px;
  padding:12px 16px;
  padding:var(--space-s-1Qj4Fv, 12px) var(--space-m-208vQh, 16px);
  background-color:#ffffff;
  background-color:#ffffff;
  background-color:var(--color-background-popover-BPqwPK, #ffffff);
  box-shadow:0px 1px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0px 1px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0px 1px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
  border:1px solid #d5dbdb;
  border:1px solid #d5dbdb;
  border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-popover-hSim3z, #d5dbdb);
}
._body-overflow-visible_xjuzf_e0h3u_73:not(#\9){
  overflow:visible;
}

._variant-annotation_xjuzf_e0h3u_77:not(#\9){
  background-color:#f1faff;
  background-color:#f1faff;
  background-color:var(--color-background-status-info-WkeJRF, #f1faff);
  border-color:#0073bb;
  border-color:#0073bb;
  border-color:var(--color-border-status-info-3uW6lA, #0073bb);
}

._body-size-small_xjuzf_e0h3u_82:not(#\9){
  max-width:210px;
}
._body-size-small_xjuzf_e0h3u_82._fixed-width_xjuzf_e0h3u_85:not(#\9){
  width:210px;
}

._body-size-medium_xjuzf_e0h3u_89:not(#\9){
  max-width:310px;
}
._body-size-medium_xjuzf_e0h3u_89._fixed-width_xjuzf_e0h3u_85:not(#\9){
  width:310px;
}

._body-size-large_xjuzf_e0h3u_96:not(#\9){
  max-width:480px;
}
._body-size-large_xjuzf_e0h3u_96._fixed-width_xjuzf_e0h3u_85:not(#\9){
  width:480px;
}

._has-dismiss_xjuzf_e0h3u_103:not(#\9){
  display:flex;
  align-items:flex-start;
}

._dismiss_xjuzf_e0h3u_108:not(#\9){
  margin:calc(-1 * (4px + 2px));
  margin:calc(-1 * (4px + 2px));
  margin:calc(-1 * (var(--space-xxs-10c0Z4, 4px) + var(--space-xxxs-2Fa4lw, 2px)));
  margin-left:0;
  flex:0 0 auto;
  order:1;
}

._header_xjuzf_e0h3u_119:not(#\9){
  word-wrap:break-word;
  max-width:100%;
  overflow:hidden;
  font-size:16px;
  font-size:16px;
  font-size:var(--font-heading-xs-size-x2Xc-V, 16px);
  line-height:20px;
  line-height:20px;
  line-height:var(--font-heading-xs-lineHeight-8Y2p4W, 20px);
  margin-bottom:8px;
  margin-bottom:8px;
  margin-bottom:var(--space-xs-3JTBUA, 8px);
  flex:1 1 auto;
}
._header_xjuzf_e0h3u_119 > h2:not(#\9){
  font-size:16px;
  font-size:16px;
  font-size:var(--font-heading-xs-size-x2Xc-V, 16px);
  line-height:20px;
  line-height:20px;
  line-height:var(--font-heading-xs-lineHeight-8Y2p4W, 20px);
  display:inline;
  font-weight:400;
}

._content_xjuzf_e0h3u_135:not(#\9){
  word-wrap:break-word;
  max-width:100%;
  overflow:hidden;
  color:#545b64;
  color:#545b64;
  color:var(--color-text-body-secondary-1oBHNI, #545b64);
  flex:1 1 auto;
}
._content-overflow-visible_xjuzf_e0h3u_142:not(#\9){
  overflow:visible;
}

._container_xjuzf_e0h3u_146:not(#\9){
  display:inline-block;
  position:fixed;
  top:-9999px;
  left:-9999px;
  z-index:2000;
}

._container-arrow_xjuzf_e0h3u_154:not(#\9){
  position:absolute;
  display:inline-block;
}
._container-arrow-position-right-top_xjuzf_e0h3u_158:not(#\9), ._container-arrow-position-right-bottom_xjuzf_e0h3u_158:not(#\9){
  -webkit-transform:rotate(-90deg);
          transform:rotate(-90deg);
  -webkit-transform-origin:0 100%;
          transform-origin:0 100%;
}
._container-arrow-position-right-top_xjuzf_e0h3u_158:not(#\9){
  top:calc(12px + 10px);
  left:0;
}
._container-arrow-position-right-bottom_xjuzf_e0h3u_158:not(#\9){
  bottom:12px;
  left:0;
}
._container-arrow-position-left-top_xjuzf_e0h3u_170:not(#\9), ._container-arrow-position-left-bottom_xjuzf_e0h3u_170:not(#\9){
  -webkit-transform:rotate(90deg);
          transform:rotate(90deg);
  -webkit-transform-origin:100% 100%;
          transform-origin:100% 100%;
}
._container-arrow-position-left-top_xjuzf_e0h3u_170:not(#\9){
  top:calc(12px + 10px);
  right:0;
}
._container-arrow-position-left-bottom_xjuzf_e0h3u_170:not(#\9){
  bottom:12px;
  right:0;
}
._container-arrow-position-top-center_xjuzf_e0h3u_182:not(#\9), ._container-arrow-position-top-responsive_xjuzf_e0h3u_182:not(#\9){
  -webkit-transform:rotate(180deg);
          transform:rotate(180deg);
  -webkit-transform-origin:50% 50%;
          transform-origin:50% 50%;
}
._container-arrow-position-top-center_xjuzf_e0h3u_182:not(#\9){
  bottom:-10px;
  left:calc(50% - 10px);
}
._container-arrow-position-bottom-center_xjuzf_e0h3u_190:not(#\9){
  top:-10px;
  left:calc(50% - 10px);
}
._container_xjuzf_e0h3u_146:not(#\9){
  -webkit-animation:_awsui-motion-fade-in_xjuzf_e0h3u_1 180ms ease-out;
          animation:_awsui-motion-fade-in_xjuzf_e0h3u_1 180ms ease-out;
  animation:_awsui-motion-fade-in_xjuzf_e0h3u_1 180ms ease-out;
  -webkit-animation:_awsui-motion-fade-in_xjuzf_e0h3u_1 var(--motion-duration-show-paced-2crOWV, 180ms) var(--motion-easing-show-paced-25z_bd, ease-out);
          animation:_awsui-motion-fade-in_xjuzf_e0h3u_1 var(--motion-duration-show-paced-2crOWV, 180ms) var(--motion-easing-show-paced-25z_bd, ease-out);
}
@-webkit-keyframes _awsui-motion-fade-in_xjuzf_e0h3u_1{
  from{
    opacity:0.2;
  }
  to{
    opacity:1;
  }
}
@keyframes _awsui-motion-fade-in_xjuzf_e0h3u_1{
  from{
    opacity:0.2;
  }
  to{
    opacity:1;
  }
}
@media (prefers-reduced-motion: reduce){
  ._container_xjuzf_e0h3u_146:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._container_xjuzf_e0h3u_146:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._root_xjuzf_e0h3u_219:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  color:inherit;
}

._trigger_xjuzf_e0h3u_230:not(#\9){
  display:inline-block;
  color:inherit;
}

._trigger-type-text_xjuzf_e0h3u_235:not(#\9){
  border:0;
  margin:0;
  padding:0;
  background-color:transparent;
  cursor:pointer;
  border-bottom:1px dashed currentColor;
}
._trigger-type-text_xjuzf_e0h3u_235:not(#\9):focus{
  outline:none;
}
._trigger-type-text_xjuzf_e0h3u_235[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._trigger-type-text_xjuzf_e0h3u_235[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(1px - 1px);
}
._trigger-type-text_xjuzf_e0h3u_235[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-1px;
  top:-1px;
  width:calc(100% + 2 * 1px);
  height:calc(100% + 2 * 1px);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._trigger-type-text_xjuzf_e0h3u_235 > span:not(#\9){
  position:relative;
}
._arrow_lm6vo_1bdx0_3:not(#\9){
  width:20px;
  height:10px;
}
._arrow-outer_lm6vo_1bdx0_7:not(#\9), ._arrow-inner_lm6vo_1bdx0_7:not(#\9){
  position:absolute;
  overflow:hidden;
  width:20px;
  height:10px;
  top:0;
  left:0;
}
._arrow-outer_lm6vo_1bdx0_7:not(#\9)::after, ._arrow-inner_lm6vo_1bdx0_7:not(#\9)::after{
  content:"";
  box-sizing:border-box;
  display:inline-block;
  position:absolute;
  border-radius:2px 0 0 0;
  bottom:0;
  left:0;
  width:14px;
  height:14px;
  -webkit-transform:rotate(45deg);
          transform:rotate(45deg);
  -webkit-transform-origin:0 100%;
          transform-origin:0 100%;
}
._arrow-outer_lm6vo_1bdx0_7:not(#\9)::after{
  background-color:#0073bb;
  background-color:#0073bb;
  background-color:var(--color-border-status-info-3uW6lA, #0073bb);
}
._arrow-inner_lm6vo_1bdx0_7:not(#\9){
  top:2px;
}
._arrow-inner_lm6vo_1bdx0_7:not(#\9)::after{
  border-radius:1px 0 0 0;
  background-color:#f1faff;
  background-color:#f1faff;
  background-color:var(--color-background-status-info-WkeJRF, #f1faff);
}
._arrow-position-right-top_lm6vo_1bdx0_38 > ._arrow-outer_lm6vo_1bdx0_7:not(#\9)::after, ._arrow-position-right-bottom_lm6vo_1bdx0_38 > ._arrow-outer_lm6vo_1bdx0_7:not(#\9)::after{
  box-shadow:-0.71px 0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:-0.71px 0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:-0.71px 0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}
._arrow-position-left-top_lm6vo_1bdx0_41 > ._arrow-outer_lm6vo_1bdx0_7:not(#\9)::after, ._arrow-position-left-bottom_lm6vo_1bdx0_41 > ._arrow-outer_lm6vo_1bdx0_7:not(#\9)::after{
  box-shadow:0.71px -0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0.71px -0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0.71px -0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}
._arrow-position-top-center_lm6vo_1bdx0_44 > ._arrow-outer_lm6vo_1bdx0_7:not(#\9)::after, ._arrow-position-top-responsive_lm6vo_1bdx0_44 > ._arrow-outer_lm6vo_1bdx0_7:not(#\9)::after{
  box-shadow:-0.71px -0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:-0.71px -0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:-0.71px -0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}
._arrow-position-bottom-center_lm6vo_1bdx0_47 > ._arrow-outer_lm6vo_1bdx0_7:not(#\9)::after, ._arrow-position-bottom-responsive_lm6vo_1bdx0_47 > ._arrow-outer_lm6vo_1bdx0_7:not(#\9)::after{
  box-shadow:0.71px 0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0.71px 0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0.71px 0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}

._root_lm6vo_1bdx0_51:not(#\9),
._layout-wrapper_lm6vo_1bdx0_52:not(#\9){
  display:flex;
  flex-direction:column;
}

._root-no-scroll_lm6vo_1bdx0_57:not(#\9){
  position:relative;
  z-index:1;
}

._close-button_lm6vo_1bdx0_62:not(#\9){
  position:absolute;
  outline:none;
  right:16px;
  right:16px;
  right:var(--space-m-208vQh, 16px);
  top:15px;
  top:15px;
  top:var(--size-vertical-panel-icon-offset-_sbW5y, 15px);
}

._layout_lm6vo_1bdx0_52:not(#\9){
  flex:1 1 auto;
  display:flex;
  align-items:stretch;
  background-color:#f2f3f3;
  background-color:#f2f3f3;
  background-color:var(--color-background-layout-main-3Bydyi, #f2f3f3);
}
._layout-no-scroll_lm6vo_1bdx0_75:not(#\9){
  overflow:hidden;
}

._layout-main_lm6vo_1bdx0_79:not(#\9){
  flex:1 1;
  min-width:0;
  background-color:#f2f3f3;
  background-color:#f2f3f3;
  background-color:var(--color-background-layout-main-3Bydyi, #f2f3f3);
  position:relative;
}
._layout-main-scrollable_lm6vo_1bdx0_85:not(#\9){
  overflow:auto;
}
._unfocusable_lm6vo_1bdx0_90 *:not(#\9){
  visibility:hidden;
}

._drawer_lm6vo_1bdx0_94:not(#\9){
  flex-shrink:0;
  position:relative;
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 var(--color-shadow-medium-3CtI4L, rgba(0, 28, 36, 0.3)), 1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15)), -1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15));
  word-wrap:break-word;
}
._drawer_lm6vo_1bdx0_94:not(#\9):not(._drawer-mobile_lm6vo_1bdx0_100){
  z-index:850;
}
._drawer-closed_lm6vo_1bdx0_103:not(#\9){
  cursor:pointer;
  min-width:40px;
}
._drawer-closed_lm6vo_1bdx0_103._drawer-mobile_lm6vo_1bdx0_100:not(#\9){
  display:none;
}

._drawer-content_lm6vo_1bdx0_111:not(#\9){
  position:fixed;
  overflow:auto;
  background-color:#ffffff;
  background-color:#ffffff;
  background-color:var(--color-background-layout-panel-content-1Z_Abk, #ffffff);
}
._drawer-mobile_lm6vo_1bdx0_100 > ._drawer-content_lm6vo_1bdx0_111:not(#\9){
  z-index:1001;
  top:0;
  bottom:0;
  left:0;
  right:0;
}
._drawer-closed_lm6vo_1bdx0_103 > ._drawer-content_lm6vo_1bdx0_111:not(#\9){
  width:40px;
}
._drawer-closed_lm6vo_1bdx0_103 > ._drawer-content_lm6vo_1bdx0_111:not(#\9):hover{
  background:#eaeded;
  background:#eaeded;
  background:var(--color-background-layout-panel-hover-38loDV, #eaeded);
}
._drawer-content_lm6vo_1bdx0_111 > [aria-hidden=true]:not(#\9){
  display:none;
}

._toggle_lm6vo_1bdx0_133:not(#\9){
  box-sizing:border-box;
  padding:4px 7px;
  padding:4px 7px;
  padding:var(--space-xxs-10c0Z4, 4px) 7px;
}

._breadcrumbs-desktop_lm6vo_1bdx0_138:not(#\9){
  padding:16px 40px 12px;
  padding:16px 40px 12px;
  padding:var(--space-scaled-m-p3tAne, 16px) var(--space-scaled-2x-xxxl-3VX7k3, 40px) var(--space-scaled-s-1sAHT4, 12px);
}

._content-wrapper_lm6vo_1bdx0_142:not(#\9){
  padding:0 40px 20px;
  padding:0 40px 20px;
  padding:0 var(--space-scaled-2x-xxxl-3VX7k3, 40px) var(--space-scaled-l-17GFGI, 20px);
}
._content-wrapper-mobile_lm6vo_1bdx0_145:not(#\9){
  padding-left:20px;
  padding-left:20px;
  padding-left:var(--space-l-1RnAcv, 20px);
  padding-right:20px;
  padding-right:20px;
  padding-right:var(--space-l-1RnAcv, 20px);
}
._content-wrapper_lm6vo_1bdx0_142:not(#\9):first-child{
  padding-top:16px;
  padding-top:16px;
  padding-top:var(--space-scaled-m-p3tAne, 16px);
}

._notifications-sticky_lm6vo_1bdx0_153:not(#\9){
  top:0;
  position:sticky;
  z-index:825;
}

._announcement-popover-target_lm6vo_1bdx0_159:not(#\9){
  position:fixed;
  pointer-events:none;
  -webkit-transform:translateY(32px);
          transform:translateY(32px);
  transform:translateY(32px);
  -webkit-transform:translateY(var(--space-xxl-2yP61J, 32px));
          transform:translateY(var(--space-xxl-2yP61J, 32px));
}

._navigation-toggle_lm6vo_1bdx0_165 > *:not(#\9){
  pointer-events:none;
}

._tools-toggle_lm6vo_1bdx0_169 > *:not(#\9){
  pointer-events:none;
}
._block-body-scroll_19d8l_30kom_3:not(#\9){
  overflow:hidden;
}

._mobile-bar_19d8l_30kom_7:not(#\9){
  position:sticky;
  display:flex;
  align-items:center;
  flex-shrink:0;
  z-index:1000;
  width:100%;
  height:calc(2 * 16px + 8px);
  height:calc(2 * 16px + 8px);
  height:calc(2 * var(--space-m-208vQh, 16px) + var(--space-scaled-xs-19adjU, 8px));
  box-sizing:border-box;
  background-color:#ffffff;
  background-color:#ffffff;
  background-color:var(--color-background-layout-panel-content-1Z_Abk, #ffffff);
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 var(--color-shadow-medium-3CtI4L, rgba(0, 28, 36, 0.3)), 1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15)), -1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15));
}

._mobile-bar-breadcrumbs_19d8l_30kom_20:not(#\9){
  min-width:0;
  flex:1 1;
  margin-left:16px;
  margin-left:16px;
  margin-left:var(--space-m-208vQh, 16px);
  margin-right:16px;
  margin-right:16px;
  margin-right:var(--space-m-208vQh, 16px);
}

._mobile-toggle_19d8l_30kom_27:not(#\9){
  box-sizing:border-box;
  width:40px;
  padding:4px 7px;
  padding:4px 7px;
  padding:var(--space-xxs-10c0Z4, 4px) 7px;
  cursor:pointer;
  z-index:1;
}
._mobile-toggle_19d8l_30kom_27:not(#\9):first-child{
  border-right:1px solid #d5dbdb;
  border-right:1px solid #d5dbdb;
  border-right:1px solid var(--color-border-layout-11nezK, #d5dbdb);
}
._mobile-toggle_19d8l_30kom_27:not(#\9):last-child{
  border-left:1px solid #d5dbdb;
  border-left:1px solid #d5dbdb;
  border-left:1px solid var(--color-border-layout-11nezK, #d5dbdb);
}
._mobile-toggle_19d8l_30kom_27:not(#\9):hover{
  background:#eaeded;
  background:#eaeded;
  background:var(--color-background-layout-panel-hover-38loDV, #eaeded);
}
._empty_n4qlp_edbyg_3:not(#\9){
  -webkit-animation:_awsui-motion-fade-in_n4qlp_edbyg_1 180ms ease-out;
          animation:_awsui-motion-fade-in_n4qlp_edbyg_1 180ms ease-out;
  animation:_awsui-motion-fade-in_n4qlp_edbyg_1 180ms ease-out;
  -webkit-animation:_awsui-motion-fade-in_n4qlp_edbyg_1 var(--motion-duration-transition-show-paced-2crOWV, 180ms) var(--motion-easing-transition-show-paced-25z_bd, ease-out);
          animation:_awsui-motion-fade-in_n4qlp_edbyg_1 var(--motion-duration-transition-show-paced-2crOWV, 180ms) var(--motion-easing-transition-show-paced-25z_bd, ease-out);
}
@-webkit-keyframes _awsui-motion-fade-in_n4qlp_edbyg_1{
  from{
    opacity:0.2;
  }
  to{
    opacity:1;
  }
}
@keyframes _awsui-motion-fade-in_n4qlp_edbyg_1{
  from{
    opacity:0.2;
  }
  to{
    opacity:1;
  }
}
@media (prefers-reduced-motion: reduce){
  ._empty_n4qlp_edbyg_3:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._empty_n4qlp_edbyg_3:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._root_n4qlp_edbyg_25:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:block;
}

._empty_n4qlp_edbyg_3:not(#\9){
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#687078;
  color:#687078;
  color:var(--color-text-empty-6_f-gS, #687078);
}

._button-container_n4qlp_edbyg_62:not(#\9){
  padding-top:calc(4px + 22px);
  padding-top:calc(4px + 22px);
  padding-top:calc(var(--space-xxs-10c0Z4, 4px) + var(--font-body-m-lineHeight-2Vn4pu, 22px));
}

._divider_n4qlp_edbyg_66:not(#\9){
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid var(--color-border-divider-default-38loDV, #eaeded);
}

._additional-info_n4qlp_edbyg_70:not(#\9){
  color:#687078;
  color:#687078;
  color:var(--color-text-form-secondary-2ZkiuH, #687078);
  font-size:12px;
  font-size:12px;
  font-size:var(--font-body-s-size-3NP7Rr, 12px);
  line-height:16px;
  line-height:16px;
  line-height:var(--font-body-s-lineHeight-x2Xc-V, 16px);
  display:block;
  word-wrap:break-word;
  margin-top:4px;
  margin-top:4px;
  margin-top:var(--space-xxs-10c0Z4, 4px);
}
._additional-info_n4qlp_edbyg_70 > a:not(#\9){
  font-weight:700;
  letter-spacing:0.01em;
}
._grid_14yj0_1dwv9_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:flex;
  flex-wrap:wrap;
  margin:calc(20px / -2);
  margin:calc(20px / -2);
  margin:calc(var(--space-grid-gutter-17GFGI, 20px) / -2);
  pointer-events:none;
}
._grid_14yj0_1dwv9_3._no-gutters_14yj0_1dwv9_16:not(#\9){
  margin:0;
}

._grid-column_14yj0_1dwv9_20:not(#\9){
  box-sizing:border-box;
  position:relative;
  padding:calc(20px / 2);
  padding:calc(20px / 2);
  padding:calc(var(--space-grid-gutter-17GFGI, 20px) / 2);
  display:flex;
}
._grid_14yj0_1dwv9_3._no-gutters_14yj0_1dwv9_16 > ._grid-column_14yj0_1dwv9_20:not(#\9){
  padding:0;
}
._grid-column_14yj0_1dwv9_20._colspan-1_14yj0_1dwv9_29:not(#\9){
  flex:0 0 8.3333333333%;
  max-width:8.3333333333%;
}
._grid-column_14yj0_1dwv9_20._push-1_14yj0_1dwv9_33:not(#\9){
  left:8.3333333333%;
}
._grid-column_14yj0_1dwv9_20._pull-1_14yj0_1dwv9_36:not(#\9){
  right:8.3333333333%;
}
._grid-column_14yj0_1dwv9_20._colspan-2_14yj0_1dwv9_39:not(#\9){
  flex:0 0 16.6666666667%;
  max-width:16.6666666667%;
}
._grid-column_14yj0_1dwv9_20._push-2_14yj0_1dwv9_43:not(#\9){
  left:16.6666666667%;
}
._grid-column_14yj0_1dwv9_20._pull-2_14yj0_1dwv9_46:not(#\9){
  right:16.6666666667%;
}
._grid-column_14yj0_1dwv9_20._colspan-3_14yj0_1dwv9_49:not(#\9){
  flex:0 0 25%;
  max-width:25%;
}
._grid-column_14yj0_1dwv9_20._push-3_14yj0_1dwv9_53:not(#\9){
  left:25%;
}
._grid-column_14yj0_1dwv9_20._pull-3_14yj0_1dwv9_56:not(#\9){
  right:25%;
}
._grid-column_14yj0_1dwv9_20._colspan-4_14yj0_1dwv9_59:not(#\9){
  flex:0 0 33.3333333333%;
  max-width:33.3333333333%;
}
._grid-column_14yj0_1dwv9_20._push-4_14yj0_1dwv9_63:not(#\9){
  left:33.3333333333%;
}
._grid-column_14yj0_1dwv9_20._pull-4_14yj0_1dwv9_66:not(#\9){
  right:33.3333333333%;
}
._grid-column_14yj0_1dwv9_20._colspan-5_14yj0_1dwv9_69:not(#\9){
  flex:0 0 41.6666666667%;
  max-width:41.6666666667%;
}
._grid-column_14yj0_1dwv9_20._push-5_14yj0_1dwv9_73:not(#\9){
  left:41.6666666667%;
}
._grid-column_14yj0_1dwv9_20._pull-5_14yj0_1dwv9_76:not(#\9){
  right:41.6666666667%;
}
._grid-column_14yj0_1dwv9_20._colspan-6_14yj0_1dwv9_79:not(#\9){
  flex:0 0 50%;
  max-width:50%;
}
._grid-column_14yj0_1dwv9_20._push-6_14yj0_1dwv9_83:not(#\9){
  left:50%;
}
._grid-column_14yj0_1dwv9_20._pull-6_14yj0_1dwv9_86:not(#\9){
  right:50%;
}
._grid-column_14yj0_1dwv9_20._colspan-7_14yj0_1dwv9_89:not(#\9){
  flex:0 0 58.3333333333%;
  max-width:58.3333333333%;
}
._grid-column_14yj0_1dwv9_20._push-7_14yj0_1dwv9_93:not(#\9){
  left:58.3333333333%;
}
._grid-column_14yj0_1dwv9_20._pull-7_14yj0_1dwv9_96:not(#\9){
  right:58.3333333333%;
}
._grid-column_14yj0_1dwv9_20._colspan-8_14yj0_1dwv9_99:not(#\9){
  flex:0 0 66.6666666667%;
  max-width:66.6666666667%;
}
._grid-column_14yj0_1dwv9_20._push-8_14yj0_1dwv9_103:not(#\9){
  left:66.6666666667%;
}
._grid-column_14yj0_1dwv9_20._pull-8_14yj0_1dwv9_106:not(#\9){
  right:66.6666666667%;
}
._grid-column_14yj0_1dwv9_20._colspan-9_14yj0_1dwv9_109:not(#\9){
  flex:0 0 75%;
  max-width:75%;
}
._grid-column_14yj0_1dwv9_20._push-9_14yj0_1dwv9_113:not(#\9){
  left:75%;
}
._grid-column_14yj0_1dwv9_20._pull-9_14yj0_1dwv9_116:not(#\9){
  right:75%;
}
._grid-column_14yj0_1dwv9_20._colspan-10_14yj0_1dwv9_119:not(#\9){
  flex:0 0 83.3333333333%;
  max-width:83.3333333333%;
}
._grid-column_14yj0_1dwv9_20._push-10_14yj0_1dwv9_123:not(#\9){
  left:83.3333333333%;
}
._grid-column_14yj0_1dwv9_20._pull-10_14yj0_1dwv9_126:not(#\9){
  right:83.3333333333%;
}
._grid-column_14yj0_1dwv9_20._colspan-11_14yj0_1dwv9_129:not(#\9){
  flex:0 0 91.6666666667%;
  max-width:91.6666666667%;
}
._grid-column_14yj0_1dwv9_20._push-11_14yj0_1dwv9_133:not(#\9){
  left:91.6666666667%;
}
._grid-column_14yj0_1dwv9_20._pull-11_14yj0_1dwv9_136:not(#\9){
  right:91.6666666667%;
}
._grid-column_14yj0_1dwv9_20._colspan-12_14yj0_1dwv9_139:not(#\9){
  flex:0 0 100%;
  max-width:100%;
}
._grid-column_14yj0_1dwv9_20._push-12_14yj0_1dwv9_143:not(#\9){
  left:100%;
}
._grid-column_14yj0_1dwv9_20._pull-12_14yj0_1dwv9_146:not(#\9){
  right:100%;
}
._grid-column_14yj0_1dwv9_20._push-0_14yj0_1dwv9_149:not(#\9){
  left:auto;
}
._grid-column_14yj0_1dwv9_20._pull-0_14yj0_1dwv9_152:not(#\9){
  right:auto;
}
._grid-column_14yj0_1dwv9_20._offset-1_14yj0_1dwv9_155:not(#\9){
  margin-left:8.3333333333%;
}
._grid-column_14yj0_1dwv9_20._offset-2_14yj0_1dwv9_158:not(#\9){
  margin-left:16.6666666667%;
}
._grid-column_14yj0_1dwv9_20._offset-3_14yj0_1dwv9_161:not(#\9){
  margin-left:25%;
}
._grid-column_14yj0_1dwv9_20._offset-4_14yj0_1dwv9_164:not(#\9){
  margin-left:33.3333333333%;
}
._grid-column_14yj0_1dwv9_20._offset-5_14yj0_1dwv9_167:not(#\9){
  margin-left:41.6666666667%;
}
._grid-column_14yj0_1dwv9_20._offset-6_14yj0_1dwv9_170:not(#\9){
  margin-left:50%;
}
._grid-column_14yj0_1dwv9_20._offset-7_14yj0_1dwv9_173:not(#\9){
  margin-left:58.3333333333%;
}
._grid-column_14yj0_1dwv9_20._offset-8_14yj0_1dwv9_176:not(#\9){
  margin-left:66.6666666667%;
}
._grid-column_14yj0_1dwv9_20._offset-9_14yj0_1dwv9_179:not(#\9){
  margin-left:75%;
}
._grid-column_14yj0_1dwv9_20._offset-10_14yj0_1dwv9_182:not(#\9){
  margin-left:83.3333333333%;
}
._grid-column_14yj0_1dwv9_20._offset-11_14yj0_1dwv9_185:not(#\9){
  margin-left:91.6666666667%;
}

._restore-pointer-events_14yj0_1dwv9_189:not(#\9){
  pointer-events:auto;
  width:100%;
}
._root_14mhv_167ty_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  box-sizing:border-box;
}

._label_14mhv_167ty_14:not(#\9){
  box-sizing:border-box;
  color:#16191f;
  color:#16191f;
  color:var(--color-text-form-label-WiQFVx, #16191f);
  display:inline;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  margin-right:8px;
  margin-right:8px;
  margin-right:var(--space-xs-3JTBUA, 8px);
}
._label_14mhv_167ty_14:not(#\9):only-child{
  margin:0;
}

._description_14mhv_167ty_30:not(#\9),
._constraint_14mhv_167ty_31:not(#\9){
  color:#687078;
  color:#687078;
  color:var(--color-text-form-secondary-2ZkiuH, #687078);
  font-size:12px;
  font-size:12px;
  font-size:var(--font-body-s-size-3NP7Rr, 12px);
  line-height:16px;
  line-height:16px;
  line-height:var(--font-body-s-lineHeight-x2Xc-V, 16px);
}

._hints_14mhv_167ty_37:not(#\9),
._constraint-has-error_14mhv_167ty_38:not(#\9){
  padding-top:4px;
  padding-top:4px;
  padding-top:var(--space-xxs-10c0Z4, 4px);
}

._controls_14mhv_167ty_46:not(#\9):not(._label-hidden_14mhv_167ty_46){
  padding-top:4px;
  padding-top:4px;
  padding-top:var(--space-xxs-10c0Z4, 4px);
}

._control_14mhv_167ty_46:not(#\9){
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
}

._error_14mhv_167ty_56:not(#\9){
  color:#d13212;
  color:#d13212;
  color:var(--color-text-status-error-1jj2sL, #d13212);
  font-size:12px;
  font-size:12px;
  font-size:var(--font-body-s-size-3NP7Rr, 12px);
  line-height:16px;
  line-height:16px;
  line-height:var(--font-body-s-lineHeight-x2Xc-V, 16px);
  display:flex;
  align-items:flex-start;
}

._error__message_14mhv_167ty_64:not(#\9){
  margin-left:4px;
  margin-left:4px;
  margin-left:var(--space-xxs-10c0Z4, 4px);
}

._visually-hidden_14mhv_167ty_68:not(#\9){
  position:absolute !important;
  top:-9999px !important;
  left:-9999px !important;
}
div._column-layout_vvxn7_1c7v9_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  word-wrap:break-word;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13:not(#\9){
  margin:calc(20px / -2);
  margin:calc(20px / -2);
  margin:calc(var(--space-grid-gutter-17GFGI, 20px) / -2);
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-no-gutters_vvxn7_1c7v9_16:not(#\9){
  margin:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-variant-text-grid_vvxn7_1c7v9_19:not(#\9){
  margin:calc(-1 * 20px);
  margin:calc(-1 * 20px);
  margin:calc(-1 * var(--space-grid-gutter-17GFGI, 20px));
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-variant-text-grid_vvxn7_1c7v9_19 > *:not(#\9){
  border-left:1px solid #eaeded;
  border-left:1px solid #eaeded;
  border-left:var(--border-divider-section-width-3GX4nr, 1px) solid var(--color-border-divider-default-38loDV, #eaeded);
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-variant-text-grid_vvxn7_1c7v9_19._grid-breakpoint-default_vvxn7_1c7v9_25 > *:not(#\9):nth-child(1n+1){
  border-left-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-variant-text-grid_vvxn7_1c7v9_19._grid-columns-1_vvxn7_1c7v9_28._grid-breakpoint-xxs_vvxn7_1c7v9_28 > *:not(#\9):nth-child(1n+1){
  border-left-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-variant-text-grid_vvxn7_1c7v9_19._grid-columns-1_vvxn7_1c7v9_28._grid-breakpoint-xs_vvxn7_1c7v9_31 > *:not(#\9):nth-child(1n+1){
  border-left-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-variant-text-grid_vvxn7_1c7v9_19._grid-columns-2_vvxn7_1c7v9_34._grid-breakpoint-xxs_vvxn7_1c7v9_28 > *:not(#\9):nth-child(2n+1){
  border-left-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-variant-text-grid_vvxn7_1c7v9_19._grid-columns-2_vvxn7_1c7v9_34._grid-breakpoint-xs_vvxn7_1c7v9_31 > *:not(#\9):nth-child(2n+1){
  border-left-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-variant-text-grid_vvxn7_1c7v9_19._grid-columns-3_vvxn7_1c7v9_40._grid-breakpoint-xxs_vvxn7_1c7v9_28 > *:not(#\9):nth-child(2n+1){
  border-left-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-variant-text-grid_vvxn7_1c7v9_19._grid-columns-3_vvxn7_1c7v9_40._grid-breakpoint-xs_vvxn7_1c7v9_31 > *:not(#\9):nth-child(3n+1){
  border-left-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-variant-text-grid_vvxn7_1c7v9_19._grid-columns-4_vvxn7_1c7v9_46._grid-breakpoint-xxs_vvxn7_1c7v9_28 > *:not(#\9):nth-child(2n+1){
  border-left-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-variant-text-grid_vvxn7_1c7v9_19._grid-columns-4_vvxn7_1c7v9_46._grid-breakpoint-xs_vvxn7_1c7v9_31 > *:not(#\9):nth-child(4n+1){
  border-left-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13:not(#\9):not(._grid-no-gutters_vvxn7_1c7v9_16) > *{
  padding:calc(20px / 2);
  padding:calc(20px / 2);
  padding:calc(var(--space-grid-gutter-17GFGI, 20px) / 2);
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13:not(#\9):not(._grid-no-gutters_vvxn7_1c7v9_16)._grid-variant-text-grid_vvxn7_1c7v9_19 > *{
  padding:0 20px;
  padding:0 20px;
  padding:0 var(--space-grid-gutter-17GFGI, 20px);
  margin:20px 0;
  margin:20px 0;
  margin:var(--space-grid-gutter-17GFGI, 20px) 0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-vertical-borders_vvxn7_1c7v9_59 > *:not(#\9){
  border-right:1px solid #eaeded;
  border-right:1px solid #eaeded;
  border-right:var(--border-divider-section-width-3GX4nr, 1px) solid var(--color-border-divider-default-38loDV, #eaeded);
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-vertical-borders_vvxn7_1c7v9_59._grid-breakpoint-default_vvxn7_1c7v9_25 > *:not(#\9):nth-child(1n){
  border-right-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-vertical-borders_vvxn7_1c7v9_59._grid-columns-1_vvxn7_1c7v9_28._grid-breakpoint-xxs_vvxn7_1c7v9_28 > *:not(#\9):nth-child(1n){
  border-right-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-vertical-borders_vvxn7_1c7v9_59._grid-columns-1_vvxn7_1c7v9_28._grid-breakpoint-xs_vvxn7_1c7v9_31 > *:not(#\9):nth-child(1n){
  border-right-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-vertical-borders_vvxn7_1c7v9_59._grid-columns-2_vvxn7_1c7v9_34._grid-breakpoint-xxs_vvxn7_1c7v9_28 > *:not(#\9):nth-child(2n){
  border-right-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-vertical-borders_vvxn7_1c7v9_59._grid-columns-2_vvxn7_1c7v9_34._grid-breakpoint-xs_vvxn7_1c7v9_31 > *:not(#\9):nth-child(2n){
  border-right-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-vertical-borders_vvxn7_1c7v9_59._grid-columns-3_vvxn7_1c7v9_40._grid-breakpoint-xxs_vvxn7_1c7v9_28 > *:not(#\9):nth-child(2n){
  border-right-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-vertical-borders_vvxn7_1c7v9_59._grid-columns-3_vvxn7_1c7v9_40._grid-breakpoint-xs_vvxn7_1c7v9_31 > *:not(#\9):nth-child(3n){
  border-right-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-vertical-borders_vvxn7_1c7v9_59._grid-columns-4_vvxn7_1c7v9_46._grid-breakpoint-xxs_vvxn7_1c7v9_28 > *:not(#\9):nth-child(2n){
  border-right-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-vertical-borders_vvxn7_1c7v9_59._grid-columns-4_vvxn7_1c7v9_46._grid-breakpoint-xs_vvxn7_1c7v9_31 > *:not(#\9):nth-child(4n){
  border-right-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-horizontal-borders_vvxn7_1c7v9_89 > *:not(#\9){
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid #eaeded;
  border-bottom:var(--border-divider-section-width-3GX4nr, 1px) solid var(--color-border-divider-default-38loDV, #eaeded);
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-horizontal-borders_vvxn7_1c7v9_89 > *:not(#\9):last-child{
  border-bottom-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-horizontal-borders_vvxn7_1c7v9_89._grid-columns-2_vvxn7_1c7v9_34._grid-breakpoint-xxs_vvxn7_1c7v9_28 > *:not(#\9):nth-last-child(2):nth-child(2n+1){
  border-bottom-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-horizontal-borders_vvxn7_1c7v9_89._grid-columns-2_vvxn7_1c7v9_34._grid-breakpoint-xs_vvxn7_1c7v9_31 > *:not(#\9):nth-last-child(2):nth-child(2n+1){
  border-bottom-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-horizontal-borders_vvxn7_1c7v9_89._grid-columns-3_vvxn7_1c7v9_40._grid-breakpoint-xxs_vvxn7_1c7v9_28 > *:not(#\9):nth-last-child(2):nth-child(2n+1){
  border-bottom-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-horizontal-borders_vvxn7_1c7v9_89._grid-columns-3_vvxn7_1c7v9_40._grid-breakpoint-xs_vvxn7_1c7v9_31 > *:not(#\9):nth-last-child(2):nth-child(3n+1){
  border-bottom-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-horizontal-borders_vvxn7_1c7v9_89._grid-columns-3_vvxn7_1c7v9_40._grid-breakpoint-xs_vvxn7_1c7v9_31 > *:not(#\9):nth-last-child(2):nth-child(3n+2){
  border-bottom-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-horizontal-borders_vvxn7_1c7v9_89._grid-columns-3_vvxn7_1c7v9_40._grid-breakpoint-xs_vvxn7_1c7v9_31 > *:not(#\9):nth-last-child(3):nth-child(3n+1){
  border-bottom-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-horizontal-borders_vvxn7_1c7v9_89._grid-columns-4_vvxn7_1c7v9_46._grid-breakpoint-xxs_vvxn7_1c7v9_28 > *:not(#\9):nth-last-child(2):nth-child(2n+1){
  border-bottom-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-horizontal-borders_vvxn7_1c7v9_89._grid-columns-4_vvxn7_1c7v9_46._grid-breakpoint-xs_vvxn7_1c7v9_31 > *:not(#\9):nth-last-child(2):nth-child(4n+1){
  border-bottom-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-horizontal-borders_vvxn7_1c7v9_89._grid-columns-4_vvxn7_1c7v9_46._grid-breakpoint-xs_vvxn7_1c7v9_31 > *:not(#\9):nth-last-child(2):nth-child(4n+2){
  border-bottom-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-horizontal-borders_vvxn7_1c7v9_89._grid-columns-4_vvxn7_1c7v9_46._grid-breakpoint-xs_vvxn7_1c7v9_31 > *:not(#\9):nth-last-child(2):nth-child(4n+3){
  border-bottom-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-horizontal-borders_vvxn7_1c7v9_89._grid-columns-4_vvxn7_1c7v9_46._grid-breakpoint-xs_vvxn7_1c7v9_31 > *:not(#\9):nth-last-child(3):nth-child(4n+1){
  border-bottom-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-horizontal-borders_vvxn7_1c7v9_89._grid-columns-4_vvxn7_1c7v9_46._grid-breakpoint-xs_vvxn7_1c7v9_31 > *:not(#\9):nth-last-child(3):nth-child(4n+2){
  border-bottom-width:0;
}
div._column-layout_vvxn7_1c7v9_3 > ._grid_vvxn7_1c7v9_13._grid-horizontal-borders_vvxn7_1c7v9_89._grid-columns-4_vvxn7_1c7v9_46._grid-breakpoint-xs_vvxn7_1c7v9_31 > *:not(#\9):nth-last-child(4):nth-child(4n+1){
  border-bottom-width:0;
}
._options-list_19gcf_1jske_3:not(#\9){
  padding:0;
  margin:0;
  outline:none;
  border:none;
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  white-space:inherit;
  overflow-y:auto;
  flex:1 1 auto;
  min-height:0;
}

._decrease-top-margin_19gcf_1jske_21:not(#\9){
  margin-top:-1px;
}
._option_1p2cx_rxd5a_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:flex;
  align-items:flex-start;
  width:100%;
  min-width:0;
  box-sizing:border-box;
  color:inherit;
}
._option_1p2cx_rxd5a_3:not(#\9):not(._disabled_1p2cx_rxd5a_18){
  cursor:inherit;
}

._content_1p2cx_rxd5a_22:not(#\9){
  width:100%;
  display:flex;
  flex-direction:column;
  min-width:0;
}

._label-content_1p2cx_rxd5a_29:not(#\9){
  display:flex;
  justify-content:space-between;
  flex-wrap:wrap;
}

._label_1p2cx_rxd5a_29:not(#\9),
._tag_1p2cx_rxd5a_36:not(#\9),
._label-tag_1p2cx_rxd5a_37:not(#\9){
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
}

._label_1p2cx_rxd5a_29:not(#\9),
._tag_1p2cx_rxd5a_36:not(#\9){
  flex-wrap:wrap;
}

._label-prefix_1p2cx_rxd5a_48:not(#\9){
  font-weight:700;
}

._label-tag_1p2cx_rxd5a_37:not(#\9){
  padding-left:12px;
  padding-left:12px;
  padding-left:var(--space-s-1Qj4Fv, 12px);
  flex:auto;
  text-align:right;
}
._label-tag_1p2cx_rxd5a_37:not(#\9):empty{
  display:none;
}

._tags_1p2cx_rxd5a_61:not(#\9),
._description_1p2cx_rxd5a_62:not(#\9){
  font-size:12px;
  font-size:12px;
  font-size:var(--font-body-s-size-3NP7Rr, 12px);
  line-height:16px;
  line-height:16px;
  line-height:var(--font-body-s-lineHeight-x2Xc-V, 16px);
  color:#687078;
  color:#687078;
  color:var(--color-text-dropdown-item-secondary--r75hR, #687078);
  flex-wrap:wrap;
}

._tags_1p2cx_rxd5a_61:not(#\9){
  display:flex;
  align-items:stretch;
}

._tag_1p2cx_rxd5a_36:not(#\9):not(:last-child){
  padding-right:16px;
  padding-right:16px;
  padding-right:var(--space-m-208vQh, 16px);
}

._icon_1p2cx_rxd5a_78:not(#\9){
  padding-right:8px;
  padding-right:8px;
  padding-right:var(--space-xs-3JTBUA, 8px);
  align-content:center;
  display:flex;
  flex-shrink:0;
}

._icon-size-big_1p2cx_rxd5a_85:not(#\9){
  padding-top:2px;
  padding-top:2px;
  padding-top:var(--space-xxxs-2Fa4lw, 2px);
}

._filtering-match-highlight_1p2cx_rxd5a_89:not(#\9){
  background-color:#f1faff;
  background-color:#f1faff;
  background-color:var(--color-background-dropdown-item-filter-match-WkeJRF, #f1faff);
  color:#0073bb;
  color:#0073bb;
  color:var(--color-text-dropdown-item-filter-match-Bc0LvG, #0073bb);
  font-weight:bold;
}

._disabled_1p2cx_rxd5a_18:not(#\9){
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-dropdown-item-disabled-KGAbe7, #aab7b8);
  pointer-events:none;
}
._disabled_1p2cx_rxd5a_18 > ._content_1p2cx_rxd5a_22 > ._tags_1p2cx_rxd5a_61:not(#\9),
._disabled_1p2cx_rxd5a_18 > ._content_1p2cx_rxd5a_22 > ._description_1p2cx_rxd5a_62:not(#\9){
  color:currentColor;
}

._trigger-variant_1p2cx_rxd5a_104:not(#\9){
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
._selectable-item_15o6u_171yp_3:not(#\9){
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  position:relative;
  list-style:none;
  border:1px solid transparent;
  border:1px solid transparent;
  border:var(--border-field-width-3GX4nr, 1px) solid transparent;
  border-top-color:#eaeded;
  border-top-color:#eaeded;
  border-top-color:var(--color-border-dropdown-item-default-2jqiTh, #eaeded);
  border-bottom-color:#eaeded;
  border-bottom-color:#eaeded;
  border-bottom-color:var(--color-border-dropdown-item-default-2jqiTh, #eaeded);
  background-color:#ffffff;
  background-color:#ffffff;
  background-color:var(--color-background-dropdown-item-default-3w9fMz, #ffffff);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-dropdown-item-default-WiQFVx, #16191f);
  border-left-width:0;
  border-right-width:0;
  padding:4px calc(8px + 1px);
  padding:4px calc(8px + 1px);
  padding:var(--space-xxs-10c0Z4, 4px) calc(var(--space-xs-3JTBUA, 8px) + var(--border-field-width-3GX4nr, 1px));
}
._selectable-item_15o6u_171yp_3._pad-bottom_15o6u_171yp_20:not(#\9){
  padding-bottom:calc(4px + 2px);
  padding-bottom:calc(4px + 2px);
  padding-bottom:calc(var(--space-xxs-10c0Z4, 4px) + var(--space-xxxs-2Fa4lw, 2px));
  border-bottom-color:transparent;
}
._selectable-item_15o6u_171yp_3:not(#\9):not(:first-child){
  margin-top:calc(-1 * 1px);
  margin-top:calc(-1 * 1px);
  margin-top:calc(-1 * var(--border-field-width-3GX4nr, 1px));
}
._selectable-item_15o6u_171yp_3._has-background_15o6u_171yp_27:not(#\9){
  background-color:#f2f3f3;
  background-color:#f2f3f3;
  background-color:var(--color-background-dropdown-item-hover-35tDd2, #f2f3f3);
}
._selectable-item_15o6u_171yp_3._highlighted_15o6u_171yp_30:not(#\9), ._selectable-item_15o6u_171yp_3._selected_15o6u_171yp_30:not(#\9){
  color:#16191f;
  color:#16191f;
  color:var(--color-text-dropdown-item-highlighted-2S6vEC, #16191f);
  border-left-width:1px;
  border-left-width:1px;
  border-left-width:var(--border-field-width-3GX4nr, 1px);
  border-right-width:1px;
  border-right-width:1px;
  border-right-width:var(--border-field-width-3GX4nr, 1px);
  border-radius:0px;
  border-radius:0px;
  border-radius:var(--border-item-radius-Z3aihh, 0px);
  padding-left:8px;
  padding-left:8px;
  padding-left:var(--space-xs-3JTBUA, 8px);
  padding-right:8px;
  padding-right:8px;
  padding-right:var(--space-xs-3JTBUA, 8px);
}
._selectable-item_15o6u_171yp_3._highlighted_15o6u_171yp_30:not(#\9){
  z-index:1;
  background-color:#f2f3f3;
  background-color:#f2f3f3;
  background-color:var(--color-background-dropdown-item-hover-35tDd2, #f2f3f3);
  border-color:#879596;
  border-color:#879596;
  border-color:var(--color-border-dropdown-item-hover-3Saafg, #879596);
}
._selectable-item_15o6u_171yp_3._selected_15o6u_171yp_30:not(#\9){
  z-index:2;
  background-color:#f1faff;
  background-color:#f1faff;
  background-color:var(--color-background-dropdown-item-selected-nrt43a, #f1faff);
  border-color:#00a1c9;
  border-color:#00a1c9;
  border-color:var(--color-border-dropdown-item-selected-1BqG1q, #00a1c9);
}
._selectable-item_15o6u_171yp_3._highlighted_15o6u_171yp_30._selected_15o6u_171yp_30._is-keyboard_15o6u_171yp_48:not(#\9){
  box-shadow:inset 0 0 0 1px #00a1c9;
  box-shadow:inset 0 0 0 1px #00a1c9;
  box-shadow:inset 0 0 0 1px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._selectable-item_15o6u_171yp_3._parent_15o6u_171yp_51:not(#\9){
  font-weight:bold;
  color:#545b64;
  color:#545b64;
  color:var(--color-text-dropdown-group-label-2XOlKp, #545b64);
  border-top-color:#eaeded;
  border-top-color:#eaeded;
  border-top-color:var(--color-border-dropdown-group-PaVVo1, #eaeded);
  padding:8px;
  padding:8px;
  padding:var(--space-xs-3JTBUA, 8px);
}
._selectable-item_15o6u_171yp_3._child_15o6u_171yp_57:not(#\9){
  padding-left:calc(32px + 1px);
  padding-left:calc(32px + 1px);
  padding-left:calc(var(--space-xxl-2yP61J, 32px) + var(--border-field-width-3GX4nr, 1px));
}
._selectable-item_15o6u_171yp_3._child_15o6u_171yp_57._highlighted_15o6u_171yp_30:not(#\9), ._selectable-item_15o6u_171yp_3._child_15o6u_171yp_57._selected_15o6u_171yp_30:not(#\9){
  padding-left:32px;
  padding-left:32px;
  padding-left:var(--space-xxl-2yP61J, 32px);
}
._selectable-item_15o6u_171yp_3._disabled_15o6u_171yp_63:not(#\9){
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-dropdown-item-disabled-KGAbe7, #aab7b8);
}
._selectable-item_15o6u_171yp_3:not(#\9):not(._disabled_15o6u_171yp_63):not(._parent_15o6u_171yp_51){
  cursor:pointer;
}
._selectable-item_15o6u_171yp_3._virtual_15o6u_171yp_69:not(#\9){
  position:absolute;
  top:0;
  left:0;
  width:100%;
  box-sizing:border-box;
}

._measure-strut_15o6u_171yp_77:not(#\9){
  position:absolute;
  pointer-events:none;
  height:100%;
  width:100%;
  top:0;
  left:0;
  padding-top:1px;
}
._measure-strut-first_15o6u_171yp_86:not(#\9){
  padding-bottom:1px;
}
._root_12hwc_kivd0_1:not(#\9){
  width:100%;
}

._layout-strut_12hwc_kivd0_5:not(#\9){
  width:100%;
  position:relative;
  -webkit-transform:translate3d(0, 0, 0);
          transform:translate3d(0, 0, 0);
}
._dropdown_qwoo0_x2ciy_3:not(#\9){
  -webkit-animation:_awsui-motion-fade-in-dropdown_qwoo0_x2ciy_1 135ms ease-out;
          animation:_awsui-motion-fade-in-dropdown_qwoo0_x2ciy_1 135ms ease-out;
  animation:_awsui-motion-fade-in-dropdown_qwoo0_x2ciy_1 135ms ease-out;
  -webkit-animation:_awsui-motion-fade-in-dropdown_qwoo0_x2ciy_1 var(--motion-duration-show-quick-TMbCmo, 135ms) var(--motion-easing-show-quick-25z_bd, ease-out);
          animation:_awsui-motion-fade-in-dropdown_qwoo0_x2ciy_1 var(--motion-duration-show-quick-TMbCmo, 135ms) var(--motion-easing-show-quick-25z_bd, ease-out);
}
@-webkit-keyframes _awsui-motion-fade-in-dropdown_qwoo0_x2ciy_1{
  from{
    opacity:0.4;
  }
  to{
    opacity:1;
  }
}
@keyframes _awsui-motion-fade-in-dropdown_qwoo0_x2ciy_1{
  from{
    opacity:0.4;
  }
  to{
    opacity:1;
  }
}
@media (prefers-reduced-motion: reduce){
  ._dropdown_qwoo0_x2ciy_3:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._dropdown_qwoo0_x2ciy_3:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._root_qwoo0_x2ciy_25:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  position:relative;
  white-space:inherit;
}
._root_qwoo0_x2ciy_25._interior_qwoo0_x2ciy_36:not(#\9){
  position:static;
}

._dropdown_qwoo0_x2ciy_3:not(#\9){
  position:absolute;
  display:none;
  z-index:2000;
  -webkit-user-select:none;
          user-select:none;
  background-color:#ffffff;
  background-color:#ffffff;
  background-color:var(--color-background-dropdown-item-default-3w9fMz, #ffffff);
  outline:none;
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 var(--color-shadow-medium-3CtI4L, rgba(0, 28, 36, 0.3)), 1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15)), -1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15));
  border-top:1px solid #eaeded;
  border-top:1px solid #eaeded;
  border-top:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-container-top-1gYBaj, #eaeded);
  border-radius:0px;
  border-radius:0px;
  border-radius:var(--border-container-radius-Z3aihh, 0px);
  box-sizing:border-box;
  border-bottom:none;
}
@media screen and (-ms-high-contrast: active){
  ._dropdown_qwoo0_x2ciy_3:not(#\9){
    border:1px solid #eaeded;
    border:1px solid #eaeded;
    border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-container-top-1gYBaj, #eaeded);
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
  ._dropdown_qwoo0_x2ciy_3:not(#\9){
    border:1px solid #eaeded;
    border:1px solid #eaeded;
    border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-container-top-1gYBaj, #eaeded);
  }
}
._dropdown-drop-up_qwoo0_x2ciy_63:not(#\9){
  box-shadow:0 -1px 1px 0 rgba(0, 28, 36, 0.3), 1px -1px 1px 0 rgba(0, 28, 36, 0.15), -1px -1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 -1px 1px 0 rgba(0, 28, 36, 0.3), 1px -1px 1px 0 rgba(0, 28, 36, 0.15), -1px -1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 -1px 1px 0 var(--color-shadow-medium-3CtI4L, rgba(0, 28, 36, 0.3)), 1px -1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15)), -1px -1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15));
}
._dropdown-drop-up_qwoo0_x2ciy_63._with-limited-width_qwoo0_x2ciy_66:not(#\9){
  border-top:none;
}
._dropdown-drop-left_qwoo0_x2ciy_69:not(#\9){
  right:0;
}
._dropdown-drop-right_qwoo0_x2ciy_72:not(#\9){
  left:0;
}
._dropdown_qwoo0_x2ciy_3._occupy-entire-width_qwoo0_x2ciy_75:not(#\9){
  min-width:100%;
}
._dropdown_qwoo0_x2ciy_3._hide-upper-border_qwoo0_x2ciy_78:not(#\9){
  border-top:none;
}
._dropdown_qwoo0_x2ciy_3._interior_qwoo0_x2ciy_36:not(#\9){
  margin-top:-1px;
}
._dropdown_qwoo0_x2ciy_3._open_qwoo0_x2ciy_84:not(#\9){
  display:block;
}
._dropdown_qwoo0_x2ciy_3._open_qwoo0_x2ciy_84._nowrap_qwoo0_x2ciy_87:not(#\9){
  white-space:nowrap;
  overflow:scroll;
}

._dropdown-content_qwoo0_x2ciy_92:not(#\9){
  display:flex;
  flex-direction:column;
  width:100%;
}

._ie11-wrapper_qwoo0_x2ciy_98:not(#\9){
  display:flex;
}

._stretch-trigger-height_qwoo0_x2ciy_102:not(#\9){
  height:100%;
}
._root_1cbgc_6n3wa_7:not(#\9){
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}
._root_1cbgc_6n3wa_7._status-error_1cbgc_6n3wa_14:not(#\9){
  color:#d13212;
  color:#d13212;
  color:var(--color-text-status-error-1jj2sL, #d13212);
}
._root_1cbgc_6n3wa_7._status-warning_1cbgc_6n3wa_17:not(#\9){
  color:#d13212;
  color:#d13212;
  color:var(--color-text-status-error-1jj2sL, #d13212);
}
._root_1cbgc_6n3wa_7._status-success_1cbgc_6n3wa_20:not(#\9){
  color:#1d8102;
  color:#1d8102;
  color:var(--color-text-status-success-CuCgtA, #1d8102);
}
._root_1cbgc_6n3wa_7._status-info_1cbgc_6n3wa_23:not(#\9){
  color:#0073bb;
  color:#0073bb;
  color:var(--color-text-status-info-Bc0LvG, #0073bb);
}
._root_1cbgc_6n3wa_7._status-stopped_1cbgc_6n3wa_26:not(#\9){
  color:#687078;
  color:#687078;
  color:var(--color-text-status-inactive-2ZkiuH, #687078);
}
._root_1cbgc_6n3wa_7._status-pending_1cbgc_6n3wa_29:not(#\9){
  color:#687078;
  color:#687078;
  color:var(--color-text-status-inactive-2ZkiuH, #687078);
}
._root_1cbgc_6n3wa_7._status-in-progress_1cbgc_6n3wa_32:not(#\9){
  color:#687078;
  color:#687078;
  color:var(--color-text-status-inactive-2ZkiuH, #687078);
}
._root_1cbgc_6n3wa_7._status-loading_1cbgc_6n3wa_35:not(#\9){
  color:#687078;
  color:#687078;
  color:var(--color-text-status-inactive-2ZkiuH, #687078);
}
._root_1cbgc_6n3wa_7._color-override-red_1cbgc_6n3wa_38:not(#\9){
  color:#d13212;
  color:#d13212;
  color:var(--color-text-status-error-1jj2sL, #d13212);
}
._root_1cbgc_6n3wa_7._color-override-grey_1cbgc_6n3wa_41:not(#\9){
  color:#687078;
  color:#687078;
  color:var(--color-text-status-inactive-2ZkiuH, #687078);
}
._root_1cbgc_6n3wa_7._color-override-blue_1cbgc_6n3wa_44:not(#\9){
  color:#0073bb;
  color:#0073bb;
  color:var(--color-text-status-info-Bc0LvG, #0073bb);
}
._root_1cbgc_6n3wa_7._color-override-green_1cbgc_6n3wa_47:not(#\9){
  color:#1d8102;
  color:#1d8102;
  color:var(--color-text-status-success-CuCgtA, #1d8102);
}
._icon_1cbgc_6n3wa_50:not(#\9){
  padding-right:4px;
  padding-right:4px;
  padding-right:var(--space-xxs-10c0Z4, 4px);
}

._container_1cbgc_6n3wa_54:not(#\9){
  display:inline-block;
  word-break:break-all;
  word-wrap:break-word;
}
._root_vrgzu_1ugj9_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  padding:4px 8px calc(4px + 2px);
  padding:4px 8px calc(4px + 2px);
  padding:var(--space-xxs-10c0Z4, 4px) var(--space-xs-3JTBUA, 8px) calc(var(--space-xxs-10c0Z4, 4px) + var(--space-xxxs-2Fa4lw, 2px));
  color:#687078;
  color:#687078;
  color:var(--color-text-dropdown-footer--r75hR, #687078);
}
._root_vrgzu_1ugj9_3 > ._recovery_vrgzu_1ugj9_14:not(#\9){
  color:#0073bb;
  color:#0073bb;
  color:var(--color-text-link-default-Bc0LvG, #0073bb);
  text-decoration:underline;
  cursor:pointer;
}
._root_1afi9_17mwi_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}

._root_1afi9_17mwi_3:not(#\9):not(._hidden_1afi9_17mwi_13){
  border-top:1px solid #eaeded;
  border-top:1px solid #eaeded;
  border-top:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-dropdown-item-default-2jqiTh, #eaeded);
}
._input_2rhyz_d0d1l_7:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  padding:4px 8px;
  padding:4px 8px;
  padding:var(--space-scaled-xxs-wz9S8K, 4px) var(--space-xs-3JTBUA, 8px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  width:100%;
  cursor:text;
  box-sizing:border-box;
  background-color:#ffffff;
  background-color:#ffffff;
  background-color:var(--color-background-input-default-528DLz, #ffffff);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  border:1px solid #aab7b8;
  border:1px solid #aab7b8;
  border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-input-default-27WVST, #aab7b8);
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  height:32px;
  height:32px;
  height:var(--size-vertical-input-2yP61J, 32px);
}
._input_2rhyz_d0d1l_7._input-readonly_2rhyz_d0d1l_27:not(#\9){
  background-color:#ffffff;
  background-color:#ffffff;
  background-color:var(--color-background-input-default-528DLz, #ffffff);
  border:1px solid #eaeded;
  border:1px solid #eaeded;
  border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-input-disabled-1QUbGm, #eaeded);
}
._input_2rhyz_d0d1l_7:not(#\9)::-webkit-input-placeholder{
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-input-placeholder-TVd7uP, #aab7b8);
  font-style:italic;
}
._input_2rhyz_d0d1l_7:not(#\9)::-ms-input-placeholder{
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-input-placeholder-TVd7uP, #aab7b8);
  font-style:italic;
}
._input_2rhyz_d0d1l_7:not(#\9)::-moz-placeholder{
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-input-placeholder-TVd7uP, #aab7b8);
  font-style:italic;
  opacity:1;
}
._input_2rhyz_d0d1l_7:not(#\9):-moz-placeholder{
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-input-placeholder-TVd7uP, #aab7b8);
  font-style:italic;
  opacity:1;
}
._input_2rhyz_d0d1l_7:not(#\9):focus{
  outline:2px dotted transparent;
  border:1px solid #00a1c9;
  border:1px solid #00a1c9;
  border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-item-focused-2uP71z, #00a1c9);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._input_2rhyz_d0d1l_7:not(#\9):disabled{
  background-color:#eaeded;
  background-color:#eaeded;
  background-color:var(--color-background-input-disabled-38loDV, #eaeded);
  border:1px solid #eaeded;
  border:1px solid #eaeded;
  border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-input-disabled-1QUbGm, #eaeded);
  color:#879596;
  color:#879596;
  color:var(--color-text-input-disabled-3ki3SQ, #879596);
  cursor:auto;
  cursor:default;
}
._input_2rhyz_d0d1l_7:not(#\9):-ms-input-placeholder{
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-input-placeholder-TVd7uP, #aab7b8);
  font-style:italic;
}
._input_2rhyz_d0d1l_7:not(#\9):invalid{
  box-shadow:none;
}
._input_2rhyz_d0d1l_7._input-invalid_2rhyz_d0d1l_69:not(#\9){
  color:#d13212;
  color:#d13212;
  color:var(--color-text-status-error-1jj2sL, #d13212);
  border-color:#d13212;
  border-color:#d13212;
  border-color:var(--color-text-status-error-1jj2sL, #d13212);
  padding-left:calc( 8px - (4px - 1px) );
  padding-left:calc( 8px - (4px - 1px) );
  padding-left:calc( var(--space-xs-3JTBUA, 8px) - (var(--border-invalid-width-SMAYHq, 4px) - var(--border-field-width-3GX4nr, 1px)) );
  border-left-width:4px;
  border-left-width:4px;
  border-left-width:var(--border-invalid-width-SMAYHq, 4px);
}
._input_2rhyz_d0d1l_7._input-invalid_2rhyz_d0d1l_69._input-has-icon-left_2rhyz_d0d1l_75:not(#\9){
  padding-left:calc( calc(16px + (2 * 8px)) - (4px - 1px) );
  padding-left:calc( calc(16px + (2 * 8px)) - (4px - 1px) );
  padding-left:calc( calc(var(--size-icon-normal-x2Xc-V, 16px) + (2 * var(--space-xs-3JTBUA, 8px))) - (var(--border-invalid-width-SMAYHq, 4px) - var(--border-field-width-3GX4nr, 1px)) );
}
._input_2rhyz_d0d1l_7._input-type-search_2rhyz_d0d1l_78:not(#\9){
  box-sizing:border-box;
  -webkit-appearance:textfield;
}
._input_2rhyz_d0d1l_7._input-type-search_2rhyz_d0d1l_78:not(#\9)::-ms-clear{
  display:none;
}
._input_2rhyz_d0d1l_7._input-type-search_2rhyz_d0d1l_78:not(#\9)::-webkit-search-decoration{
  -webkit-appearance:none;
}
._input_2rhyz_d0d1l_7._input-type-search_2rhyz_d0d1l_78:not(#\9)::-webkit-search-cancel-button{
  display:none;
}
._input_2rhyz_d0d1l_7._input-has-icon-left_2rhyz_d0d1l_75:not(#\9){
  padding-left:calc(16px + (2 * 8px));
  padding-left:calc(16px + (2 * 8px));
  padding-left:calc(var(--size-icon-normal-x2Xc-V, 16px) + (2 * var(--space-xs-3JTBUA, 8px)));
}
._input_2rhyz_d0d1l_7._input-has-icon-right_2rhyz_d0d1l_94:not(#\9){
  padding-right:calc(16px + (2 * 8px));
  padding-right:calc(16px + (2 * 8px));
  padding-right:calc(var(--size-icon-normal-x2Xc-V, 16px) + (2 * var(--space-xs-3JTBUA, 8px)));
}
._input_2rhyz_d0d1l_7._input-has-no-border-radius_2rhyz_d0d1l_97:not(#\9){
  border-radius:0px;
  border-radius:0px;
  border-radius:var(--border-item-radius-Z3aihh, 0px);
}

._input-container_2rhyz_d0d1l_101:not(#\9){
  display:flex;
  position:relative;
}

._input-icon_2rhyz_d0d1l_106:not(#\9){
  position:absolute;
}
._input-icon_2rhyz_d0d1l_106:not(#\9):not(._input-icon-hoverable_2rhyz_d0d1l_109){
  pointer-events:none;
}

._input-icon-left_2rhyz_d0d1l_113:not(#\9){
  left:8px;
  left:8px;
  left:var(--space-xs-3JTBUA, 8px);
  top:calc(50% - 22px / 2);
  top:calc(50% - 22px / 2);
  top:calc(50% - var(--font-body-m-lineHeight-2Vn4pu, 22px) / 2);
}

._input-icon-right_2rhyz_d0d1l_118:not(#\9){
  right:8px;
  right:8px;
  right:var(--space-xs-3JTBUA, 8px);
  top:calc(50% - 22px / 2);
  top:calc(50% - 22px / 2);
  top:calc(50% - var(--font-body-m-lineHeight-2Vn4pu, 22px) / 2);
  color:#545b64;
  color:#545b64;
  color:var(--color-text-interactive-default-1oBHNI, #545b64);
}

._input-icon-hoverable_2rhyz_d0d1l_109:not(#\9){
  cursor:pointer;
}
._input-icon-hoverable_2rhyz_d0d1l_109:not(#\9):hover{
  color:#16191f;
  color:#16191f;
  color:var(--color-text-interactive-hover-18qOom, #16191f);
}
._badge_1yjyg_1v598_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  font-size:12px;
  font-size:12px;
  font-size:var(--font-body-s-size-3NP7Rr, 12px);
  line-height:16px;
  line-height:16px;
  line-height:var(--font-body-s-lineHeight-x2Xc-V, 16px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  display:inline-block;
  border-radius:16px;
  border-radius:16px;
  border-radius:var(--border-badge-radius-x2Xc-V, 16px);
  padding:0 8px;
  padding:0 8px;
  padding:0 var(--space-xs-3JTBUA, 8px);
  color:#fafafa;
  color:#fafafa;
  color:var(--color-text-notification-default-JWSlel, #fafafa);
}
._badge_1yjyg_1v598_3._badge-color-grey_1yjyg_1v598_19:not(#\9){
  background-color:#545b64;
  background-color:#545b64;
  background-color:var(--color-background-notification-grey-3n2Isj, #545b64);
}
._badge_1yjyg_1v598_3._badge-color-green_1yjyg_1v598_22:not(#\9){
  background-color:#1d8102;
  background-color:#1d8102;
  background-color:var(--color-background-notification-green-1ceXEb, #1d8102);
}
._badge_1yjyg_1v598_3._badge-color-blue_1yjyg_1v598_25:not(#\9){
  background-color:#0073bb;
  background-color:#0073bb;
  background-color:var(--color-background-notification-blue-Y4F8hz, #0073bb);
}
._badge_1yjyg_1v598_3._badge-color-red_1yjyg_1v598_28:not(#\9){
  background-color:#d13212;
  background-color:#d13212;
  background-color:var(--color-background-notification-red-3bUMsg, #d13212);
}

._marker_1isd1_uy285_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  margin-right:4px;
  margin-right:4px;
  margin-right:var(--space-xxs-10c0Z4, 4px);
  margin-top:4px;
  margin-top:4px;
  margin-top:var(--space-xxs-10c0Z4, 4px);
  border-radius:2px;
  width:14px;
  flex-shrink:0;
}
._marker--line_1isd1_uy285_17:not(#\9){
  margin-top:9px;
  height:4px;
}
._marker--rectangle_1isd1_uy285_21:not(#\9){
  height:14px;
  border-radius:2px;
}
._marker--dashed_1isd1_uy285_25:not(#\9){
  height:4px;
  width:6px;
  margin-top:9px;
  margin-right:12px;
}
._marker--dashed_1isd1_uy285_25:not(#\9)::after{
  content:"";
  display:block;
  width:6px;
  height:4px;
  margin-left:8px;
  border-radius:2px;
  background-color:inherit;
}
._marker_1kjc7_uh9do_3:not(#\9){
  transition:opacity 90ms linear;
  transition:opacity 90ms linear;
  transition:opacity var(--motion-duration-transition-quick-2hvSaJ, 90ms) var(--motion-easing-transition-quick-2nchNs, linear);
}
@media (prefers-reduced-motion: reduce){
  ._marker_1kjc7_uh9do_3:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._marker_1kjc7_uh9do_3:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._root_1kjc7_uh9do_17:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}

._list_1kjc7_uh9do_31:not(#\9){
  display:flex;
  flex-wrap:wrap;
  list-style:none;
  margin:0;
  padding:0;
}

._marker_1kjc7_uh9do_3:not(#\9){
  display:inline-flex;
  align-items:flex-start;
  margin-right:16px;
  margin-right:16px;
  margin-right:var(--space-m-208vQh, 16px);
  padding:0;
  cursor:pointer;
  opacity:1;
}
._marker_1kjc7_uh9do_3:not(#\9):focus{
  outline:none;
}
._marker_1kjc7_uh9do_3[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._marker_1kjc7_uh9do_3[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(2px - 1px);
}
._marker_1kjc7_uh9do_3[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-2px;
  top:-2px;
  width:calc(100% + 2 * 2px);
  height:calc(100% + 2 * 2px);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._marker_1kjc7_uh9do_3:not(#\9):last-child{
  margin-right:0;
}
._marker_1kjc7_uh9do_3._marker--dimmed_1kjc7_uh9do_71:not(#\9){
  opacity:0.35;
}
._root_f8g6q_hz7f9_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}
._placeholder_dwuol_jk9vu_3:not(#\9){
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-input-placeholder-TVd7uP, #aab7b8);
  font-style:italic;
}

._item_dwuol_jk9vu_8:not(#\9){
  display:flex;
  align-items:center;
}
._item_dwuol_jk9vu_8 > ._checkbox_dwuol_jk9vu_12:not(#\9){
  position:relative;
  min-height:14px;
  min-width:14px;
  height:14px;
  width:14px;
  margin-right:8px;
  margin-right:8px;
  margin-right:var(--space-xs-3JTBUA, 8px);
}

._filter_dwuol_jk9vu_21:not(#\9){
  z-index:3;
  flex-shrink:0;
}

._trigger_dwuol_jk9vu_26:not(#\9){
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}

._layout-strut_dwuol_jk9vu_32:not(#\9){
  width:100%;
  position:relative;
  -webkit-transform:translate3d(0, 0, 0);
          transform:translate3d(0, 0, 0);
}
._button-trigger_18eso_nvju6_3 > ._arrow_18eso_nvju6_3:not(#\9){
  transition:-webkit-transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:-webkit-transform var(--motion-duration-rotate-180-TMbCmo, 135ms) var(--motion-easing-rotate-180-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1));
  transition:transform var(--motion-duration-rotate-180-TMbCmo, 135ms) var(--motion-easing-rotate-180-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1));
  transition:transform var(--motion-duration-rotate-180-TMbCmo, 135ms) var(--motion-easing-rotate-180-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1)), -webkit-transform var(--motion-duration-rotate-180-TMbCmo, 135ms) var(--motion-easing-rotate-180-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1));
}
@media (prefers-reduced-motion: reduce){
  ._button-trigger_18eso_nvju6_3 > ._arrow_18eso_nvju6_3:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._button-trigger_18eso_nvju6_3 > ._arrow_18eso_nvju6_3:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._button-trigger_18eso_nvju6_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  position:relative;
  display:flex;
  width:100%;
  justify-content:space-between;
  align-items:center;
  padding:4px calc(16px + (2 * 8px)) 4px 8px;
  padding:4px calc(16px + (2 * 8px)) 4px 8px;
  padding:var(--space-scaled-xxs-wz9S8K, 4px) calc(var(--size-icon-normal-x2Xc-V, 16px) + (2 * var(--space-xs-3JTBUA, 8px))) var(--space-scaled-xxs-wz9S8K, 4px) var(--space-xs-3JTBUA, 8px);
  background-color:#ffffff;
  background-color:#ffffff;
  background-color:var(--color-background-input-default-528DLz, #ffffff);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  border:1px solid #aab7b8;
  border:1px solid #aab7b8;
  border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-input-default-27WVST, #aab7b8);
  min-height:32px;
  min-height:32px;
  min-height:var(--size-vertical-input-2yP61J, 32px);
}
._button-trigger_18eso_nvju6_3 > ._placeholder_18eso_nvju6_36:not(#\9){
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-input-placeholder-TVd7uP, #aab7b8);
  font-style:italic;
}
._button-trigger_18eso_nvju6_3 > ._arrow_18eso_nvju6_3:not(#\9){
  position:absolute;
  right:8px;
  right:8px;
  right:var(--space-xs-3JTBUA, 8px);
  top:calc(50% - 22px / 2);
  top:calc(50% - 22px / 2);
  top:calc(50% - var(--font-body-m-lineHeight-2Vn4pu, 22px) / 2);
  color:#545b64;
  color:#545b64;
  color:var(--color-text-interactive-default-1oBHNI, #545b64);
}
._button-trigger_18eso_nvju6_3:not(#\9):hover > ._arrow_18eso_nvju6_3{
  color:#16191f;
  color:#16191f;
  color:var(--color-text-interactive-hover-18qOom, #16191f);
}
._button-trigger_18eso_nvju6_3._pressed_18eso_nvju6_49 > ._arrow_18eso_nvju6_3:not(#\9){
  -webkit-transform:rotate(-180deg);
          transform:rotate(-180deg);
}
._button-trigger_18eso_nvju6_3._disabled_18eso_nvju6_52:not(#\9){
  background-color:#eaeded;
  background-color:#eaeded;
  background-color:var(--color-background-input-disabled-38loDV, #eaeded);
  border:1px solid #eaeded;
  border:1px solid #eaeded;
  border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-input-disabled-1QUbGm, #eaeded);
  color:#879596;
  color:#879596;
  color:var(--color-text-input-disabled-3ki3SQ, #879596);
  cursor:auto;
}
._button-trigger_18eso_nvju6_3._disabled_18eso_nvju6_52 > ._arrow_18eso_nvju6_3:not(#\9){
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
}
._button-trigger_18eso_nvju6_3._read-only_18eso_nvju6_61:not(#\9){
  background-color:#ffffff;
  background-color:#ffffff;
  background-color:var(--color-background-input-default-528DLz, #ffffff);
  border:1px solid #eaeded;
  border:1px solid #eaeded;
  border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-input-disabled-1QUbGm, #eaeded);
}
._button-trigger_18eso_nvju6_3[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  border:1px solid #00a1c9;
  border:1px solid #00a1c9;
  border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-item-focused-2uP71z, #00a1c9);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._button-trigger_18eso_nvju6_3:not(#\9):invalid{
  box-shadow:none;
}
._button-trigger_18eso_nvju6_3._invalid_18eso_nvju6_74:not(#\9), ._button-trigger_18eso_nvju6_3._invalid_18eso_nvju6_74:not(#\9):focus{
  color:#d13212;
  color:#d13212;
  color:var(--color-text-status-error-1jj2sL, #d13212);
  border-color:#d13212;
  border-color:#d13212;
  border-color:var(--color-text-status-error-1jj2sL, #d13212);
  padding-left:calc( 8px - (4px - 1px) );
  padding-left:calc( 8px - (4px - 1px) );
  padding-left:calc( var(--space-xs-3JTBUA, 8px) - (var(--border-invalid-width-SMAYHq, 4px) - var(--border-field-width-3GX4nr, 1px)) );
  border-left-width:4px;
  border-left-width:4px;
  border-left-width:var(--border-invalid-width-SMAYHq, 4px);
}
._button-trigger_18eso_nvju6_3._in-filtering-token_18eso_nvju6_80:not(#\9){
  border-color:#00a1c9;
  border-color:#00a1c9;
  border-color:var(--color-border-item-selected-6FRHtw, #00a1c9);
  border-top-right-radius:0;
  border-bottom-right-radius:0;
  height:100%;
}
._button-trigger_18eso_nvju6_3:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._toggle_1rbf8_153vl_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:flex;
  align-items:center;
  background-color:transparent;
  border:1px solid transparent;
  border:1px solid transparent;
  border:var(--border-field-width-3GX4nr, 1px) solid transparent;
  margin-top:8px;
  margin-top:8px;
  margin-top:var(--space-scaled-xs-19adjU, 8px);
  padding:0;
  margin-left:-1px;
}
._toggle_1rbf8_153vl_3:not(#\9):hover{
  cursor:pointer;
}
._toggle_1rbf8_153vl_3:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._toggle_1rbf8_153vl_3[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  border:1px solid #00a1c9;
  border:1px solid #00a1c9;
  border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-item-focused-2uP71z, #00a1c9);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._toggle_1rbf8_153vl_3 > ._description_1rbf8_153vl_32:not(#\9){
  color:#0073bb;
  color:#0073bb;
  color:var(--color-text-link-default-Bc0LvG, #0073bb);
  margin-left:4px;
  margin-left:4px;
  margin-left:var(--space-xxs-10c0Z4, 4px);
}
._root_dm8gx_wdkgs_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  padding-top:8px;
  padding-top:8px;
  padding-top:var(--space-xs-3JTBUA, 8px);
}

._token_dm8gx_wdkgs_14:not(#\9){
  border:1px solid #00a1c9;
  border:1px solid #00a1c9;
  border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-item-selected-6FRHtw, #00a1c9);
  padding:4px 4px 4px 8px;
  padding:4px 4px 4px 8px;
  padding:var(--space-scaled-xxs-wz9S8K, 4px) var(--space-xxs-10c0Z4, 4px) var(--space-scaled-xxs-wz9S8K, 4px) var(--space-xs-3JTBUA, 8px);
  display:flex;
  align-items:flex-start;
  background:#f1faff;
  background:#f1faff;
  background:var(--color-background-item-selected-WkeJRF, #f1faff);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  height:100%;
  box-sizing:border-box;
}

._dismiss-button_dm8gx_wdkgs_26:not(#\9){
  margin:calc(-1 * 1px) calc(-1 * 1px) 0 4px;
  margin:calc(-1 * 1px) calc(-1 * 1px) 0 4px;
  margin:calc(-1 * var(--border-field-width-3GX4nr, 1px)) calc(-1 * var(--border-field-width-3GX4nr, 1px)) 0 var(--space-xxs-10c0Z4, 4px);
  border:1px solid transparent;
  border:1px solid transparent;
  border:var(--border-field-width-3GX4nr, 1px) solid transparent;
  padding:0 4px;
  padding:0 4px;
  padding:0 var(--space-xxs-10c0Z4, 4px);
  color:#545b64;
  color:#545b64;
  color:var(--color-text-interactive-default-1oBHNI, #545b64);
  background-color:transparent;
}
._dismiss-button_dm8gx_wdkgs_26[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._dismiss-button_dm8gx_wdkgs_26[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(0px - 1px);
}
._dismiss-button_dm8gx_wdkgs_26[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:0px;
  top:0px;
  width:calc(100% + 2 * 0px);
  height:calc(100% + 2 * 0px);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._dismiss-button_dm8gx_wdkgs_26:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._dismiss-button_dm8gx_wdkgs_26:not(#\9):hover{
  cursor:pointer;
  color:#16191f;
  color:#16191f;
  color:var(--color-text-interactive-hover-18qOom, #16191f);
}

._token-disabled_dm8gx_wdkgs_60:not(#\9){
  border-color:#d5dbdb;
  border-color:#d5dbdb;
  border-color:var(--color-border-control-disabled-Rx1zFC, #d5dbdb);
  background-color:#ffffff;
  background-color:#ffffff;
  background-color:var(--color-background-container-content-3w9fMz, #ffffff);
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-disabled-TVd7uP, #aab7b8);
  pointer-events:none;
}
._token-disabled_dm8gx_wdkgs_60 > ._dismiss-button_dm8gx_wdkgs_26:not(#\9){
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
}
._token-disabled_dm8gx_wdkgs_60 > ._dismiss-button_dm8gx_wdkgs_26:not(#\9):hover{
  cursor:auto;
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
}
._root_3bgfn_20xue_3:not(#\9){
  position:absolute !important;
  top:-9999px !important;
  left:-9999px !important;
}
._root_1fn7j_1gbqm_3:not(#\9){
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
}
._root_1fn7j_1gbqm_3 > ._styled-box_1fn7j_1gbqm_10:not(#\9){
  fill:#ffffff;
  fill:#ffffff;
  fill:var(--color-background-control-default-528DLz, #ffffff);
  stroke:#aab7b8;
  stroke:#aab7b8;
  stroke:var(--color-border-control-default-27WVST, #aab7b8);
  stroke-width:1px;
  stroke-width:1px;
  stroke-width:var(--border-field-width-3GX4nr, 1px);
  transition:fill 90ms linear, stroke 90ms linear;
  transition:fill 90ms linear, stroke 90ms linear;
  transition:fill var(--motion-duration-transition-quick-2hvSaJ, 90ms) var(--motion-easing-transition-quick-2nchNs, linear), stroke var(--motion-duration-transition-quick-2hvSaJ, 90ms) var(--motion-easing-transition-quick-2nchNs, linear);
}
._root_1fn7j_1gbqm_3 > ._styled-box-checked_1fn7j_1gbqm_16:not(#\9), ._root_1fn7j_1gbqm_3 > ._styled-box-indeterminate_1fn7j_1gbqm_16:not(#\9){
  fill:#0073bb;
  fill:#0073bb;
  fill:var(--color-background-control-checked-3uW6lA, #0073bb);
  stroke:#0073bb;
  stroke:#0073bb;
  stroke:var(--color-border-control-checked-3JzNc6, #0073bb);
}
._root_1fn7j_1gbqm_3 > ._styled-box-disabled_1fn7j_1gbqm_20:not(#\9){
  fill:#d5dbdb;
  fill:#d5dbdb;
  fill:var(--color-background-control-disabled-11nezK, #d5dbdb);
  stroke:#d5dbdb;
  stroke:#d5dbdb;
  stroke:var(--color-border-control-disabled-Rx1zFC, #d5dbdb);
}
@media (prefers-reduced-motion: reduce){
  ._root_1fn7j_1gbqm_3 > ._styled-box_1fn7j_1gbqm_10:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._root_1fn7j_1gbqm_3 > ._styled-box_1fn7j_1gbqm_10:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}
._root_1fn7j_1gbqm_3 > ._styled-line_1fn7j_1gbqm_34:not(#\9){
  stroke:#ffffff;
  stroke:#ffffff;
  stroke:var(--color-foreground-control-default-3g5IJp, #ffffff);
  stroke-width:2;
  fill:none;
}
._root_1fn7j_1gbqm_3 > ._styled-line-disabled_1fn7j_1gbqm_39:not(#\9){
  stroke:#ffffff;
  stroke:#ffffff;
  stroke:var(--color-foreground-control-disabled-3d6ker, #ffffff);
}
._root_1px7g_kog46_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}

._custom-icon-wrapper_1px7g_kog46_17:not(#\9){
  display:flex;
  align-items:flex-start;
  align-self:stretch;
}
._link_4c84z_1uc0b_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  color:#0073bb;
  color:#0073bb;
  color:var(--color-text-link-default-Bc0LvG, #0073bb);
  text-decoration:none;
  display:inline;
  white-space:inherit;
}
._link_4c84z_1uc0b_3:not(#\9):hover{
  cursor:pointer;
}
._link_4c84z_1uc0b_3:not(#\9):focus{
  outline:none;
}
._link_4c84z_1uc0b_3:not(#\9):active, ._link_4c84z_1uc0b_3:not(#\9):focus, ._link_4c84z_1uc0b_3:not(#\9):hover{
  text-decoration:underline;
}
._link_4c84z_1uc0b_3[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:thin dotted;
  outline:5px auto -webkit-focus-ring-color;
  outline-offset:2px;
  outline-color:#00a1c9;
  outline-color:#00a1c9;
  outline-color:var(--color-border-item-focused-2uP71z, #00a1c9);
}
._link_4c84z_1uc0b_3._weight-bold_4c84z_1uc0b_31:not(#\9){
  font-weight:700;
  letter-spacing:0.01em;
}
._link_4c84z_1uc0b_3._weight-normal_4c84z_1uc0b_35:not(#\9){
  font-weight:inherit;
  letter-spacing:normal;
}
._link_4c84z_1uc0b_3._color-inverted_4c84z_1uc0b_39:not(#\9){
  color:#fafafa;
  color:#fafafa;
  color:var(--color-text-notification-default-JWSlel, #fafafa);
  text-decoration:underline;
}
._link_4c84z_1uc0b_3._font-size-body-s_4c84z_1uc0b_43:not(#\9){
  font-size:12px;
  font-size:12px;
  font-size:var(--font-body-s-size-3NP7Rr, 12px);
  line-height:16px;
  line-height:16px;
  line-height:var(--font-body-s-lineHeight-x2Xc-V, 16px);
}
._link_4c84z_1uc0b_3._font-size-body-m_4c84z_1uc0b_47:not(#\9){
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
}
._link_4c84z_1uc0b_3._font-size-heading-xs_4c84z_1uc0b_51:not(#\9){
  font-size:16px;
  font-size:16px;
  font-size:var(--font-heading-xs-size-x2Xc-V, 16px);
  line-height:20px;
  line-height:20px;
  line-height:var(--font-heading-xs-lineHeight-8Y2p4W, 20px);
}
._link_4c84z_1uc0b_3._font-size-heading-s_4c84z_1uc0b_55:not(#\9){
  font-size:16px;
  font-size:16px;
  font-size:var(--font-heading-s-size-x2Xc-V, 16px);
  line-height:20px;
  line-height:20px;
  line-height:var(--font-heading-s-lineHeight-8Y2p4W, 20px);
}
._link_4c84z_1uc0b_3._font-size-heading-m_4c84z_1uc0b_59:not(#\9){
  font-size:18px;
  font-size:18px;
  font-size:var(--font-heading-m-size-3IMO0y, 18px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-heading-m-lineHeight-2Vn4pu, 22px);
}
._link_4c84z_1uc0b_3._font-size-heading-l_4c84z_1uc0b_63:not(#\9){
  font-size:18px;
  font-size:18px;
  font-size:var(--font-heading-l-size-3IMO0y, 18px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-heading-l-lineHeight-2Vn4pu, 22px);
}
._link_4c84z_1uc0b_3._font-size-heading-xl_4c84z_1uc0b_67:not(#\9){
  font-size:28px;
  font-size:28px;
  font-size:var(--font-heading-xl-size-2ac18Z, 28px);
  line-height:36px;
  line-height:36px;
  line-height:var(--font-heading-xl-lineHeight-3i5g66, 36px);
}
._link_4c84z_1uc0b_3._font-size-display-l_4c84z_1uc0b_71:not(#\9){
  font-size:44px;
  font-size:44px;
  font-size:var(--font-display-l-size-3ybwom, 44px);
  line-height:56px;
  line-height:56px;
  line-height:var(--font-display-l-lineHeight-2EHwYK, 56px);
}

._icon_4c84z_1uc0b_76:not(#\9){
  display:inline-block;
}
._empty_11n0s_1eyv1_5:not(#\9){
  color:#687078;
  color:#687078;
  color:var(--color-text-empty-6_f-gS, #687078);
}
._root_1t44z_r3hgw_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  position:absolute;
  pointer-events:none;
}
._root_1t44z_r3hgw_3._dismissable_1t44z_r3hgw_14:not(#\9){
  pointer-events:initial;
}
._root_10ipo_8r8qj_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}

._list_10ipo_8r8qj_13:not(#\9){
  display:flex;
  margin:0;
  padding:0;
  list-style:none;
  flex-direction:column;
}
._list_10ipo_8r8qj_13 > li:not(#\9){
  display:inline-flex;
  justify-content:space-between;
}

._key_10ipo_8r8qj_25:not(#\9){
  display:inline-flex;
  align-items:flex-start;
}
._series_1yz9c_458w5_3:not(#\9),
._series--bar_1yz9c_458w5_4 > rect:not(#\9){
  transition:opacity 90ms linear;
  transition:opacity 90ms linear;
  transition:opacity var(--motion-duration-transition-quick-2hvSaJ, 90ms) var(--motion-easing-transition-quick-2nchNs, linear);
}
@media (prefers-reduced-motion: reduce){
  ._series_1yz9c_458w5_3:not(#\9),
._series--bar_1yz9c_458w5_4 > rect:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._series_1yz9c_458w5_3:not(#\9),
.awsui-motion-disabled ._series--bar_1yz9c_458w5_4 > rect:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._root_1yz9c_458w5_20:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  position:relative;
  display:block;
}

._has-default-filter_1yz9c_458w5_36 > :not(#\9):first-child{
  flex:280px 0 1;
}

._chart-container_1yz9c_458w5_40:not(#\9){
  display:flex;
  width:100%;
  flex-direction:column;
}

._chart-container__vertical_1yz9c_458w5_46:not(#\9){
  display:flex;
  flex-direction:column;
  width:100%;
}

._chart-container__horizontal_1yz9c_458w5_52:not(#\9){
  display:flex;
}

._content_1yz9c_458w5_56:not(#\9){
  display:flex;
  position:relative;
  justify-content:center;
  align-items:center;
}

._content--reserve-filter_1yz9c_458w5_63:not(#\9){
  margin-top:calc(2 * 22px);
  margin-top:calc(2 * 22px);
  margin-top:calc(2 * var(--font-body-m-lineHeight-2Vn4pu, 22px));
}

._content--reserve-legend_1yz9c_458w5_67:not(#\9){
  margin-bottom:calc(2 * 22px);
  margin-bottom:calc(2 * 22px);
  margin-bottom:calc(2 * var(--font-body-m-lineHeight-2Vn4pu, 22px));
}

._svg_1yz9c_458w5_71:not(#\9){
  shape-rendering:auto;
  overflow:visible;
  display:flex;
  width:100%;
  max-width:100%;
}

._chart--clickable_1yz9c_458w5_79:not(#\9){
  cursor:pointer;
}

._axis_1yz9c_458w5_83:not(#\9), ._vertical-marker_1yz9c_458w5_83:not(#\9){
  stroke:#d5dbdb;
  stroke:#d5dbdb;
  stroke:var(--color-charts-line-axis-11nezK, #d5dbdb);
  stroke-width:1px;
}

._axis--emphasized_1yz9c_458w5_88:not(#\9){
  stroke-width:2px;
}

._vertical-marker_1yz9c_458w5_83:not(#\9){
  pointer-events:none;
}

._vertical-marker-circle_1yz9c_458w5_96:not(#\9){
  fill:#ffffff;
  fill:#ffffff;
  fill:var(--color-background-container-content-3w9fMz, #ffffff);
  pointer-events:none;
  stroke-width:2px;
  stroke-width:2px;
  stroke-width:var(--border-line-chart-width-TgsrkU, 2px);
}

._vertical-marker-circle-active_1yz9c_458w5_102:not(#\9){
  cursor:pointer;
  stroke-width:2px;
  stroke-width:2px;
  stroke-width:var(--border-line-chart-width-TgsrkU, 2px);
}

._axis-label_1yz9c_458w5_107:not(#\9){
  font-weight:bold;
  fill:#16191f;
  fill:#16191f;
  fill:var(--color-text-body-default-mcmuEw, #16191f);
}

._grid_1yz9c_458w5_117:not(#\9){
  stroke:#d5dbdb;
  stroke:#d5dbdb;
  stroke:var(--color-charts-line-grid-11nezK, #d5dbdb);
  stroke-width:1px;
}

._ticks_1yz9c_458w5_122 > line:not(#\9){
  stroke:#d5dbdb;
  stroke:#d5dbdb;
  stroke:var(--color-charts-line-tick-11nezK, #d5dbdb);
  stroke-width:1px;
}
._ticks_1yz9c_458w5_122 > text:not(#\9){
  fill:#545b64;
  fill:#545b64;
  fill:var(--color-text-body-secondary-1oBHNI, #545b64);
}

._ticks--hidden_1yz9c_458w5_135:not(#\9){
  opacity:0;
}

._ticks--bottom_1yz9c_458w5_139 > text:not(#\9){
  text-anchor:middle;
  dominant-baseline:hanging;
}

._labels-left_1yz9c_458w5_144 > ._ticks_1yz9c_458w5_122 > text:not(#\9){
  text-anchor:end;
  dominant-baseline:central;
}

._series_1yz9c_458w5_3:not(#\9){
  display:block;
  stroke-width:2px;
  stroke-width:2px;
  stroke-width:var(--border-line-chart-width-TgsrkU, 2px);
  fill:none;
  stroke-linecap:round;
}

._series--focusable_1yz9c_458w5_161:not(#\9){
  outline:none;
}

._series--dimmed_1yz9c_458w5_165:not(#\9){
  opacity:0.3;
}

._series--bar_1yz9c_458w5_4 > rect:not(#\9){
  stroke:#ffffff;
  stroke:#ffffff;
  stroke:var(--color-background-container-content-3w9fMz, #ffffff);
  stroke-width:2px;
}

._series--threshold_1yz9c_458w5_174:not(#\9),
._series--line_1yz9c_458w5_175:not(#\9){
  pointer-events:none;
}

._series--threshold_1yz9c_458w5_174:not(#\9){
  stroke-dasharray:3 5;
}

._bar-groups__group_1yz9c_458w5_183:not(#\9){
  display:block;
  fill:none;
  pointer-events:all;
  cursor:pointer;
  outline:none;
}
._bar-groups__group_1yz9c_458w5_183[data-awsui-focus-visible=true]:not(#\9):focus{
  stroke:#00a1c9;
  stroke:#00a1c9;
  stroke:var(--color-border-item-focused-2uP71z, #00a1c9);
  stroke-width:2px;
}

._bar-groups--pinned_1yz9c_458w5_195 > ._bar-groups__group_1yz9c_458w5_183:not(#\9){
  pointer-events:none;
}

._focus-outline_1yz9c_458w5_199:not(#\9){
  opacity:0;
  outline:none;
  pointer-events:none;
  fill:none;
  stroke:#00a1c9;
  stroke:#00a1c9;
  stroke:var(--color-border-item-focused-2uP71z, #00a1c9);
  stroke-width:2px;
}
._focus-outline_1yz9c_458w5_199._focus-outline--active_1yz9c_458w5_207[data-awsui-focus-visible=true]:not(#\9){
  opacity:1;
}

._labels-left_1yz9c_458w5_144:not(#\9){
  position:relative;
  margin-right:12px;
}
._labels-left_1yz9c_458w5_144 > ._axis-label_1yz9c_458w5_107:not(#\9){
  position:absolute;
  visibility:visible;
  white-space:nowrap;
}

._labels-left__label_1yz9c_458w5_221:not(#\9){
  position:absolute;
}

._labels-left--hidden_1yz9c_458w5_225:not(#\9){
  visibility:hidden;
}
._labels-left--hidden_1yz9c_458w5_225 > ._labels-left__label_1yz9c_458w5_221:not(#\9){
  position:relative;
  white-space:nowrap;
}

._labels-bottom_1yz9c_458w5_233:not(#\9){
  position:relative;
  display:block;
  width:100%;
  overflow:visible;
}
._breadcrumb-group_d19fg_1sym2_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  margin:0;
  padding:4px 0;
  padding:4px 0;
  padding:var(--space-xxs-10c0Z4, 4px) 0;
}
._breadcrumb-group_d19fg_1sym2_3 > ol:not(#\9){
  display:flex;
  align-items:center;
  padding:0;
  margin:0;
  list-style:none;
  width:100%;
  flex-wrap:wrap;
}
._breadcrumb-group_d19fg_1sym2_3 > ol > li:not(#\9){
  display:inline-block;
  padding:0;
  margin:0;
}
._breadcrumb-group_d19fg_1sym2_3 > ol > ._ellipsis_d19fg_1sym2_28:not(#\9){
  display:none;
}
._breadcrumb-group_d19fg_1sym2_3 > ol > ._ellipsis_d19fg_1sym2_28 > ._icon_d19fg_1sym2_31:not(#\9){
  margin:0 10px;
}
._breadcrumb-group_d19fg_1sym2_3 > ._item_d19fg_1sym2_34:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:inline;
}
._breadcrumb-group_d19fg_1sym2_3._mobile_d19fg_1sym2_44 > ol:not(#\9){
  flex-wrap:nowrap;
}
._breadcrumb-group_d19fg_1sym2_3._mobile_d19fg_1sym2_44 > ol > ._ellipsis_d19fg_1sym2_28:not(#\9){
  display:flex;
  flex-shrink:0;
}
._breadcrumb-group_d19fg_1sym2_3._mobile_d19fg_1sym2_44 > ol > ._item_d19fg_1sym2_34:not(#\9){
  min-width:0;
}
._breadcrumb-group_d19fg_1sym2_3._mobile_d19fg_1sym2_44 > ol > ._item_d19fg_1sym2_34:not(#\9):not(:first-child):not(:last-child){
  display:none;
}
._button-dropdown_sne0l_1mec2_3:not(#\9){
  display:inline-block;
}

._items-list-container_sne0l_1mec2_7:not(#\9){
  padding:0;
  margin:0;
  -webkit-animation:_awsui-motion-fade-in-0_sne0l_1mec2_1 500ms ease-out;
          animation:_awsui-motion-fade-in-0_sne0l_1mec2_1 500ms ease-out;
  animation:_awsui-motion-fade-in-0_sne0l_1mec2_1 500ms ease-out;
  -webkit-animation:_awsui-motion-fade-in-0_sne0l_1mec2_1 500ms var(--motion-easing-show-quick-25z_bd, ease-out);
          animation:_awsui-motion-fade-in-0_sne0l_1mec2_1 500ms var(--motion-easing-show-quick-25z_bd, ease-out);
  -webkit-animation-fill-mode:none;
          animation-fill-mode:none;
}
@media (prefers-reduced-motion: reduce){
  ._items-list-container_sne0l_1mec2_7:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._items-list-container_sne0l_1mec2_7:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._rotate-up_sne0l_1mec2_24:not(#\9){
  -webkit-transform:rotate(-180deg);
          transform:rotate(-180deg);
  transition:-webkit-transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:-webkit-transform var(--motion-duration-rotate-180-TMbCmo, 135ms) var(--motion-easing-rotate-180-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1));
  transition:transform var(--motion-duration-rotate-180-TMbCmo, 135ms) var(--motion-easing-rotate-180-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1));
  transition:transform var(--motion-duration-rotate-180-TMbCmo, 135ms) var(--motion-easing-rotate-180-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1)), -webkit-transform var(--motion-duration-rotate-180-TMbCmo, 135ms) var(--motion-easing-rotate-180-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1));
}
@media (prefers-reduced-motion: reduce){
  ._rotate-up_sne0l_1mec2_24:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._rotate-up_sne0l_1mec2_24:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._rotate-down_sne0l_1mec2_39:not(#\9){
  -webkit-transform:rotate(0deg);
          transform:rotate(0deg);
  transition:-webkit-transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:-webkit-transform var(--motion-duration-rotate-180-TMbCmo, 135ms) var(--motion-easing-rotate-180-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1));
  transition:transform var(--motion-duration-rotate-180-TMbCmo, 135ms) var(--motion-easing-rotate-180-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1));
  transition:transform var(--motion-duration-rotate-180-TMbCmo, 135ms) var(--motion-easing-rotate-180-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1)), -webkit-transform var(--motion-duration-rotate-180-TMbCmo, 135ms) var(--motion-easing-rotate-180-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1));
}
@media (prefers-reduced-motion: reduce){
  ._rotate-down_sne0l_1mec2_39:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._rotate-down_sne0l_1mec2_39:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}
._item-element_93a1u_1hbn8_3:not(#\9){
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
  display:block;
  position:relative;
  z-index:1;
  border:1px solid transparent;
  border:1px solid transparent;
  border:var(--border-field-width-3GX4nr, 1px) solid transparent;
  list-style:none;
  padding:4px 20px;
  padding:4px 20px;
  padding:var(--space-xxs-10c0Z4, 4px) var(--space-l-1RnAcv, 20px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-dropdown-item-default-WiQFVx, #16191f);
  margin-top:calc(-1 * 1px);
  margin-top:calc(-1 * 1px);
  margin-top:calc(-1 * var(--border-field-width-3GX4nr, 1px));
}
._item-element_93a1u_1hbn8_3:not(#\9):not(._disabled_93a1u_1hbn8_16){
  cursor:pointer;
}
._item-element_93a1u_1hbn8_3:not(#\9):first-child{
  margin-top:0;
}
._item-element_93a1u_1hbn8_3:not(#\9):focus{
  outline:none;
}
._item-element_93a1u_1hbn8_3._last_93a1u_1hbn8_25:not(#\9){
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid #eaeded;
  border-bottom:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-dropdown-group-PaVVo1, #eaeded);
}
._item-element_93a1u_1hbn8_3._highlighted_93a1u_1hbn8_28:not(#\9), ._item-element_93a1u_1hbn8_3:not(#\9):hover:not(._disabled_93a1u_1hbn8_16){
  background-color:#f2f3f3;
  background-color:#f2f3f3;
  background-color:var(--color-background-dropdown-item-hover-35tDd2, #f2f3f3);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-dropdown-item-highlighted-2S6vEC, #16191f);
  border-color:#879596;
  border-color:#879596;
  border-color:var(--color-border-dropdown-item-hover-3Saafg, #879596);
  border-radius:0px;
  border-radius:0px;
  border-radius:var(--border-dropdown-radius-Z3aihh, 0px);
  cursor:pointer;
  z-index:2;
}
._item-element_93a1u_1hbn8_3._disabled_93a1u_1hbn8_16:not(#\9){
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-dropdown-item-disabled-KGAbe7, #aab7b8);
}
._item-element_93a1u_1hbn8_3._has-category-header_93a1u_1hbn8_39:not(#\9){
  padding-left:32px;
  padding-left:32px;
  padding-left:var(--space-xxl-2yP61J, 32px);
}
._item-element_93a1u_1hbn8_3._item-link_93a1u_1hbn8_42:not(#\9){
  padding:0;
}
._item-element_93a1u_1hbn8_3._item-link_93a1u_1hbn8_42 > a:not(#\9){
  display:block;
  padding:4px 20px;
  padding:4px 20px;
  padding:var(--space-xxs-10c0Z4, 4px) var(--space-l-1RnAcv, 20px);
  color:currentColor;
  text-decoration:none;
}
._item-element_93a1u_1hbn8_3._item-link_93a1u_1hbn8_42._has-category-header_93a1u_1hbn8_39 > a:not(#\9){
  padding-left:32px;
  padding-left:32px;
  padding-left:var(--space-xxl-2yP61J, 32px);
}
._header_16mm3_44t8a_3:not(#\9){
  position:relative;
  margin:0;
  color:#545b64;
  color:#545b64;
  color:var(--color-text-dropdown-group-label-2XOlKp, #545b64);
  border:1px solid transparent;
  border:1px solid transparent;
  border:var(--border-field-width-3GX4nr, 1px) solid transparent;
  font-weight:bold;
  display:flex;
  justify-content:space-between;
  padding:4px 20px;
  padding:4px 20px;
  padding:var(--space-xxs-10c0Z4, 4px) var(--space-l-1RnAcv, 20px);
  z-index:1;
}
._header_16mm3_44t8a_3._disabled_16mm3_44t8a_14:not(#\9){
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-dropdown-item-disabled-KGAbe7, #aab7b8);
}
._header_16mm3_44t8a_3._expandable-header_16mm3_44t8a_17:not(#\9){
  border-top-color:#eaeded;
  border-top-color:#eaeded;
  border-top-color:var(--color-border-dropdown-group-PaVVo1, #eaeded);
  border-bottom-color:#eaeded;
  border-bottom-color:#eaeded;
  border-bottom-color:var(--color-border-dropdown-group-PaVVo1, #eaeded);
}
._header_16mm3_44t8a_3._expandable-header_16mm3_44t8a_17:not(#\9):focus{
  outline:none;
}
._header_16mm3_44t8a_3._expandable-header_16mm3_44t8a_17._rolled-down_16mm3_44t8a_24:not(#\9){
  border-bottom-color:transparent;
}
._header_16mm3_44t8a_3._expandable-header_16mm3_44t8a_17._highlighted_16mm3_44t8a_27:not(#\9), ._header_16mm3_44t8a_3._expandable-header_16mm3_44t8a_17:not(#\9):not(._disabled_16mm3_44t8a_14):hover{
  background-color:#f2f3f3;
  background-color:#f2f3f3;
  background-color:var(--color-background-dropdown-item-hover-35tDd2, #f2f3f3);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-dropdown-item-highlighted-2S6vEC, #16191f);
  border-color:#879596;
  border-color:#879596;
  border-color:var(--color-border-dropdown-item-hover-3Saafg, #879596);
  border-radius:0px;
  border-radius:0px;
  border-radius:var(--border-dropdown-radius-Z3aihh, 0px);
  cursor:pointer;
  z-index:2;
}

._category_16mm3_44t8a_36:not(#\9){
  list-style:none;
  margin-top:calc(-1 * 1px);
  margin-top:calc(-1 * 1px);
  margin-top:calc(-1 * var(--border-field-width-3GX4nr, 1px));
  padding:0;
}
._category_16mm3_44t8a_36:not(#\9):first-child{
  margin-top:0;
}
._category_16mm3_44t8a_36._expandable_16mm3_44t8a_17:not(#\9){
  border-top:0;
}
._category_16mm3_44t8a_36:not(#\9):last-child{
  border-bottom:none;
}

._expand-icon_16mm3_44t8a_51:not(#\9){
  position:relative;
  left:12px;
  left:12px;
  left:var(--space-s-1Qj4Fv, 12px);
  width:16px;
  width:16px;
  width:var(--space-m-208vQh, 16px);
  display:inline-block;
  transition:-webkit-transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:-webkit-transform var(--motion-duration-rotate-180-TMbCmo, 135ms) var(--motion-easing-rotate-180-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1));
  transition:transform var(--motion-duration-rotate-180-TMbCmo, 135ms) var(--motion-easing-rotate-180-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1));
  transition:transform var(--motion-duration-rotate-180-TMbCmo, 135ms) var(--motion-easing-rotate-180-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1)), -webkit-transform var(--motion-duration-rotate-180-TMbCmo, 135ms) var(--motion-easing-rotate-180-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1));
}
._expand-icon-up_16mm3_44t8a_58:not(#\9){
  -webkit-transform:rotate(-180deg);
          transform:rotate(-180deg);
}
._expand-icon-right_16mm3_44t8a_61:not(#\9){
  -webkit-transform:rotate(-90deg);
          transform:rotate(-90deg);
}
@media (prefers-reduced-motion: reduce){
  ._expand-icon_16mm3_44t8a_51:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._expand-icon_16mm3_44t8a_51:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._items-list-container_16mm3_44t8a_75:not(#\9){
  padding:0;
  margin:-1px 0 0 0;
  overflow-y:auto;
}
._root_14cnr_11j5p_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  position:static;
  white-space:inherit;
  display:inline;
}
._root_14cnr_11j5p_3 > ._trigger_14cnr_11j5p_15:not(#\9){
  display:inline;
}
._root_14cnr_11j5p_3 > ._dropdown_14cnr_11j5p_18:not(#\9){
  position:static;
  -webkit-user-select:none;
          user-select:none;
  background-color:#ffffff;
  background-color:#ffffff;
  background-color:var(--color-background-dropdown-item-default-3w9fMz, #ffffff);
  outline:none;
  border-top:none;
  border-bottom:none;
  display:flex;
  flex-direction:column;
  width:100%;
}
._root_14cnr_11j5p_3 > ._dropdown_14cnr_11j5p_18._nowrap_14cnr_11j5p_29:not(#\9){
  white-space:nowrap;
}
._root_14cnr_11j5p_3 > ._dropdown_14cnr_11j5p_18._open_14cnr_11j5p_32:not(#\9){
  display:flex;
}
._link_1kosq_gr6q4_3:not(#\9):after{
  display:none;
}

._breadcrumb_1kosq_gr6q4_7:not(#\9){
  display:flex;
}
._breadcrumb_1kosq_gr6q4_7 > ._icon_1kosq_gr6q4_10:not(#\9){
  margin:0 8px;
  margin:0 8px;
  margin:0 var(--space-xs-3JTBUA, 8px);
}
._breadcrumb_1kosq_gr6q4_7 > a:not(#\9){
  color:#0073bb;
  color:#0073bb;
  color:var(--color-text-link-default-Bc0LvG, #0073bb);
  text-decoration:none;
}
._breadcrumb_1kosq_gr6q4_7 > a:not(#\9):hover{
  cursor:pointer;
}
._breadcrumb_1kosq_gr6q4_7 > a:not(#\9):focus{
  outline:none;
}
._breadcrumb_1kosq_gr6q4_7 > a:not(#\9):active, ._breadcrumb_1kosq_gr6q4_7 > a:not(#\9):focus, ._breadcrumb_1kosq_gr6q4_7 > a:not(#\9):hover{
  text-decoration:underline;
}
._breadcrumb_1kosq_gr6q4_7 > a[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:thin dotted;
  outline:5px auto -webkit-focus-ring-color;
  outline-offset:2px;
  outline-color:#00a1c9;
  outline-color:#00a1c9;
  outline-color:var(--color-border-item-focused-2uP71z, #00a1c9);
}
._breadcrumb_1kosq_gr6q4_7._last_1kosq_gr6q4_32 > ._icon_1kosq_gr6q4_10:not(#\9){
  display:none;
}
._breadcrumb_1kosq_gr6q4_7._last_1kosq_gr6q4_32 > a:not(#\9){
  color:#687078;
  color:#687078;
  color:var(--color-text-breadcrumb-6_f-gS, #687078);
  text-decoration:none;
  cursor:default;
  pointer-events:none;
}

._compressed_1kosq_gr6q4_42:not(#\9){
  min-width:0;
  overflow:hidden;
}
._compressed_1kosq_gr6q4_42 > span:not(#\9){
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  display:block;
}
._card-inner_p8a6i_1x663_3:not(#\9){
  transition-property:background-color, border-top-color, border-bottom-color, border-left-color, border-right-color;
  transition-duration:180ms;
  transition-duration:180ms;
  transition-duration:var(--motion-duration-transition-show-paced-2crOWV, 180ms);
  transition-timing-function:ease-out;
  transition-timing-function:ease-out;
  transition-timing-function:var(--motion-easing-transition-show-paced-25z_bd, ease-out);
}
@media (prefers-reduced-motion: reduce){
  ._card-inner_p8a6i_1x663_3:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._card-inner_p8a6i_1x663_3:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._root_p8a6i_1x663_19:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}

._header_p8a6i_1x663_34:not(#\9){
  padding:12px 20px;
  padding:12px 20px;
  padding:var(--space-scaled-s-1sAHT4, 12px) var(--space-l-1RnAcv, 20px);
}

._list_p8a6i_1x663_38:not(#\9){
  display:flex;
  flex-wrap:wrap;
  box-sizing:border-box;
  padding:0;
  list-style:none;
  margin:0 0 0 calc(20px * -1);
  margin:0 0 0 calc(20px * -1);
  margin:0 0 0 calc(var(--space-grid-gutter-17GFGI, 20px) * -1);
}
._list_p8a6i_1x663_38._list-grid-1_p8a6i_1x663_46 > ._card_p8a6i_1x663_3:not(#\9){
  width:100%;
}
._list_p8a6i_1x663_38._list-grid-2_p8a6i_1x663_49 > ._card_p8a6i_1x663_3:not(#\9){
  width:50%;
}
._list_p8a6i_1x663_38._list-grid-3_p8a6i_1x663_52 > ._card_p8a6i_1x663_3:not(#\9){
  width:33.3333333333%;
}
._list_p8a6i_1x663_38._list-grid-4_p8a6i_1x663_55 > ._card_p8a6i_1x663_3:not(#\9){
  width:25%;
}
._list_p8a6i_1x663_38._list-grid-5_p8a6i_1x663_58 > ._card_p8a6i_1x663_3:not(#\9){
  width:20%;
}
._list_p8a6i_1x663_38._list-grid-6_p8a6i_1x663_61 > ._card_p8a6i_1x663_3:not(#\9){
  width:16.6666666667%;
}
._list_p8a6i_1x663_38._list-grid-7_p8a6i_1x663_64 > ._card_p8a6i_1x663_3:not(#\9){
  width:14.2857142857%;
}
._list_p8a6i_1x663_38._list-grid-8_p8a6i_1x663_67 > ._card_p8a6i_1x663_3:not(#\9){
  width:12.5%;
}
._list_p8a6i_1x663_38._list-grid-9_p8a6i_1x663_70 > ._card_p8a6i_1x663_3:not(#\9){
  width:11.1111111111%;
}
._list_p8a6i_1x663_38._list-grid-10_p8a6i_1x663_73 > ._card_p8a6i_1x663_3:not(#\9){
  width:10%;
}
._list_p8a6i_1x663_38._list-grid-11_p8a6i_1x663_76 > ._card_p8a6i_1x663_3:not(#\9){
  width:9.0909090909%;
}
._list_p8a6i_1x663_38._list-grid-12_p8a6i_1x663_79 > ._card_p8a6i_1x663_3:not(#\9){
  width:8.3333333333%;
}
._list_p8a6i_1x663_38._list-grid-13_p8a6i_1x663_82 > ._card_p8a6i_1x663_3:not(#\9){
  width:7.6923076923%;
}
._list_p8a6i_1x663_38._list-grid-14_p8a6i_1x663_85 > ._card_p8a6i_1x663_3:not(#\9){
  width:7.1428571429%;
}
._list_p8a6i_1x663_38._list-grid-15_p8a6i_1x663_88 > ._card_p8a6i_1x663_3:not(#\9){
  width:6.6666666667%;
}
._list_p8a6i_1x663_38._list-grid-16_p8a6i_1x663_91 > ._card_p8a6i_1x663_3:not(#\9){
  width:6.25%;
}
._list_p8a6i_1x663_38._list-grid-17_p8a6i_1x663_94 > ._card_p8a6i_1x663_3:not(#\9){
  width:5.8823529412%;
}
._list_p8a6i_1x663_38._list-grid-18_p8a6i_1x663_97 > ._card_p8a6i_1x663_3:not(#\9){
  width:5.5555555556%;
}
._list_p8a6i_1x663_38._list-grid-19_p8a6i_1x663_100 > ._card_p8a6i_1x663_3:not(#\9){
  width:5.2631578947%;
}
._list_p8a6i_1x663_38._list-grid-20_p8a6i_1x663_103 > ._card_p8a6i_1x663_3:not(#\9){
  width:5%;
}

._selection-control_p8a6i_1x663_107:not(#\9){
  position:absolute;
  box-sizing:border-box;
  width:54px;
  top:0;
  right:0;
  padding:20px 20px;
  padding:20px 20px;
  padding:var(--space-scaled-l-17GFGI, 20px) var(--space-l-1RnAcv, 20px);
}

._loading_p8a6i_1x663_116:not(#\9),
._empty_p8a6i_1x663_117:not(#\9){
  overflow:hidden;
  text-align:center;
  color:#687078;
  color:#687078;
  color:var(--color-text-empty-6_f-gS, #687078);
  margin-bottom:20px;
  margin-bottom:20px;
  margin-bottom:var(--space-scaled-l-17GFGI, 20px);
}

._has-header_p8a6i_1x663_124:not(#\9){
  margin-top:20px;
  margin-top:20px;
  margin-top:var(--space-grid-gutter-17GFGI, 20px);
}

._card_p8a6i_1x663_3:not(#\9){
  display:flex;
  overflow-wrap:break-word;
  word-wrap:break-word;
  margin:0;
  padding:0;
  list-style:none;
}
._card-inner_p8a6i_1x663_3:not(#\9){
  position:relative;
  background-color:#ffffff;
  background-color:#ffffff;
  background-color:var(--color-background-container-content-3w9fMz, #ffffff);
  margin:0 0 20px 20px;
  margin:0 0 20px 20px;
  margin:0 0 var(--space-grid-gutter-17GFGI, 20px) var(--space-grid-gutter-17GFGI, 20px);
  padding:20px 20px 12px;
  padding:20px 20px 12px;
  padding:var(--space-scaled-l-17GFGI, 20px) var(--space-l-1RnAcv, 20px) var(--space-s-1Qj4Fv, 12px);
  border-color:transparent;
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 var(--color-shadow-medium-3CtI4L, rgba(0, 28, 36, 0.3)), 1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15)), -1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15));
  border-top:1px solid #eaeded;
  border-top:1px solid #eaeded;
  border-top:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-container-top-1gYBaj, #eaeded);
  border-radius:0px;
  border-radius:0px;
  border-radius:var(--border-container-radius-Z3aihh, 0px);
  box-sizing:border-box;
  width:100%;
  min-width:0;
  box-sizing:border-box;
}
@media screen and (-ms-high-contrast: active){
  ._card-inner_p8a6i_1x663_3:not(#\9){
    padding:calc(20px - 1px) calc(20px - 1px) calc(12px - 1px);
    padding:calc(20px - 1px) calc(20px - 1px) calc(12px - 1px);
    padding:calc(var(--space-scaled-l-17GFGI, 20px) - 1px) calc(var(--space-l-1RnAcv, 20px) - 1px) calc(var(--space-s-1Qj4Fv, 12px) - 1px);
  }
}
@media screen and (-ms-high-contrast: active){
  ._card-inner_p8a6i_1x663_3:not(#\9){
    border:1px solid #eaeded;
    border:1px solid #eaeded;
    border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-container-top-1gYBaj, #eaeded);
  }
}
._card-header_p8a6i_1x663_160:not(#\9){
  font-size:18px;
  font-size:18px;
  font-size:var(--font-heading-m-size-3IMO0y, 18px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-heading-m-lineHeight-2Vn4pu, 22px);
}
._card-header-inner_p8a6i_1x663_164:not(#\9){
  width:100%;
  display:inline-block;
}
._card-selectable_p8a6i_1x663_168 > ._card-inner_p8a6i_1x663_3 > ._card-header_p8a6i_1x663_160:not(#\9){
  width:90%;
}
._card-selected_p8a6i_1x663_171 > ._card-inner_p8a6i_1x663_3:not(#\9){
  border:1px solid #00a1c9;
  border:1px solid #00a1c9;
  border:var(--border-item-width-3GX4nr, 1px) solid var(--color-border-item-selected-6FRHtw, #00a1c9);
  background-color:#f1faff;
  background-color:#f1faff;
  background-color:var(--color-background-item-selected-WkeJRF, #f1faff);
  padding:20px calc(20px - 1px) calc(12px - 1px) calc(20px - 1px);
  padding:20px calc(20px - 1px) calc(12px - 1px) calc(20px - 1px);
  padding:var(--space-scaled-l-17GFGI, 20px) calc(var(--space-l-1RnAcv, 20px) - var(--border-item-width-3GX4nr, 1px)) calc(var(--space-s-1Qj4Fv, 12px) - var(--border-item-width-3GX4nr, 1px)) calc(var(--space-l-1RnAcv, 20px) - var(--border-item-width-3GX4nr, 1px));
}
._card-selected_p8a6i_1x663_171 > ._card-inner_p8a6i_1x663_3 > ._card-header_p8a6i_1x663_160 > ._selection-control_p8a6i_1x663_107:not(#\9){
  margin-right:calc(-1 * 1px);
  margin-right:calc(-1 * 1px);
  margin-right:calc(-1 * var(--border-item-width-3GX4nr, 1px));
}

._section_p8a6i_1x663_180:not(#\9){
  display:inline-block;
  box-sizing:border-box;
  padding:8px 0 8px 0;
  padding:8px 0 8px 0;
  padding:var(--space-xs-3JTBUA, 8px) 0 var(--space-scaled-xs-19adjU, 8px) 0;
  vertical-align:top;
}
._section-header_p8a6i_1x663_186:not(#\9){
  color:#545b64;
  color:#545b64;
  color:var(--color-text-label-3qT4rX, #545b64);
}
._root_wih1l_1qkhb_3:not(#\9){
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}

._tools_wih1l_1qkhb_11:not(#\9){
  display:flex;
  align-items:flex-start;
  flex-wrap:wrap;
  padding:8px 0 4px;
  padding:8px 0 4px;
  padding:var(--space-scaled-xs-19adjU, 8px) 0 var(--space-scaled-xxs-wz9S8K, 4px);
}
._tools-filtering_wih1l_1qkhb_17:not(#\9){
  flex:1 1;
  margin-right:20px;
  margin-right:20px;
  margin-right:var(--space-l-1RnAcv, 20px);
}
._tools-align-right_wih1l_1qkhb_21:not(#\9){
  display:flex;
  margin-left:auto;
}
._tools-pagination_wih1l_1qkhb_25 + ._tools-preferences_wih1l_1qkhb_25:not(#\9){
  border-left:1px solid #eaeded;
  border-left:1px solid #eaeded;
  border-left:1px solid var(--color-border-divider-default-38loDV, #eaeded);
  box-sizing:border-box;
  margin-left:8px;
  margin-left:8px;
  margin-left:var(--space-xs-3JTBUA, 8px);
  padding-left:8px;
  padding-left:8px;
  padding-left:var(--space-xs-3JTBUA, 8px);
}
._tools-small_wih1l_1qkhb_31 > ._tools-filtering_wih1l_1qkhb_17:not(#\9){
  margin-right:0;
  margin-bottom:8px;
  margin-bottom:8px;
  margin-bottom:var(--space-scaled-xs-19adjU, 8px);
  flex-basis:100%;
}

._table_wih1l_1qkhb_37:not(#\9){
  width:100%;
  border-spacing:0;
}
._table-layout-fixed_wih1l_1qkhb_41:not(#\9){
  table-layout:fixed;
}

._wrapper_wih1l_1qkhb_45:not(#\9){
  position:relative;
  width:100%;
  overflow-x:auto;
}

._cell_wih1l_1qkhb_51:not(#\9){
  box-sizing:border-box;
  padding:8px 20px;
  padding:8px 20px;
  padding:var(--space-scaled-xs-19adjU, 8px) var(--space-scaled-l-17GFGI, 20px);
}

._header-cell_wih1l_1qkhb_56:not(#\9){
  position:relative;
  text-align:left;
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid #eaeded;
  border-bottom:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-divider-default-38loDV, #eaeded);
  background:#fafafa;
  background:#fafafa;
  background:var(--color-background-container-header-1OJlgw, #fafafa);
  color:#545b64;
  color:#545b64;
  color:var(--color-text-column-header-3qT4rX, #545b64);
  font-weight:bold;
  padding:4px 8px;
  padding:4px 8px;
  padding:var(--space-scaled-xxs-wz9S8K, 4px) var(--space-scaled-xs-19adjU, 8px);
}
._header-cell-sticky_wih1l_1qkhb_65:not(#\9){
  border-bottom:0;
}
._header-cell_wih1l_1qkhb_56:not(#\9):first-child{
  padding-left:8px;
  padding-left:8px;
  padding-left:var(--space-xs-3JTBUA, 8px);
}
._header-cell_wih1l_1qkhb_56:not(#\9):last-child, ._header-cell_wih1l_1qkhb_56._header-cell-sortable_wih1l_1qkhb_71:not(#\9){
  padding-right:8px;
  padding-right:8px;
  padding-right:var(--space-xs-3JTBUA, 8px);
}
._header-cell_wih1l_1qkhb_56:not(#\9):not(:last-child):before{
  content:"";
  position:absolute;
  right:0;
  bottom:25%;
  height:50%;
  border-left:1px solid #eaeded;
  border-left:1px solid #eaeded;
  border-left:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-divider-default-38loDV, #eaeded);
  box-sizing:border-box;
}

._body-cell_wih1l_1qkhb_84:not(#\9){
  border-top:1px solid transparent;
  border-top:1px solid transparent;
  border-top:var(--border-field-width-3GX4nr, 1px) solid transparent;
  word-wrap:break-word;
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid #eaeded;
  border-bottom:var(--border-divider-list-width-3GX4nr, 1px) solid var(--color-border-divider-default-38loDV, #eaeded);
}
._body-cell_wih1l_1qkhb_84:not(#\9):not(._body-cell-wrap_wih1l_1qkhb_89){
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
._body-cell_wih1l_1qkhb_84:not(#\9):first-child{
  border-left:1px solid transparent;
  border-left:1px solid transparent;
  border-left:var(--border-field-width-3GX4nr, 1px) solid transparent;
  padding-left:calc(20px - 1px);
  padding-left:calc(20px - 1px);
  padding-left:calc(var(--space-l-1RnAcv, 20px) - var(--border-field-width-3GX4nr, 1px));
}
._body-cell_wih1l_1qkhb_84:not(#\9):last-child{
  border-right:1px solid transparent;
  border-right:1px solid transparent;
  border-right:var(--border-field-width-3GX4nr, 1px) solid transparent;
  padding-right:calc(20px - 1px);
  padding-right:calc(20px - 1px);
  padding-right:calc(var(--space-l-1RnAcv, 20px) - var(--border-field-width-3GX4nr, 1px));
}
._row_wih1l_1qkhb_102:not(#\9):last-child > ._body-cell_wih1l_1qkhb_84{
  border-bottom:1px solid transparent;
  border-bottom:1px solid transparent;
  border-bottom:var(--border-field-width-3GX4nr, 1px) solid transparent;
}
._row_wih1l_1qkhb_102._row-selected_wih1l_1qkhb_105 > ._body-cell_wih1l_1qkhb_84:not(#\9){
  background-color:#f1faff;
  background-color:#f1faff;
  background-color:var(--color-background-item-selected-WkeJRF, #f1faff);
  border-top:1px solid #00a1c9;
  border-top:1px solid #00a1c9;
  border-top:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-item-selected-6FRHtw, #00a1c9);
  border-bottom:1px solid #00a1c9;
  border-bottom:1px solid #00a1c9;
  border-bottom:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-item-selected-6FRHtw, #00a1c9);
}
._row_wih1l_1qkhb_102._row-selected_wih1l_1qkhb_105 > ._body-cell_wih1l_1qkhb_84:not(#\9):first-child{
  border-left:1px solid #00a1c9;
  border-left:1px solid #00a1c9;
  border-left:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-item-selected-6FRHtw, #00a1c9);
  border-radius:0px 0 0 0px;
  border-radius:0px 0 0 0px;
  border-radius:var(--border-item-radius-Z3aihh, 0px) 0 0 var(--border-item-radius-Z3aihh, 0px);
}
._row_wih1l_1qkhb_102._row-selected_wih1l_1qkhb_105 > ._body-cell_wih1l_1qkhb_84:not(#\9):last-child{
  border-right:1px solid #00a1c9;
  border-right:1px solid #00a1c9;
  border-right:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-item-selected-6FRHtw, #00a1c9);
  border-radius:0 0px 0px 0;
  border-radius:0 0px 0px 0;
  border-radius:0 var(--border-item-radius-Z3aihh, 0px) var(--border-item-radius-Z3aihh, 0px) 0;
}
._row_wih1l_1qkhb_102._row-selected_wih1l_1qkhb_105 + ._row-selected_wih1l_1qkhb_105 > ._body-cell_wih1l_1qkhb_84:not(#\9){
  border-top:1px solid transparent;
  border-top:1px solid transparent;
  border-top:var(--border-field-width-3GX4nr, 1px) solid transparent;
}

._cell-merged_wih1l_1qkhb_122:not(#\9){
  text-align:center;
  padding:0;
}
._cell-merged-content_wih1l_1qkhb_126:not(#\9){
  box-sizing:border-box;
  padding:16px 20px 20px;
  padding:16px 20px 20px;
  padding:var(--space-scaled-m-p3tAne, 16px) var(--space-l-1RnAcv, 20px) var(--space-scaled-l-17GFGI, 20px);
  position:sticky;
  left:0;
}

._empty_wih1l_1qkhb_133:not(#\9){
  color:#687078;
  color:#687078;
  color:var(--color-text-empty-6_f-gS, #687078);
}

._selection-control_wih1l_1qkhb_141:not(#\9){
  position:relative;
  box-sizing:border-box;
  width:54px;
  max-width:54px;
  min-width:54px;
}
._selection-control_wih1l_1qkhb_141._header-cell_wih1l_1qkhb_56:not(#\9){
  padding:8px 20px;
  padding:8px 20px;
  padding:var(--space-scaled-xs-19adjU, 8px) var(--space-scaled-l-17GFGI, 20px);
}

._sorting-icon_wih1l_1qkhb_152:not(#\9){
  position:absolute;
  top:4px;
  top:4px;
  top:var(--space-scaled-xxs-wz9S8K, 4px);
  right:4px;
  right:4px;
  right:var(--space-xxs-10c0Z4, 4px);
  color:#879596;
  color:#879596;
  color:var(--color-text-icon-caret-BxOb2m, #879596);
}

._header-cell-disabled_wih1l_1qkhb_159._header-cell-sorted_wih1l_1qkhb_159 > ._header-cell-content_wih1l_1qkhb_159 > ._sorting-icon_wih1l_1qkhb_152:not(#\9){
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
}

._header-cell-content_wih1l_1qkhb_159:not(#\9){
  position:relative;
  padding:4px 12px;
  padding:4px 12px;
  padding:var(--space-scaled-xxs-wz9S8K, 4px) var(--space-s-1Qj4Fv, 12px);
}
._header-cell-sortable_wih1l_1qkhb_71 > ._header-cell-content_wih1l_1qkhb_159:not(#\9){
  padding-right:calc(24px + 4px);
  padding-right:calc(24px + 4px);
  padding-right:calc(var(--space-xl-3Pai2K, 24px) + var(--space-xxs-10c0Z4, 4px));
}
._header-cell-content_wih1l_1qkhb_159:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._header-cell-content_wih1l_1qkhb_159[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._header-cell-content_wih1l_1qkhb_159[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(0px - 1px);
}
._header-cell-content_wih1l_1qkhb_159[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:0px;
  top:0px;
  width:calc(100% + 2 * 0px);
  height:calc(100% + 2 * 0px);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._header-cell-content_wih1l_1qkhb_159._header-cell-fake-focus_wih1l_1qkhb_192:not(#\9){
  position:relative;
}
._header-cell-content_wih1l_1qkhb_159._header-cell-fake-focus_wih1l_1qkhb_192:not(#\9){
  outline:2px dotted transparent;
  outline-offset:calc(0px - 1px);
}
._header-cell-content_wih1l_1qkhb_159._header-cell-fake-focus_wih1l_1qkhb_192:not(#\9)::before{
  content:" ";
  display:block;
  position:absolute;
  left:0px;
  top:0px;
  width:calc(100% + 2 * 0px);
  height:calc(100% + 2 * 0px);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}

._header-cell-sortable_wih1l_1qkhb_71:not(#\9):not(._header-cell-disabled_wih1l_1qkhb_159) > ._header-cell-content_wih1l_1qkhb_159{
  cursor:pointer;
}
._header-cell-sortable_wih1l_1qkhb_71:not(#\9):not(._header-cell-disabled_wih1l_1qkhb_159) > ._header-cell-content_wih1l_1qkhb_159:hover, ._header-cell-sortable_wih1l_1qkhb_71:not(#\9):not(._header-cell-disabled_wih1l_1qkhb_159)._header-cell-sorted_wih1l_1qkhb_159 > ._header-cell-content_wih1l_1qkhb_159{
  color:#16191f;
  color:#16191f;
  color:var(--color-text-interactive-active-18qOom, #16191f);
}
._header-cell-sortable_wih1l_1qkhb_71:not(#\9):not(._header-cell-disabled_wih1l_1qkhb_159) > ._header-cell-content_wih1l_1qkhb_159:hover > ._sorting-icon_wih1l_1qkhb_152, ._header-cell-sortable_wih1l_1qkhb_71:not(#\9):not(._header-cell-disabled_wih1l_1qkhb_159)._header-cell-sorted_wih1l_1qkhb_159 > ._header-cell-content_wih1l_1qkhb_159 > ._sorting-icon_wih1l_1qkhb_152{
  color:#16191f;
  color:#16191f;
  color:var(--color-text-interactive-active-18qOom, #16191f);
}

._header-cell-text_wih1l_1qkhb_226:not(#\9):not(._header-cell-text-wrap_wih1l_1qkhb_226){
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}

._sticky-scrollbar_wih1l_1qkhb_232:not(#\9){
  height:15px;
  position:sticky;
  display:none;
  overflow-x:auto;
  overflow-y:hidden;
  margin-top:-15px;
  bottom:0;
  width:100%;
}
._sticky-scrollbar-content_wih1l_1qkhb_242:not(#\9){
  height:15px;
}
._sticky-scrollbar-visible_wih1l_1qkhb_245:not(#\9){
  display:block;
}

._header-secondary_wih1l_1qkhb_249:not(#\9){
  overflow:auto;
  -ms-overflow-style:none;
  scrollbar-width:none;
  width:100%;
}
._header-secondary_wih1l_1qkhb_249:not(#\9)::-webkit-scrollbar{
  display:none;
}

._header-controls_wih1l_1qkhb_262:not(#\9){
  padding:12px 20px;
  padding:12px 20px;
  padding:var(--space-scaled-s-1sAHT4, 12px) var(--space-l-1RnAcv, 20px);
}
._header-controls_wih1l_1qkhb_262 + ._header-secondary_wih1l_1qkhb_249:not(#\9){
  border-top:1px solid #eaeded;
  border-top:1px solid #eaeded;
  border-top:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-divider-default-38loDV, #eaeded);
}
._label_1ut8b_1aol6_7:not(#\9){
  display:flex;
  align-items:center;
  justify-content:center;
  position:absolute;
  padding-right:8px;
  padding-right:8px;
  padding-right:var(--space-xs-3JTBUA, 8px);
  padding-left:8px;
  padding-left:8px;
  padding-left:var(--space-xs-3JTBUA, 8px);
  padding-bottom:4px;
  padding-bottom:4px;
  padding-bottom:var(--space-xxs-10c0Z4, 4px);
  height:100%;
  top:0;
  width:100%;
  left:0;
  box-sizing:border-box;
  border-right:1px solid transparent;
}

._stud_1ut8b_1aol6_23:not(#\9){
  visibility:hidden;
}
._wrapper_1wepg_1hdxh_3:not(#\9){
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
  display:flex;
}

._label-wrapper_1wepg_1hdxh_10:not(#\9){
  position:relative;
  display:flex;
}

._content_1wepg_1hdxh_15:not(#\9){
  word-wrap:break-word;
  max-width:100%;
  overflow:hidden;
}

._empty-content_1wepg_1hdxh_21:not(#\9){
  width:0px;
}

._description_1wepg_1hdxh_25:not(#\9){
  color:#687078;
  color:#687078;
  color:var(--color-text-form-secondary-2ZkiuH, #687078);
  font-size:12px;
  font-size:12px;
  font-size:var(--font-body-s-size-3NP7Rr, 12px);
  line-height:16px;
  line-height:16px;
  line-height:var(--font-body-s-lineHeight-x2Xc-V, 16px);
}
._description-bottom-padding_1wepg_1hdxh_30:not(#\9){
  padding-bottom:4px;
  padding-bottom:4px;
  padding-bottom:var(--space-scaled-xxs-wz9S8K, 4px);
}

._label_1wepg_1hdxh_10:not(#\9),
._description_1wepg_1hdxh_25:not(#\9){
  padding-left:8px;
  padding-left:8px;
  padding-left:var(--space-xs-3JTBUA, 8px);
}
._label-disabled_1wepg_1hdxh_38:not(#\9),
._description-disabled_1wepg_1hdxh_39:not(#\9){
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-control-disabled-KGAbe7, #aab7b8);
}

._control_1wepg_1hdxh_43:not(#\9){
  position:relative;
}
._control_1wepg_1hdxh_43 > input:not(#\9), ._control_1wepg_1hdxh_43 > svg:not(#\9), ._control_1wepg_1hdxh_43 > ._outline_1wepg_1hdxh_46:not(#\9){
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
}
._control_1wepg_1hdxh_43 > input:not(#\9){
  opacity:0;
  z-index:1;
  -webkit-user-select:none;
          user-select:none;
  cursor:default;
  margin:0;
}
._root_k2y2q_1iai4_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:block;
}

._checkbox-control_k2y2q_1iai4_14:not(#\9){
  margin-top:calc((22px - 14px) / 2);
  margin-top:calc((22px - 14px) / 2);
  margin-top:calc((var(--font-body-m-lineHeight-2Vn4pu, 22px) - 14px) / 2);
  min-height:14px;
  min-width:14px;
  height:14px;
  width:14px;
}

._input_k2y2q_1iai4_22[data-awsui-focus-visible=true]:not(#\9):focus + div{
  position:relative;
}
._input_k2y2q_1iai4_22[data-awsui-focus-visible=true]:not(#\9):focus + div{
  outline:2px dotted transparent;
  outline-offset:calc(2px - 1px);
}
._input_k2y2q_1iai4_22[data-awsui-focus-visible=true]:not(#\9):focus + div::before{
  content:" ";
  display:block;
  position:absolute;
  left:-2px;
  top:-2px;
  width:calc(100% + 2 * 2px);
  height:calc(100% + 2 * 2px);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._root_1mabk_1rup1_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:block;
}

._radio_1mabk_1rup1_14 + ._radio_1mabk_1rup1_14:not(#\9){
  margin-top:4px;
  margin-top:4px;
  margin-top:var(--space-scaled-xxs-wz9S8K, 4px);
}

._radio--has-description_1mabk_1rup1_22 + ._radio_1mabk_1rup1_14:not(#\9){
  margin-top:8px;
  margin-top:8px;
  margin-top:var(--space-scaled-xs-19adjU, 8px);
}

._radio-control_1mabk_1rup1_26:not(#\9){
  margin-top:calc((22px - 14px) / 2);
  margin-top:calc((22px - 14px) / 2);
  margin-top:calc((var(--font-body-m-lineHeight-2Vn4pu, 22px) - 14px) / 2);
  min-height:14px;
  min-width:14px;
  height:14px;
  width:14px;
}

._input_1mabk_1rup1_34[data-awsui-focus-visible=true]:not(#\9):focus + div{
  position:relative;
}
._input_1mabk_1rup1_34[data-awsui-focus-visible=true]:not(#\9):focus + div{
  outline:2px dotted transparent;
  outline-offset:calc(2px - 1px);
}
._input_1mabk_1rup1_34[data-awsui-focus-visible=true]:not(#\9):focus + div::before{
  content:" ";
  display:block;
  position:absolute;
  left:-2px;
  top:-2px;
  width:calc(100% + 2 * 2px);
  height:calc(100% + 2 * 2px);
  border-radius:50%;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}

._styled-circle-border_1mabk_1rup1_53:not(#\9){
  stroke:#aab7b8;
  stroke:#aab7b8;
  stroke:var(--color-border-control-default-27WVST, #aab7b8);
  fill:#ffffff;
  fill:#ffffff;
  fill:var(--color-background-control-default-528DLz, #ffffff);
}
._styled-circle-border_1mabk_1rup1_53._styled-circle-disabled_1mabk_1rup1_57:not(#\9){
  fill:#d5dbdb;
  fill:#d5dbdb;
  fill:var(--color-background-control-disabled-11nezK, #d5dbdb);
  stroke:#d5dbdb;
  stroke:#d5dbdb;
  stroke:var(--color-background-control-disabled-11nezK, #d5dbdb);
}

._styled-circle-fill_1mabk_1rup1_62:not(#\9){
  stroke:#0073bb;
  stroke:#0073bb;
  stroke:var(--color-background-control-checked-3uW6lA, #0073bb);
  fill:#ffffff;
  fill:#ffffff;
  fill:var(--color-foreground-control-default-3g5IJp, #ffffff);
  opacity:0;
  transition:opacity 90ms linear;
  transition:opacity 90ms linear;
  transition:opacity var(--motion-duration-transition-quick-2hvSaJ, 90ms) var(--motion-easing-transition-quick-2nchNs, linear);
}
._styled-circle-fill_1mabk_1rup1_62._styled-circle-checked_1mabk_1rup1_68:not(#\9){
  opacity:1;
}
._styled-circle-fill_1mabk_1rup1_62._styled-circle-disabled_1mabk_1rup1_57:not(#\9){
  fill:#ffffff;
  fill:#ffffff;
  fill:var(--color-foreground-control-disabled-3d6ker, #ffffff);
  stroke:#d5dbdb;
  stroke:#d5dbdb;
  stroke:var(--color-background-control-disabled-11nezK, #d5dbdb);
}
@media (prefers-reduced-motion: reduce){
  ._styled-circle-fill_1mabk_1rup1_62:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._styled-circle-fill_1mabk_1rup1_62:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}
._root_14iqq_l22ut_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:block;
  word-wrap:break-word;
}
._root_14iqq_l22ut_3:not(#\9):not(._root-disable-content-borders_14iqq_l22ut_14){
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 var(--color-shadow-medium-3CtI4L, rgba(0, 28, 36, 0.3)), 1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15)), -1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15));
  border-top:1px solid #eaeded;
  border-top:1px solid #eaeded;
  border-top:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-container-top-1gYBaj, #eaeded);
  border-radius:0px;
  border-radius:0px;
  border-radius:var(--border-container-radius-Z3aihh, 0px);
  box-sizing:border-box;
  background-color:#ffffff;
  background-color:#ffffff;
  background-color:var(--color-background-container-content-3w9fMz, #ffffff);
}
@media screen and (-ms-high-contrast: active){
  ._root_14iqq_l22ut_3:not(#\9):not(._root-disable-content-borders_14iqq_l22ut_14){
    border:1px solid #eaeded;
    border:1px solid #eaeded;
    border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-container-top-1gYBaj, #eaeded);
  }
}

._content_14iqq_l22ut_27:not(#\9):not(._content-no-paddings_14iqq_l22ut_27){
  padding:20px 20px;
  padding:20px 20px;
  padding:var(--space-scaled-l-17GFGI, 20px) var(--space-l-1RnAcv, 20px);
}
._content_14iqq_l22ut_27:not(#\9):not(._content-no-paddings_14iqq_l22ut_27)._content-has-header_14iqq_l22ut_30{
  padding-top:16px;
  padding-top:16px;
  padding-top:var(--space-scaled-m-p3tAne, 16px);
}

._header_14iqq_l22ut_34:not(#\9){
  background-color:#fafafa;
  background-color:#fafafa;
  background-color:var(--color-background-container-header-1OJlgw, #fafafa);
  border-radius:0px 0px 0 0;
  border-radius:0px 0px 0 0;
  border-radius:var(--border-container-radius-Z3aihh, 0px) var(--border-container-radius-Z3aihh, 0px) 0 0;
}
._header_14iqq_l22ut_34:not(#\9):not(:empty){
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid var(--color-border-divider-default-38loDV, #eaeded);
}
._header_14iqq_l22ut_34:not(#\9):not(._header-no-paddings_14iqq_l22ut_41){
  padding:12px 20px;
  padding:12px 20px;
  padding:var(--space-scaled-s-1sAHT4, 12px) var(--space-l-1RnAcv, 20px);
}
._header-sticky_14iqq_l22ut_44:not(#\9){
  top:0;
  position:sticky;
  z-index:800;
}
._header-stuck_14iqq_l22ut_49:not(#\9){
  box-shadow:0px 1px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0px 1px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0px 1px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}
._root-disable-content-borders_14iqq_l22ut_14 > ._header_14iqq_l22ut_34:not(#\9):not(:empty){
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 var(--color-shadow-medium-3CtI4L, rgba(0, 28, 36, 0.3)), 1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15)), -1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15));
  border-top:1px solid #eaeded;
  border-top:1px solid #eaeded;
  border-top:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-container-top-1gYBaj, #eaeded);
  border-radius:0px;
  border-radius:0px;
  border-radius:var(--border-container-radius-Z3aihh, 0px);
  box-sizing:border-box;
  border-bottom:none;
}
@media screen and (-ms-high-contrast: active){
  ._root-disable-content-borders_14iqq_l22ut_14 > ._header_14iqq_l22ut_34:not(#\9):not(:empty){
    border:1px solid #eaeded;
    border:1px solid #eaeded;
    border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-container-top-1gYBaj, #eaeded);
  }
}

._footer_14iqq_l22ut_65:not(#\9){
  border-top:1px solid #eaeded;
  border-top:1px solid #eaeded;
  border-top:1px solid var(--color-border-divider-default-38loDV, #eaeded);
  padding:12px 20px;
  padding:12px 20px;
  padding:var(--space-scaled-s-1sAHT4, 12px) var(--space-l-1RnAcv, 20px);
}
._code-editor_1gl9c_1mwgw_13 .ace_editor:not(#\9){
  font-family:Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;
  font-size:14px;
  line-height:20px;
}
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-cell.ace_error:not(#\9),
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-cell.ace_warning:not(#\9){
  cursor:pointer;
  background-repeat:no-repeat;
  background-size:16px 16px;
  background-position:4px 2px;
}
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-cell.ace_info:not(#\9){
  background-image:none;
}
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-cell.ace_error:not(#\9){
  color:#d13212;
  color:#d13212;
  color:var(--color-red-600-2N9Epo, #d13212);
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23d13212' fill-opacity='0' stroke-width='2' stroke-linejoin='round'%3E %3Ccircle cx='8' cy='8' r='7'/%3E %3Cpath d='M10.828 5.172l-5.656 5.656M10.828 10.828L5.172 5.172'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-cell.ace_error:not(#\9){
    color:#ff5d64;
    color:#ff5d64;
    color:var(--color-red-500-c21Nsx, #ff5d64);
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23ff5d64' fill-opacity='0' stroke-width='2' stroke-linejoin='round'%3E %3Ccircle cx='8' cy='8' r='7'/%3E %3Cpath d='M10.828 5.172l-5.656 5.656M10.828 10.828L5.172 5.172'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-cell.ace_warning:not(#\9){
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23d13212' fill-opacity='0' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M8 1l7 14H1L8 1z'/%3E %3Cpath d='M7.99 12H8v.01h-.01zM8 6v4'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-cell.ace_warning:not(#\9){
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23ff5d64' fill-opacity='0' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M8 1l7 14H1L8 1z'/%3E %3Cpath d='M7.99 12H8v.01h-.01zM8 6v4'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-cell:not(#\9){
  padding-left:24px;
  padding-right:16px;
}
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_fold-widget:not(#\9){
  width:14px;
  margin-right:-15px;
  background-color:transparent;
  border:none;
  box-shadow:none;
}
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_marker-layer > .ace_active-line:not(#\9){
  background:transparent;
  box-sizing:border-box;
  border-top:1px solid #d5dbdb;
  border-bottom:1px solid #d5dbdb;
}
._code-editor_1gl9c_1mwgw_13 .ace_editor.ace_dark .ace_marker-layer > .ace_active-line:not(#\9){
  border-top:1px solid #687078;
  border-bottom:1px solid #687078;
}
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter:not(#\9){
  background-color:#f2f3f3;
  background-color:#f2f3f3;
  background-color:var(--color-background-code-editor-gutter-default-ikVlHL, #f2f3f3);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-code-editor-gutter-default-mcmuEw, #16191f);
}
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_fold-widget.ace_open:not(#\9){
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23545b64' fill='%23545b64' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_fold-widget.ace_open:not(#\9){
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23d5dbdb' fill='%23d5dbdb' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_fold-widget.ace_open:not(#\9):hover{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%2316191f' fill='%2316191f' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_fold-widget.ace_open:not(#\9):hover{
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23fafafa' fill='%23fafafa' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_fold-widget.ace_closed:not(#\9){
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23545b64' fill='%23545b64' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_fold-widget.ace_closed:not(#\9){
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23d5dbdb' fill='%23d5dbdb' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_fold-widget.ace_closed:not(#\9):hover{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%2316191f' fill='%2316191f' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_fold-widget.ace_closed:not(#\9):hover{
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23fafafa' fill='%23fafafa' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line:not(#\9){
  background-color:#687078;
  background-color:#687078;
  background-color:var(--color-background-code-editor-gutter-active-line-default-3mUVYZ, #687078);
  color:#ffffff;
  color:#ffffff;
  color:var(--color-text-code-editor-gutter-active-line-DnITSk, #ffffff);
}
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line > .ace_fold-widget.ace_open:not(#\9){
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23d5dbdb' fill='%23d5dbdb' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line > .ace_fold-widget.ace_open:not(#\9){
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23414750' fill='%23414750' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line > .ace_fold-widget.ace_open:not(#\9):hover{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23fafafa' fill='%23fafafa' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line > .ace_fold-widget.ace_open:not(#\9):hover{
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%2316191f' fill='%2316191f' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line > .ace_fold-widget.ace_closed:not(#\9){
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23d5dbdb' fill='%23d5dbdb' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line > .ace_fold-widget.ace_closed:not(#\9){
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23414750' fill='%23414750' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line > .ace_fold-widget.ace_closed:not(#\9):hover{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23fafafa' fill='%23fafafa' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line > .ace_fold-widget.ace_closed:not(#\9):hover{
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%2316191f' fill='%2316191f' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line.ace_error:not(#\9){
  color:white;
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23fff' fill-opacity='0' stroke-width='2' stroke-linejoin='round'%3E %3Ccircle cx='8' cy='8' r='7'/%3E %3Cpath d='M10.828 5.172l-5.656 5.656M10.828 10.828L5.172 5.172'/%3E %3C/svg%3E");
  background-color:#d13212;
  background-color:#d13212;
  background-color:var(--color-background-code-editor-gutter-active-line-error-1jj2sL, #d13212);
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line.ace_error:not(#\9){
    color:#16191f;
    color:#16191f;
    color:var(--color-grey-900-w0NbTF, #16191f);
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%2316191f' fill-opacity='0' stroke-width='2' stroke-linejoin='round'%3E %3Ccircle cx='8' cy='8' r='7'/%3E %3Cpath d='M10.828 5.172l-5.656 5.656M10.828 10.828L5.172 5.172'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line.ace_warning:not(#\9){
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23fff' fill-opacity='0' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M8 1l7 14H1L8 1z'/%3E %3Cpath d='M7.99 12H8v.01h-.01zM8 6v4'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line.ace_warning:not(#\9){
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%2316191f' fill-opacity='0' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M8 1l7 14H1L8 1z'/%3E %3Cpath d='M7.99 12H8v.01h-.01zM8 6v4'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line.ace_error > .ace_fold-widget.ace_open:not(#\9), ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line.ace_warning > .ace_fold-widget.ace_open:not(#\9){
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23d5dbdb' fill='%23d5dbdb' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line.ace_error > .ace_fold-widget.ace_open:not(#\9), .awsui-polaris-dark-mode ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line.ace_warning > .ace_fold-widget.ace_open:not(#\9){
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23414750' fill='%23414750' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line.ace_error > .ace_fold-widget.ace_open:not(#\9):hover, ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line.ace_warning > .ace_fold-widget.ace_open:not(#\9):hover{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23fafafa' fill='%23fafafa' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line.ace_error > .ace_fold-widget.ace_open:not(#\9):hover, .awsui-polaris-dark-mode ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line.ace_warning > .ace_fold-widget.ace_open:not(#\9):hover{
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%2316191f' fill='%2316191f' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line.ace_error > .ace_fold-widget.ace_closed:not(#\9), ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line.ace_warning > .ace_fold-widget.ace_closed:not(#\9){
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23d5dbdb' fill='%23d5dbdb' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line.ace_error > .ace_fold-widget.ace_closed:not(#\9), .awsui-polaris-dark-mode ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line.ace_warning > .ace_fold-widget.ace_closed:not(#\9){
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23414750' fill='%23414750' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line.ace_error > .ace_fold-widget.ace_closed:not(#\9):hover, ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line.ace_warning > .ace_fold-widget.ace_closed:not(#\9):hover{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23fafafa' fill='%23fafafa' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line.ace_error > .ace_fold-widget.ace_closed:not(#\9):hover, .awsui-polaris-dark-mode ._code-editor_1gl9c_1mwgw_13 .ace_editor .ace_gutter-active-line.ace_warning > .ace_fold-widget.ace_closed:not(#\9):hover{
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%2316191f' fill='%2316191f' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
  }
}
._pane_1gl9c_1mwgw_244:not(#\9){
  display:flex;
  position:relative;
  flex-direction:row;
  flex:1 1;
  border-top:1px solid #d5dbdb;
  border-top:1px solid #d5dbdb;
  border-top:1px solid var(--color-border-code-editor-default-1MzhzU, #d5dbdb);
  background:#f2f3f3;
  background:#f2f3f3;
  background:var(--color-background-code-editor-status-bar-ikVlHL, #f2f3f3);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
}
._pane__close-container_1gl9c_1mwgw_253:not(#\9){
  position:absolute;
  top:0;
  right:calc(12px / 2);
  right:calc(12px / 2);
  right:calc(var(--space-s-1Qj4Fv, 12px) / 2);
}
._pane__close-container_1gl9c_1mwgw_253 > button:not(#\9){
  margin:0;
}
._pane__list_1gl9c_1mwgw_261:not(#\9){
  flex:1 1;
  overflow:auto;
  max-height:100%;
  box-sizing:border-box;
  margin-right:calc(22px + 2 * 8px);
  margin-right:calc(22px + 2 * 8px);
  margin-right:calc(var(--font-body-m-lineHeight-2Vn4pu, 22px) + 2 * var(--space-xs-3JTBUA, 8px));
}
._pane__table_1gl9c_1mwgw_268:not(#\9){
  width:100%;
  border-spacing:0;
  margin:12px 0;
  margin:12px 0;
  margin:var(--space-s-1Qj4Fv, 12px) 0;
}
._pane__item_1gl9c_1mwgw_273:not(#\9){
  border:1px solid transparent;
  border:1px solid transparent;
  border:1px solid var(--color-transparent-25e1K_, transparent);
}
._pane__item_1gl9c_1mwgw_273:not(#\9):hover, ._pane__item_1gl9c_1mwgw_273:not(#\9):focus, ._pane__item--highlighted_1gl9c_1mwgw_276:not(#\9){
  cursor:pointer;
  background-color:#eaeded;
  background-color:#eaeded;
  background-color:var(--color-background-code-editor-pane-item-hover-38loDV, #eaeded);
  outline:1px solid #687078;
  outline:1px solid #687078;
  outline:1px solid var(--color-border-code-editor-pane-item-hover-3mUVYZ, #687078);
  outline-offset:-1px;
}
._pane__location_1gl9c_1mwgw_282:not(#\9), ._pane__description_1gl9c_1mwgw_282:not(#\9){
  padding:4px 12px;
  padding:4px 12px;
  padding:var(--space-xxs-10c0Z4, 4px) var(--space-s-1Qj4Fv, 12px);
}
._pane__location_1gl9c_1mwgw_282:not(#\9){
  vertical-align:baseline;
  white-space:nowrap;
  padding-left:calc(20px + 12px);
  padding-left:calc(20px + 12px);
  padding-left:calc(var(--space-l-1RnAcv, 20px) + var(--space-s-1Qj4Fv, 12px));
}
._pane__description_1gl9c_1mwgw_282:not(#\9){
  padding-right:0;
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
}

._focus-lock_1gl9c_1mwgw_297:not(#\9){
  height:100%;
}

._code-editor_1gl9c_1mwgw_13:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:inline-block;
  border:1px solid #d5dbdb;
  border:1px solid #d5dbdb;
  border:1px solid var(--color-border-code-editor-default-1MzhzU, #d5dbdb);
  width:100%;
}

._resizable-box_1gl9c_1mwgw_314:not(#\9){
  position:relative;
  width:100%;
}

._resizable-box-handle_1gl9c_1mwgw_319:not(#\9){
  position:absolute;
  right:0;
  bottom:0;
  z-index:10;
  width:20px;
  width:20px;
  width:var(--space-l-1RnAcv, 20px);
  height:20px;
  height:20px;
  height:var(--space-l-1RnAcv, 20px);
  background-repeat:no-repeat;
  background-origin:content-box;
  box-sizing:border-box;
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23687078' fill-opacity='0' stroke-width='1'%3E %3Cpath d='M 8 13 L 13 8 M 13 11 L 11 13 M 5 13 L 13 5'/%3E %3C/svg%3E");
  background-position:bottom right;
  background-size:20px;
  background-size:20px;
  background-size:var(--space-l-1RnAcv, 20px);
  cursor:ns-resize;
}

._editor_1gl9c_1mwgw_335:not(#\9){
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
}
._editor_1gl9c_1mwgw_335:not(#\9):focus{
  position:relative;
  position:absolute;
  overflow:visible;
}
._editor_1gl9c_1mwgw_335:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(3px - 1px);
}
._editor_1gl9c_1mwgw_335:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-3px;
  top:-3px;
  width:calc(100% + 2 * 3px);
  height:calc(100% + 2 * 3px);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}

._status-bar_1gl9c_1mwgw_363:not(#\9){
  display:flex;
  vertical-align:middle;
  border-top:1px solid #d5dbdb;
  border-top:1px solid #d5dbdb;
  border-top:1px solid var(--color-border-code-editor-default-1MzhzU, #d5dbdb);
  background-color:#f2f3f3;
  background-color:#f2f3f3;
  background-color:var(--color-background-code-editor-status-bar-ikVlHL, #f2f3f3);
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
}
._status-bar__left_1gl9c_1mwgw_372:not(#\9){
  flex:1 1;
  display:flex;
  flex-wrap:wrap;
  padding-left:20px;
  padding-left:20px;
  padding-left:var(--space-l-1RnAcv, 20px);
  border-right:1px solid #d5dbdb;
  border-right:1px solid #d5dbdb;
  border-right:1px solid var(--color-border-code-editor-default-1MzhzU, #d5dbdb);
}
._status-bar__right_1gl9c_1mwgw_379:not(#\9){
  display:flex;
  align-items:center;
}
._status-bar__language-mode_1gl9c_1mwgw_383:not(#\9), ._status-bar__cursor-position_1gl9c_1mwgw_383:not(#\9){
  display:inline-block;
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  padding:8px 12px;
  padding:8px 12px;
  padding:var(--space-scaled-xs-19adjU, 8px) var(--space-s-1Qj4Fv, 12px);
}
._status-bar__cog-button_1gl9c_1mwgw_388:not(#\9){
  padding:calc(4px - 1px) calc(8px - 2px);
  padding:calc(4px - 1px) calc(8px - 2px);
  padding:calc(var(--space-scaled-xxs-wz9S8K, 4px) - 1px) calc(var(--space-xs-3JTBUA, 8px) - 2px);
}
._status-bar__cog-button_1gl9c_1mwgw_388 > button:not(#\9){
  margin:0;
}

._tab-button_1gl9c_1mwgw_395:not(#\9){
  position:relative;
  display:inline-block;
  padding:8px 12px;
  padding:8px 12px;
  padding:var(--space-scaled-xs-19adjU, 8px) var(--space-s-1Qj4Fv, 12px);
  line-height:inherit;
  color:#d13212;
  color:#d13212;
  color:var(--color-text-status-error-1jj2sL, #d13212);
  background:none;
  border:none;
  font-weight:bold;
  outline:none;
  cursor:pointer;
  z-index:1;
}
._tab-button--active_1gl9c_1mwgw_408:not(#\9)::after, ._tab-button_1gl9c_1mwgw_395:not(#\9):hover::after{
  content:"";
  position:absolute;
  left:0;
  right:0;
  bottom:0;
  height:2px;
  background:#d13212;
  background:#d13212;
  background:var(--color-text-status-error-1jj2sL, #d13212);
}
._tab-button_1gl9c_1mwgw_395:not(#\9):hover{
  color:#ffffff;
  color:#ffffff;
  color:var(--color-text-code-editor-tab-button-error-DnITSk, #ffffff);
  background:#d13212;
  background:#d13212;
  background:var(--color-text-status-error-1jj2sL, #d13212);
}
._tab-button--disabled_1gl9c_1mwgw_421:not(#\9){
  font-weight:normal;
  color:#879596;
  color:#879596;
  color:var(--color-text-code-editor-status-bar-disabled-3ki3SQ, #879596);
  cursor:default;
}
._tab-button--disabled_1gl9c_1mwgw_421:not(#\9):hover{
  color:#879596;
  color:#879596;
  color:var(--color-text-code-editor-status-bar-disabled-3ki3SQ, #879596);
  background:transparent;
}
._tab-button--disabled_1gl9c_1mwgw_421:not(#\9)::after{
  display:none;
}
._tab-button_1gl9c_1mwgw_395[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._tab-button_1gl9c_1mwgw_395[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(3px - 1px);
}
._tab-button_1gl9c_1mwgw_395[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-3px;
  top:-3px;
  width:calc(100% + 2 * 3px);
  height:calc(100% + 2 * 3px);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}

._loading-screen_1gl9c_1mwgw_455:not(#\9),
._error-screen_1gl9c_1mwgw_456:not(#\9){
  display:flex;
  align-items:center;
  justify-content:center;
  height:480px;
  color:#545b64;
  color:#545b64;
  color:var(--color-text-body-secondary-1oBHNI, #545b64);
  background:#f2f3f3;
  background:#f2f3f3;
  background:var(--color-background-code-editor-status-bar-ikVlHL, #f2f3f3);
}

._error-screen_1gl9c_1mwgw_456:not(#\9){
  color:#d13212;
  color:#d13212;
  color:var(--color-text-status-error-1jj2sL, #d13212);
}
._root_2qdw9_10he8_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  cursor:inherit;
  display:flex;
  width:100%;
  flex-wrap:wrap;
  justify-content:space-between;
}
._root-no-actions_2qdw9_10he8_17._root-variant-h2_2qdw9_10he8_17:not(#\9), ._root-no-actions_2qdw9_10he8_17._root-variant-h3_2qdw9_10he8_17:not(#\9), ._root-has-description_2qdw9_10he8_17._root-variant-h2_2qdw9_10he8_17:not(#\9), ._root-has-description_2qdw9_10he8_17._root-variant-h3_2qdw9_10he8_17:not(#\9){
  padding-bottom:4px;
  padding-bottom:4px;
  padding-bottom:var(--space-scaled-xxs-wz9S8K, 4px);
}

._main_2qdw9_10he8_21:not(#\9){
  word-wrap:break-word;
  max-width:100%;
  overflow:hidden;
}
:not(#\9):not(._root-no-actions_2qdw9_10he8_17) > ._main_2qdw9_10he8_21{
  margin-right:8px;
  margin-right:8px;
  margin-right:var(--space-xs-3JTBUA, 8px);
}
._main-variant-h1_2qdw9_10he8_29:not(#\9){
  padding:4px 0;
  padding:4px 0;
  padding:var(--space-scaled-2x-xxs-wz9S8K, 4px) 0;
}
._main-variant-h2_2qdw9_10he8_32:not(#\9), ._main-variant-h3_2qdw9_10he8_32:not(#\9){
  padding-top:4px;
  padding-top:4px;
  padding-top:var(--space-scaled-xxs-wz9S8K, 4px);
}
:not(#\9):not(._root-has-description_2qdw9_10he8_17) > ._main-variant-h2_2qdw9_10he8_32, :not(#\9):not(._root-has-description_2qdw9_10he8_17) > ._main-variant-h3_2qdw9_10he8_32{
  padding-bottom:2px;
  padding-bottom:2px;
  padding-bottom:var(--space-xxxs-2Fa4lw, 2px);
}

._actions_2qdw9_10he8_39:not(#\9){
  display:flex;
  align-items:flex-start;
}
._actions-variant-h1_2qdw9_10he8_43:not(#\9){
  padding:8px 0;
  padding:8px 0;
  padding:var(--space-scaled-xs-19adjU, 8px) 0;
}
._actions-variant-h2_2qdw9_10he8_46:not(#\9), ._actions-variant-h3_2qdw9_10he8_46:not(#\9){
  padding:4px 0;
  padding:4px 0;
  padding:var(--space-scaled-xxs-wz9S8K, 4px) 0;
}
._root-has-description_2qdw9_10he8_17 > ._actions-variant-h2_2qdw9_10he8_46:not(#\9), ._root-has-description_2qdw9_10he8_17 > ._actions-variant-h3_2qdw9_10he8_46:not(#\9){
  padding-bottom:0;
}

._title_2qdw9_10he8_53:not(#\9){
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
  color:#16191f;
  color:#16191f;
  color:var(--color-text-heading-default-2S6vEC, #16191f);
}
._title-variant-h2_2qdw9_10he8_59:not(#\9), ._title-variant-h3_2qdw9_10he8_59:not(#\9){
  padding-top:4px;
  padding-top:4px;
  padding-top:var(--space-scaled-xxs-wz9S8K, 4px);
  padding-bottom:4px;
  padding-bottom:4px;
  padding-bottom:var(--space-scaled-xxs-wz9S8K, 4px);
  font-size:18px;
  font-size:18px;
  font-size:var(--font-heading-m-size-3IMO0y, 18px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-heading-m-lineHeight-2Vn4pu, 22px);
}

._info_2qdw9_10he8_66:not(#\9){
  padding-right:12px;
  padding-right:12px;
  padding-right:var(--space-s-1Qj4Fv, 12px);
}

._description_2qdw9_10he8_70:not(#\9){
  margin:0;
  padding:0;
  color:#545b64;
  color:#545b64;
  color:var(--color-text-heading-secondary-1oBHNI, #545b64);
}
._description-variant-h1_2qdw9_10he8_75:not(#\9){
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  padding-top:4px;
  padding-top:4px;
  padding-top:var(--space-scaled-xxs-wz9S8K, 4px);
}
._description-variant-h2_2qdw9_10he8_80:not(#\9), ._description-variant-h3_2qdw9_10he8_80:not(#\9){
  font-size:12px;
  font-size:12px;
  font-size:var(--font-body-s-size-3NP7Rr, 12px);
  line-height:16px;
  line-height:16px;
  line-height:var(--font-body-s-lineHeight-x2Xc-V, 16px);
  padding-bottom:4px;
  padding-bottom:4px;
  padding-bottom:var(--space-scaled-xxs-wz9S8K, 4px);
}

._heading_2qdw9_10he8_86:not(#\9){
  margin:0;
  display:inline;
  font-size:inherit;
  margin-right:8px;
  margin-right:8px;
  margin-right:var(--space-xs-3JTBUA, 8px);
  font-weight:400;
}
._heading_2qdw9_10he8_86:not(#\9):only-child{
  margin:0;
}
._heading-variant-h1_2qdw9_10he8_96:not(#\9){
  font-size:28px;
  font-size:28px;
  font-size:var(--font-heading-xl-size-2ac18Z, 28px);
  line-height:36px;
  line-height:36px;
  line-height:var(--font-heading-xl-lineHeight-3i5g66, 36px);
}
._heading-variant-h2_2qdw9_10he8_100:not(#\9){
  font-weight:700;
}

._counter_2qdw9_10he8_108:not(#\9){
  color:#687078;
  color:#687078;
  color:var(--color-text-counter-2ZkiuH, #687078);
  font-weight:normal;
}
@-webkit-keyframes _modal-slide-up_1d2i7_1h5fp_1{
  0%{
    -webkit-transform:translate(0, 10px);
            transform:translate(0, 10px);
  }
  100%{
    -webkit-transform:translate(0, 0);
            transform:translate(0, 0);
  }
}
@keyframes _modal-slide-up_1d2i7_1h5fp_1{
  0%{
    -webkit-transform:translate(0, 10px);
            transform:translate(0, 10px);
  }
  100%{
    -webkit-transform:translate(0, 0);
            transform:translate(0, 0);
  }
}
._dialog_1d2i7_1h5fp_11:not(#\9){
  -webkit-animation:_modal-slide-up_1d2i7_1h5fp_1 180ms ease-out, _awsui-motion-fade-in-0_1d2i7_1h5fp_1 180ms ease-out;
          animation:_modal-slide-up_1d2i7_1h5fp_1 180ms ease-out, _awsui-motion-fade-in-0_1d2i7_1h5fp_1 180ms ease-out;
  animation:_modal-slide-up_1d2i7_1h5fp_1 180ms ease-out, _awsui-motion-fade-in-0_1d2i7_1h5fp_1 180ms ease-out;
  -webkit-animation:_modal-slide-up_1d2i7_1h5fp_1 var(--motion-duration-slow-39Jq2G, 180ms) ease-out, _awsui-motion-fade-in-0_1d2i7_1h5fp_1 var(--motion-duration-slow-39Jq2G, 180ms) ease-out;
          animation:_modal-slide-up_1d2i7_1h5fp_1 var(--motion-duration-slow-39Jq2G, 180ms) ease-out, _awsui-motion-fade-in-0_1d2i7_1h5fp_1 var(--motion-duration-slow-39Jq2G, 180ms) ease-out;
  -webkit-animation-delay:90ms;
          animation-delay:90ms;
  animation-delay:90ms;
  -webkit-animation-delay:var(--motion-duration-fast-oPeLPK, 90ms);
          animation-delay:var(--motion-duration-fast-oPeLPK, 90ms);
  -webkit-animation-fill-mode:both;
          animation-fill-mode:both;
}
@-webkit-keyframes _awsui-motion-fade-in-0_1d2i7_1h5fp_1{
  from{
    opacity:0;
  }
  to{
    opacity:1;
  }
}
@keyframes _awsui-motion-fade-in-0_1d2i7_1h5fp_1{
  from{
    opacity:0;
  }
  to{
    opacity:1;
  }
}
@media (prefers-reduced-motion: reduce){
  ._dialog_1d2i7_1h5fp_11:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._dialog_1d2i7_1h5fp_11:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._overlay_1d2i7_1h5fp_35:not(#\9){
  -webkit-animation:_awsui-motion-fade-in_1d2i7_1h5fp_1 270ms ease-out;
          animation:_awsui-motion-fade-in_1d2i7_1h5fp_1 270ms ease-out;
  animation:_awsui-motion-fade-in_1d2i7_1h5fp_1 270ms ease-out;
  -webkit-animation:_awsui-motion-fade-in_1d2i7_1h5fp_1 var(--motion-duration-extra-slow-39YF13, 270ms) ease-out;
          animation:_awsui-motion-fade-in_1d2i7_1h5fp_1 var(--motion-duration-extra-slow-39YF13, 270ms) ease-out;
}
@-webkit-keyframes _awsui-motion-fade-in_1d2i7_1h5fp_1{
  from{
    opacity:0.2;
  }
  to{
    opacity:1;
  }
}
@keyframes _awsui-motion-fade-in_1d2i7_1h5fp_1{
  from{
    opacity:0.2;
  }
  to{
    opacity:1;
  }
}
@media (prefers-reduced-motion: reduce){
  ._overlay_1d2i7_1h5fp_35:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._overlay_1d2i7_1h5fp_35:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._root_1d2i7_1h5fp_57:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  background-color:rgba(242, 243, 243, 0.9);
  background-color:rgba(242, 243, 243, 0.9);
  background-color:var(--color-background-modal-overlay-3ptHv2, rgba(242, 243, 243, 0.9));
  display:flex;
  align-items:center;
  justify-items:center;
  bottom:0;
  left:0;
  right:0;
  top:0;
  position:fixed;
  z-index:5000;
  outline:0;
  overflow:auto;
  cursor:pointer;
}
._root_1d2i7_1h5fp_57._hidden_1d2i7_1h5fp_79:not(#\9){
  display:none;
}

._focus-lock_1d2i7_1h5fp_83:not(#\9){
  align-self:flex-start;
  margin:auto;
  padding:calc(2 * 32px) 0;
  padding:calc(2 * 32px) 0;
  padding:calc(2 * var(--space-scaled-xxl-238u6Z, 32px)) 0;
  z-index:5000;
  pointer-events:none;
}

._dialog_1d2i7_1h5fp_11:not(#\9){
  position:static;
  top:0;
  -webkit-transform:translate(0, 0);
          transform:translate(0, 0);
  width:calc(100vw - 20px);
  width:calc(100vw - 20px);
  width:calc(100vw - var(--space-l-1RnAcv, 20px));
  box-sizing:border-box;
  outline:none;
  z-index:5000;
  pointer-events:all;
}
._dialog_1d2i7_1h5fp_11._small_1d2i7_1h5fp_101:not(#\9){
  max-width:320px;
}
._dialog_1d2i7_1h5fp_11._medium_1d2i7_1h5fp_104:not(#\9){
  max-width:600px;
}
._dialog_1d2i7_1h5fp_11._large_1d2i7_1h5fp_107:not(#\9){
  max-width:820px;
}
._dialog_1d2i7_1h5fp_11._max_1d2i7_1h5fp_110._breakpoint-xs_1d2i7_1h5fp_110:not(#\9){
  max-width:calc(100vw - (2 * 4 * 10px + 40px));
  max-width:calc(100vw - (2 * 4 * 10px + 40px));
  max-width:calc(100vw - (2 * 4 * 10px + var(--space-xxxl-25ySnK, 40px)));
  margin:auto;
}

._container_1d2i7_1h5fp_115:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:block;
  background-color:#ffffff;
  background-color:#ffffff;
  background-color:var(--color-background-container-content-3w9fMz, #ffffff);
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 var(--color-shadow-medium-3CtI4L, rgba(0, 28, 36, 0.3)), 1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15)), -1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15));
  border-top:1px solid #eaeded;
  border-top:1px solid #eaeded;
  border-top:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-container-top-1gYBaj, #eaeded);
  border-radius:0px;
  border-radius:0px;
  border-radius:var(--border-container-radius-Z3aihh, 0px);
  box-sizing:border-box;
  word-wrap:break-word;
}
@media screen and (-ms-high-contrast: active){
  ._container_1d2i7_1h5fp_115:not(#\9){
    border:1px solid #eaeded;
    border:1px solid #eaeded;
    border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-container-top-1gYBaj, #eaeded);
  }
}

._content_1d2i7_1h5fp_137:not(#\9){
  padding:16px 20px 20px;
  padding:16px 20px 20px;
  padding:var(--space-scaled-m-p3tAne, 16px) var(--space-l-1RnAcv, 20px) var(--space-scaled-l-17GFGI, 20px);
}

._inner-header_1d2i7_1h5fp_141:not(#\9){
  flex-wrap:nowrap;
  padding:2px 0;
  padding:2px 0;
  padding:var(--space-xxxs-2Fa4lw, 2px) 0;
}

._header_1d2i7_1h5fp_146:not(#\9){
  padding:8px 20px;
  padding:8px 20px;
  padding:var(--space-xs-3JTBUA, 8px) var(--space-l-1RnAcv, 20px);
  background-color:#fafafa;
  background-color:#fafafa;
  background-color:var(--color-background-container-header-1OJlgw, #fafafa);
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid var(--color-border-divider-default-38loDV, #eaeded);
  border-radius:0px 0px 0 0;
  border-radius:0px 0px 0 0;
  border-radius:var(--border-container-radius-Z3aihh, 0px) var(--border-container-radius-Z3aihh, 0px) 0 0;
}

._footer_1d2i7_1h5fp_157:not(#\9){
  border-top:1px solid #eaeded;
  border-top:1px solid #eaeded;
  border-top:1px solid var(--color-border-divider-default-38loDV, #eaeded);
  padding:12px 20px;
  padding:12px 20px;
  padding:var(--space-s-1Qj4Fv, 12px) var(--space-l-1RnAcv, 20px);
}
._footer_1d2i7_1h5fp_157:not(#\9):after{
  content:"";
  display:table;
  clear:both;
}

._dismiss-control_1d2i7_1h5fp_167:not(#\9){
  margin-right:calc(-1 * 4px);
  margin-right:calc(-1 * 4px);
  margin-right:calc(-1 * var(--space-xxs-10c0Z4, 4px));
}

._modal-open_1d2i7_1h5fp_171:not(#\9){
  overflow:hidden;
  box-sizing:border-box;
}
._root_r2vco_hz7f9_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}
._visible-content-title_tc96w_1v8xe_12:not(#\9){
  color:#16191f;
  color:#16191f;
  color:var(--color-text-form-label-WiQFVx, #16191f);
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  margin-bottom:20px;
  margin-bottom:20px;
  margin-bottom:var(--space-scaled-l-17GFGI, 20px);
}

._visible-content-group-label_tc96w_1v8xe_19:not(#\9){
  color:#545b64;
  color:#545b64;
  color:var(--color-text-label-3qT4rX, #545b64);
  padding-bottom:8px;
  padding-bottom:8px;
  padding-bottom:var(--space-xs-3JTBUA, 8px);
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid var(--color-border-divider-default-38loDV, #eaeded);
}

._visible-content-option_tc96w_1v8xe_25:not(#\9){
  display:flex;
  flex-wrap:nowrap;
  justify-content:space-between;
  padding:8px 0px 8px 20px;
  padding:8px 0px 8px 20px;
  padding:var(--space-xs-3JTBUA, 8px) 0px var(--space-xs-3JTBUA, 8px) var(--space-scaled-l-17GFGI, 20px);
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid var(--color-border-divider-default-38loDV, #eaeded);
}
._visible-content-option_tc96w_1v8xe_25:not(#\9):last-child{
  border-bottom:none;
}

._visible-content-option-label_tc96w_1v8xe_36:not(#\9){
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  padding-right:20px;
  padding-right:20px;
  padding-right:var(--space-l-1RnAcv, 20px);
}

._second-column-small_tc96w_1v8xe_52:not(#\9){
  padding-top:calc(2 * 20px);
  padding-top:calc(2 * 20px);
  padding-top:calc(2 * var(--space-scaled-l-17GFGI, 20px));
}
._root_4yi2u_1m8vx_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:block;
}

._input_4yi2u_1m8vx_14[data-awsui-focus-visible=true]:not(#\9):focus + div{
  position:relative;
}
._input_4yi2u_1m8vx_14[data-awsui-focus-visible=true]:not(#\9):focus + div{
  outline:2px dotted transparent;
  outline-offset:calc(2px - 1px);
}
._input_4yi2u_1m8vx_14[data-awsui-focus-visible=true]:not(#\9):focus + div::before{
  content:" ";
  display:block;
  position:absolute;
  left:-2px;
  top:-2px;
  width:calc(100% + 2 * 2px);
  height:calc(100% + 2 * 2px);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}

._toggle-control_4yi2u_1m8vx_33:not(#\9){
  margin-top:calc((22px - 16px) / 2);
  margin-top:calc((22px - 16px) / 2);
  margin-top:calc((var(--font-body-m-lineHeight-2Vn4pu, 22px) - 16px) / 2);
  min-height:16px;
  min-width:24px;
  height:16px;
  width:24px;
  background:#545b64;
  background:#545b64;
  background:var(--color-background-toggle-default-3zU3ek, #545b64);
  border-radius:8px;
}
._toggle-control-checked_4yi2u_1m8vx_42:not(#\9){
  background:#0073bb;
  background:#0073bb;
  background:var(--color-background-control-checked-3uW6lA, #0073bb);
}
._toggle-control-disabled_4yi2u_1m8vx_45:not(#\9){
  background:#d5dbdb;
  background:#d5dbdb;
  background:var(--color-background-control-disabled-11nezK, #d5dbdb);
}
._toggle-control-disabled_4yi2u_1m8vx_45._toggle-control-checked_4yi2u_1m8vx_42:not(#\9){
  background:#99cbe4;
  background:#99cbe4;
  background:var(--color-background-toggle-checked-disabled-uMVvXl, #99cbe4);
}

._toggle-handle_4yi2u_1m8vx_52:not(#\9){
  position:absolute;
  border-radius:6px;
  background:#ffffff;
  background:#ffffff;
  background:var(--color-foreground-control-default-3g5IJp, #ffffff);
  box-shadow:1px 1px rgba(0, 0, 0, 0.25);
  width:12px;
  height:12px;
  top:2px;
  left:2px;
  transition:background-color 90ms cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 90ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform 90ms cubic-bezier(0.165, 0.84, 0.44, 1), background-color 90ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform 90ms cubic-bezier(0.165, 0.84, 0.44, 1), background-color 90ms cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 90ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform 90ms cubic-bezier(0.165, 0.84, 0.44, 1), background-color 90ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:background-color var(--motion-duration-fast-oPeLPK, 90ms) var(--motion-easing-ease-out-quart-cJfwJd, cubic-bezier(0.165, 0.84, 0.44, 1)), -webkit-transform var(--motion-duration-fast-oPeLPK, 90ms) var(--motion-easing-ease-out-quart-cJfwJd, cubic-bezier(0.165, 0.84, 0.44, 1));
  transition:transform var(--motion-duration-fast-oPeLPK, 90ms) var(--motion-easing-ease-out-quart-cJfwJd, cubic-bezier(0.165, 0.84, 0.44, 1)), background-color var(--motion-duration-fast-oPeLPK, 90ms) var(--motion-easing-ease-out-quart-cJfwJd, cubic-bezier(0.165, 0.84, 0.44, 1));
  transition:transform var(--motion-duration-fast-oPeLPK, 90ms) var(--motion-easing-ease-out-quart-cJfwJd, cubic-bezier(0.165, 0.84, 0.44, 1)), background-color var(--motion-duration-fast-oPeLPK, 90ms) var(--motion-easing-ease-out-quart-cJfwJd, cubic-bezier(0.165, 0.84, 0.44, 1)), -webkit-transform var(--motion-duration-fast-oPeLPK, 90ms) var(--motion-easing-ease-out-quart-cJfwJd, cubic-bezier(0.165, 0.84, 0.44, 1));
}
._toggle-handle-checked_4yi2u_1m8vx_63:not(#\9){
  -webkit-transform:translateX(8px);
          transform:translateX(8px);
}
._toggle-handle-disabled_4yi2u_1m8vx_66:not(#\9){
  background:#ffffff;
  background:#ffffff;
  background:var(--color-foreground-control-disabled-3d6ker, #ffffff);
  box-shadow:none;
}
@media (prefers-reduced-motion: reduce){
  ._toggle-handle_4yi2u_1m8vx_52:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._toggle-handle_4yi2u_1m8vx_52:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}
._calendar_145pa_wli34_3:not(#\9){
  -webkit-animation:_awsui-motion-fade-in-0_145pa_wli34_1 135ms ease-out;
          animation:_awsui-motion-fade-in-0_145pa_wli34_1 135ms ease-out;
  animation:_awsui-motion-fade-in-0_145pa_wli34_1 135ms ease-out;
  -webkit-animation:_awsui-motion-fade-in-0_145pa_wli34_1 var(--motion-duration-show-quick-TMbCmo, 135ms) var(--motion-easing-show-quick-25z_bd, ease-out);
          animation:_awsui-motion-fade-in-0_145pa_wli34_1 var(--motion-duration-show-quick-TMbCmo, 135ms) var(--motion-easing-show-quick-25z_bd, ease-out);
  -webkit-animation-fill-mode:both;
          animation-fill-mode:both;
}
@-webkit-keyframes _awsui-motion-fade-in-0_145pa_wli34_1{
  from{
    opacity:0;
  }
  to{
    opacity:1;
  }
}
@keyframes _awsui-motion-fade-in-0_145pa_wli34_1{
  from{
    opacity:0;
  }
  to{
    opacity:1;
  }
}
@media (prefers-reduced-motion: reduce){
  ._calendar_145pa_wli34_3:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._calendar_145pa_wli34_3:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._root_145pa_wli34_26:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}

._date-picker-container_145pa_wli34_36:not(#\9){
  position:relative;
  max-width:234px;
}

._date-picker-trigger_145pa_wli34_41:not(#\9){
  display:flex;
}

._date-picker-input_145pa_wli34_45:not(#\9){
  padding-right:8px;
  padding-right:8px;
  padding-right:var(--space-xs-3JTBUA, 8px);
  width:100%;
}

._calendar_145pa_wli34_3:not(#\9){
  display:block;
  width:234px;
}
._calendar-inner_145pa_wli34_58:not(#\9){
  margin:8px;
  margin:8px;
  margin:var(--space-xs-3JTBUA, 8px);
}
._calendar-header_145pa_wli34_61:not(#\9){
  display:flex;
  justify-content:space-between;
  align-items:center;
}
._calendar-header-month_145pa_wli34_66:not(#\9){
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  font-weight:700;
  color:#16191f;
  color:#16191f;
  color:var(--color-text-dropdown-item-default-WiQFVx, #16191f);
}
._calendar-day-names_145pa_wli34_78:not(#\9){
  display:flex;
  justify-content:stretch;
}
._calendar-day-name_145pa_wli34_78:not(#\9){
  flex:1 1;
  width:0;
  word-break:break-word;
  text-align:center;
  padding:12px 0 4px;
  padding:12px 0 4px;
  padding:var(--space-s-1Qj4Fv, 12px) 0 var(--space-xxs-10c0Z4, 4px);
  color:#545b64;
  color:#545b64;
  color:var(--color-text-dropdown-group-label-2XOlKp, #545b64);
  font-size:12px;
  font-size:12px;
  font-size:var(--font-body-s-size-3NP7Rr, 12px);
  line-height:16px;
  line-height:16px;
  line-height:var(--font-body-s-lineHeight-x2Xc-V, 16px);
}
._calendar-dates_145pa_wli34_92:not(#\9){
  border:1px solid #eaeded;
  border:1px solid #eaeded;
  border:1px solid var(--color-border-dropdown-item-default-2jqiTh, #eaeded);
}
._calendar-week_145pa_wli34_95:not(#\9){
  display:flex;
  justify-content:stretch;
}
._calendar-day_145pa_wli34_78:not(#\9){
  flex:1 1;
  width:0;
  word-break:break-word;
  text-align:center;
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid var(--color-border-dropdown-item-default-2jqiTh, #eaeded);
  border-right:1px solid #eaeded;
  border-right:1px solid #eaeded;
  border-right:1px solid var(--color-border-dropdown-item-default-2jqiTh, #eaeded);
  border-radius:0px;
  border-radius:0px;
  border-radius:var(--border-calendar-grid-radius-Z3aihh, 0px);
  padding:4px 0;
  padding:4px 0;
  padding:var(--space-xxs-10c0Z4, 4px) 0;
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-dropdown-item-disabled-KGAbe7, #aab7b8);
  position:relative;
}
._calendar-day_145pa_wli34_78:not(#\9):last-child{
  border-right:none;
}
._calendar-day-in-last-week_145pa_wli34_114:not(#\9){
  border-bottom:none;
}
._calendar-day-enabled_145pa_wli34_120:not(#\9){
  cursor:pointer;
  color:#687078;
  color:#687078;
  color:var(--color-text-dropdown-item-secondary--r75hR, #687078);
}
._calendar-day-enabled_145pa_wli34_120._calendar-day-current-month_145pa_wli34_124:not(#\9){
  color:#16191f;
  color:#16191f;
  color:var(--color-text-dropdown-item-default-WiQFVx, #16191f);
}
._calendar-day-enabled_145pa_wli34_120._calendar-day-current-month_145pa_wli34_124:not(#\9):hover{
  color:var(var(--color-text-dropdown-item-highlighted-2S6vEC, #16191f));
  background-color:#f2f3f3;
  background-color:#f2f3f3;
  background-color:var(--color-background-dropdown-item-hover-35tDd2, #f2f3f3);
}
._calendar-day-enabled_145pa_wli34_120._calendar-day-current-month_145pa_wli34_124:not(#\9):hover:not(._calendar-day-selected_145pa_wli34_131)::before{
  border:1px solid #879596;
  border:1px solid #879596;
  border:var(--border-item-width-3GX4nr, 1px) solid var(--color-border-dropdown-item-hover-3Saafg, #879596);
  border-radius:0px;
  border-radius:0px;
  border-radius:var(--border-calendar-grid-radius-Z3aihh, 0px);
}
._calendar-day-today_145pa_wli34_135:not(#\9){
  background-color:#f2f3f3;
  background-color:#f2f3f3;
  background-color:var(--color-background-calendar-today-3Bydyi, #f2f3f3);
}
._calendar-day_145pa_wli34_78:not(#\9)::before{
  content:"";
  position:absolute;
  z-index:1;
  top:-1px;
  left:-1px;
  bottom:-1px;
  right:-1px;
  background-color:transparent;
}
._calendar-day_145pa_wli34_78 > span:not(#\9){
  position:relative;
  z-index:1;
}
._calendar-day_145pa_wli34_78:not(#\9):focus{
  outline:none;
}
._calendar-day_145pa_wli34_78:not(#\9):focus::before{
  outline:2px dotted transparent;
  border:1px solid #00a1c9;
  border:1px solid #00a1c9;
  border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-item-focused-2uP71z, #00a1c9);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._calendar-day-selected_145pa_wli34_131:not(#\9){
  border-color:transparent;
  position:relative;
}
._calendar-day-selected_145pa_wli34_131:not(#\9)::before{
  background-color:#f1faff;
  background-color:#f1faff;
  background-color:var(--color-background-dropdown-item-selected-nrt43a, #f1faff);
  border:1px solid #00a1c9;
  border:1px solid #00a1c9;
  border:var(--border-item-width-3GX4nr, 1px) solid var(--color-border-dropdown-item-selected-1BqG1q, #00a1c9);
  border-radius:0px;
  border-radius:0px;
  border-radius:var(--border-calendar-grid-radius-Z3aihh, 0px);
  z-index:2;
}
._calendar-day-selected_145pa_wli34_131 > span:not(#\9){
  color:var(var(--color-text-dropdown-item-highlighted-2S6vEC, #16191f));
  position:relative;
  z-index:2;
}
._calendar_mgja0_12yiw_3:not(#\9){
  -webkit-animation:_awsui-motion-fade-in-0_mgja0_12yiw_1 135ms ease-out;
          animation:_awsui-motion-fade-in-0_mgja0_12yiw_1 135ms ease-out;
  animation:_awsui-motion-fade-in-0_mgja0_12yiw_1 135ms ease-out;
  -webkit-animation:_awsui-motion-fade-in-0_mgja0_12yiw_1 var(--motion-duration-show-quick-TMbCmo, 135ms) var(--motion-easing-show-quick-25z_bd, ease-out);
          animation:_awsui-motion-fade-in-0_mgja0_12yiw_1 var(--motion-duration-show-quick-TMbCmo, 135ms) var(--motion-easing-show-quick-25z_bd, ease-out);
  -webkit-animation-fill-mode:both;
          animation-fill-mode:both;
}
@-webkit-keyframes _awsui-motion-fade-in-0_mgja0_12yiw_1{
  from{
    opacity:0;
  }
  to{
    opacity:1;
  }
}
@keyframes _awsui-motion-fade-in-0_mgja0_12yiw_1{
  from{
    opacity:0;
  }
  to{
    opacity:1;
  }
}
@media (prefers-reduced-motion: reduce){
  ._calendar_mgja0_12yiw_3:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._calendar_mgja0_12yiw_3:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._root_mgja0_12yiw_26:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  max-width:32em;
}

._trigger-wrapper_mgja0_12yiw_37:not(#\9){
  min-width:calc(234px + 2 * 20px);
  min-width:calc(234px + 2 * 20px);
  min-width:calc(234px + 2 * var(--space-l-1RnAcv, 20px));
}

._trigger-flexbox_mgja0_12yiw_41:not(#\9){
  display:flex;
}

._preferred-wordbreak_mgja0_12yiw_45:not(#\9){
  display:inline-block;
}

._calendar_mgja0_12yiw_3:not(#\9){
  display:block;
  width:calc(468px + 12px);
  width:calc(468px + 12px);
  width:calc(468px + var(--space-s-1Qj4Fv, 12px));
}
._calendar_mgja0_12yiw_3._one-grid_mgja0_12yiw_53:not(#\9){
  width:234px;
}
._calendar-header_mgja0_12yiw_56:not(#\9){
  display:flex;
  justify-content:space-between;
  align-items:center;
  position:relative;
}
._calendar-header-months-wrapper_mgja0_12yiw_62:not(#\9){
  position:absolute;
  right:0;
  left:0;
  top:0;
  bottom:0;
  display:flex;
  justify-content:space-around;
  align-items:center;
  flex:1 1;
  pointer-events:none;
}
._calendar-header-month_mgja0_12yiw_62:not(#\9){
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  font-weight:700;
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  display:flex;
  pointer-events:auto;
}
._calendar-day-names_mgja0_12yiw_88:not(#\9){
  display:flex;
  justify-content:stretch;
}
._calendar-day-name_mgja0_12yiw_88:not(#\9){
  flex:1 1;
  width:0;
  word-break:break-word;
  text-align:center;
  padding:12px 0 4px;
  padding:12px 0 4px;
  padding:var(--space-s-1Qj4Fv, 12px) 0 var(--space-xxs-10c0Z4, 4px);
  color:#545b64;
  color:#545b64;
  color:var(--color-text-body-secondary-1oBHNI, #545b64);
  font-size:12px;
  font-size:12px;
  font-size:var(--font-body-s-size-3NP7Rr, 12px);
  line-height:16px;
  line-height:16px;
  line-height:var(--font-body-s-lineHeight-x2Xc-V, 16px);
}
._calendar-week_mgja0_12yiw_102:not(#\9){
  display:flex;
  justify-content:stretch;
}

._grid_mgja0_12yiw_112:not(#\9){
  width:234px;
}

._date-and-time-wrapper_mgja0_12yiw_116:not(#\9){
  display:flex;
  width:234px;
}
._date-and-time-wrapper_mgja0_12yiw_116 > div:not(#\9){
  width:50%;
}
._date-and-time-wrapper__date_mgja0_12yiw_123:not(#\9){
  margin-right:8px;
  margin-right:8px;
  margin-right:var(--space-xs-3JTBUA, 8px);
}

._date-and-time-constrainttext_mgja0_12yiw_127:not(#\9){
  color:#687078;
  color:#687078;
  color:var(--color-text-form-secondary-2ZkiuH, #687078);
  font-size:12px;
  font-size:12px;
  font-size:var(--font-body-s-size-3NP7Rr, 12px);
  line-height:16px;
  line-height:16px;
  line-height:var(--font-body-s-lineHeight-x2Xc-V, 16px);
}

._footer_mgja0_12yiw_133:not(#\9){
  display:flex;
  flex-wrap:wrap;
  justify-content:flex-end;
  border-top:1px solid #eaeded;
  border-top:1px solid #eaeded;
  border-top:1px solid var(--color-border-dropdown-item-default-2jqiTh, #eaeded);
  padding:0 20px 12px;
  padding:0 20px 12px;
  padding:0 var(--space-l-1RnAcv, 20px) var(--space-s-1Qj4Fv, 12px);
}
._footer_mgja0_12yiw_133._has-clear-button_mgja0_12yiw_140:not(#\9){
  justify-content:space-between;
}
._footer_mgja0_12yiw_133._one-grid_mgja0_12yiw_53:not(#\9){
  padding:0 8px 12px;
  padding:0 8px 12px;
  padding:0 var(--space-xs-3JTBUA, 8px) var(--space-s-1Qj4Fv, 12px);
}

._footer-button-wrapper_mgja0_12yiw_147:not(#\9){
  padding-top:12px;
  padding-top:12px;
  padding-top:var(--space-s-1Qj4Fv, 12px);
}

._icon-wrapper_mgja0_12yiw_151:not(#\9){
  color:#545b64;
  color:#545b64;
  color:var(--color-text-interactive-default-1oBHNI, #545b64);
  margin-right:8px;
  margin-right:8px;
  margin-right:var(--space-xs-3JTBUA, 8px);
}

._label_mgja0_12yiw_156:not(#\9){
  padding-right:8px;
  padding-right:8px;
  padding-right:var(--space-xs-3JTBUA, 8px);
}
._label_mgja0_12yiw_156 > span:not(#\9){
  -webkit-user-select:text;
          user-select:text;
  cursor:default;
}

._label_mgja0_12yiw_156._label-enabled_mgja0_12yiw_164:not(#\9):hover > span > ._icon-wrapper_mgja0_12yiw_151{
  color:#16191f;
  color:#16191f;
  color:var(--color-text-interactive-hover-18qOom, #16191f);
}

._label-text_mgja0_12yiw_168:not(#\9){
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-input-placeholder-TVd7uP, #aab7b8);
  font-style:italic;
}

._dropdown_mgja0_12yiw_177:not(#\9){
  -webkit-user-select:text;
          user-select:text;
  border-top:1px solid #eaeded;
  border-top:1px solid #eaeded;
  border-top:1px solid var(--color-border-container-top-1gYBaj, #eaeded);
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid var(--color-border-container-top-1gYBaj, #eaeded);
  border-radius:0px;
  border-radius:0px;
  border-radius:var(--border-item-radius-Z3aihh, 0px);
  background-color:#ffffff;
  background-color:#ffffff;
  background-color:var(--color-background-container-content-3w9fMz, #ffffff);
  width:calc(468px + 8px + 2 * 20px);
  width:calc(468px + 8px + 2 * 20px);
  width:calc(468px + var(--space-xs-3JTBUA, 8px) + 2 * var(--space-l-1RnAcv, 20px));
}
._dropdown_mgja0_12yiw_177._one-grid_mgja0_12yiw_53:not(#\9){
  width:calc(234px + 2 * 20px);
  width:calc(234px + 2 * 20px);
  width:calc(234px + 2 * var(--space-l-1RnAcv, 20px));
}
._day_vci2l_6bfhv_3:not(#\9){
  flex:1 1;
  width:0;
  word-break:break-word;
  text-align:center;
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid var(--color-border-dropdown-item-default-2jqiTh, #eaeded);
  border-right:1px solid #eaeded;
  border-right:1px solid #eaeded;
  border-right:1px solid var(--color-border-dropdown-item-default-2jqiTh, #eaeded);
  padding:4px 0;
  padding:4px 0;
  padding:var(--space-xxs-10c0Z4, 4px) 0;
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-dropdown-item-disabled-KGAbe7, #aab7b8);
  position:relative;
}
._day_vci2l_6bfhv_3:not(#\9):focus{
  outline:none;
}
._day_vci2l_6bfhv_3:not(#\9)::after{
  content:"";
  position:absolute;
  z-index:1;
  top:-1px;
  left:-1px;
  bottom:-1px;
  right:-1px;
  background-color:transparent;
}
._day_vci2l_6bfhv_3 > span:not(#\9){
  position:relative;
  z-index:1;
}
._day_vci2l_6bfhv_3[data-awsui-focus-visible=true]:not(#\9):focus{
  z-index:2;
  position:relative;
}
._day_vci2l_6bfhv_3[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(0px - 1px);
}
._day_vci2l_6bfhv_3[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:0px;
  top:0px;
  width:calc(100% + 2 * 0px);
  height:calc(100% + 2 * 0px);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}

._in-first-row_vci2l_6bfhv_51:not(#\9):not(._in-previous-month_vci2l_6bfhv_51){
  border-top:1px solid #eaeded;
  border-top:1px solid #eaeded;
  border-top:1px solid var(--color-border-dropdown-item-default-2jqiTh, #eaeded);
}

._in-previous-month_vci2l_6bfhv_51:not(#\9):not(._last-day-of-month_vci2l_6bfhv_55){
  border-right-color:transparent;
}

._in-next-month_vci2l_6bfhv_59:not(#\9){
  border-color:transparent;
}

._in-first-column_vci2l_6bfhv_63:not(#\9){
  border-left:1px solid transparent;
}
._in-first-column_vci2l_6bfhv_63._in-current-month_vci2l_6bfhv_66:not(#\9){
  border-left:1px solid #eaeded;
  border-left:1px solid #eaeded;
  border-left:1px solid var(--color-border-dropdown-item-default-2jqiTh, #eaeded);
}

._enabled_vci2l_6bfhv_70:not(#\9){
  cursor:pointer;
}
._enabled_vci2l_6bfhv_70._in-current-month_vci2l_6bfhv_66:not(#\9){
  color:#16191f;
  color:#16191f;
  color:var(--color-text-dropdown-item-default-WiQFVx, #16191f);
}
._enabled_vci2l_6bfhv_70._in-current-month_vci2l_6bfhv_66:not(#\9):hover{
  color:var(var(--color-text-dropdown-item-highlighted-2S6vEC, #16191f));
  background-color:#f2f3f3;
  background-color:#f2f3f3;
  background-color:var(--color-background-dropdown-item-hover-35tDd2, #f2f3f3);
}
._enabled_vci2l_6bfhv_70._in-current-month_vci2l_6bfhv_66:not(#\9):hover:not(._selected_vci2l_6bfhv_80)::after{
  border:1px solid #879596;
  border:1px solid #879596;
  border:1px solid var(--color-border-dropdown-item-hover-3Saafg, #879596);
}

._today_vci2l_6bfhv_84:not(#\9){
  background-color:#f2f3f3;
  background-color:#f2f3f3;
  background-color:var(--color-background-calendar-today-3Bydyi, #f2f3f3);
}

._selected_vci2l_6bfhv_80:not(#\9){
  border-color:transparent;
  position:relative;
  z-index:2;
}
._selected_vci2l_6bfhv_80[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._selected_vci2l_6bfhv_80[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(2px - 1px);
}
._selected_vci2l_6bfhv_80[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-2px;
  top:-2px;
  width:calc(100% + 2 * 2px);
  height:calc(100% + 2 * 2px);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._selected_vci2l_6bfhv_80:not(#\9)::after{
  background-color:#0073bb;
  background-color:#0073bb;
  background-color:var(--color-background-control-checked-3uW6lA, #0073bb);
  border:1px solid #0073bb;
  border:1px solid #0073bb;
  border:1px solid var(--color-background-control-checked-3uW6lA, #0073bb);
  z-index:0;
}
._selected_vci2l_6bfhv_80 > span:not(#\9){
  color:#ffffff;
  color:#ffffff;
  color:var(--color-background-control-default-528DLz, #ffffff);
  position:relative;
  z-index:2;
}

._in-range_vci2l_6bfhv_130:not(#\9){
  background-color:#f1faff;
  background-color:#f1faff;
  background-color:var(--color-background-dropdown-item-selected-nrt43a, #f1faff);
}
._in-range_vci2l_6bfhv_130._in-range-border-top_vci2l_6bfhv_133:not(#\9)::after{
  border-top:1px solid #0073bb;
  border-top:1px solid #0073bb;
  border-top:1px solid var(--color-background-control-checked-3uW6lA, #0073bb);
}
._in-range_vci2l_6bfhv_130._in-range-border-bottom_vci2l_6bfhv_136:not(#\9)::after{
  border-bottom:1px solid #0073bb;
  border-bottom:1px solid #0073bb;
  border-bottom:1px solid var(--color-background-control-checked-3uW6lA, #0073bb);
}
._in-range_vci2l_6bfhv_130._in-range-border-left_vci2l_6bfhv_139:not(#\9)::after{
  border-left:1px solid #0073bb;
  border-left:1px solid #0073bb;
  border-left:1px solid var(--color-background-control-checked-3uW6lA, #0073bb);
}
._in-range_vci2l_6bfhv_130._in-range-border-right_vci2l_6bfhv_142:not(#\9)::after{
  border-right:1px solid #0073bb;
  border-right:1px solid #0073bb;
  border-right:1px solid var(--color-background-control-checked-3uW6lA, #0073bb);
}

._segment_8cbea_1odt6_3:not(#\9){
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  word-wrap:break-word;
  max-width:100%;
  overflow:hidden;
  border:1px solid #687078;
  border:1px solid #687078;
  border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-segment-default-3mUVYZ, #687078);
  padding:4px 20px;
  padding:4px 20px;
  padding:var(--space-scaled-xxs-wz9S8K, 4px) var(--space-l-1RnAcv, 20px);
  border-right-width:0;
  font-weight:bold;
  letter-spacing:0.25px;
  background:#ffffff;
  background:#ffffff;
  background:var(--color-background-button-normal-default-3w9fMz, #ffffff);
  color:#545b64;
  color:#545b64;
  color:var(--color-text-button-normal-default-1oBHNI, #545b64);
  overflow:visible;
}
._segment_8cbea_1odt6_3:not(#\9):focus{
  outline:none;
}
._segment_8cbea_1odt6_3:not(#\9):nth-child(1){
  grid-column:1;
  -ms-grid-column:1;
}
._segment_8cbea_1odt6_3:not(#\9):nth-child(2){
  grid-column:2;
  -ms-grid-column:2;
}
._segment_8cbea_1odt6_3:not(#\9):nth-child(3){
  grid-column:3;
  -ms-grid-column:3;
}
._segment_8cbea_1odt6_3:not(#\9):nth-child(4){
  grid-column:4;
  -ms-grid-column:4;
}
._segment_8cbea_1odt6_3:not(#\9):nth-child(5){
  grid-column:5;
  -ms-grid-column:5;
}
._segment_8cbea_1odt6_3:not(#\9):nth-child(6){
  grid-column:6;
  -ms-grid-column:6;
}
._segment_8cbea_1odt6_3[data-awsui-focus-visible=true]:not(#\9):focus{
  z-index:1;
  outline:2px dotted transparent;
  border:1px solid #00a1c9;
  border:1px solid #00a1c9;
  border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-item-focused-2uP71z, #00a1c9);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px var(--color-border-item-focused-2uP71z, #00a1c9);
  padding-right:calc(20px - 1px);
  padding-right:calc(20px - 1px);
  padding-right:calc(var(--space-l-1RnAcv, 20px) - var(--border-field-width-3GX4nr, 1px));
}
._segment_8cbea_1odt6_3[data-awsui-focus-visible=true]:not(#\9):focus + ._segment_8cbea_1odt6_3{
  border-left-width:0;
  padding-left:calc(20px + 1px);
  padding-left:calc(20px + 1px);
  padding-left:calc(var(--space-l-1RnAcv, 20px) + var(--border-field-width-3GX4nr, 1px));
}
._segment_8cbea_1odt6_3[data-awsui-focus-visible=true]:not(#\9):focus:first-child{
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
}
._segment_8cbea_1odt6_3[data-awsui-focus-visible=true]:not(#\9):focus:last-child{
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  padding-right:20px;
  padding-right:20px;
  padding-right:var(--space-l-1RnAcv, 20px);
}
._segment_8cbea_1odt6_3._disabled_8cbea_1odt6_64:not(#\9){
  background:#ffffff;
  background:#ffffff;
  background:var(--color-background-button-normal-disabled-3w9fMz, #ffffff);
  border-color:#d5dbdb #687078;
  border-color:#d5dbdb #687078;
  border-color:var(--color-border-button-normal-disabled-11nezK, #d5dbdb) var(--color-border-segment-default-3mUVYZ, #687078);
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
}
._segment_8cbea_1odt6_3:not(#\9):last-child{
  border-right-width:1px;
  border-right-width:1px;
  border-right-width:var(--border-field-width-3GX4nr, 1px);
  border-radius:0 2px 2px 0;
  border-radius:0 2px 2px 0;
  border-radius:0 var(--border-field-radius-TgsrkU, 2px) var(--border-field-radius-TgsrkU, 2px) 0;
}
._segment_8cbea_1odt6_3._disabled_8cbea_1odt6_64:not(#\9):last-child{
  border-right-color:#d5dbdb;
  border-right-color:#d5dbdb;
  border-right-color:var(--color-border-button-normal-disabled-11nezK, #d5dbdb);
}
._segment_8cbea_1odt6_3:not(#\9):first-child{
  border-radius:2px 0 0 2px;
  border-radius:2px 0 0 2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px) 0 0 var(--border-field-radius-TgsrkU, 2px);
}
._segment_8cbea_1odt6_3._disabled_8cbea_1odt6_64:not(#\9):first-child{
  border-left-color:#d5dbdb;
  border-left-color:#d5dbdb;
  border-left-color:var(--color-border-button-normal-disabled-11nezK, #d5dbdb);
}
._segment_8cbea_1odt6_3._selected_8cbea_1odt6_82:not(#\9){
  background:#eaeded;
  background:#eaeded;
  background:var(--color-background-button-normal-active-2WWahd, #eaeded);
  border-color:#16191f;
  border-color:#16191f;
  border-color:var(--color-border-segment-hover-1dD-mx, #16191f);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-button-normal-hover-18qOom, #16191f);
}
._segment_8cbea_1odt6_3._selected_8cbea_1odt6_82 + ._segment_8cbea_1odt6_3:not(#\9):not(:focus){
  border-left-color:#16191f;
  border-left-color:#16191f;
  border-left-color:var(--color-border-segment-hover-1dD-mx, #16191f);
}
._segment_8cbea_1odt6_3:not(#\9):hover:not(._selected_8cbea_1odt6_82):not(._disabled_8cbea_1odt6_64):not(:focus){
  background:#fafafa;
  background:#fafafa;
  background:var(--color-background-button-normal-hover-1OJlgw, #fafafa);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-button-normal-hover-18qOom, #16191f);
  border-color:#16191f;
  border-color:#16191f;
  border-color:var(--color-border-segment-hover-1dD-mx, #16191f);
  cursor:pointer;
}
._segment_8cbea_1odt6_3:not(#\9):hover:not(._selected_8cbea_1odt6_82):not(._disabled_8cbea_1odt6_64):not(:focus) + ._segment_8cbea_1odt6_3{
  border-left-color:#16191f;
  border-left-color:#16191f;
  border-left-color:var(--color-border-segment-hover-1dD-mx, #16191f);
}

._with-text_8cbea_1odt6_104:not(#\9){
  position:relative;
  left:calc(-1 * 4px);
  left:calc(-1 * 4px);
  left:calc(-1 * var(--space-xxs-10c0Z4, 4px));
  margin-right:4px;
  margin-right:4px;
  margin-right:var(--space-xxs-10c0Z4, 4px);
}

._with-no-text_8cbea_1odt6_110:not(#\9){
  margin-right:auto;
  margin-left:auto;
  right:0;
  left:0;
}

._root_8cbea_1odt6_117:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}

._segment-part_8cbea_1odt6_127:not(#\9){
  display:inline-grid;
}
@media (max-width: 688px){
  ._segment-part_8cbea_1odt6_127:not(#\9){
    display:none;
  }
}

._select_8cbea_1odt6_82:not(#\9){
  display:none;
}
@media (max-width: 688px){
  ._select_8cbea_1odt6_82:not(#\9){
    display:block;
  }
}

._segment-count-2_8cbea_1odt6_146:not(#\9){
  grid-template-columns:1fr 1fr;
}

._segment-count-3_8cbea_1odt6_151:not(#\9){
  grid-template-columns:1fr 1fr 1fr;
}

._segment-count-4_8cbea_1odt6_156:not(#\9){
  grid-template-columns:1fr 1fr 1fr 1fr;
}

._segment-count-5_8cbea_1odt6_161:not(#\9){
  grid-template-columns:1fr 1fr 1fr 1fr 1fr;
}

._segment-count-6_8cbea_1odt6_166:not(#\9){
  grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr;
}
._custom-range_16zmw_13e0s_3:not(#\9){
  padding-left:calc(14px + 8px);
  padding-left:calc(14px + 8px);
  padding-left:calc(14px + var(--space-xs-3JTBUA, 8px));
  display:flex;
  width:80%;
}

._custom-range-form-controls_16zmw_13e0s_9:not(#\9){
  display:flex;
  width:100%;
}
._custom-range-form-controls_16zmw_13e0s_9 > ._custom-range-duration_16zmw_13e0s_13:not(#\9),
._custom-range-form-controls_16zmw_13e0s_9 > ._custom-range-unit_16zmw_13e0s_14:not(#\9){
  width:50%;
}
._custom-range-form-controls_16zmw_13e0s_9 > ._custom-range-duration_16zmw_13e0s_13:not(#\9){
  margin-right:8px;
  margin-right:8px;
  margin-right:var(--space-xs-3JTBUA, 8px);
}
._custom-range-form-controls_16zmw_13e0s_9._vertical_16zmw_13e0s_20:not(#\9){
  flex-direction:column;
}
._custom-range-form-controls_16zmw_13e0s_9._vertical_16zmw_13e0s_20 > ._custom-range-duration_16zmw_13e0s_13:not(#\9),
._custom-range-form-controls_16zmw_13e0s_9._vertical_16zmw_13e0s_20 > ._custom-range-unit_16zmw_13e0s_14:not(#\9){
  width:100%;
}
._custom-range-form-controls_16zmw_13e0s_9._vertical_16zmw_13e0s_20 > ._custom-range-unit_16zmw_13e0s_14:not(#\9){
  margin-top:12px;
  margin-top:12px;
  margin-top:var(--space-s-1Qj4Fv, 12px);
}
._content-enter_gwq0h_1wovw_3:not(#\9){
  -webkit-animation:_awsui-motion-fade-in_gwq0h_1wovw_1 180ms ease-out;
          animation:_awsui-motion-fade-in_gwq0h_1wovw_1 180ms ease-out;
  animation:_awsui-motion-fade-in_gwq0h_1wovw_1 180ms ease-out;
  -webkit-animation:_awsui-motion-fade-in_gwq0h_1wovw_1 var(--motion-duration-show-paced-2crOWV, 180ms) var(--motion-easing-show-paced-25z_bd, ease-out);
          animation:_awsui-motion-fade-in_gwq0h_1wovw_1 var(--motion-duration-show-paced-2crOWV, 180ms) var(--motion-easing-show-paced-25z_bd, ease-out);
}
@-webkit-keyframes _awsui-motion-fade-in_gwq0h_1wovw_1{
  from{
    opacity:0.2;
  }
  to{
    opacity:1;
  }
}
@keyframes _awsui-motion-fade-in_gwq0h_1wovw_1{
  from{
    opacity:0.2;
  }
  to{
    opacity:1;
  }
}
@media (prefers-reduced-motion: reduce){
  ._content-enter_gwq0h_1wovw_3:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._content-enter_gwq0h_1wovw_3:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._trigger-expanded_gwq0h_1wovw_25:not(#\9){
  transition:border-bottom-color 180ms ease-out;
  transition:border-bottom-color 180ms ease-out;
  transition:border-bottom-color var(--motion-duration-show-paced-2crOWV, 180ms) var(--motion-easing-show-paced-25z_bd, ease-out);
}
@media (prefers-reduced-motion: reduce){
  ._trigger-expanded_gwq0h_1wovw_25:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._trigger-expanded_gwq0h_1wovw_25:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._icon_gwq0h_1wovw_39:not(#\9){
  transition:-webkit-transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:-webkit-transform var(--motion-duration-rotate-90-TMbCmo, 135ms) var(--motion-easing-rotate-90-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1));
  transition:transform var(--motion-duration-rotate-90-TMbCmo, 135ms) var(--motion-easing-rotate-90-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1));
  transition:transform var(--motion-duration-rotate-90-TMbCmo, 135ms) var(--motion-easing-rotate-90-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1)), -webkit-transform var(--motion-duration-rotate-90-TMbCmo, 135ms) var(--motion-easing-rotate-90-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1));
}
@media (prefers-reduced-motion: reduce){
  ._icon_gwq0h_1wovw_39:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._icon_gwq0h_1wovw_39:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._root_gwq0h_1wovw_53:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
  display:block;
}

._icon_gwq0h_1wovw_39:not(#\9){
  -webkit-transform:rotate(-90deg);
          transform:rotate(-90deg);
}
._icon_gwq0h_1wovw_39._expanded_gwq0h_1wovw_70:not(#\9){
  -webkit-transform:rotate(0deg);
          transform:rotate(0deg);
}

._icon-container_gwq0h_1wovw_74:not(#\9){
  position:relative;
  margin-left:calc((22px - 16px) / -2);
  margin-left:calc((22px - 16px) / -2);
  margin-left:calc((var(--font-body-m-lineHeight-2Vn4pu, 22px) - var(--size-icon-normal-x2Xc-V, 16px)) / -2);
  margin-right:calc(4px + 1px);
  margin-right:calc(4px + 1px);
  margin-right:calc(var(--space-xxs-10c0Z4, 4px) + var(--border-field-width-3GX4nr, 1px));
}

._trigger_gwq0h_1wovw_25:not(#\9){
  cursor:pointer;
  box-sizing:border-box;
  display:flex;
  border:none;
  width:100%;
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  text-align:left;
}
._trigger-default_gwq0h_1wovw_89:not(#\9){
  padding:4px 4px;
  padding:4px 4px;
  padding:var(--space-scaled-xxs-wz9S8K, 4px) var(--space-xxs-10c0Z4, 4px);
}
._trigger-footer_gwq0h_1wovw_92:not(#\9){
  padding:4px 0;
  padding:4px 0;
  padding:var(--space-scaled-xxs-wz9S8K, 4px) 0;
}
._trigger-default_gwq0h_1wovw_89:not(#\9), ._trigger-footer_gwq0h_1wovw_92:not(#\9){
  border:1px solid transparent;
  border:1px solid transparent;
  border:var(--border-field-width-3GX4nr, 1px) solid transparent;
}
._trigger-navigation_gwq0h_1wovw_98:not(#\9){
  border-left:1px solid transparent;
  border-left:1px solid transparent;
  border-left:var(--border-field-width-3GX4nr, 1px) solid transparent;
}
._trigger-default_gwq0h_1wovw_89:not(#\9), ._trigger-navigation_gwq0h_1wovw_98:not(#\9), ._trigger-footer_gwq0h_1wovw_92:not(#\9){
  color:#545b64;
  color:#545b64;
  color:var(--color-text-interactive-default-1oBHNI, #545b64);
  font-weight:700;
}
._trigger-default_gwq0h_1wovw_89:not(#\9):hover, ._trigger-navigation_gwq0h_1wovw_98:not(#\9):hover, ._trigger-footer_gwq0h_1wovw_92:not(#\9):hover{
  color:#16191f;
  color:#16191f;
  color:var(--color-text-interactive-hover-18qOom, #16191f);
}
._trigger-container_gwq0h_1wovw_108:not(#\9){
  padding:12px 20px;
  padding:12px 20px;
  padding:var(--space-scaled-s-1sAHT4, 12px) var(--space-l-1RnAcv, 20px);
}
._trigger-container_gwq0h_1wovw_108[data-awsui-focus-visible=true]:not(#\9):focus{
  padding:calc(12px - 1px) calc(20px - 1px);
  padding:calc(12px - 1px) calc(20px - 1px);
  padding:calc(var(--space-scaled-s-1sAHT4, 12px) - var(--border-field-width-3GX4nr, 1px)) calc(var(--space-l-1RnAcv, 20px) - var(--border-field-width-3GX4nr, 1px));
}
._trigger-default_gwq0h_1wovw_89._trigger-expanded_gwq0h_1wovw_25:not(#\9){
  border-bottom-color:#eaeded;
  border-bottom-color:#eaeded;
  border-bottom-color:var(--color-border-divider-default-38loDV, #eaeded);
}

._header_gwq0h_1wovw_118:not(#\9){
  display:flex;
}
._header-container_gwq0h_1wovw_121:not(#\9){
  width:100%;
}
._header-container_gwq0h_1wovw_121 > ._icon-container_gwq0h_1wovw_74:not(#\9){
  margin-top:8px;
  margin-top:8px;
  margin-top:var(--space-scaled-xs-19adjU, 8px);
  margin-right:8px;
  margin-right:8px;
  margin-right:var(--space-xs-3JTBUA, 8px);
}
._header-navigation_gwq0h_1wovw_128 > ._icon-container_gwq0h_1wovw_74:not(#\9){
  display:inline-flex;
  cursor:pointer;
  color:#879596;
  color:#879596;
  color:var(--color-text-icon-caret-BxOb2m, #879596);
  border:0;
  padding:0;
  background:transparent;
  outline:none;
  text-decoration:none;
  flex-direction:column;
}
._header-navigation_gwq0h_1wovw_128 > ._icon-container_gwq0h_1wovw_74:not(#\9):hover{
  color:#16191f;
  color:#16191f;
  color:var(--color-text-interactive-hover-18qOom, #16191f);
}
._header-navigation_gwq0h_1wovw_128 > ._icon-container_gwq0h_1wovw_74[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._header-navigation_gwq0h_1wovw_128 > ._icon-container_gwq0h_1wovw_74[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(2px - 1px);
}
._header-navigation_gwq0h_1wovw_128 > ._icon-container_gwq0h_1wovw_74[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-2px;
  top:-2px;
  width:calc(100% + 2 * 2px);
  height:calc(100% + 2 * 2px);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}

._content_gwq0h_1wovw_3:not(#\9){
  display:none;
}
._content-default_gwq0h_1wovw_164:not(#\9){
  padding:8px 0;
  padding:8px 0;
  padding:var(--space-scaled-xs-19adjU, 8px) 0;
}
._content-footer_gwq0h_1wovw_167:not(#\9){
  padding:8px 0;
  padding:8px 0;
  padding:var(--space-xs-3JTBUA, 8px) 0;
}
._content-expanded_gwq0h_1wovw_170:not(#\9){
  display:block;
}

._focusable_gwq0h_1wovw_174:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._focusable_gwq0h_1wovw_174[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  border:1px solid #00a1c9;
  border:1px solid #00a1c9;
  border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-item-focused-2uP71z, #00a1c9);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._flashbar_1q84n_jmvvu_3:not(#\9),
._flash_1q84n_jmvvu_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}

._flash_1q84n_jmvvu_3:not(#\9){
  position:relative;
  display:flex;
  justify-content:flex-start;
  align-items:flex-start;
  padding:8px 8px;
  padding:8px 8px;
  padding:var(--space-scaled-xs-19adjU, 8px) var(--space-xs-3JTBUA, 8px);
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 var(--color-shadow-medium-3CtI4L, rgba(0, 28, 36, 0.3)), 1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15)), -1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15));
  border-top:1px solid #eaeded;
  border-top:1px solid #eaeded;
  border-top:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-container-top-1gYBaj, #eaeded);
  border-radius:0px;
  border-radius:0px;
  border-radius:var(--border-container-radius-Z3aihh, 0px);
  box-sizing:border-box;
  border-top:none;
  color:#fafafa;
  color:#fafafa;
  color:var(--color-text-notification-default-JWSlel, #fafafa);
  overflow-wrap:break-word;
  word-wrap:break-word;
}
@media screen and (-ms-high-contrast: active){
  ._flash_1q84n_jmvvu_3:not(#\9){
    border:1px solid #eaeded;
    border:1px solid #eaeded;
    border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-container-top-1gYBaj, #eaeded);
  }
}
._flash_1q84n_jmvvu_3:not(#\9):not(:last-child){
  margin-bottom:2px;
  margin-bottom:2px;
  margin-bottom:var(--space-xxxs-2Fa4lw, 2px);
}

._flash-body_1q84n_jmvvu_38:not(#\9){
  display:flex;
  flex-grow:1;
  min-width:0;
}

._flash-icon_1q84n_jmvvu_44:not(#\9){
  margin:4px 4px;
  margin:4px 4px;
  margin:var(--space-scaled-xxs-wz9S8K, 4px) var(--space-xxs-10c0Z4, 4px);
  flex:0 0 auto;
}

._flash-message_1q84n_jmvvu_49:not(#\9){
  flex:1 1;
  word-wrap:break-word;
  max-width:100%;
  overflow:hidden;
  margin:4px 4px;
  margin:4px 4px;
  margin:var(--space-scaled-xxs-wz9S8K, 4px) var(--space-xxs-10c0Z4, 4px);
  margin-right:8px;
  margin-right:8px;
  margin-right:var(--space-xs-3JTBUA, 8px);
}

._flash-header_1q84n_jmvvu_58:not(#\9){
  font-weight:700;
}

._dismiss-button-wrapper_1q84n_jmvvu_66:not(#\9){
  flex:0 0 auto;
  margin-left:8px;
  margin-left:8px;
  margin-left:var(--space-xs-3JTBUA, 8px);
  margin-right:4px;
  margin-right:4px;
  margin-right:var(--space-xxs-10c0Z4, 4px);
}

._action-button-wrapper_1q84n_jmvvu_76:not(#\9){
  white-space:nowrap;
  margin-left:12px;
  margin-left:12px;
  margin-left:var(--space-s-1Qj4Fv, 12px);
  margin-right:4px;
  margin-right:4px;
  margin-right:var(--space-xxs-10c0Z4, 4px);
}

._flashbar_1q84n_jmvvu_3._breakpoint-default_1q84n_jmvvu_82 > ._flash_1q84n_jmvvu_3 > ._flash-body_1q84n_jmvvu_38:not(#\9){
  display:block;
}
._flashbar_1q84n_jmvvu_3._breakpoint-default_1q84n_jmvvu_82 > ._flash_1q84n_jmvvu_3 > ._flash-body_1q84n_jmvvu_38 > ._action-button-wrapper_1q84n_jmvvu_76:not(#\9){
  margin-left:4px;
  margin-left:4px;
  margin-left:var(--space-xxs-10c0Z4, 4px);
}

._flash-type-success_1q84n_jmvvu_93:not(#\9){
  background-color:#1d8102;
  background-color:#1d8102;
  background-color:var(--color-background-notification-green-1ceXEb, #1d8102);
}

._flash-type-error_1q84n_jmvvu_97:not(#\9){
  background-color:#d13212;
  background-color:#d13212;
  background-color:var(--color-background-notification-red-3bUMsg, #d13212);
}

._flash-type-warning_1q84n_jmvvu_101:not(#\9){
  background-color:#0073bb;
  background-color:#0073bb;
  background-color:var(--color-background-notification-blue-Y4F8hz, #0073bb);
}

._flash-type-info_1q84n_jmvvu_105:not(#\9){
  background-color:#0073bb;
  background-color:#0073bb;
  background-color:var(--color-background-notification-blue-Y4F8hz, #0073bb);
}
._root_1i0s3_lphwm_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}

._header_1i0s3_lphwm_13:not(#\9){
  margin-bottom:16px;
  margin-bottom:16px;
  margin-bottom:var(--space-scaled-m-p3tAne, 16px);
}

._actions_1i0s3_lphwm_25:not(#\9){
  display:flex;
  justify-content:flex-end;
  margin-top:20px;
  margin-top:20px;
  margin-top:var(--space-scaled-l-17GFGI, 20px);
}
._help-panel_1d237_1yp8l_4:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  word-wrap:break-word;
  padding:20px 32px 0;
  padding:20px 32px 0;
  padding:var(--space-scaled-l-17GFGI, 20px) var(--space-scaled-xxl-238u6Z, 32px) 0;
}
._help-panel_1d237_1yp8l_4 hr:not(#\9){
  border:none;
  border-top:1px solid #eaeded;
  border-top:1px solid #eaeded;
  border-top:var(--border-divider-list-width-3GX4nr, 1px) solid var(--color-border-divider-default-38loDV, #eaeded);
  margin:24px calc(-1 * 8px);
  margin:24px calc(-1 * 8px);
  margin:var(--space-scaled-xl-axUbkA, 24px) calc(-1 * var(--space-xs-3JTBUA, 8px));
}
._help-panel_1d237_1yp8l_4 ol:not(#\9),
._help-panel_1d237_1yp8l_4 ul:not(#\9){
  padding-left:20px;
  padding-left:20px;
  padding-left:var(--space-l-1RnAcv, 20px);
  list-style-position:outside;
}
._help-panel_1d237_1yp8l_4 a:not(#\9),
._help-panel_1d237_1yp8l_4 p:not(#\9),
._help-panel_1d237_1yp8l_4 ul:not(#\9),
._help-panel_1d237_1yp8l_4 ol:not(#\9),
._help-panel_1d237_1yp8l_4 li:not(#\9),
._help-panel_1d237_1yp8l_4 h2:not(#\9),
._help-panel_1d237_1yp8l_4 h3:not(#\9),
._help-panel_1d237_1yp8l_4 h4:not(#\9),
._help-panel_1d237_1yp8l_4 h5:not(#\9),
._help-panel_1d237_1yp8l_4 pre:not(#\9),
._help-panel_1d237_1yp8l_4 code:not(#\9){
  margin:8px 0;
  margin:8px 0;
  margin:var(--space-xs-3JTBUA, 8px) 0;
  padding-top:0;
  padding-bottom:0;
}
._help-panel_1d237_1yp8l_4 code:not(#\9){
  font-size:12px;
  font-size:12px;
  font-size:var(--font-body-s-size-3NP7Rr, 12px);
  line-height:16px;
  line-height:16px;
  line-height:var(--font-body-s-lineHeight-x2Xc-V, 16px);
  font-family:Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;
  font-family:Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;
  font-family:var(--font-fontFamily-monospace-1bAn4w, Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace);
  background-color:#f2f3f3;
  background-color:#f2f3f3;
  background-color:var(--color-background-layout-main-3Bydyi, #f2f3f3);
  padding:0 4px;
  padding:0 4px;
  padding:0 var(--space-xxs-10c0Z4, 4px);
  word-wrap:break-word;
  white-space:pre-wrap;
}
._help-panel_1d237_1yp8l_4 pre:not(#\9){
  font-size:12px;
  font-size:12px;
  font-size:var(--font-body-s-size-3NP7Rr, 12px);
  line-height:16px;
  line-height:16px;
  line-height:var(--font-body-s-lineHeight-x2Xc-V, 16px);
  font-family:Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;
  font-family:Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;
  font-family:var(--font-fontFamily-monospace-1bAn4w, Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace);
  background-color:#f2f3f3;
  background-color:#f2f3f3;
  background-color:var(--color-background-layout-main-3Bydyi, #f2f3f3);
  padding:0 4px;
  padding:0 4px;
  padding:0 var(--space-xxs-10c0Z4, 4px);
  word-wrap:break-word;
  white-space:pre-wrap;
  padding:4px;
  padding:4px;
  padding:var(--space-xxs-10c0Z4, 4px);
}
._help-panel_1d237_1yp8l_4 dl:not(#\9){
  margin:8px 0;
  margin:8px 0;
  margin:var(--space-xs-3JTBUA, 8px) 0;
}
._help-panel_1d237_1yp8l_4 dl *:not(#\9){
  margin-top:0;
  margin-bottom:0;
}
._help-panel_1d237_1yp8l_4 dt:not(#\9){
  margin-top:8px;
  margin-top:8px;
  margin-top:var(--space-xs-3JTBUA, 8px);
  font-weight:700;
}
._help-panel_1d237_1yp8l_4 dd:not(#\9){
  margin:0 0 8px;
  margin:0 0 8px;
  margin:0 0 var(--space-xs-3JTBUA, 8px);
}
._help-panel_1d237_1yp8l_4 h2:not(#\9),
._help-panel_1d237_1yp8l_4 h3:not(#\9),
._help-panel_1d237_1yp8l_4 h4:not(#\9),
._help-panel_1d237_1yp8l_4 h5:not(#\9),
._help-panel_1d237_1yp8l_4 h6:not(#\9){
  margin-top:24px;
  margin-top:24px;
  margin-top:var(--space-xl-3Pai2K, 24px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-heading-default-2S6vEC, #16191f);
}
._help-panel_1d237_1yp8l_4 > :not(#\9):last-child{
  margin-bottom:40px;
  margin-bottom:40px;
  margin-bottom:var(--space-xxxl-25ySnK, 40px);
}
._help-panel_1d237_1yp8l_4 p:not(#\9){
  color:inherit;
  text-decoration:none;
}
._help-panel_1d237_1yp8l_4 h1:not(#\9){
  font-size:28px;
  font-size:28px;
  font-size:var(--font-heading-xl-size-2ac18Z, 28px);
  line-height:36px;
  line-height:36px;
  line-height:var(--font-heading-xl-lineHeight-3i5g66, 36px);
}
._help-panel_1d237_1yp8l_4 h2:not(#\9){
  font-size:18px;
  font-size:18px;
  font-size:var(--font-heading-l-size-3IMO0y, 18px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-heading-l-lineHeight-2Vn4pu, 22px);
}
._help-panel_1d237_1yp8l_4 h3:not(#\9){
  font-size:18px;
  font-size:18px;
  font-size:var(--font-heading-m-size-3IMO0y, 18px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-heading-m-lineHeight-2Vn4pu, 22px);
}
._help-panel_1d237_1yp8l_4 h4:not(#\9){
  font-size:16px;
  font-size:16px;
  font-size:var(--font-heading-s-size-x2Xc-V, 16px);
  line-height:20px;
  line-height:20px;
  line-height:var(--font-heading-s-lineHeight-8Y2p4W, 20px);
}
._help-panel_1d237_1yp8l_4 h5:not(#\9){
  font-size:16px;
  font-size:16px;
  font-size:var(--font-heading-xs-size-x2Xc-V, 16px);
  line-height:20px;
  line-height:20px;
  line-height:var(--font-heading-xs-lineHeight-8Y2p4W, 20px);
}
._help-panel_1d237_1yp8l_4 h3:not(#\9),
._help-panel_1d237_1yp8l_4 h5:not(#\9){
  font-weight:400;
}
._help-panel_1d237_1yp8l_4 h2:not(#\9),
._help-panel_1d237_1yp8l_4 h4:not(#\9),
._help-panel_1d237_1yp8l_4 b:not(#\9),
._help-panel_1d237_1yp8l_4 strong:not(#\9){
  font-weight:700;
}
._help-panel_1d237_1yp8l_4 a:not(#\9){
  color:#0073bb;
  color:#0073bb;
  color:var(--color-text-link-default-Bc0LvG, #0073bb);
  text-decoration:none;
}
._help-panel_1d237_1yp8l_4 a:not(#\9):hover, ._help-panel_1d237_1yp8l_4 a:not(#\9):active, ._help-panel_1d237_1yp8l_4 a:not(#\9):focus{
  text-decoration:underline;
}
._help-panel_1d237_1yp8l_4 a:not(#\9):focus{
  outline:thin dotted;
  outline:5px auto -webkit-focus-ring-color;
  outline-offset:2px;
}
._help-panel_1d237_1yp8l_4 ._header_1d237_1yp8l_130:not(#\9){
  font-size:18px;
  font-size:18px;
  font-size:var(--font-heading-m-size-3IMO0y, 18px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-heading-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-heading-default-2S6vEC, #16191f);
  font-weight:700;
  padding-bottom:20px;
  padding-bottom:20px;
  padding-bottom:var(--space-scaled-l-17GFGI, 20px);
  padding-left:32px;
  padding-left:32px;
  padding-left:var(--space-scaled-xxl-238u6Z, 32px);
  padding-right:calc(24px + 32px);
  padding-right:calc(24px + 32px);
  padding-right:calc(var(--space-xl-3Pai2K, 24px) + var(--space-scaled-xxl-238u6Z, 32px));
  border:none;
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid #eaeded;
  border-bottom:var(--border-divider-list-width-3GX4nr, 1px) solid var(--color-border-divider-default-38loDV, #eaeded);
  margin:0 calc(-1 * 32px) 20px;
  margin:0 calc(-1 * 32px) 20px;
  margin:0 calc(-1 * var(--space-scaled-xxl-238u6Z, 32px)) var(--space-scaled-l-17GFGI, 20px);
}
._help-panel_1d237_1yp8l_4 ._header_1d237_1yp8l_130 h2:not(#\9),
._help-panel_1d237_1yp8l_4 ._header_1d237_1yp8l_130 h3:not(#\9),
._help-panel_1d237_1yp8l_4 ._header_1d237_1yp8l_130 h4:not(#\9),
._help-panel_1d237_1yp8l_4 ._header_1d237_1yp8l_130 h5:not(#\9),
._help-panel_1d237_1yp8l_4 ._header_1d237_1yp8l_130 h6:not(#\9){
  font-size:18px;
  font-size:18px;
  font-size:var(--font-heading-m-size-3IMO0y, 18px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-heading-m-lineHeight-2Vn4pu, 22px);
  padding-top:0;
  padding-bottom:0;
  margin-top:0;
  margin-bottom:0;
}

._content_1d237_1yp8l_155:not(#\9){
  color:#545b64;
  color:#545b64;
  color:var(--color-text-body-secondary-1oBHNI, #545b64);
}
._content_1d237_1yp8l_155 h2:not(#\9):first-child,
._content_1d237_1yp8l_155 h3:not(#\9):first-child,
._content_1d237_1yp8l_155 h4:not(#\9):first-child,
._content_1d237_1yp8l_155 h5:not(#\9):first-child,
._content_1d237_1yp8l_155 h6:not(#\9):first-child{
  margin-top:0;
}

._footer_1d237_1yp8l_166:not(#\9){
  color:#545b64;
  color:#545b64;
  color:var(--color-text-body-secondary-1oBHNI, #545b64);
  border-top:1px solid #eaeded;
  border-top:1px solid #eaeded;
  border-top:var(--border-divider-list-width-3GX4nr, 1px) solid var(--color-border-divider-default-38loDV, #eaeded);
  margin:24px calc(-1 * 8px);
  margin:24px calc(-1 * 8px);
  margin:var(--space-scaled-xl-axUbkA, 24px) calc(-1 * var(--space-xs-3JTBUA, 8px));
  padding:0 12px;
  padding:0 12px;
  padding:0 var(--space-s-1Qj4Fv, 12px);
}
._footer_1d237_1yp8l_166 > :not(#\9):first-child{
  margin-top:24px;
  margin-top:24px;
  margin-top:var(--space-scaled-xl-axUbkA, 24px);
}
._footer_1d237_1yp8l_166 ul:not(#\9){
  list-style:none;
  padding-left:0;
}
._wrapper_cqcqk_1qva4_7:not(#\9){
  position:relative;
}

._elementWrapper_cqcqk_1qva4_11:not(#\9){
  flex:1 1;
}

._markerWrapper_cqcqk_1qva4_15:not(#\9){
  position:absolute;
  top:0;
}

._placement-right_cqcqk_1qva4_20:not(#\9){
  left:100%;
  margin-left:4px;
  margin-left:4px;
  margin-left:var(--space-xxs-10c0Z4, 4px);
}

._placement-left_cqcqk_1qva4_25:not(#\9){
  right:100%;
  margin-right:4px;
  margin-right:4px;
  margin-right:var(--space-xxs-10c0Z4, 4px);
}

._inlineWrapper_cqcqk_1qva4_30:not(#\9){
  margin-left:4px;
  margin-left:4px;
  margin-left:var(--space-xxs-10c0Z4, 4px);
}

._root_fvjdu_le2hi_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  box-sizing:border-box;
  padding-left:0;
  margin:0;
  list-style:none;
}
._root_fvjdu_le2hi_3 > li:not(#\9){
  margin:4px 4px;
  margin:4px 4px;
  margin:var(--space-scaled-xxs-wz9S8K, 4px) var(--space-xxs-10c0Z4, 4px);
  text-align:center;
  box-sizing:border-box;
  padding:0;
}
._root_fvjdu_le2hi_3 > li:not(#\9):first-child{
  margin-left:0;
}
._root_fvjdu_le2hi_3 > li:not(#\9):last-child{
  margin-right:0;
}

._button_fvjdu_le2hi_32:not(#\9),
._dots_fvjdu_le2hi_33:not(#\9){
  min-width:20px;
  min-width:20px;
  min-width:var(--space-l-1RnAcv, 20px);
  color:#545b64;
  color:#545b64;
  color:var(--color-text-interactive-default-1oBHNI, #545b64);
  border:1px solid transparent;
  border:1px solid transparent;
  border:var(--border-field-width-3GX4nr, 1px) solid transparent;
}

._root-disabled_fvjdu_le2hi_39 > ._dots_fvjdu_le2hi_33:not(#\9){
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
}

._button_fvjdu_le2hi_32:not(#\9){
  cursor:pointer;
  text-align:center;
  box-sizing:border-box;
  background:transparent;
  line-height:inherit;
  padding:0;
}
._button_fvjdu_le2hi_32:not(#\9):focus{
  outline:none;
}
._button_fvjdu_le2hi_32[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  border:1px solid #00a1c9;
  border:1px solid #00a1c9;
  border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-item-focused-2uP71z, #00a1c9);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._button-disabled_fvjdu_le2hi_60:not(#\9){
  cursor:default;
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
}
._button-current_fvjdu_le2hi_64:not(#\9){
  font-weight:700;
  color:#16191f;
  color:#16191f;
  color:var(--color-text-interactive-active-18qOom, #16191f);
}
._button-current_fvjdu_le2hi_64._button-disabled_fvjdu_le2hi_60:not(#\9){
  color:#545b64;
  color:#545b64;
  color:var(--color-text-body-secondary-1oBHNI, #545b64);
}
._button_fvjdu_le2hi_32:not(#\9):not(._button-disabled_fvjdu_le2hi_60):hover{
  color:#16191f;
  color:#16191f;
  color:var(--color-text-interactive-hover-18qOom, #16191f);
}
._segment_1edmh_dqgac_3 + path:not(#\9){
  transition:opacity 90ms linear;
  transition:opacity 90ms linear;
  transition:opacity var(--motion-duration-transition-quick-2hvSaJ, 90ms) var(--motion-easing-transition-quick-2nchNs, linear);
}
@media (prefers-reduced-motion: reduce){
  ._segment_1edmh_dqgac_3 + path:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._segment_1edmh_dqgac_3 + path:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._segment_1edmh_dqgac_3:not(#\9){
  transition:opacity 90ms linear;
  transition:opacity 90ms linear;
  transition:opacity var(--motion-duration-transition-quick-2hvSaJ, 90ms) var(--motion-easing-transition-quick-2nchNs, linear);
}
@media (prefers-reduced-motion: reduce){
  ._segment_1edmh_dqgac_3:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._segment_1edmh_dqgac_3:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}
._segment_1edmh_dqgac_3 > ._segment__hover_1edmh_dqgac_30:not(#\9){
  transition:opacity 90ms linear;
  transition:opacity 90ms linear;
  transition:opacity var(--motion-duration-transition-quick-2hvSaJ, 90ms) var(--motion-easing-transition-quick-2nchNs, linear);
}
@media (prefers-reduced-motion: reduce){
  ._segment_1edmh_dqgac_3 > ._segment__hover_1edmh_dqgac_30:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._segment_1edmh_dqgac_3 > ._segment__hover_1edmh_dqgac_30:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._label_1edmh_dqgac_44:not(#\9){
  transition:opacity 90ms linear;
  transition:opacity 90ms linear;
  transition:opacity var(--motion-duration-transition-quick-2hvSaJ, 90ms) var(--motion-easing-transition-quick-2nchNs, linear);
}
@media (prefers-reduced-motion: reduce){
  ._label_1edmh_dqgac_44:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._label_1edmh_dqgac_44:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._root_1edmh_dqgac_58:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:block;
}

._has-default-filter_1edmh_dqgac_73 > :not(#\9):first-child{
  flex:280px 0 1;
}

._pie_1edmh_dqgac_77:not(#\9){
  display:block;
  position:relative;
  width:100%;
  shape-rendering:geometricPrecision;
}

._content_1edmh_dqgac_84:not(#\9){
  display:flex;
  position:relative;
  justify-content:center;
  align-items:center;
}
._content_1edmh_dqgac_84._content--small_1edmh_dqgac_90:not(#\9){
  min-height:calc( 2 * (50px + 8px + calc(2 * 22px)) );
  min-height:calc( 2 * (50px + 8px + calc(2 * 22px)) );
  min-height:calc( 2 * (50px + var(--space-xs-3JTBUA, 8px) + calc(2 * var(--font-body-m-lineHeight-2Vn4pu, 22px))) );
}
._content_1edmh_dqgac_84._content--medium_1edmh_dqgac_93:not(#\9){
  min-height:calc( 2 * (100px + 12px + calc(2 * 22px)) );
  min-height:calc( 2 * (100px + 12px + calc(2 * 22px)) );
  min-height:calc( 2 * (100px + var(--space-s-1Qj4Fv, 12px) + calc(2 * var(--font-body-m-lineHeight-2Vn4pu, 22px))) );
}
._content_1edmh_dqgac_84._content--large_1edmh_dqgac_96:not(#\9){
  min-height:calc( 2 * (140px + 12px + calc(2 * 22px)) );
  min-height:calc( 2 * (140px + 12px + calc(2 * 22px)) );
  min-height:calc( 2 * (140px + var(--space-s-1Qj4Fv, 12px) + calc(2 * var(--font-body-m-lineHeight-2Vn4pu, 22px))) );
}
._content_1edmh_dqgac_84._content--reserve-filter_1edmh_dqgac_99:not(#\9){
  margin-top:calc(2 * 22px);
  margin-top:calc(2 * 22px);
  margin-top:calc(2 * var(--font-body-m-lineHeight-2Vn4pu, 22px));
}
._content_1edmh_dqgac_84._content--reserve-legend_1edmh_dqgac_102:not(#\9){
  margin-bottom:calc(2 * 22px);
  margin-bottom:calc(2 * 22px);
  margin-bottom:calc(2 * var(--font-body-m-lineHeight-2Vn4pu, 22px));
}

._chart-container_1edmh_dqgac_110:not(#\9){
  display:flex;
  flex:1 1;
}

._inner-content_1edmh_dqgac_115:not(#\9){
  display:flex;
  position:absolute;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  text-align:center;
  top:0;
  left:0;
  bottom:0;
  right:0;
  pointer-events:none;
}

._segment__highlight_1edmh_dqgac_129:not(#\9){
  stroke:none;
  opacity:0;
}

._focus-outline_1edmh_dqgac_134:not(#\9){
  display:none;
  pointer-events:none;
  fill:none;
  stroke:#00a1c9;
  stroke:#00a1c9;
  stroke:var(--color-border-item-focused-2uP71z, #00a1c9);
  stroke-width:2px;
}

._segment_1edmh_dqgac_3:not(#\9){
  stroke:#ffffff;
  stroke:#ffffff;
  stroke:var(--color-background-container-content-3w9fMz, #ffffff);
  stroke-width:2px;
  stroke-linejoin:round;
  opacity:1;
  cursor:pointer;
}
._segment_1edmh_dqgac_3:not(#\9):focus{
  outline:none;
}
._segment_1edmh_dqgac_3[data-awsui-focus-visible=true]:not(#\9):focus > ._focus-outline_1edmh_dqgac_134{
  display:block;
}
._segment_1edmh_dqgac_3._segment--dimmed_1edmh_dqgac_155:not(#\9){
  opacity:0.35;
}
._segment_1edmh_dqgac_3._segment--highlighted_1edmh_dqgac_158 > ._segment__highlight_1edmh_dqgac_129:not(#\9), ._segment_1edmh_dqgac_3:not(#\9):hover:not(._segment--dimmed_1edmh_dqgac_155) > ._segment__highlight_1edmh_dqgac_129{
  opacity:1;
}

._label_1edmh_dqgac_44:not(#\9){
  color:#16191f;
  color:#16191f;
  color:var(--color-text-heading-default-2S6vEC, #16191f);
  opacity:1;
}
._label_1edmh_dqgac_44 > line:not(#\9){
  stroke:#aab7b8;
  stroke:#aab7b8;
  stroke:var(--color-border-control-default-27WVST, #aab7b8);
  stroke-width:1px;
}
._label_1edmh_dqgac_44 > div:not(#\9){
  margin-top:-0.75em;
}
._label_1edmh_dqgac_44._label--dimmed_1edmh_dqgac_173:not(#\9){
  opacity:0.35;
}
._label_1edmh_dqgac_44._label--align-right_1edmh_dqgac_176 > div:not(#\9){
  text-align:right;
}

._label-text_1edmh_dqgac_180 > text:not(#\9){
  fill:#16191f;
  fill:#16191f;
  fill:var(--color-text-heading-default-2S6vEC, #16191f);
  dominant-baseline:middle;
}

text._label__description_1edmh_dqgac_185:not(#\9){
  color:#545b64;
  color:#545b64;
  color:var(--color-text-label-3qT4rX, #545b64);
  fill:#545b64;
  fill:#545b64;
  fill:var(--color-text-label-3qT4rX, #545b64);
}

._popover-header_1edmh_dqgac_198:not(#\9){
  display:inline-flex;
  align-items:flex-start;
}
._progress_11huc_lrx8r_5:not(#\9)::-webkit-progress-value{
  -webkit-transition:width 135ms linear;
  transition:width 135ms linear;
  transition:width 135ms linear;
  -webkit-transition:width var(--motion-duration-moderate-3V3IbE, 135ms) linear;
  transition:width var(--motion-duration-moderate-3V3IbE, 135ms) linear;
}
@media (prefers-reduced-motion: reduce){
  ._progress_11huc_lrx8r_5:not(#\9)::-webkit-progress-value{
    -webkit-animation:none;
            animation:none;
    -webkit-transition:none;
    transition:none;
  }
}
.awsui-motion-disabled ._progress_11huc_lrx8r_5:not(#\9)::-webkit-progress-value{
  -webkit-animation:none;
          animation:none;
  -webkit-transition:none;
  transition:none;
}

._result-state_11huc_lrx8r_19:not(#\9){
  -webkit-animation:_awsui-motion-fade-in_11huc_lrx8r_1 180ms ease-out;
          animation:_awsui-motion-fade-in_11huc_lrx8r_1 180ms ease-out;
  animation:_awsui-motion-fade-in_11huc_lrx8r_1 180ms ease-out;
  -webkit-animation:_awsui-motion-fade-in_11huc_lrx8r_1 var(--motion-duration-transition-show-paced-2crOWV, 180ms) var(--motion-easing-transition-show-paced-25z_bd, ease-out);
          animation:_awsui-motion-fade-in_11huc_lrx8r_1 var(--motion-duration-transition-show-paced-2crOWV, 180ms) var(--motion-easing-transition-show-paced-25z_bd, ease-out);
}
@-webkit-keyframes _awsui-motion-fade-in_11huc_lrx8r_1{
  from{
    opacity:0.2;
  }
  to{
    opacity:1;
  }
}
@keyframes _awsui-motion-fade-in_11huc_lrx8r_1{
  from{
    opacity:0.2;
  }
  to{
    opacity:1;
  }
}
@media (prefers-reduced-motion: reduce){
  ._result-state_11huc_lrx8r_19:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._result-state_11huc_lrx8r_19:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._root_11huc_lrx8r_41:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}

._with-result-button_11huc_lrx8r_51:not(#\9){
  padding-right:16px;
  padding-right:16px;
  padding-right:var(--space-m-208vQh, 16px);
}

._result-button_11huc_lrx8r_63:not(#\9){
  display:inline-block;
  margin:4px 0;
  margin:4px 0;
  margin:var(--space-scaled-xxs-wz9S8K, 4px) 0;
}

._word-wrap_11huc_lrx8r_68:not(#\9){
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
}

._flash_11huc_lrx8r_74:not(#\9){
  color:inherit;
}

._progress-container_11huc_lrx8r_78:not(#\9){
  display:flex;
  align-items:center;
  max-width:800px;
  height:22px;
  height:22px;
  height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
}

._percentage-container_11huc_lrx8r_85:not(#\9){
  width:33px;
  flex-shrink:0;
  white-space:nowrap;
  text-align:right;
  font-size:12px;
  font-size:12px;
  font-size:var(--font-body-s-size-3NP7Rr, 12px);
  line-height:16px;
  line-height:16px;
  line-height:var(--font-body-s-lineHeight-x2Xc-V, 16px);
}

._progress_11huc_lrx8r_5:not(#\9){
  width:100%;
  margin-right:12px;
  margin-right:12px;
  margin-right:var(--space-s-1Qj4Fv, 12px);
  min-width:0;
  height:4px;
  border:0 none;
  box-sizing:border-box;
  border-radius:10px;
  background-color:#eaeded;
  background-color:#eaeded;
  background-color:var(--color-background-progress-bar-layout-default-38loDV, #eaeded);
}
._progress_11huc_lrx8r_5:not(#\9)::-webkit-progress-bar{
  height:4px;
  border:0 none;
  box-sizing:border-box;
  border-radius:10px;
  background-color:#eaeded;
  background-color:#eaeded;
  background-color:var(--color-background-progress-bar-layout-default-38loDV, #eaeded);
}
._progress_11huc_lrx8r_5:not(#\9)::-webkit-progress-value{
  border-radius:10px;
  background-color:#0073bb;
  background-color:#0073bb;
  background-color:var(--color-background-progress-bar-content-default-3uW6lA, #0073bb);
}
._progress_11huc_lrx8r_5:not(#\9)::-moz-progress-bar{
  border-radius:10px;
  background-color:#0073bb;
  background-color:#0073bb;
  background-color:var(--color-background-progress-bar-content-default-3uW6lA, #0073bb);
}
._progress_11huc_lrx8r_5:not(#\9)::-ms-fill{
  border-radius:10px;
  background-color:#0073bb;
  border:none;
}
._progress_11huc_lrx8r_5._progress-in-flash_11huc_lrx8r_128:not(#\9){
  background-color:rgba(255, 255, 255, 0.25);
  background-color:rgba(255, 255, 255, 0.25);
  background-color:var(--color-background-progress-bar-layout-in-flash-2azuvH, rgba(255, 255, 255, 0.25));
}
._progress_11huc_lrx8r_5._progress-in-flash_11huc_lrx8r_128:not(#\9)::-webkit-progress-bar{
  background-color:rgba(255, 255, 255, 0.25);
  background-color:rgba(255, 255, 255, 0.25);
  background-color:var(--color-background-progress-bar-layout-in-flash-2azuvH, rgba(255, 255, 255, 0.25));
}
._progress_11huc_lrx8r_5._progress-in-flash_11huc_lrx8r_128:not(#\9)::-webkit-progress-value{
  background-color:rgba(255, 255, 255, 0.7);
  background-color:rgba(255, 255, 255, 0.7);
  background-color:var(--color-background-progress-bar-content-in-flash-1gkz93, rgba(255, 255, 255, 0.7));
}
._progress_11huc_lrx8r_5._progress-in-flash_11huc_lrx8r_128:not(#\9)::-moz-progress-bar{
  background-color:rgba(255, 255, 255, 0.7);
  background-color:rgba(255, 255, 255, 0.7);
  background-color:var(--color-background-progress-bar-content-in-flash-1gkz93, rgba(255, 255, 255, 0.7));
}
._progress_11huc_lrx8r_5._progress-in-flash_11huc_lrx8r_128:not(#\9)::-ms-fill{
  background-color:rgba(255, 255, 255, 0.7);
}
._root_1wzqe_j60o5_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}

._search-field_1wzqe_j60o5_13:not(#\9){
  display:flex;
  align-items:center;
  max-width:calc(688px - 2 * 20px);
  max-width:calc(688px - 2 * 20px);
  max-width:calc(688px - 2 * var(--space-l-1RnAcv, 20px));
}

._input-wrapper_1wzqe_j60o5_19:not(#\9){
  flex-grow:1;
}

._add-token_1wzqe_j60o5_23:not(#\9){
  border-left:1px solid #eaeded;
  border-left:1px solid #eaeded;
  border-left:1px solid var(--color-border-divider-default-38loDV, #eaeded);
  box-sizing:border-box;
  margin-left:16px;
  margin-left:16px;
  margin-left:var(--space-m-208vQh, 16px);
  padding-left:16px;
  padding-left:16px;
  padding-left:var(--space-m-208vQh, 16px);
}

._tokens_1wzqe_j60o5_30:not(#\9){
  margin-top:8px;
  margin-top:8px;
  margin-top:var(--space-xs-3JTBUA, 8px);
}

._token-operator_1wzqe_j60o5_34:not(#\9){
  font-weight:bold;
}

._token-editor_1wzqe_j60o5_38:not(#\9){
  margin:4px;
  margin:4px;
  margin:var(--space-xxs-10c0Z4, 4px);
  min-width:300px;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
}
._token-editor-line_1wzqe_j60o5_45:not(#\9){
  display:flex;
  justify-content:space-between;
  align-items:center;
}
._token-editor-label_1wzqe_j60o5_50:not(#\9){
  word-wrap:break-word;
  max-width:100%;
  overflow:hidden;
}
._token-editor-field_1wzqe_j60o5_55:not(#\9){
  width:200px;
  flex-shrink:0;
  margin-left:24px;
  margin-left:24px;
  margin-left:var(--space-xl-3Pai2K, 24px);
}
._token-editor-actions_1wzqe_j60o5_60:not(#\9){
  display:flex;
  justify-content:flex-end;
  margin-top:24px;
  margin-top:24px;
  margin-top:var(--space-scaled-xl-axUbkA, 24px);
}
._token-editor-commit_1wzqe_j60o5_65:not(#\9){
  margin-left:8px;
  margin-left:8px;
  margin-left:var(--space-xs-3JTBUA, 8px);
}

._custom-control_1wzqe_j60o5_69:not(#\9){
  margin-right:12px;
  margin-right:12px;
  margin-right:var(--space-s-1Qj4Fv, 12px);
}

._input_1wzqe_j60o5_19:not(#\9){
  flex:1 1;
}

._results_1wzqe_j60o5_77:not(#\9){
  color:#16191f;
  color:#16191f;
  color:var(--color-text-form-label-WiQFVx, #16191f);
  display:inline-block;
  box-sizing:border-box;
  white-space:nowrap;
}
._results-visible_1wzqe_j60o5_83:not(#\9){
  padding-left:12px;
  padding-left:12px;
  padding-left:var(--space-s-1Qj4Fv, 12px);
}

._separator_1wzqe_j60o5_87:not(#\9){
  margin:0px 16px;
  margin:0px 16px;
  margin:0px var(--space-scaled-m-p3tAne, 16px);
  height:100%;
  width:1px;
  background-color:#eaeded;
  background-color:#eaeded;
  background-color:var(--color-border-divider-default-38loDV, #eaeded);
}

._toggle-collapsed_1wzqe_j60o5_94:not(#\9){
  margin-top:calc(-1 * 4px);
  margin-top:calc(-1 * 4px);
  margin-top:calc(-1 * var(--space-scaled-xxs-wz9S8K, 4px));
  margin-left:8px;
  margin-left:8px;
  margin-left:var(--space-xs-3JTBUA, 8px);
}

._token-trigger_1wzqe_j60o5_99:not(#\9){
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
}

._token-editor-cancel_1wzqe_j60o5_105:not(#\9){
  margin-right:8px;
  margin-right:8px;
  margin-right:var(--space-xs-3JTBUA, 8px);
}
._root_10m3l_jikv3_3:not(#\9){
  display:flex;
  align-content:stretch;
}

._token_10m3l_jikv3_8:not(#\9){
  border:1px solid #00a1c9;
  border:1px solid #00a1c9;
  border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-item-selected-6FRHtw, #00a1c9);
  display:flex;
  align-items:stretch;
  background:#f1faff;
  background:#f1faff;
  background:var(--color-background-item-selected-WkeJRF, #f1faff);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  height:100%;
  box-sizing:border-box;
}

._show-operation_10m3l_jikv3_19:not(#\9){
  border-left:none;
  border-top-left-radius:0;
  border-bottom-left-radius:0;
}

._token-content_10m3l_jikv3_29:not(#\9){
  padding:4px 4px 4px 8px;
  padding:4px 4px 4px 8px;
  padding:var(--space-scaled-xxs-wz9S8K, 4px) var(--space-xxs-10c0Z4, 4px) var(--space-scaled-xxs-wz9S8K, 4px) var(--space-xs-3JTBUA, 8px);
}

._dismiss-button_10m3l_jikv3_33:not(#\9){
  width:30px;
  margin:0 0 0 4px;
  margin:0 0 0 4px;
  margin:0 0 0 var(--space-xxs-10c0Z4, 4px);
  border:none;
  padding:0 4px;
  padding:0 4px;
  padding:0 var(--space-xxs-10c0Z4, 4px);
  color:#545b64;
  color:#545b64;
  color:var(--color-text-interactive-default-1oBHNI, #545b64);
  background-color:transparent;
  border-left:1px solid #00a1c9;
  border-left:1px solid #00a1c9;
  border-left:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-item-selected-6FRHtw, #00a1c9);
}
._dismiss-button_10m3l_jikv3_33[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._dismiss-button_10m3l_jikv3_33[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(0px - 1px);
}
._dismiss-button_10m3l_jikv3_33[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:0px;
  top:0px;
  width:calc(100% + 2 * 0px);
  height:calc(100% + 2 * 0px);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._dismiss-button_10m3l_jikv3_33:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._dismiss-button_10m3l_jikv3_33:not(#\9):hover{
  cursor:pointer;
  color:#16191f;
  color:#16191f;
  color:var(--color-text-interactive-hover-18qOom, #16191f);
}
._layout_1vtzr_1jo3c_13:not(#\9){
  display:flex;
  flex-wrap:wrap;
  align-items:flex-end;
  margin:calc(-1 * 8px);
  margin:calc(-1 * 8px);
  margin:calc(-1 * var(--space-xs-3JTBUA, 8px));
}
._layout_1vtzr_1jo3c_13 > *:not(#\9){
  margin:8px;
  margin:8px;
  margin:var(--space-xs-3JTBUA, 8px);
}
._layout-uri_1vtzr_1jo3c_22:not(#\9){
  min-width:200px;
  flex:1 1;
}
._layout-version_1vtzr_1jo3c_26:not(#\9){
  max-width:180px;
  min-width:140px;
  width:20%;
}
._layout-divider_1vtzr_1jo3c_31:not(#\9){
  height:calc( 2 * 1px + 2 * 4px + 22px);
  height:calc( 2 * 1px + 2 * 4px + 22px);
  height:calc( 2 * 1px + 2 * var(--space-scaled-xxs-wz9S8K, 4px) + var(--font-body-m-lineHeight-2Vn4pu, 22px) );
  border-left:1px solid #eaeded;
  border-left:1px solid #eaeded;
  border-left:1px solid var(--color-border-divider-default-38loDV, #eaeded);
}
._resize-active_x7peu_1pxnd_3 *:not(#\9){
  cursor:col-resize;
  -webkit-user-select:none;
          user-select:none;
}

._resizer_x7peu_1pxnd_8:not(#\9){
  bottom:0;
  cursor:col-resize;
  position:absolute;
  right:calc(-1 * 20px / 2);
  right:calc(-1 * 20px / 2);
  right:calc(-1 * var(--space-l-1RnAcv, 20px) / 2);
  top:0;
  width:20px;
  width:20px;
  width:var(--space-l-1RnAcv, 20px);
  z-index:10;
}
._resize-active_x7peu_1pxnd_3 ._resizer_x7peu_1pxnd_8:not(#\9){
  pointer-events:none;
}
th:not(#\9):last-child > ._resizer_x7peu_1pxnd_8{
  width:calc(20px / 2);
  width:calc(20px / 2);
  width:calc(var(--space-l-1RnAcv, 20px) / 2);
  right:0;
}
th:not(#\9):not(:last-child) > ._resizer_x7peu_1pxnd_8:hover::before, th:not(#\9):not(:last-child) > ._resizer-active_x7peu_1pxnd_24::before{
  content:"";
  position:absolute;
  left:calc(20px / 2 - 2px);
  left:calc(20px / 2 - 2px);
  left:calc(var(--space-l-1RnAcv, 20px) / 2 - 2px);
  bottom:25%;
  height:50%;
  box-sizing:border-box;
  border-left:2px solid #879596;
  border-left:2px solid #879596;
  border-left:2px solid var(--color-border-divider-active-3Saafg, #879596);
}

._tracker_x7peu_1pxnd_34:not(#\9){
  display:none;
  position:absolute;
  border-left:1px dashed #879596;
  border-left:1px dashed #879596;
  border-left:1px dashed var(--color-border-divider-active-3Saafg, #879596);
  width:0;
  top:0;
  bottom:0;
}
._resize-active_x7peu_1pxnd_3 ._tracker_x7peu_1pxnd_34:not(#\9){
  display:block;
}
._root_1sdq3_1r381_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:flex;
  align-items:center;
  max-width:calc(688px - 2 * 20px);
  max-width:calc(688px - 2 * 20px);
  max-width:calc(688px - 2 * var(--space-l-1RnAcv, 20px));
}

._input_1sdq3_1r381_16:not(#\9){
  flex:1 1;
}

._results_1sdq3_1r381_20:not(#\9){
  color:#16191f;
  color:#16191f;
  color:var(--color-text-form-label-WiQFVx, #16191f);
  display:inline-block;
  box-sizing:border-box;
  white-space:nowrap;
}
._results-visible_1sdq3_1r381_26:not(#\9){
  padding-left:12px;
  padding-left:12px;
  padding-left:var(--space-s-1Qj4Fv, 12px);
}
._modal-actions_12hyz_nutcu_1:not(#\9){
  justify-content:flex-end;
}

._root_l0dv0_1cq71_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
}

._header_l0dv0_1cq71_16:not(#\9){
  padding:20px 32px;
  padding:20px 32px;
  padding:var(--space-scaled-l-17GFGI, 20px) var(--space-xxl-2yP61J, 32px);
  padding-right:calc(32px + 24px);
  padding-right:calc(32px + 24px);
  padding-right:calc(var(--space-scaled-xxl-238u6Z, 32px) + var(--space-xl-3Pai2K, 24px));
}

._header-link_l0dv0_1cq71_21:not(#\9){
  font-size:18px;
  font-size:18px;
  font-size:var(--font-heading-m-size-3IMO0y, 18px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-heading-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-heading-default-2S6vEC, #16191f);
  font-weight:700;
}

._list_l0dv0_1cq71_28:not(#\9){
  margin:0;
  padding:0;
  padding-left:20px;
  padding-left:20px;
  padding-left:var(--space-l-1RnAcv, 20px);
}

._list-variant-root_l0dv0_1cq71_34:not(#\9){
  margin:0;
  margin-bottom:40px;
  margin-bottom:40px;
  margin-bottom:var(--space-scaled-xxxl-3VX7k3, 40px);
  padding:0 32px 0 32px;
  padding:0 32px 0 32px;
  padding:0 var(--space-scaled-xxl-238u6Z, 32px) 0 var(--space-xxl-2yP61J, 32px);
}

._list-variant-expandable-link-group_l0dv0_1cq71_40:not(#\9){
  padding-left:40px;
  padding-left:40px;
  padding-left:var(--space-xxxl-25ySnK, 40px);
}

._list-item_l0dv0_1cq71_44:not(#\9){
  margin:8px 0;
  margin:8px 0;
  margin:var(--space-scaled-xs-19adjU, 8px) 0;
  padding:0;
  list-style:none;
}

._section_l0dv0_1cq71_50:not(#\9),
._expandable-link-group_l0dv0_1cq71_51:not(#\9){
  margin-left:calc(-1 * 20px);
  margin-left:calc(-1 * 20px);
  margin-left:calc(-1 * var(--space-l-1RnAcv, 20px));
}

._section_l0dv0_1cq71_50:not(#\9){
  margin-top:calc(20px - 1px);
  margin-top:calc(20px - 1px);
  margin-top:calc(var(--space-scaled-2x-l-17GFGI, 20px) - var(--border-divider-list-width-3GX4nr, 1px));
  margin-bottom:calc(20px - 1px);
  margin-bottom:calc(20px - 1px);
  margin-bottom:calc(var(--space-scaled-2x-l-17GFGI, 20px) - var(--border-divider-list-width-3GX4nr, 1px));
}
._section_l0dv0_1cq71_50 > *:not(#\9){
  padding:0;
}

._section-header_l0dv0_1cq71_63:not(#\9){
  color:#545b64;
  color:#545b64;
  color:var(--color-text-body-secondary-1oBHNI, #545b64);
  font-weight:700;
}

._link_l0dv0_1cq71_68:not(#\9){
  color:#545b64;
  color:#545b64;
  color:var(--color-text-body-secondary-1oBHNI, #545b64);
  font-weight:400;
}

._link-active_l0dv0_1cq71_73:not(#\9){
  font-weight:700;
  color:#ec7211;
  color:#ec7211;
  color:var(--color-text-accent-NYv9af, #ec7211);
}

._header-link_l0dv0_1cq71_21:not(#\9),
._link_l0dv0_1cq71_68:not(#\9){
  text-decoration:none;
}
._header-link_l0dv0_1cq71_21:not(#\9):hover,
._link_l0dv0_1cq71_68:not(#\9):hover{
  color:#ec7211;
  color:#ec7211;
  color:var(--color-text-accent-NYv9af, #ec7211);
}
._header-link_l0dv0_1cq71_21:not(#\9):focus,
._link_l0dv0_1cq71_68:not(#\9):focus{
  outline:none;
}
._header-link_l0dv0_1cq71_21:not(#\9):hover, ._header-link_l0dv0_1cq71_21:not(#\9):focus, ._link_l0dv0_1cq71_68:not(#\9):hover, ._link_l0dv0_1cq71_68:not(#\9):focus{
  text-decoration:none;
}
._header-link_l0dv0_1cq71_21[data-awsui-focus-visible=true]:not(#\9):focus,
._link_l0dv0_1cq71_68[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:thin dotted;
  outline:5px auto -webkit-focus-ring-color;
  outline-offset:2px;
  outline-color:#00a1c9;
  outline-color:#00a1c9;
  outline-color:var(--color-border-item-focused-2uP71z, #00a1c9);
}

._info_l0dv0_1cq71_103:not(#\9){
  margin-left:8px;
  margin-left:8px;
  margin-left:var(--space-xs-3JTBUA, 8px);
}

._external-icon_l0dv0_1cq71_107:not(#\9){
  margin-left:4px;
  margin-left:4px;
  margin-left:var(--space-xxs-10c0Z4, 4px);
}

._divider_l0dv0_1cq71_111:not(#\9){
  border:none;
  border-top:1px solid #eaeded;
  border-top:1px solid #eaeded;
  border-top:var(--border-divider-list-width-3GX4nr, 1px) solid var(--color-border-divider-default-38loDV, #eaeded);
  margin-bottom:20px;
  margin-bottom:20px;
  margin-bottom:var(--space-scaled-l-17GFGI, 20px);
}

._divider-default_l0dv0_1cq71_117:not(#\9){
  margin:24px calc(-1 * 12px);
  margin:24px calc(-1 * 12px);
  margin:var(--space-scaled-2x-xl-axUbkA, 24px) calc(-1 * var(--space-s-1Qj4Fv, 12px));
}

._divider-header_l0dv0_1cq71_121:not(#\9){
  margin-top:calc(-1 * 1px);
  margin-top:calc(-1 * 1px);
  margin-top:calc(-1 * var(--border-divider-list-width-3GX4nr, 1px));
}
div._column-layout_vj6p7_1c3jc_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  word-wrap:break-word;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13:not(#\9){
  margin:calc(20px / -2);
  margin:calc(20px / -2);
  margin:calc(var(--space-grid-gutter-17GFGI, 20px) / -2);
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-no-gutters_vj6p7_1c3jc_16:not(#\9){
  margin:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-variant-text-grid_vj6p7_1c3jc_19:not(#\9){
  margin:calc(-1 * 20px);
  margin:calc(-1 * 20px);
  margin:calc(-1 * var(--space-grid-gutter-17GFGI, 20px));
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-variant-text-grid_vj6p7_1c3jc_19 > *:not(#\9){
  border-left:1px solid #eaeded;
  border-left:1px solid #eaeded;
  border-left:var(--border-divider-section-width-3GX4nr, 1px) solid var(--color-border-divider-default-38loDV, #eaeded);
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-variant-text-grid_vj6p7_1c3jc_19._grid-breakpoint-default_vj6p7_1c3jc_25 > *:not(#\9):nth-child(1n+1){
  border-left-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-variant-text-grid_vj6p7_1c3jc_19._grid-columns-1_vj6p7_1c3jc_28._grid-breakpoint-xxs_vj6p7_1c3jc_28 > *:not(#\9):nth-child(1n+1){
  border-left-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-variant-text-grid_vj6p7_1c3jc_19._grid-columns-1_vj6p7_1c3jc_28._grid-breakpoint-xs_vj6p7_1c3jc_31 > *:not(#\9):nth-child(1n+1){
  border-left-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-variant-text-grid_vj6p7_1c3jc_19._grid-columns-2_vj6p7_1c3jc_34._grid-breakpoint-xxs_vj6p7_1c3jc_28 > *:not(#\9):nth-child(2n+1){
  border-left-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-variant-text-grid_vj6p7_1c3jc_19._grid-columns-2_vj6p7_1c3jc_34._grid-breakpoint-xs_vj6p7_1c3jc_31 > *:not(#\9):nth-child(2n+1){
  border-left-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-variant-text-grid_vj6p7_1c3jc_19._grid-columns-3_vj6p7_1c3jc_40._grid-breakpoint-xxs_vj6p7_1c3jc_28 > *:not(#\9):nth-child(2n+1){
  border-left-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-variant-text-grid_vj6p7_1c3jc_19._grid-columns-3_vj6p7_1c3jc_40._grid-breakpoint-xs_vj6p7_1c3jc_31 > *:not(#\9):nth-child(3n+1){
  border-left-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-variant-text-grid_vj6p7_1c3jc_19._grid-columns-4_vj6p7_1c3jc_46._grid-breakpoint-xxs_vj6p7_1c3jc_28 > *:not(#\9):nth-child(2n+1){
  border-left-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-variant-text-grid_vj6p7_1c3jc_19._grid-columns-4_vj6p7_1c3jc_46._grid-breakpoint-xs_vj6p7_1c3jc_31 > *:not(#\9):nth-child(4n+1){
  border-left-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13:not(#\9):not(._grid-no-gutters_vj6p7_1c3jc_16) > *{
  padding:calc(20px / 2);
  padding:calc(20px / 2);
  padding:calc(var(--space-grid-gutter-17GFGI, 20px) / 2);
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13:not(#\9):not(._grid-no-gutters_vj6p7_1c3jc_16)._grid-variant-text-grid_vj6p7_1c3jc_19 > *{
  padding:0 20px;
  padding:0 20px;
  padding:0 var(--space-grid-gutter-17GFGI, 20px);
  margin:20px 0;
  margin:20px 0;
  margin:var(--space-grid-gutter-17GFGI, 20px) 0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-vertical-borders_vj6p7_1c3jc_59 > *:not(#\9){
  border-right:1px solid #eaeded;
  border-right:1px solid #eaeded;
  border-right:var(--border-divider-section-width-3GX4nr, 1px) solid var(--color-border-divider-default-38loDV, #eaeded);
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-vertical-borders_vj6p7_1c3jc_59._grid-breakpoint-default_vj6p7_1c3jc_25 > *:not(#\9):nth-child(1n){
  border-right-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-vertical-borders_vj6p7_1c3jc_59._grid-columns-1_vj6p7_1c3jc_28._grid-breakpoint-xxs_vj6p7_1c3jc_28 > *:not(#\9):nth-child(1n){
  border-right-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-vertical-borders_vj6p7_1c3jc_59._grid-columns-1_vj6p7_1c3jc_28._grid-breakpoint-xs_vj6p7_1c3jc_31 > *:not(#\9):nth-child(1n){
  border-right-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-vertical-borders_vj6p7_1c3jc_59._grid-columns-2_vj6p7_1c3jc_34._grid-breakpoint-xxs_vj6p7_1c3jc_28 > *:not(#\9):nth-child(2n){
  border-right-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-vertical-borders_vj6p7_1c3jc_59._grid-columns-2_vj6p7_1c3jc_34._grid-breakpoint-xs_vj6p7_1c3jc_31 > *:not(#\9):nth-child(2n){
  border-right-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-vertical-borders_vj6p7_1c3jc_59._grid-columns-3_vj6p7_1c3jc_40._grid-breakpoint-xxs_vj6p7_1c3jc_28 > *:not(#\9):nth-child(2n){
  border-right-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-vertical-borders_vj6p7_1c3jc_59._grid-columns-3_vj6p7_1c3jc_40._grid-breakpoint-xs_vj6p7_1c3jc_31 > *:not(#\9):nth-child(3n){
  border-right-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-vertical-borders_vj6p7_1c3jc_59._grid-columns-4_vj6p7_1c3jc_46._grid-breakpoint-xxs_vj6p7_1c3jc_28 > *:not(#\9):nth-child(2n){
  border-right-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-vertical-borders_vj6p7_1c3jc_59._grid-columns-4_vj6p7_1c3jc_46._grid-breakpoint-xs_vj6p7_1c3jc_31 > *:not(#\9):nth-child(4n){
  border-right-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-horizontal-borders_vj6p7_1c3jc_89 > *:not(#\9){
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid #eaeded;
  border-bottom:var(--border-divider-section-width-3GX4nr, 1px) solid var(--color-border-divider-default-38loDV, #eaeded);
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-horizontal-borders_vj6p7_1c3jc_89 > *:not(#\9):last-child{
  border-bottom-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-horizontal-borders_vj6p7_1c3jc_89._grid-columns-2_vj6p7_1c3jc_34._grid-breakpoint-xxs_vj6p7_1c3jc_28 > *:not(#\9):nth-last-child(2):nth-child(2n+1){
  border-bottom-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-horizontal-borders_vj6p7_1c3jc_89._grid-columns-2_vj6p7_1c3jc_34._grid-breakpoint-xs_vj6p7_1c3jc_31 > *:not(#\9):nth-last-child(2):nth-child(2n+1){
  border-bottom-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-horizontal-borders_vj6p7_1c3jc_89._grid-columns-3_vj6p7_1c3jc_40._grid-breakpoint-xxs_vj6p7_1c3jc_28 > *:not(#\9):nth-last-child(2):nth-child(2n+1){
  border-bottom-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-horizontal-borders_vj6p7_1c3jc_89._grid-columns-3_vj6p7_1c3jc_40._grid-breakpoint-xs_vj6p7_1c3jc_31 > *:not(#\9):nth-last-child(2):nth-child(3n+1){
  border-bottom-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-horizontal-borders_vj6p7_1c3jc_89._grid-columns-3_vj6p7_1c3jc_40._grid-breakpoint-xs_vj6p7_1c3jc_31 > *:not(#\9):nth-last-child(2):nth-child(3n+2){
  border-bottom-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-horizontal-borders_vj6p7_1c3jc_89._grid-columns-3_vj6p7_1c3jc_40._grid-breakpoint-xs_vj6p7_1c3jc_31 > *:not(#\9):nth-last-child(3):nth-child(3n+1){
  border-bottom-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-horizontal-borders_vj6p7_1c3jc_89._grid-columns-4_vj6p7_1c3jc_46._grid-breakpoint-xxs_vj6p7_1c3jc_28 > *:not(#\9):nth-last-child(2):nth-child(2n+1){
  border-bottom-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-horizontal-borders_vj6p7_1c3jc_89._grid-columns-4_vj6p7_1c3jc_46._grid-breakpoint-xs_vj6p7_1c3jc_31 > *:not(#\9):nth-last-child(2):nth-child(4n+1){
  border-bottom-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-horizontal-borders_vj6p7_1c3jc_89._grid-columns-4_vj6p7_1c3jc_46._grid-breakpoint-xs_vj6p7_1c3jc_31 > *:not(#\9):nth-last-child(2):nth-child(4n+2){
  border-bottom-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-horizontal-borders_vj6p7_1c3jc_89._grid-columns-4_vj6p7_1c3jc_46._grid-breakpoint-xs_vj6p7_1c3jc_31 > *:not(#\9):nth-last-child(2):nth-child(4n+3){
  border-bottom-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-horizontal-borders_vj6p7_1c3jc_89._grid-columns-4_vj6p7_1c3jc_46._grid-breakpoint-xs_vj6p7_1c3jc_31 > *:not(#\9):nth-last-child(3):nth-child(4n+1){
  border-bottom-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-horizontal-borders_vj6p7_1c3jc_89._grid-columns-4_vj6p7_1c3jc_46._grid-breakpoint-xs_vj6p7_1c3jc_31 > *:not(#\9):nth-last-child(3):nth-child(4n+2){
  border-bottom-width:0;
}
div._column-layout_vj6p7_1c3jc_3 > ._grid_vj6p7_1c3jc_13._grid-horizontal-borders_vj6p7_1c3jc_89._grid-columns-4_vj6p7_1c3jc_46._grid-breakpoint-xs_vj6p7_1c3jc_31 > *:not(#\9):nth-last-child(4):nth-child(4n+1){
  border-bottom-width:0;
}

._root_vj6p7_1c3jc_135:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
  display:block;
}

._tile-container_vj6p7_1c3jc_149:not(#\9){
  box-sizing:border-box;
  border:1px solid #aab7b8;
  border:1px solid #aab7b8;
  border:1px solid var(--color-border-control-default-27WVST, #aab7b8);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  padding:8px 16px;
  padding:8px 16px;
  padding:var(--space-xs-3JTBUA, 8px) var(--space-scaled-m-p3tAne, 16px);
  display:flex;
  flex-direction:column;
  transition:border-color 90ms linear, background-color 90ms linear;
  transition:border-color 90ms linear, background-color 90ms linear;
  transition:border-color var(--motion-duration-transition-quick-2hvSaJ, 90ms) var(--motion-easing-transition-quick-2nchNs, linear), background-color var(--motion-duration-transition-quick-2hvSaJ, 90ms) var(--motion-easing-transition-quick-2nchNs, linear);
}
._tile-container_vj6p7_1c3jc_149._has-metadata_vj6p7_1c3jc_158:not(#\9){
  padding-bottom:12px;
  padding-bottom:12px;
  padding-bottom:var(--space-s-1Qj4Fv, 12px);
}
._tile-container_vj6p7_1c3jc_149._selected_vj6p7_1c3jc_161:not(#\9){
  border:1px solid #00a1c9;
  border:1px solid #00a1c9;
  border:1px solid var(--color-border-item-selected-6FRHtw, #00a1c9);
  background:#f1faff;
  background:#f1faff;
  background:var(--color-background-item-selected-WkeJRF, #f1faff);
}
._tile-container_vj6p7_1c3jc_149._disabled_vj6p7_1c3jc_165:not(#\9){
  border:1px solid transparent;
  border:1px solid transparent;
  border:1px solid var(--color-border-tiles-disabled-1D4yG6, transparent);
  background:#eaeded;
  background:#eaeded;
  background:var(--color-background-tiles-disabled-1gYBaj, #eaeded);
}
@media (prefers-reduced-motion: reduce){
  ._tile-container_vj6p7_1c3jc_149:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._tile-container_vj6p7_1c3jc_149:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._columns_vj6p7_1c3jc_180:not(#\9){
  display:flex;
  flex-wrap:wrap;
  margin:calc(20px / -2);
  margin:calc(20px / -2);
  margin:calc(var(--space-grid-gutter-17GFGI, 20px) / -2);
}
._columns_vj6p7_1c3jc_180._column-1_vj6p7_1c3jc_185 > ._tile-container_vj6p7_1c3jc_149:not(#\9){
  margin:calc(20px / 2);
  margin:calc(20px / 2);
  margin:calc(var(--space-grid-gutter-17GFGI, 20px) / 2);
  box-sizing:border-box;
  flex:0 0;
  flex-basis:calc(100% - 20px);
  flex-basis:calc(100% - 20px);
  flex-basis:calc(100% - var(--space-grid-gutter-17GFGI, 20px));
  max-width:calc(100% - 20px);
  max-width:calc(100% - 20px);
  max-width:calc(100% - var(--space-grid-gutter-17GFGI, 20px));
}
._columns_vj6p7_1c3jc_180._column-1_vj6p7_1c3jc_185 > ._tile-container_vj6p7_1c3jc_149._breakpoint-xs_vj6p7_1c3jc_192:not(#\9){
  flex:0 0;
  flex-basis:calc(100% - 20px);
  flex-basis:calc(100% - 20px);
  flex-basis:calc(100% - var(--space-grid-gutter-17GFGI, 20px));
  max-width:calc(100% - 20px);
  max-width:calc(100% - 20px);
  max-width:calc(100% - var(--space-grid-gutter-17GFGI, 20px));
}
._columns_vj6p7_1c3jc_180._column-1_vj6p7_1c3jc_185 > ._tile-container_vj6p7_1c3jc_149._breakpoint-xxs_vj6p7_1c3jc_197:not(#\9){
  flex:0 0;
  flex-basis:calc(100% - 20px);
  flex-basis:calc(100% - 20px);
  flex-basis:calc(100% - var(--space-grid-gutter-17GFGI, 20px));
  max-width:calc(100% - 20px);
  max-width:calc(100% - 20px);
  max-width:calc(100% - var(--space-grid-gutter-17GFGI, 20px));
}
._columns_vj6p7_1c3jc_180._column-2_vj6p7_1c3jc_202 > ._tile-container_vj6p7_1c3jc_149:not(#\9){
  margin:calc(20px / 2);
  margin:calc(20px / 2);
  margin:calc(var(--space-grid-gutter-17GFGI, 20px) / 2);
  box-sizing:border-box;
  flex:0 0;
  flex-basis:calc(100% - 20px);
  flex-basis:calc(100% - 20px);
  flex-basis:calc(100% - var(--space-grid-gutter-17GFGI, 20px));
  max-width:calc(100% - 20px);
  max-width:calc(100% - 20px);
  max-width:calc(100% - var(--space-grid-gutter-17GFGI, 20px));
}
._columns_vj6p7_1c3jc_180._column-2_vj6p7_1c3jc_202 > ._tile-container_vj6p7_1c3jc_149._breakpoint-xs_vj6p7_1c3jc_192:not(#\9){
  flex:0 0;
  flex-basis:calc(50% - 20px);
  flex-basis:calc(50% - 20px);
  flex-basis:calc(50% - var(--space-grid-gutter-17GFGI, 20px));
  max-width:calc(50% - 20px);
  max-width:calc(50% - 20px);
  max-width:calc(50% - var(--space-grid-gutter-17GFGI, 20px));
}
._columns_vj6p7_1c3jc_180._column-2_vj6p7_1c3jc_202 > ._tile-container_vj6p7_1c3jc_149._breakpoint-xxs_vj6p7_1c3jc_197:not(#\9){
  flex:0 0;
  flex-basis:calc(50% - 20px);
  flex-basis:calc(50% - 20px);
  flex-basis:calc(50% - var(--space-grid-gutter-17GFGI, 20px));
  max-width:calc(50% - 20px);
  max-width:calc(50% - 20px);
  max-width:calc(50% - var(--space-grid-gutter-17GFGI, 20px));
}
._columns_vj6p7_1c3jc_180._column-3_vj6p7_1c3jc_219 > ._tile-container_vj6p7_1c3jc_149:not(#\9){
  margin:calc(20px / 2);
  margin:calc(20px / 2);
  margin:calc(var(--space-grid-gutter-17GFGI, 20px) / 2);
  box-sizing:border-box;
  flex:0 0;
  flex-basis:calc(100% - 20px);
  flex-basis:calc(100% - 20px);
  flex-basis:calc(100% - var(--space-grid-gutter-17GFGI, 20px));
  max-width:calc(100% - 20px);
  max-width:calc(100% - 20px);
  max-width:calc(100% - var(--space-grid-gutter-17GFGI, 20px));
}
._columns_vj6p7_1c3jc_180._column-3_vj6p7_1c3jc_219 > ._tile-container_vj6p7_1c3jc_149._breakpoint-xs_vj6p7_1c3jc_192:not(#\9){
  flex:0 0;
  flex-basis:calc(33.3333333333% - 20px);
  flex-basis:calc(33.3333333333% - 20px);
  flex-basis:calc(33.3333333333% - var(--space-grid-gutter-17GFGI, 20px));
  max-width:calc(33.3333333333% - 20px);
  max-width:calc(33.3333333333% - 20px);
  max-width:calc(33.3333333333% - var(--space-grid-gutter-17GFGI, 20px));
}
._columns_vj6p7_1c3jc_180._column-3_vj6p7_1c3jc_219 > ._tile-container_vj6p7_1c3jc_149._breakpoint-xxs_vj6p7_1c3jc_197:not(#\9){
  flex:0 0;
  flex-basis:calc(50% - 20px);
  flex-basis:calc(50% - 20px);
  flex-basis:calc(50% - var(--space-grid-gutter-17GFGI, 20px));
  max-width:calc(50% - 20px);
  max-width:calc(50% - 20px);
  max-width:calc(50% - var(--space-grid-gutter-17GFGI, 20px));
}
._columns_vj6p7_1c3jc_180._column-4_vj6p7_1c3jc_236 > ._tile-container_vj6p7_1c3jc_149:not(#\9){
  margin:calc(20px / 2);
  margin:calc(20px / 2);
  margin:calc(var(--space-grid-gutter-17GFGI, 20px) / 2);
  box-sizing:border-box;
  flex:0 0;
  flex-basis:calc(100% - 20px);
  flex-basis:calc(100% - 20px);
  flex-basis:calc(100% - var(--space-grid-gutter-17GFGI, 20px));
  max-width:calc(100% - 20px);
  max-width:calc(100% - 20px);
  max-width:calc(100% - var(--space-grid-gutter-17GFGI, 20px));
}
._columns_vj6p7_1c3jc_180._column-4_vj6p7_1c3jc_236 > ._tile-container_vj6p7_1c3jc_149._breakpoint-xs_vj6p7_1c3jc_192:not(#\9){
  flex:0 0;
  flex-basis:calc(25% - 20px);
  flex-basis:calc(25% - 20px);
  flex-basis:calc(25% - var(--space-grid-gutter-17GFGI, 20px));
  max-width:calc(25% - 20px);
  max-width:calc(25% - 20px);
  max-width:calc(25% - var(--space-grid-gutter-17GFGI, 20px));
}
._columns_vj6p7_1c3jc_180._column-4_vj6p7_1c3jc_236 > ._tile-container_vj6p7_1c3jc_149._breakpoint-xxs_vj6p7_1c3jc_197:not(#\9){
  flex:0 0;
  flex-basis:calc(50% - 20px);
  flex-basis:calc(50% - 20px);
  flex-basis:calc(50% - var(--space-grid-gutter-17GFGI, 20px));
  max-width:calc(50% - 20px);
  max-width:calc(50% - 20px);
  max-width:calc(50% - var(--space-grid-gutter-17GFGI, 20px));
}

._control_vj6p7_1c3jc_254:not(#\9){
  flex-grow:1;
  margin-bottom:12px;
  margin-bottom:12px;
  margin-bottom:var(--space-s-1Qj4Fv, 12px);
}
._control_vj6p7_1c3jc_254._no-image_vj6p7_1c3jc_258:not(#\9){
  margin-bottom:0;
}

._image_vj6p7_1c3jc_262:not(#\9){
  text-align:center;
  flex:none;
}
._image_vj6p7_1c3jc_262 > img:not(#\9){
  max-width:100%;
}
._image_vj6p7_1c3jc_262._disabled_vj6p7_1c3jc_165 > img:not(#\9){
  opacity:0.3;
}
._resize-active_1r9lg_10dqp_4 *:not(#\9){
  -webkit-user-select:none;
          user-select:none;
}
._resize-side_1r9lg_10dqp_7 *:not(#\9){
  cursor:ew-resize;
}
._resize-bottom_1r9lg_10dqp_10 *:not(#\9){
  cursor:ns-resize;
}
._root_1r9lg_10dqp_15:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}

._drawer_1r9lg_10dqp_33:not(#\9){
  flex-shrink:0;
  position:relative;
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 var(--color-shadow-medium-3CtI4L, rgba(0, 28, 36, 0.3)), 1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15)), -1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15));
  border-radius:0px 0 0 0px;
  border-radius:0px 0 0 0px;
  border-radius:var(--border-container-radius-Z3aihh, 0px) 0 0 var(--border-container-radius-Z3aihh, 0px);
  word-wrap:break-word;
  background-color:#ffffff;
  background-color:#ffffff;
  background-color:var(--color-background-layout-panel-content-1Z_Abk, #ffffff);
}
._drawer_1r9lg_10dqp_33:not(#\9):not(._drawer-mobile_1r9lg_10dqp_41){
  z-index:851;
}
._drawer-closed_1r9lg_10dqp_44:not(#\9){
  cursor:pointer;
  min-width:40px;
}

._drawer-content-side_1r9lg_10dqp_49:not(#\9){
  display:flex;
  align-items:center;
  position:fixed;
  overflow:auto;
}
._drawer-mobile_1r9lg_10dqp_41 > ._drawer-content-side_1r9lg_10dqp_49:not(#\9){
  z-index:1001;
  top:0;
  bottom:0;
  left:0;
  right:0;
}
._drawer-closed_1r9lg_10dqp_44 > ._drawer-content-side_1r9lg_10dqp_49:not(#\9){
  width:40px;
}
._drawer-closed_1r9lg_10dqp_44 > ._drawer-content-side_1r9lg_10dqp_49:not(#\9):hover{
  background:#eaeded;
  background:#eaeded;
  background:var(--color-background-layout-panel-hover-38loDV, #eaeded);
}
._drawer-content-side_1r9lg_10dqp_49 > [aria-hidden=true]:not(#\9){
  display:none;
}

:not(#\9):not(._drawer-closed_1r9lg_10dqp_44) > ._drawer-content-bottom_1r9lg_10dqp_72{
  overflow-y:auto;
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
}

._position-bottom_1r9lg_10dqp_81:not(#\9){
  position:fixed;
  overflow-y:auto;
  border-left:1px solid rgba(0, 28, 36, 0.15);
  border-left:1px solid rgba(0, 28, 36, 0.15);
  border-left:1px solid var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15));
  border-right:1px solid rgba(0, 28, 36, 0.15);
  border-right:1px solid rgba(0, 28, 36, 0.15);
  border-right:1px solid var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15));
  border-radius:0px 0px 0 0;
  border-radius:0px 0px 0 0;
  border-radius:var(--border-container-radius-Z3aihh, 0px) var(--border-container-radius-Z3aihh, 0px) 0 0;
  box-shadow:0 -2px 1px -1px rgba(0, 28, 36, 0.15), 0 -1px 1px -1px rgba(0, 28, 36, 0.3);
  box-shadow:0 -2px 1px -1px rgba(0, 28, 36, 0.15), 0 -1px 1px -1px rgba(0, 28, 36, 0.3);
  box-shadow:0 -2px 1px -1px var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15)), 0 -1px 1px -1px var(--color-shadow-medium-3CtI4L, rgba(0, 28, 36, 0.3));
}
._position-bottom_1r9lg_10dqp_81._drawer-closed_1r9lg_10dqp_44:not(#\9):hover{
  background:#eaeded;
  background:#eaeded;
  background:var(--color-background-layout-panel-hover-38loDV, #eaeded);
}
._position-bottom_1r9lg_10dqp_81 > ._drawer-content-bottom_1r9lg_10dqp_72 > [aria-hidden=true]:not(#\9){
  display:none;
}

._slider_1r9lg_10dqp_96:not(#\9){
  padding:0;
  cursor:ns-resize;
  margin:2px 0 0 0;
  height:16px;
}
._slider_1r9lg_10dqp_96:not(#\9):focus{
  outline:none;
}
._slider_1r9lg_10dqp_96[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._slider_1r9lg_10dqp_96[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(0px - 1px);
}
._slider_1r9lg_10dqp_96[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:0px;
  top:0px;
  width:calc(100% + 2 * 0px);
  height:calc(100% + 2 * 0px);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}

._slider-side_1r9lg_10dqp_124:not(#\9){
  cursor:ew-resize;
  margin:0 0 0 2px;
}

._slider-wrapper-bottom_1r9lg_10dqp_129:not(#\9){
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:18px;
  display:flex;
  justify-content:center;
  z-index:2;
}

._slider-wrapper-side_1r9lg_10dqp_140:not(#\9){
  position:absolute;
  left:0;
  top:0;
  height:100%;
  width:18px;
  display:flex;
  align-items:center;
}

._open-button-side_1r9lg_10dqp_154:not(#\9){
  flex:0 0 auto;
  align-self:flex-start;
  box-sizing:border-box;
  margin:4px auto 0;
  margin:4px auto 0;
  margin:var(--space-xxs-10c0Z4, 4px) auto 0;
}

._slider-icon_1r9lg_10dqp_161:not(#\9){
  stroke:#545b64;
  stroke:#545b64;
  stroke:var(--color-text-interactive-default-1oBHNI, #545b64);
}
._slider-icon_1r9lg_10dqp_161:not(#\9):hover{
  stroke:#16191f;
  stroke:#16191f;
  stroke:var(--color-text-interactive-hover-18qOom, #16191f);
}
._slider-icon-bottom_1r9lg_10dqp_167:not(#\9){
  margin:auto;
}
._slider-icon-side_1r9lg_10dqp_170:not(#\9){
  -webkit-transform:rotate(90deg);
          transform:rotate(90deg);
}

._pane-header-wrapper-bottom_1r9lg_10dqp_174:not(#\9){
  position:sticky;
  top:0;
  display:flex;
  align-items:center;
  flex-direction:column;
  z-index:1;
  padding:0 40px;
  padding:0 40px;
  padding:0 var(--space-scaled-2x-xxxl-3VX7k3, 40px);
}
._drawer-mobile_1r9lg_10dqp_41 > ._drawer-content-bottom_1r9lg_10dqp_72 > ._pane-header-wrapper-bottom_1r9lg_10dqp_174:not(#\9){
  padding:0 20px;
  padding:0 20px;
  padding:0 var(--space-l-1RnAcv, 20px);
}
._drawer-disable-content-paddings_1r9lg_10dqp_186 > ._drawer-content-bottom_1r9lg_10dqp_72 > ._pane-header-wrapper-bottom_1r9lg_10dqp_174:not(#\9){
  padding:0 20px;
  padding:0 20px;
  padding:0 var(--space-l-1RnAcv, 20px);
}
:not(#\9):not(._drawer-closed_1r9lg_10dqp_44) > ._drawer-content-bottom_1r9lg_10dqp_72 > ._pane-header-wrapper-bottom_1r9lg_10dqp_174{
  background-color:#ffffff;
  background-color:#ffffff;
  background-color:var(--color-background-layout-panel-content-1Z_Abk, #ffffff);
}

._content-bottom_1r9lg_10dqp_193:not(#\9){
  padding:0 40px;
  padding:0 40px;
  padding:0 var(--space-scaled-2x-xxxl-3VX7k3, 40px);
}
._drawer-mobile_1r9lg_10dqp_41 > ._drawer-content-bottom_1r9lg_10dqp_72 > ._content-bottom_1r9lg_10dqp_193:not(#\9){
  padding:0 20px;
  padding:0 20px;
  padding:0 var(--space-l-1RnAcv, 20px);
}
._drawer-disable-content-paddings_1r9lg_10dqp_186 > ._drawer-content-bottom_1r9lg_10dqp_72 > ._content-bottom_1r9lg_10dqp_193:not(#\9){
  padding:0;
}

._content-side_1r9lg_10dqp_203:not(#\9){
  flex:auto;
  align-self:flex-start;
  padding:0 20px 20px;
  padding:0 20px 20px;
  padding:0 var(--space-scaled-l-17GFGI, 20px) var(--space-scaled-l-17GFGI, 20px);
  box-sizing:border-box;
  overflow-y:auto;
  position:absolute;
  top:0;
  left:12px;
  left:12px;
  left:var(--space-scaled-s-1sAHT4, 12px);
  right:0;
  height:100%;
}

._header_1r9lg_10dqp_216:not(#\9){
  display:flex;
  flex:auto;
  flex-direction:row;
  align-items:flex-start;
  justify-content:space-between;
  width:100%;
  margin:20px 0;
  margin:20px 0;
  margin:var(--space-scaled-l-17GFGI, 20px) 0;
}

._header-actions_1r9lg_10dqp_226:not(#\9){
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  flex:0 0 auto;
}

._divider_1r9lg_10dqp_233:not(#\9){
  border-right:1px solid #eaeded;
  border-right:1px solid #eaeded;
  border-right:1px solid var(--color-border-divider-default-38loDV, #eaeded);
  margin:0 8px;
  margin:0 8px;
  margin:0 var(--space-scaled-xs-19adjU, 8px);
}

._preference-icon--layout-border_1r9lg_10dqp_238:not(#\9){
  stroke:#232f3e;
  stroke:#232f3e;
  stroke:var(--color-background-home-header-ScmmEP, #232f3e);
  fill:#ffffff;
  fill:#ffffff;
  fill:var(--color-background-container-content-3w9fMz, #ffffff);
}
._preference-icon--layout-background_1r9lg_10dqp_242:not(#\9){
  fill:#f2f3f3;
  fill:#f2f3f3;
  fill:var(--color-background-layout-main-3Bydyi, #f2f3f3);
}
._preference-icon--layout-header_1r9lg_10dqp_245:not(#\9){
  fill:#232f3e;
  fill:#232f3e;
  fill:var(--color-background-home-header-ScmmEP, #232f3e);
}
._preference-icon--border_1r9lg_10dqp_248:not(#\9){
  stroke:#545b64;
  stroke:#545b64;
  stroke:var(--color-text-label-3qT4rX, #545b64);
  fill:#ffffff;
  fill:#ffffff;
  fill:var(--color-background-container-content-3w9fMz, #ffffff);
}
._preference-icon--primary-button_1r9lg_10dqp_252:not(#\9){
  fill:#ec7211;
  fill:#ec7211;
  fill:var(--color-text-accent-NYv9af, #ec7211);
}
._preference-icon--secondary_1r9lg_10dqp_255:not(#\9){
  fill:#545b64;
  fill:#545b64;
  fill:var(--color-text-label-3qT4rX, #545b64);
}
._preference-icon--disabled-element_1r9lg_10dqp_258:not(#\9){
  fill:#d5dbdb;
  fill:#d5dbdb;
  fill:var(--color-background-control-disabled-11nezK, #d5dbdb);
}
._preference-icon--separator_1r9lg_10dqp_261:not(#\9){
  stroke:#d5dbdb;
  stroke:#d5dbdb;
  stroke:var(--color-background-control-disabled-11nezK, #d5dbdb);
}
._preference-icon--focus-text_1r9lg_10dqp_264:not(#\9){
  fill:#545b64;
  fill:#545b64;
  fill:var(--color-text-interactive-default-1oBHNI, #545b64);
}
._tabs-header_14rmt_jbyf9_5:not(#\9){
  margin:0;
  padding:0;
  display:flex;
}

._tabs-header-list_14rmt_jbyf9_11:not(#\9){
  margin:0;
  padding:0;
  display:flex;
  overflow-x:scroll;
  position:relative;
  width:100%;
  pointer-events:none;
  -ms-overflow-style:none;
  scrollbar-width:none;
}
._tabs-header-list_14rmt_jbyf9_11:not(#\9)::-webkit-scrollbar{
  display:none;
}

._pagination-button_14rmt_jbyf9_29:not(#\9){
  margin:12px 0;
  margin:12px 0;
  margin:var(--space-scaled-s-1sAHT4, 12px) 0;
  padding:0 4px;
  padding:0 4px;
  padding:0 var(--space-xxs-10c0Z4, 4px);
  display:flex;
}
._pagination-button-left_14rmt_jbyf9_34:not(#\9){
  border-right:1px solid #d5dbdb;
  border-right:1px solid #d5dbdb;
  border-right:var(--border-divider-section-width-3GX4nr, 1px) solid var(--color-border-control-disabled-Rx1zFC, #d5dbdb);
}
._pagination-button-left-scrollable_14rmt_jbyf9_37:not(#\9){
  box-shadow:5px 0px 4px -3px #eaeded, 1px 0px 0px 0px #eaeded;
  box-shadow:5px 0px 4px -3px #eaeded, 1px 0px 0px 0px #eaeded;
  box-shadow:5px 0px 4px -3px var(--color-border-divider-default-38loDV, #eaeded), 1px 0px 0px 0px var(--color-border-divider-default-38loDV, #eaeded);
}
._pagination-button-right_14rmt_jbyf9_40:not(#\9){
  border-left:1px solid #d5dbdb;
  border-left:1px solid #d5dbdb;
  border-left:var(--border-divider-section-width-3GX4nr, 1px) solid var(--color-border-control-disabled-Rx1zFC, #d5dbdb);
}
._pagination-button-right-scrollable_14rmt_jbyf9_43:not(#\9){
  box-shadow:-5px 0px 4px -3px #eaeded, -1px 0px 0px 0 #eaeded;
  box-shadow:-5px 0px 4px -3px #eaeded, -1px 0px 0px 0 #eaeded;
  box-shadow:-5px 0px 4px -3px var(--color-border-divider-default-38loDV, #eaeded), -1px 0px 0px 0 var(--color-border-divider-default-38loDV, #eaeded);
}

._tabs-tab_14rmt_jbyf9_47:not(#\9){
  list-style:none;
  padding:0;
  flex-shrink:0;
  display:flex;
  max-width:calc(90% - 20px);
  max-width:calc(90% - 20px);
  max-width:calc(90% - var(--space-l-1RnAcv, 20px));
  pointer-events:auto;
}
._tabs-tab_14rmt_jbyf9_47 > button:not(#\9){
  background-color:transparent;
}

._tabs-tab-label_14rmt_jbyf9_59:not(#\9){
  display:flex;
  align-items:center;
  padding:4px 20px;
  padding:4px 20px;
  padding:var(--space-scaled-2x-xxs-wz9S8K, 4px) var(--space-l-1RnAcv, 20px);
  text-align:left;
  position:relative;
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
}

._tabs-tab_14rmt_jbyf9_47:not(#\9):not(:last-child) > a > ._tabs-tab-label_14rmt_jbyf9_59, ._tabs-tab_14rmt_jbyf9_47:not(#\9):not(:last-child) > button > ._tabs-tab-label_14rmt_jbyf9_59{
  margin-right:calc(-1 * 1px);
  margin-right:calc(-1 * 1px);
  margin-right:calc(-1 * var(--border-divider-section-width-3GX4nr, 1px));
  border-right:1px solid #aab7b8;
  border-right:1px solid #aab7b8;
  border-right:var(--border-divider-section-width-3GX4nr, 1px) solid var(--color-border-tabs-2fNNxL, #aab7b8);
}

._tabs-tab-link_14rmt_jbyf9_75:not(#\9){
  position:relative;
  display:flex;
  align-items:stretch;
  text-decoration:none;
  cursor:pointer;
  padding:calc(12px - 1px) 0 12px 0;
  padding:calc(12px - 1px) 0 12px 0;
  padding:calc(var(--space-scaled-s-1sAHT4, 12px) - 1px) 0 var(--space-scaled-s-1sAHT4, 12px) 0;
  margin-top:1px;
  border:1px solid transparent;
  border:1px solid transparent;
  border:var(--border-divider-section-width-3GX4nr, 1px) solid transparent;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  font-weight:700;
  color:#545b64;
  color:#545b64;
  color:var(--color-text-interactive-default-1oBHNI, #545b64);
}
._tabs-tab-link_14rmt_jbyf9_75:not(#\9):hover{
  color:#ec7211;
  color:#ec7211;
  color:var(--color-text-accent-NYv9af, #ec7211);
  text-decoration:none;
}
._tabs-tab-link_14rmt_jbyf9_75:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._tabs-tab-link_14rmt_jbyf9_75[data-awsui-focus-visible=true]:not(#\9):focus{
  z-index:1;
  outline:2px dotted transparent;
  border:1px solid #00a1c9;
  border:1px solid #00a1c9;
  border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-item-focused-2uP71z, #00a1c9);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._tabs-tab-link_14rmt_jbyf9_75[data-awsui-focus-visible=true]:not(#\9):focus > a > ._tabs-tab-label_14rmt_jbyf9_59, ._tabs-tab-link_14rmt_jbyf9_75[data-awsui-focus-visible=true]:not(#\9):focus > button > ._tabs-tab-label_14rmt_jbyf9_59{
  border-right-color:transparent;
}

._tabs-tab_14rmt_jbyf9_47:not(#\9):first-child{
  margin-left:1px;
}
._tabs-tab_14rmt_jbyf9_47:not(#\9):first-child > a > ._tabs-tab-label_14rmt_jbyf9_59, ._tabs-tab_14rmt_jbyf9_47:not(#\9):first-child > button > ._tabs-tab-label_14rmt_jbyf9_59{
  padding-left:calc(20px - 1px);
  padding-left:calc(20px - 1px);
  padding-left:calc(var(--space-l-1RnAcv, 20px) - 1px);
}

._tabs-tab_14rmt_jbyf9_47:not(#\9):last-child{
  margin-right:1px;
}
._tabs-tab_14rmt_jbyf9_47:not(#\9):last-child > a > ._tabs-tab-label_14rmt_jbyf9_59, ._tabs-tab_14rmt_jbyf9_47:not(#\9):last-child > button > ._tabs-tab-label_14rmt_jbyf9_59{
  padding-right:calc(20px - 1px);
  padding-right:calc(20px - 1px);
  padding-right:calc(var(--space-l-1RnAcv, 20px) - 1px);
}

._tabs-tab-disabled_14rmt_jbyf9_122:not(#\9), ._tabs-tab-disabled_14rmt_jbyf9_122:not(#\9):hover{
  cursor:default;
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
  font-weight:400;
}

._tabs-tab-link_14rmt_jbyf9_75:not(#\9):not(._tabs-tab-disabled_14rmt_jbyf9_122):before{
  content:"";
  position:absolute;
  left:0;
  width:100%;
  bottom:calc(-1 * 1px);
  bottom:calc(-1 * 1px);
  bottom:calc(-1 * var(--border-divider-section-width-3GX4nr, 1px));
  height:2px;
  height:2px;
  height:var(--border-active-width-TgsrkU, 2px);
  border-radius:0px;
  border-radius:0px;
  border-radius:var(--border-active-radius-Z3aihh, 0px);
  background:#16191f;
  background:#16191f;
  background:var(--color-text-interactive-hover-18qOom, #16191f);
  opacity:0;
  transition:opacity var(--awsui-motion-duration-transition-quick) var(--awsui-motion-easing-transition-quick);
}
@media (prefers-reduced-motion: reduce){
  ._tabs-tab-link_14rmt_jbyf9_75:not(#\9):not(._tabs-tab-disabled_14rmt_jbyf9_122):before{
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._tabs-tab-link_14rmt_jbyf9_75:not(#\9):not(._tabs-tab-disabled_14rmt_jbyf9_122):before{
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._tabs-tab-active_14rmt_jbyf9_151:not(#\9):not(._tabs-tab-disabled_14rmt_jbyf9_122){
  color:#ec7211;
  color:#ec7211;
  color:var(--color-text-accent-NYv9af, #ec7211);
}
._tabs-tab-active_14rmt_jbyf9_151:not(#\9):not(._tabs-tab-disabled_14rmt_jbyf9_122):before{
  opacity:1;
}

._tabs-header--variant-default_14rmt_jbyf9_158:not(#\9){
  border-bottom:1px solid #aab7b8;
  border-bottom:1px solid #aab7b8;
  border-bottom:var(--border-divider-section-width-3GX4nr, 1px) solid var(--color-border-tabs-2fNNxL, #aab7b8);
  border-top:1px solid transparent;
  border-top:1px solid transparent;
  border-top:var(--border-divider-section-width-3GX4nr, 1px) solid transparent;
}

._tabs_14rmt_jbyf9_5:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:block;
  width:100%;
}

._tabs-content-wrapper_14rmt_jbyf9_179:not(#\9){
  padding:16px 0;
  padding:16px 0;
  padding:var(--space-scaled-m-p3tAne, 16px) 0;
}

._tabs-content_14rmt_jbyf9_179:not(#\9){
  display:none;
}

._tabs-content-active_14rmt_jbyf9_187:not(#\9){
  display:block;
}
._undo-button_brdst_12utu_11:not(#\9){
  color:#0073bb;
  color:#0073bb;
  color:var(--color-text-link-default-Bc0LvG, #0073bb);
  text-decoration:underline;
  cursor:pointer;
}
._undo-button_brdst_12utu_11:not(#\9):focus{
  outline:none;
}
._undo-button_brdst_12utu_11[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:thin dotted;
  outline:5px auto -webkit-focus-ring-color;
  outline-offset:2px;
  outline-color:#00a1c9;
  outline-color:#00a1c9;
  outline-color:var(--color-border-item-focused-2uP71z, #00a1c9);
}
._text-content_6absk_1ub0u_4:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}
._text-content_6absk_1ub0u_4 h1:not(#\9),
._text-content_6absk_1ub0u_4 h2:not(#\9),
._text-content_6absk_1ub0u_4 h3:not(#\9),
._text-content_6absk_1ub0u_4 h4:not(#\9),
._text-content_6absk_1ub0u_4 h5:not(#\9),
._text-content_6absk_1ub0u_4 p:not(#\9){
  font-family:inherit;
  font-weight:normal;
  text-decoration:none;
  margin:0;
  padding:4px 0;
  padding:4px 0;
  padding:var(--space-xxs-10c0Z4, 4px) 0;
}
._text-content_6absk_1ub0u_4 h1:not(#\9),
._text-content_6absk_1ub0u_4 h2:not(#\9),
._text-content_6absk_1ub0u_4 h3:not(#\9),
._text-content_6absk_1ub0u_4 h4:not(#\9),
._text-content_6absk_1ub0u_4 h5:not(#\9){
  color:#16191f;
  color:#16191f;
  color:var(--color-text-heading-default-2S6vEC, #16191f);
}
._text-content_6absk_1ub0u_4 h1:not(#\9){
  font-size:28px;
  font-size:28px;
  font-size:var(--font-heading-xl-size-2ac18Z, 28px);
  line-height:36px;
  line-height:36px;
  line-height:var(--font-heading-xl-lineHeight-3i5g66, 36px);
}
._text-content_6absk_1ub0u_4 h2:not(#\9){
  font-size:18px;
  font-size:18px;
  font-size:var(--font-heading-l-size-3IMO0y, 18px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-heading-l-lineHeight-2Vn4pu, 22px);
}
._text-content_6absk_1ub0u_4 h3:not(#\9){
  font-size:18px;
  font-size:18px;
  font-size:var(--font-heading-m-size-3IMO0y, 18px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-heading-m-lineHeight-2Vn4pu, 22px);
}
._text-content_6absk_1ub0u_4 h4:not(#\9){
  font-size:16px;
  font-size:16px;
  font-size:var(--font-heading-s-size-x2Xc-V, 16px);
  line-height:20px;
  line-height:20px;
  line-height:var(--font-heading-s-lineHeight-8Y2p4W, 20px);
}
._text-content_6absk_1ub0u_4 h5:not(#\9){
  font-size:16px;
  font-size:16px;
  font-size:var(--font-heading-xs-size-x2Xc-V, 16px);
  line-height:20px;
  line-height:20px;
  line-height:var(--font-heading-xs-lineHeight-8Y2p4W, 20px);
}
._text-content_6absk_1ub0u_4 h2:not(#\9),
._text-content_6absk_1ub0u_4 h4:not(#\9),
._text-content_6absk_1ub0u_4 b:not(#\9),
._text-content_6absk_1ub0u_4 strong:not(#\9){
  font-weight:700;
}
._text-content_6absk_1ub0u_4 p:not(#\9){
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:inherit;
}
._text-content_6absk_1ub0u_4 small:not(#\9){
  display:inline-block;
  font-size:12px;
  font-size:12px;
  font-size:var(--font-body-s-size-3NP7Rr, 12px);
  line-height:16px;
  line-height:16px;
  line-height:var(--font-body-s-lineHeight-x2Xc-V, 16px);
  color:#687078;
  color:#687078;
  color:var(--color-text-small-2ZkiuH, #687078);
}
._text-content_6absk_1ub0u_4 small a:not(#\9){
  font-size:inherit;
  font-weight:700;
  letter-spacing:0.01em;
}
._text-content_6absk_1ub0u_4 code:not(#\9),
._text-content_6absk_1ub0u_4 pre:not(#\9),
._text-content_6absk_1ub0u_4 samp:not(#\9){
  font-family:Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;
  font-family:Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;
  font-family:var(--font-fontFamily-monospace-1bAn4w, Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace);
}
._text-content_6absk_1ub0u_4 code:not(#\9){
  font-size:12px;
  font-size:12px;
  font-size:var(--font-body-s-size-3NP7Rr, 12px);
  line-height:16px;
  line-height:16px;
  line-height:var(--font-body-s-lineHeight-x2Xc-V, 16px);
}
._text-content_6absk_1ub0u_4 a:not(#\9){
  color:#0073bb;
  color:#0073bb;
  color:var(--color-text-link-default-Bc0LvG, #0073bb);
  text-decoration:none;
}
._text-content_6absk_1ub0u_4 a:not(#\9):hover, ._text-content_6absk_1ub0u_4 a:not(#\9):active, ._text-content_6absk_1ub0u_4 a:not(#\9):focus{
  text-decoration:underline;
}
._text-content_6absk_1ub0u_4 a:not(#\9):focus{
  outline:thin dotted;
  outline:5px auto -webkit-focus-ring-color;
  outline-offset:2px;
}
._text-content_6absk_1ub0u_4 ul:not(#\9),
._text-content_6absk_1ub0u_4 ol:not(#\9){
  padding-left:20px;
  padding-left:20px;
  padding-left:var(--space-l-1RnAcv, 20px);
  margin:4px 0;
  margin:4px 0;
  margin:var(--space-xxs-10c0Z4, 4px) 0;
  list-style-position:outside;
}
._text-content_6absk_1ub0u_4 ul + ul:not(#\9),
._text-content_6absk_1ub0u_4 ul + ol:not(#\9),
._text-content_6absk_1ub0u_4 ul > li + ul:not(#\9),
._text-content_6absk_1ub0u_4 ul > li + li:not(#\9),
._text-content_6absk_1ub0u_4 ul > li + ol:not(#\9),
._text-content_6absk_1ub0u_4 ul > li > ul:not(#\9),
._text-content_6absk_1ub0u_4 ul > li > ol:not(#\9),
._text-content_6absk_1ub0u_4 ol + ul:not(#\9),
._text-content_6absk_1ub0u_4 ol + ol:not(#\9),
._text-content_6absk_1ub0u_4 ol > li + ul:not(#\9),
._text-content_6absk_1ub0u_4 ol > li + li:not(#\9),
._text-content_6absk_1ub0u_4 ol > li + ol:not(#\9),
._text-content_6absk_1ub0u_4 ol > li > ul:not(#\9),
._text-content_6absk_1ub0u_4 ol > li > ol:not(#\9){
  padding-top:4px;
  padding-top:4px;
  padding-top:var(--space-xxs-10c0Z4, 4px);
}
._text-content_6absk_1ub0u_4 ul > li > ul:not(#\9),
._text-content_6absk_1ub0u_4 ul > li > ol:not(#\9),
._text-content_6absk_1ub0u_4 ol > li > ul:not(#\9),
._text-content_6absk_1ub0u_4 ol > li > ol:not(#\9){
  margin:0;
}
._textarea_qk1j1_2hbow_7:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  resize:auto;
  cursor:text;
  white-space:pre-wrap;
  padding:4px 8px;
  padding:4px 8px;
  padding:var(--space-scaled-xxs-wz9S8K, 4px) var(--space-xs-3JTBUA, 8px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  max-width:100%;
  width:100%;
  display:block;
  box-sizing:border-box;
  background-color:#ffffff;
  background-color:#ffffff;
  background-color:var(--color-background-input-default-528DLz, #ffffff);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  border:1px solid #aab7b8;
  border:1px solid #aab7b8;
  border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-input-default-27WVST, #aab7b8);
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
}
._textarea_qk1j1_2hbow_7._textarea-readonly_qk1j1_2hbow_30:not(#\9){
  background-color:#ffffff;
  background-color:#ffffff;
  background-color:var(--color-background-input-default-528DLz, #ffffff);
  border:1px solid #eaeded;
  border:1px solid #eaeded;
  border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-input-disabled-1QUbGm, #eaeded);
}
._textarea_qk1j1_2hbow_7:not(#\9)::-webkit-input-placeholder{
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-input-placeholder-TVd7uP, #aab7b8);
  font-style:italic;
  opacity:1;
}
._textarea_qk1j1_2hbow_7:not(#\9)::placeholder{
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-input-placeholder-TVd7uP, #aab7b8);
  font-style:italic;
  opacity:1;
}
._textarea_qk1j1_2hbow_7:not(#\9):-ms-input-placeholder{
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-input-placeholder-TVd7uP, #aab7b8);
  font-style:italic;
}
._textarea_qk1j1_2hbow_7:not(#\9):focus{
  outline:2px dotted transparent;
  border:1px solid #00a1c9;
  border:1px solid #00a1c9;
  border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-item-focused-2uP71z, #00a1c9);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--border-field-radius-TgsrkU, 2px);
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._textarea_qk1j1_2hbow_7:not(#\9):disabled{
  background-color:#eaeded;
  background-color:#eaeded;
  background-color:var(--color-background-input-disabled-38loDV, #eaeded);
  border:1px solid #eaeded;
  border:1px solid #eaeded;
  border:var(--border-field-width-3GX4nr, 1px) solid var(--color-border-input-disabled-1QUbGm, #eaeded);
  color:#879596;
  color:#879596;
  color:var(--color-text-input-disabled-3ki3SQ, #879596);
  cursor:auto;
  cursor:default;
}
._textarea_qk1j1_2hbow_7:not(#\9):invalid{
  box-shadow:none;
}
._textarea_qk1j1_2hbow_7._textarea-invalid_qk1j1_2hbow_59:not(#\9){
  color:#d13212;
  color:#d13212;
  color:var(--color-text-status-error-1jj2sL, #d13212);
  border-color:#d13212;
  border-color:#d13212;
  border-color:var(--color-text-status-error-1jj2sL, #d13212);
  padding-left:calc( 8px - (4px - 1px) );
  padding-left:calc( 8px - (4px - 1px) );
  padding-left:calc( var(--space-xs-3JTBUA, 8px) - (var(--border-invalid-width-SMAYHq, 4px) - var(--border-field-width-3GX4nr, 1px)) );
  border-left-width:4px;
  border-left-width:4px;
  border-left-width:var(--border-invalid-width-SMAYHq, 4px);
}
._tutorial-panel_1oin5_15r7y_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  padding:0 20px 16px;
  padding:0 20px 16px;
  padding:0 var(--space-l-1RnAcv, 20px) var(--space-m-208vQh, 16px);
}
._content-enter_ig8mp_npqso_3:not(#\9){
  -webkit-animation:_awsui-motion-fade-in_ig8mp_npqso_1 180ms ease-out;
          animation:_awsui-motion-fade-in_ig8mp_npqso_1 180ms ease-out;
  animation:_awsui-motion-fade-in_ig8mp_npqso_1 180ms ease-out;
  -webkit-animation:_awsui-motion-fade-in_ig8mp_npqso_1 var(--motion-duration-show-paced-2crOWV, 180ms) var(--motion-easing-show-paced-25z_bd, ease-out);
          animation:_awsui-motion-fade-in_ig8mp_npqso_1 var(--motion-duration-show-paced-2crOWV, 180ms) var(--motion-easing-show-paced-25z_bd, ease-out);
}
@-webkit-keyframes _awsui-motion-fade-in_ig8mp_npqso_1{
  from{
    opacity:0.2;
  }
  to{
    opacity:1;
  }
}
@keyframes _awsui-motion-fade-in_ig8mp_npqso_1{
  from{
    opacity:0.2;
  }
  to{
    opacity:1;
  }
}
@media (prefers-reduced-motion: reduce){
  ._content-enter_ig8mp_npqso_3:not(#\9){
    -webkit-animation:none;
            animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._content-enter_ig8mp_npqso_3:not(#\9){
  -webkit-animation:none;
          animation:none;
  transition:none;
}

._tutorial-list_ig8mp_npqso_25:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  padding:0;
  margin:0;
}

._tutorial-box_ig8mp_npqso_37:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  list-style:none;
  border:1px solid #aab7b8;
  border:1px solid #aab7b8;
  border:1px solid var(--color-border-control-default-27WVST, #aab7b8);
  padding:16px;
  padding:16px;
  padding:var(--space-m-208vQh, 16px);
}
._tutorial-box_ig8mp_npqso_37:not(#\9):not(:first-child){
  margin-top:20px;
  margin-top:20px;
  margin-top:var(--space-l-1RnAcv, 20px);
}

._tutorial-box-title_ig8mp_npqso_53:not(#\9){
  display:flex;
  align-items:flex-start;
  justify-content:space-between;
}

._tutorial-description-plaintext_ig8mp_npqso_59:not(#\9){
  white-space:pre-line;
}
._tutorial-description_ig8mp_npqso_59 ul:not(#\9){
  list-style-type:disc;
}

._expandable-section_ig8mp_npqso_70:not(#\9){
  display:none;
  margin-top:16px;
  margin-top:16px;
  margin-top:var(--space-m-208vQh, 16px);
}
._expandable-section_ig8mp_npqso_70._expanded_ig8mp_npqso_74:not(#\9){
  display:block;
}

._button-wrapper_ig8mp_npqso_78:not(#\9){
  margin:calc(-1 * 4px - 1px);
  margin:calc(-1 * 4px - 1px);
  margin:calc(-1 * var(--space-xxs-10c0Z4, 4px) - var(--border-field-width-3GX4nr, 1px));
}

._download-link_ig8mp_npqso_110:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  text-decoration:none;
  display:inline;
  white-space:inherit;
  font-weight:700;
  letter-spacing:0.01em;
  color:#545b64;
  color:#545b64;
  color:var(--color-text-body-secondary-1oBHNI, #545b64);
}
._download-link_ig8mp_npqso_110:not(#\9):hover{
  cursor:pointer;
}
._download-link_ig8mp_npqso_110:not(#\9):focus{
  outline:none;
}
._download-link_ig8mp_npqso_110:not(#\9):active, ._download-link_ig8mp_npqso_110:not(#\9):focus, ._download-link_ig8mp_npqso_110:not(#\9):hover{
  text-decoration:underline;
}
._download-link_ig8mp_npqso_110[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:thin dotted;
  outline:5px auto -webkit-focus-ring-color;
  outline-offset:2px;
  outline-color:#00a1c9;
  outline-color:#00a1c9;
  outline-color:var(--color-border-item-focused-2uP71z, #00a1c9);
}
._tutorial-list_1u70p_1uav8_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  padding:0;
  margin:0;
}

._tutorial-title_1u70p_1uav8_15:not(#\9){
  display:flex;
  align-items:flex-start;
  margin-left:calc(-1 * 4px);
  margin-left:calc(-1 * 4px);
  margin-left:calc(-1 * var(--space-xxs-10c0Z4, 4px));
  padding-bottom:2px;
  padding-bottom:2px;
  padding-bottom:var(--space-xxxs-2Fa4lw, 2px);
}

._task_1u70p_1uav8_22:not(#\9){
  list-style:none;
  padding-top:0;
}
._task_1u70p_1uav8_22:not(#\9):not(:first-child){
  margin-top:24px;
  margin-top:24px;
  margin-top:var(--space-xl-3Pai2K, 24px);
}

._task-title_1u70p_1uav8_30:not(#\9){
  display:flex;
  align-items:flex-start;
}

._current-task_1u70p_1uav8_35:not(#\9){
  font-weight:700;
}

._successful-task_1u70p_1uav8_39:not(#\9){
  color:#1d8102;
  color:#1d8102;
  color:var(--color-text-status-success-CuCgtA, #1d8102);
}

._congratulation-message_1u70p_1uav8_43:not(#\9){
  display:flex;
  font-size:16px;
  font-size:16px;
  font-size:var(--font-heading-s-size-x2Xc-V, 16px);
  line-height:20px;
  line-height:20px;
  line-height:var(--font-heading-s-lineHeight-8Y2p4W, 20px);
  color:#1d8102;
  color:#1d8102;
  color:var(--color-text-status-success-CuCgtA, #1d8102);
  margin-top:4px;
  margin-top:4px;
  margin-top:var(--space-xxs-10c0Z4, 4px);
}

._plaintext-congratulation-description_1u70p_1uav8_51:not(#\9){
  white-space:pre-line;
}

._divider_1u70p_1uav8_55:not(#\9){
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid var(--color-border-divider-default-38loDV, #eaeded);
}

._step-list_1u70p_1uav8_59:not(#\9){
  list-style:none;
  padding:0;
}

._step_1u70p_1uav8_59:not(#\9):not(:first-child){
  padding-top:4px;
  padding-top:4px;
  padding-top:var(--space-xxs-10c0Z4, 4px);
}

._expandable-section-wrapper_1u70p_1uav8_68:not(#\9){
  width:100%;
}
._root_1xupv_1j727_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  -webkit-font-feature-settings:normal;
          font-feature-settings:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  -webkit-hyphens:none;
          hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  -moz-tab-size:8;
       tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:14px;
  font-size:14px;
  font-size:var(--font-body-m-size-1N7V2o, 14px);
  line-height:22px;
  line-height:22px;
  line-height:var(--font-body-m-lineHeight-2Vn4pu, 22px);
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}

._wizard_1xupv_1j727_13:not(#\9){
  display:flex;
}

._form_1xupv_1j727_17:not(#\9){
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
  width:100%;
}

._navigation_1xupv_1j727_33:not(#\9){
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-disabled-TVd7uP, #aab7b8);
  display:inline-block;
  width:200px;
  min-width:200px;
  margin-right:calc(2 * 40px);
  margin-right:calc(2 * 40px);
  margin-right:calc(2 * var(--space-xxxl-25ySnK, 40px));
  padding-top:4px;
  padding-top:4px;
  padding-top:var(--space-xxs-10c0Z4, 4px);
}

._hidden_1xupv_1j727_42:not(#\9){
  display:none;
}

._navigation-links_1xupv_1j727_46:not(#\9){
  list-style:none;
  padding-left:0;
  margin:0;
}
._navigation-links_1xupv_1j727_46 > li:not(#\9){
  padding-bottom:16px;
  padding-bottom:16px;
  padding-bottom:var(--space-scaled-m-p3tAne, 16px);
  padding-top:0;
}
._navigation-links_1xupv_1j727_46 > li:not(#\9):not(:first-child){
  margin-top:16px;
  margin-top:16px;
  margin-top:var(--space-scaled-m-p3tAne, 16px);
}
._navigation-links_1xupv_1j727_46 > li:not(#\9):not(:last-child){
  border-bottom:1px solid #d5dbdb;
  border-bottom:1px solid #d5dbdb;
  border-bottom:var(--border-divider-list-width-3GX4nr, 1px) solid var(--color-border-layout-11nezK, #d5dbdb);
}

._navigation-link-active_1xupv_1j727_68:not(#\9){
  font-weight:700;
  color:#16191f;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
}

._navigation-link-disabled_1xupv_1j727_73:not(#\9){
  color:#aab7b8;
  color:#aab7b8;
  color:var(--color-text-control-disabled-KGAbe7, #aab7b8);
}
